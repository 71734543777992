import {
    DialogContent,
    Dialog,
    DialogTitle,
    Grid,
    IconButton,
    Box,
    FormHelperText,
    TextField,
    Select, MenuItem, Button, DialogActions
} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import React from "react";
import {EmployeeInformation} from "../../molecules/EmployeeInformation";
import {useFormik} from "formik";
import * as Yup from "yup";
import {DateTime} from "luxon";
import {useCreateEmployeeVacationMutation} from "./createEmployeeVacation.generated";
import {toast} from "react-toastify";
import {EmployeeVacation} from "../../../generated/graphql";
import {useEditEmployeeVacationMutation} from "./editEmployeeVacation.generated";

export interface CreateEmployeeVacationDialogProps {
    userId: number;
    employeeVacation?: EmployeeVacation;
    open?: boolean;
    onClose?: () => void;
}

export const EmployeeVacationManagementDialog = ({
                                                     userId,
                                                     employeeVacation,
                                                     open,
                                                     onClose
                                                 }: CreateEmployeeVacationDialogProps) => {
    const [createEmployeeVacationMutation] = useCreateEmployeeVacationMutation();
    const [editEmployeeVacationMutation] = useEditEmployeeVacationMutation();

    const createEmployeeVacation = async (startYear: number, vacationDays: number, remainingVacationDays: number, automaticTransfer: boolean, comment: string | undefined) => {
        const {data, errors} = await createEmployeeVacationMutation({
            variables: {
                userId: userId,
                startYear: startYear,
                vacationMinutes: vacationDays * 8 * 60,
                remainingVacationMinutes: remainingVacationDays * 8 * 60,
                automaticTransfer: automaticTransfer,
                comment: comment
            }
        });

        if (data) {
            toast.success('Urlaubsplan erfolgreich erstellt');
            if (onClose) {
                onClose();
            }
        } else if (errors) {
            const error = errors[0];
            const reason = error.extensions?.['reason'];

            switch (reason) {
                case "AuthenticationRequired":
                    toast.error('Melde dich erneut an und versuche es nochmal.');
                    return;
                case "PermissionDenied":
                    toast.error('Du kannst für diesen Mitarbeiter keinen Urlaubsplan erstellen');
                    return;
                case "YearAlreadyExists":
                    toast.error("Für dieses Jahr existiert bereits ein Urlaubsplan");
                    return;
            }
        } else {
            toast.error('Ein unerwarteter Fehler ist aufgetreten. Bitte versuche es später erneut.');
        }
    };

    const editEmployeeVacation = async (startYear: number, vacationDays: number, remainingVacationDays: number, automaticTransfer: boolean, comment: string | undefined) => {
        const {data, errors} = await editEmployeeVacationMutation({
            variables: {
                userId: userId,
                startYear: startYear,
                vacationMinutes: vacationDays * 8 * 60,
                remainingVacationMinutes: remainingVacationDays * 8 * 60,
                automaticTransfer: automaticTransfer,
                comment: comment
            }
        });

        if (data) {
            toast.success('Urlaubsplan erfolgreich editiert');
            if (onClose) {
                onClose();
            }
        } else if (errors) {
            const error = errors[0];
            const reason = error.extensions?.['reason'];

            switch (reason) {
                case "AuthenticationRequired":
                    toast.error('Melde dich erneut an und versuche es nochmal.');
                    return;
                case "PermissionDenied":
                    toast.error('Du kannst für diesen Mitarbeiter keinen Urlaubsplan erstellen');
                    return;
            }
        } else {
            toast.error('Ein unerwarteter Fehler ist aufgetreten. Bitte versuche es später erneut.');
        }
    };

    const {handleChange, values, handleSubmit, handleBlur, touched, errors} = useFormik({
        initialValues: {
            startYear: employeeVacation?.startYear ?? DateTime.now().year,
            vacationDays: (employeeVacation?.vacationMinutes ?? 0) / 60 / 8,
            remainingVacationDays: (employeeVacation?.remainingVacationMinutes ?? 0) / 60 / 8,
            automaticTransfer: employeeVacation ? (employeeVacation.automaticTransfer ? 'true' : 'false') : 'true',
            comment: employeeVacation?.comment ?? ''
        },
        validationSchema: Yup.object({
            startYear: Yup.number().required('Bitte gib an, ab wann die Urlaubstage gültig sind').min(DateTime.now().year, 'Das Jahr muss größer als das aktuelle Jahr sein'),
            vacationDays: Yup.number().required('Bitte gib an, wie viele Urlaubstage der Mitarbeiter hat').min(0, 'Die Anzahl der Urlaubstage muss größer als 0 sein'),
            remainingVacationDays: Yup.number().required('Bitte gib an, wie viele Urlaubstage der Mitarbeiter noch hat').min(0, 'Die Anzahl der Urlaubstage muss größer als 0 sein'),
            automaticTransfer: Yup.boolean().required('Bitte gib an, ob die Urlaubstage automatisch in das Folgejahr übertragen werden'),
            comment: Yup.string().optional()
        }),
        onSubmit: async (values) => {
            if(!!employeeVacation) {
                editEmployeeVacation(values.startYear, values.vacationDays, values.remainingVacationDays, values.automaticTransfer === 'true', values.comment);
            } else {
                createEmployeeVacation(values.startYear, values.vacationDays, values.remainingVacationDays, values.automaticTransfer === 'true', values.comment);
            }
        }
    });

    return <Dialog
        open={open ?? false}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        onClose={onClose}
        maxWidth="md"
    >
        <Grid container justifyContent='space-between'>
            <Grid item>
                <DialogTitle id="alert-dialog-title">
                    Urlaubsplan erstellen
                </DialogTitle>
            </Grid>
            <Grid item sx={{marginRight: '1rem', marginTop: '0.5rem'}}>
                <IconButton onClick={onClose}>
                    <FontAwesomeIcon icon={solid('xmark')} color='#333333' size="lg"/>
                </IconButton>
            </Grid>
        </Grid>
        <DialogContent>
            <Box component='form' onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <EmployeeInformation label='Gültig ab' xs={12} md={12}>
                        <TextField id="startYear"
                                   name='startYear'
                                   placeholder="Gültig ab"
                                   type="number"
                                   value={values.startYear}
                                   error={!!(errors.startYear && touched.startYear)}
                                   onChange={handleChange}
                                   onBlur={handleBlur}
                                   variant='outlined'
                                   fullWidth
                                   size='small'
                                   disabled={!!employeeVacation}
                        />
                        {errors.startYear && (
                            <FormHelperText error={!!(errors.startYear && touched.startYear)}>
                                {errors.startYear}
                            </FormHelperText>
                        )}
                    </EmployeeInformation>
                    <EmployeeInformation label='Tage Jahresurlaub' xs={12} md={12}>
                        <TextField id="vacationDays"
                                   name='vacationDays'
                                   type="number"
                                   placeholder="30"
                                   value={values.vacationDays}
                                   error={!!(errors.vacationDays && touched.vacationDays)}
                                   onChange={handleChange}
                                   onBlur={handleBlur}
                                   variant='outlined'
                                   fullWidth
                                   size='small'
                        />
                        {errors.vacationDays && (
                            <FormHelperText error={!!(errors.vacationDays && touched.vacationDays)}>
                                {errors.vacationDays}
                            </FormHelperText>
                        )}
                    </EmployeeInformation>
                    <EmployeeInformation label='Davon verbleibende Urlaubstage' xs={12} md={12}>
                        <TextField id="remainingVacationDays"
                                   name='remainingVacationDays'
                                   type="number"
                                   placeholder="30"
                                   value={values.remainingVacationDays}
                                   error={!!(errors.remainingVacationDays && touched.remainingVacationDays)}
                                   onChange={handleChange}
                                   onBlur={handleBlur}
                                   variant='outlined'
                                   fullWidth
                                   size='small'
                        />
                        {errors.remainingVacationDays && (
                            <FormHelperText error={!!(errors.remainingVacationDays && touched.remainingVacationDays)}>
                                {errors.remainingVacationDays}
                            </FormHelperText>
                        )}
                    </EmployeeInformation>
                    <EmployeeInformation label='Übertrag in das Folgejahr' xs={12} md={12}>
                        <Select
                            id='automaticTransfer'
                            name='automaticTransfer'
                            labelId="automatic-transfer-label"
                            size='small'
                            placeholder='Übertrag in das Folgejahr'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.automaticTransfer}
                            error={!!(errors.automaticTransfer && touched.automaticTransfer)}
                            fullWidth
                            displayEmpty
                        >
                            <MenuItem value='true'>Ja</MenuItem>
                            <MenuItem value='false'>Nein</MenuItem>
                        </Select>
                        {errors.automaticTransfer && (
                            <FormHelperText error={!!(errors.automaticTransfer && touched.automaticTransfer)}>
                                {errors.automaticTransfer}
                            </FormHelperText>
                        )}
                    </EmployeeInformation>
                    <EmployeeInformation label='Kommentar' xs={12} md={12}>
                        <TextField id="comment"
                                   name='comment'
                                   placeholder="Kommentar"
                                   value={values.comment}
                                   error={!!(errors.comment && touched.comment)}
                                   onChange={handleChange}
                                   onBlur={handleBlur}
                                   variant='outlined'
                                   fullWidth
                                   multiline
                                   minRows={2}
                                   size='small'
                        />
                        {errors.comment && (
                            <FormHelperText error={!!(errors.comment && touched.comment)}>
                                {errors.comment}
                            </FormHelperText>
                        )}
                    </EmployeeInformation>
                </Grid>
                <Box sx={{display: 'flex', justifyContent: 'end'}}>
                    <DialogActions sx={{gap: 2, mt: 2}}>
                        <Button variant='contained' color='inherit' type='button'>ABBRECHEN</Button>
                        <Button variant='contained' type='submit'>SPEICHERN</Button>
                    </DialogActions>
                </Box>
            </Box>
        </DialogContent>
    </Dialog>;
};
