import {Box, Button, Grid, Stack, SxProps, Theme, Typography} from "@mui/material";
import {EmployeeFragment} from "../../../../fragments/employee.generated";
import React, {useEffect, useState} from "react";
import {useListEmployeeVacationsLazyQuery} from "./listEmployeeVacations.generated";
import {
    EmployeeVacationManagementDialog
} from "../../../../components/organisms/EmployeeVacationManagementDialog/EmployeeVacationManagementDialog";
import {EmployeeVacationTable} from "../../../../components/molecules/EmployeeVacationTable/EmployeeVacationTable";
import {EmployeeVacationTransferTable} from "../../../../components/molecules/EmployeeVacationTransferTable";
import {
    CreateEmployeeTransferDialog
} from "../../../../components/organisms/CreateEmployeeTransferDialog/CreateEmployeeTransferDialog";
import flagIcon from "../../../../images/flag_icon.png";
import {useFlag} from "@unleash/proxy-client-react";
import vacationPlane from "../../../../images/vacation_plane.png";
import {DateTime} from "luxon";
import {
    EmployeeVacationDialog,
    EmployeeVacationFormValues
} from "../../../../components/organisms/EmployeeVacationDialog/EmployeeVacationDialog";
import {
    useCreateEmployeeVacationMutation
} from "../../../../components/organisms/EmployeeVacationManagementDialog/createEmployeeVacation.generated";
import {toast} from "react-toastify";
import {
    useEditEmployeeVacationMutation
} from "../../../../components/organisms/EmployeeVacationManagementDialog/editEmployeeVacation.generated";

export const EmployeeVacation = ({employee}: { employee?: EmployeeFragment }) => {
    const newVacationManagement = useFlag('new-vacation-management');

    if (newVacationManagement) {
        return <EmployeeVacationNew employee={employee}/>;
    } else {
        return <EmployeeVacationOld employee={employee}/>;
    }
};

const gridItemStyle: SxProps<Theme> = (theme) => ({
    paddingTop: '0 !important',
    alignItems: 'center'
});

const EmployeeVacationNew = ({employee}: { employee?: EmployeeFragment }) => {
    const [createDialogOpen, setCreateDialogOpen] = useState(false);
    const [editDialogOpen, setEditDialogOpen] = useState(false);

    const [createEmployeeVacationMutation] = useCreateEmployeeVacationMutation();
    const [editEmployeeVacationMutation] = useEditEmployeeVacationMutation();

    const [listEmployeeVacationsQuery, {data, refetch}] = useListEmployeeVacationsLazyQuery();

    const employeeVacations = data?.employee.vacation.listEmployeeVacations;
    const transferRows = data?.employee.vacation.listEmployeeVacationTransfers;

    const currentYearVacationRow = employeeVacations?.find(vacation => vacation.startYear === new Date().getFullYear());
    const transferVacationPreviousYear = transferRows?.find(vacation => vacation.year === new Date().getFullYear() - 1);

    const isTransferValid = transferVacationPreviousYear?.expirationDate ? DateTime.fromISO(transferVacationPreviousYear.expirationDate).plus({day: 1}) > DateTime.now() : false;
    const currentYearRemaining = (currentYearVacationRow?.remainingVacationMinutes ?? 0) + (isTransferValid ? (transferVacationPreviousYear?.vacationMinutes ?? 0) : 0);

    const pastEntries = employeeVacations?.filter(vacation => vacation.startYear < new Date().getFullYear());

    useEffect(() => {
        if (employee) {
            listEmployeeVacationsQuery({
                variables: {
                    userId: employee.userId
                },
                fetchPolicy: 'no-cache'
            });
        }
    }, [employee, listEmployeeVacationsQuery]);

    const onCreate = async (values: EmployeeVacationFormValues) => {
        if (!employee) {
            return;
        }

        const {data, errors} = await createEmployeeVacationMutation({
            variables: {
                userId: employee.userId,
                startYear: values.startYear,
                vacationMinutes: values.vacationDays * 8 * 60,
                remainingVacationMinutes: values.remainingVacationDays * 8 * 60,
                transferVacationMinutes: values.transferVacationDays > 0 ? values.transferVacationDays * 8 * 60 : undefined,
                automaticTransfer: values.automaticTransfer === 'true'
            }
        });

        if (data) {
            toast.success('Urlaubsplan erfolgreich erstellt');
            setCreateDialogOpen(false);
            refetch();
        } else if (errors) {
            const error = errors[0];
            const reason = error.extensions?.['reason'];

            switch (reason) {
                case "AuthenticationRequired":
                    toast.error('Melde dich erneut an und versuche es nochmal.');
                    return;
                case "PermissionDenied":
                    toast.error('Du kannst für diesen Mitarbeiter keinen Urlaubsplan erstellen');
                    return;
                case "YearAlreadyExists":
                    toast.error("Für dieses Jahr existiert bereits ein Urlaubsplan");
                    return;
            }
        } else {
            toast.error('Ein unerwarteter Fehler ist aufgetreten. Bitte versuche es später erneut.');
        }
    }

    const onEdit = async (values: EmployeeVacationFormValues) => {
        if (!employee) {
            return;
        }

        const {data, errors} = await editEmployeeVacationMutation({
            variables: {
                userId: employee.userId,
                startYear: values.startYear,
                vacationMinutes: values.vacationDays * 8 * 60,
                remainingVacationMinutes: values.remainingVacationDays * 8 * 60,
                transferVacationMinutes: values.transferVacationDays > 0 ? values.transferVacationDays * 8 * 60 : undefined,
                automaticTransfer: values.automaticTransfer === 'true'
            }
        });

        if (data) {
            toast.success('Urlaubsplan erfolgreich editiert');
            setEditDialogOpen(false);
            refetch();
        } else if (errors) {
            const error = errors[0];
            const reason = error.extensions?.['reason'];

            switch (reason) {
                case "AuthenticationRequired":
                    toast.error('Melde dich erneut an und versuche es nochmal.');
                    return;
                case "PermissionDenied":
                    toast.error('Du kannst für diesen Mitarbeiter keinen Urlaubsplan erstellen');
                    return;
            }
        } else {
            toast.error('Ein unerwarteter Fehler ist aufgetreten. Bitte versuche es später erneut.');
        }
    };

    if (employeeVacations?.length === 0) {
        return <Box
            sx={{display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: 5}}>
            <EmployeeVacationDialog open={createDialogOpen} onClose={() => setCreateDialogOpen(false)}
                                    onSubmit={onCreate}/>

            <Box sx={{maxWidth: '60%', display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                <Box component='img' sx={{maxHeight: '180px'}} src={vacationPlane}/>
                <Typography sx={{marginTop: 5}}>Hier werden die Soll-Stunden für das Überstundenkonto angegeben.
                    Sollen keine Überstunden erfasst werden oder wird der Mitarbeiter auf Stundenbasis bezahlt,
                    sollte hier nichts angegeben werden.</Typography>
                <Button variant='contained' sx={{mt: 4}} onClick={() => setCreateDialogOpen(true)}>URLAUBSTAGE
                    EINRICHTEN</Button>
            </Box>
        </Box>;
    }

    return <Box>
        <Typography variant='h6'>Urlaubstage im aktuellen Jahr</Typography>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6} lg={3}>
                <Typography sx={{color: '#82848B'}}>Vertragliche Urlaubstage</Typography>
                <Typography>{(currentYearVacationRow?.vacationMinutes ?? 0) / 60 / 8} Tage</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3}>
                <Typography sx={{color: '#82848B'}}>Aktuell verbleibende Tage</Typography>
                {(transferVacationPreviousYear && isTransferValid) ? <Typography>{currentYearRemaining / 60 / 8} Tage,
                        davon {transferVacationPreviousYear.vacationMinutes / 60 / 8} aus Vorjahr</Typography> :
                    <Typography>{currentYearRemaining / 60 / 8} Tage</Typography>}
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3}>
                <Typography sx={{color: '#82848B'}}>Zusätzliche Urlaubstage</Typography>
                {transferVacationPreviousYear ?
                    <Typography>{transferVacationPreviousYear.vacationMinutes / 60 / 8} aus Vorjahr (Verfall
                        zum {DateTime.fromISO(transferVacationPreviousYear.expirationDate).toFormat('dd.MM')}.)</Typography> :
                    <Typography>Keine</Typography>}
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3}>
                <Typography sx={{color: '#82848B'}}>Urlaubsübertrag in das Folgejahr</Typography>
                <Typography>{currentYearVacationRow?.automaticTransfer ? 'Aktiviert' : 'Deaktiviert'}</Typography>
            </Grid>
        </Grid>
        <Box sx={{width: '100%', display: 'flex', justifyContent: 'end', marginTop: 2}}>
            {currentYearVacationRow &&
                <EmployeeVacationDialog
                    open={editDialogOpen}
                    onClose={() => setEditDialogOpen(false)}
                    onSubmit={onEdit}
                    employeeVacation={currentYearVacationRow}
                    employeeVacationTransfer={transferVacationPreviousYear}
                />
            }
            <Button variant='contained' color='primary' onClick={() => setEditDialogOpen(true)}>Bearbeiten</Button>
        </Box>
        {(pastEntries && pastEntries.length > 0) &&
            <Box sx={{marginTop: 4}}>
                <Typography variant='h6'>Vergangene Jahre</Typography>
                <Grid container spacing={1}
                      sx={{
                          marginBottom: '1rem',
                          minHeight: '50px',
                          alignItems: 'center',
                          borderRadius: '10px',
                          marginLeft: 0,
                          width: '100%',
                      }}>
                    <Grid item xs sx={gridItemStyle}><b>Jahr</b></Grid>
                    <Grid item xs sx={gridItemStyle}><b>Urlaubstage nach Vertrag</b></Grid>
                    <Grid item xs sx={gridItemStyle}><b>Nicht genommene Tage</b></Grid>
                </Grid>

                {(pastEntries ?? []).map((entry, index) => {
                    const vacationDays = entry.vacationMinutes / 60 / 8;


                    return <Grid container key={`vacation-past-${index}`} spacing={1} sx={{
                        backgroundColor: '#F8FAFE',
                        marginBottom: '1rem',
                        minHeight: '50px',
                        alignItems: 'center',
                        borderRadius: '10px',
                        marginLeft: 0,
                        width: '100%',
                        ':hover': {
                            backgroundColor: '#E5EDFA'
                        }
                    }}>
                        <Grid item xs sx={gridItemStyle}>{entry.startYear}</Grid>
                        <Grid item xs sx={gridItemStyle}>{vacationDays} Tage</Grid>
                        <Grid item xs sx={gridItemStyle}>{entry.remainingVacationMinutes / 60 / 8} Tage</Grid>
                    </Grid>;
                })}
            </Box>
        }
    </Box>
};

export const EmployeeVacationOld = ({employee}: { employee?: EmployeeFragment }) => {
    const [createEmployeeVacationDialogOpen, setCreateEmployeeVacationDialogOpen] = useState(false);
    const [createEmployeeTransferDialogOpen, setEmployeeTransferDialogOpen] = useState(false);

    const [listEmployeeVacationsQuery, {data, refetch}] = useListEmployeeVacationsLazyQuery();

    useEffect(() => {
        if (employee) {
            listEmployeeVacationsQuery({
                variables: {
                    userId: employee.userId
                },
                fetchPolicy: 'no-cache'
            });
        }
    }, [employee, listEmployeeVacationsQuery]);

    const handleCloseCreateEmployeeVacationDialog = () => {
        setCreateEmployeeVacationDialogOpen(false);
        refetch();
    }

    const handleCloseCreateEmployeeVacationTransferDialog = () => {
        setEmployeeTransferDialogOpen(false);
        refetch();
    }

    const vacationRows = data?.employee.vacation.listEmployeeVacations;

    return <Box>
        <Typography variant='h6' sx={{marginBottom: 2}}>Urlaubstage</Typography>
        <EmployeeVacationTable userId={employee?.userId ?? -1} employeeVacationRows={vacationRows}
                               onPossibleUpdate={() => refetch()}/>
        <Box sx={{display: 'flex', justifyContent: 'end'}}>
            {employee &&
                <EmployeeVacationManagementDialog userId={employee.userId} open={createEmployeeVacationDialogOpen}
                                                  onClose={handleCloseCreateEmployeeVacationDialog}/>
            }
            <Button sx={{marginTop: 2}} variant='contained' onClick={() => setCreateEmployeeVacationDialogOpen(true)}>NEUER
                PLAN</Button>
        </Box>

        <Typography variant='h6' sx={{marginTop: 2}}>Überträge</Typography>
        <EmployeeVacationTransferTable vacationTransferRows={data?.employee.vacation.listEmployeeVacationTransfers}/>

        <Box sx={{display: 'flex', justifyContent: 'end'}}>
            {employee &&
                <CreateEmployeeTransferDialog userId={employee.userId} open={createEmployeeTransferDialogOpen}
                                              onClose={handleCloseCreateEmployeeVacationTransferDialog}/>
            }
            <Button variant='contained' onClick={() => setEmployeeTransferDialogOpen(true)}>NEUER ÜBERTRAG</Button>
        </Box>

        <Stack sx={{marginY: 4}} direction='row' alignItems='center' spacing={2}>
            <Box component='img' src={flagIcon} sx={{maxHeight: '110px'}}/>
            <Box>
                <Typography variant='h6'>Hinweis</Typography>
                <Typography sx={{fontSize: 14}}>Um ein Urlaubskonto einzurichten, muss auf „Neuer Plan“ geklickt werden.
                    Dann können alle
                    notwendigen Informationen angegeben werden. Daraus werden automatisch die Urlaubskonten für das
                    aktuelle, sowie die zu erwartenden Konten, für die Folgejahre eingerichtet. Ebenfalls kann ein
                    Urlaubsübertrag definiert werden. Auch kann manuell ein zusätzlicher Urlaubsübertrag mit Ablaufdatum
                    angelegt werden. Dies kann beispielsweise auch für zusätzlich gewährte Urlaubstage im Rahmen einer
                    Bonuszahlung o.Ä. genutzt werden. Weitere Information auf <a target="_blank" rel="noreferrer"
                                                                                 href="https://support.mytimetracker.de">support.mytimetracker.de</a></Typography>
            </Box>
        </Stack>
    </Box>;
}
