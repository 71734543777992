import * as Types from '../../generated/graphql';

import { DepartmentFragment } from '../../fragments/department.generated';
import { EmployeeFragment } from '../../fragments/employee.generated';
import { gql } from '@apollo/client';
import { DepartmentFragmentDoc } from '../../fragments/department.generated';
import { EmployeeFragmentDoc } from '../../fragments/employee.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateDepartmentCompanyInformationQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CreateDepartmentCompanyInformationQuery = { __typename?: 'Query', company: { __typename?: 'companyQueries', getCompanyInformation: { __typename?: 'Company', name: string, departments: Array<(
        { __typename?: 'Department' }
        & DepartmentFragment
      )>, employees: Array<(
        { __typename?: 'Employee' }
        & EmployeeFragment
      )> } } };


export const CreateDepartmentCompanyInformationDocument = gql`
    query CreateDepartmentCompanyInformation {
  company {
    getCompanyInformation {
      name
      departments {
        ...Department
      }
      employees {
        ...Employee
      }
    }
  }
}
    ${DepartmentFragmentDoc}
${EmployeeFragmentDoc}`;

/**
 * __useCreateDepartmentCompanyInformationQuery__
 *
 * To run a query within a React component, call `useCreateDepartmentCompanyInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreateDepartmentCompanyInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreateDepartmentCompanyInformationQuery({
 *   variables: {
 *   },
 * });
 */
export function useCreateDepartmentCompanyInformationQuery(baseOptions?: Apollo.QueryHookOptions<CreateDepartmentCompanyInformationQuery, CreateDepartmentCompanyInformationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CreateDepartmentCompanyInformationQuery, CreateDepartmentCompanyInformationQueryVariables>(CreateDepartmentCompanyInformationDocument, options);
      }
export function useCreateDepartmentCompanyInformationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CreateDepartmentCompanyInformationQuery, CreateDepartmentCompanyInformationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CreateDepartmentCompanyInformationQuery, CreateDepartmentCompanyInformationQueryVariables>(CreateDepartmentCompanyInformationDocument, options);
        }
export type CreateDepartmentCompanyInformationQueryHookResult = ReturnType<typeof useCreateDepartmentCompanyInformationQuery>;
export type CreateDepartmentCompanyInformationLazyQueryHookResult = ReturnType<typeof useCreateDepartmentCompanyInformationLazyQuery>;
export type CreateDepartmentCompanyInformationQueryResult = Apollo.QueryResult<CreateDepartmentCompanyInformationQuery, CreateDepartmentCompanyInformationQueryVariables>;