import * as Types from '../../generated/graphql';

import { WorkingTimeFragment } from '../../fragments/workingTime.generated';
import { TimeInformationFragment } from '../../fragments/timeInformation.generated';
import { gql } from '@apollo/client';
import { WorkingTimeFragmentDoc } from '../../fragments/workingTime.generated';
import { TimeInformationFragmentDoc } from '../../fragments/timeInformation.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WorkingTimeWithRangeQueryVariables = Types.Exact<{
  startDate: Types.Scalars['DateType'];
  endDate: Types.Scalars['DateType'];
  userId: Types.Scalars['Int'];
}>;


export type WorkingTimeWithRangeQuery = { __typename?: 'Query', tracking: { __typename?: 'trackingQueries', workingTimeThisWeek: (
      { __typename?: 'WorkingTime' }
      & WorkingTimeFragment
    ) }, time: { __typename?: 'timeQueries', totalBalance?: (
      { __typename?: 'WorkingTime' }
      & WorkingTimeFragment
    ) | null, timeInformationWithRange: (
      { __typename?: 'TimeInformation' }
      & TimeInformationFragment
    ) }, permission: { __typename?: 'permissionQueries', trackingPermission: { __typename?: 'trackingPermissionQueries', canCreateManualEntry: boolean } } };


export const WorkingTimeWithRangeDocument = gql`
    query WorkingTimeWithRange($startDate: DateType!, $endDate: DateType!, $userId: Int!) {
  tracking {
    workingTimeThisWeek(userId: $userId) {
      ...WorkingTime
    }
  }
  time {
    totalBalance(userId: $userId) {
      ...WorkingTime
    }
    timeInformationWithRange(
      startDate: $startDate
      endDate: $endDate
      employeeId: $userId
    ) {
      ...TimeInformation
    }
  }
  permission {
    trackingPermission {
      canCreateManualEntry(userId: $userId)
    }
  }
}
    ${WorkingTimeFragmentDoc}
${TimeInformationFragmentDoc}`;

/**
 * __useWorkingTimeWithRangeQuery__
 *
 * To run a query within a React component, call `useWorkingTimeWithRangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkingTimeWithRangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkingTimeWithRangeQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useWorkingTimeWithRangeQuery(baseOptions: Apollo.QueryHookOptions<WorkingTimeWithRangeQuery, WorkingTimeWithRangeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkingTimeWithRangeQuery, WorkingTimeWithRangeQueryVariables>(WorkingTimeWithRangeDocument, options);
      }
export function useWorkingTimeWithRangeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkingTimeWithRangeQuery, WorkingTimeWithRangeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkingTimeWithRangeQuery, WorkingTimeWithRangeQueryVariables>(WorkingTimeWithRangeDocument, options);
        }
export type WorkingTimeWithRangeQueryHookResult = ReturnType<typeof useWorkingTimeWithRangeQuery>;
export type WorkingTimeWithRangeLazyQueryHookResult = ReturnType<typeof useWorkingTimeWithRangeLazyQuery>;
export type WorkingTimeWithRangeQueryResult = Apollo.QueryResult<WorkingTimeWithRangeQuery, WorkingTimeWithRangeQueryVariables>;