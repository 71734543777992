import * as Types from '../../../../generated/graphql';

import { GrantUserTerminalAccessResponseType_GrantUserTerminalAccessEmailSent_Fragment, GrantUserTerminalAccessResponseType_GrantUserTerminalAccessInitialPin_Fragment } from '../../../../fragments/grantUserTerminalAccessResponseType.generated';
import { gql } from '@apollo/client';
import { GrantUserTerminalAccessResponseTypeFragmentDoc } from '../../../../fragments/grantUserTerminalAccessResponseType.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GrantUserTerminalAccessMutationVariables = Types.Exact<{
  userId: Types.Scalars['Int'];
}>;


export type GrantUserTerminalAccessMutation = { __typename?: 'Mutation', terminal: { __typename?: 'terminalMutations', grantUserTerminalAccess: (
      { __typename?: 'GrantUserTerminalAccessEmailSent' }
      & GrantUserTerminalAccessResponseType_GrantUserTerminalAccessEmailSent_Fragment
    ) | (
      { __typename?: 'GrantUserTerminalAccessInitialPin' }
      & GrantUserTerminalAccessResponseType_GrantUserTerminalAccessInitialPin_Fragment
    ) } };


export const GrantUserTerminalAccessDocument = gql`
    mutation GrantUserTerminalAccess($userId: Int!) {
  terminal {
    grantUserTerminalAccess(userId: $userId) {
      ...GrantUserTerminalAccessResponseType
    }
  }
}
    ${GrantUserTerminalAccessResponseTypeFragmentDoc}`;
export type GrantUserTerminalAccessMutationFn = Apollo.MutationFunction<GrantUserTerminalAccessMutation, GrantUserTerminalAccessMutationVariables>;

/**
 * __useGrantUserTerminalAccessMutation__
 *
 * To run a mutation, you first call `useGrantUserTerminalAccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGrantUserTerminalAccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [grantUserTerminalAccessMutation, { data, loading, error }] = useGrantUserTerminalAccessMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGrantUserTerminalAccessMutation(baseOptions?: Apollo.MutationHookOptions<GrantUserTerminalAccessMutation, GrantUserTerminalAccessMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GrantUserTerminalAccessMutation, GrantUserTerminalAccessMutationVariables>(GrantUserTerminalAccessDocument, options);
      }
export type GrantUserTerminalAccessMutationHookResult = ReturnType<typeof useGrantUserTerminalAccessMutation>;
export type GrantUserTerminalAccessMutationResult = Apollo.MutationResult<GrantUserTerminalAccessMutation>;
export type GrantUserTerminalAccessMutationOptions = Apollo.BaseMutationOptions<GrantUserTerminalAccessMutation, GrantUserTerminalAccessMutationVariables>;