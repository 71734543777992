import * as Types from '../../../../generated/graphql';

import { InheritableSettingsFragment } from '../../../../fragments/inheritableSettings.generated';
import { gql } from '@apollo/client';
import { InheritableSettingsFragmentDoc } from '../../../../fragments/inheritableSettings.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateManualEntryClearanceSettingMutationVariables = Types.Exact<{
  settingEntityType: Types.SettingEntityType;
  entityId?: Types.InputMaybe<Types.Scalars['Int']>;
  trackingClearanceType: Types.TrackingClearanceType;
  dayLimit?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type UpdateManualEntryClearanceSettingMutation = { __typename?: 'Mutation', setting: { __typename?: 'settingMutations', trackingSetting: { __typename?: 'trackingSettingMutations', updateManualEntryClearanceSetting: (
        { __typename?: 'InheritableSettings' }
        & InheritableSettingsFragment
      ) } } };


export const UpdateManualEntryClearanceSettingDocument = gql`
    mutation UpdateManualEntryClearanceSetting($settingEntityType: SettingEntityType!, $entityId: Int, $trackingClearanceType: TrackingClearanceType!, $dayLimit: Int) {
  setting {
    trackingSetting {
      updateManualEntryClearanceSetting(
        settingEntityType: $settingEntityType
        entityId: $entityId
        trackingClearance: $trackingClearanceType
        dayLimit: $dayLimit
      ) {
        ...InheritableSettings
      }
    }
  }
}
    ${InheritableSettingsFragmentDoc}`;
export type UpdateManualEntryClearanceSettingMutationFn = Apollo.MutationFunction<UpdateManualEntryClearanceSettingMutation, UpdateManualEntryClearanceSettingMutationVariables>;

/**
 * __useUpdateManualEntryClearanceSettingMutation__
 *
 * To run a mutation, you first call `useUpdateManualEntryClearanceSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateManualEntryClearanceSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateManualEntryClearanceSettingMutation, { data, loading, error }] = useUpdateManualEntryClearanceSettingMutation({
 *   variables: {
 *      settingEntityType: // value for 'settingEntityType'
 *      entityId: // value for 'entityId'
 *      trackingClearanceType: // value for 'trackingClearanceType'
 *      dayLimit: // value for 'dayLimit'
 *   },
 * });
 */
export function useUpdateManualEntryClearanceSettingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateManualEntryClearanceSettingMutation, UpdateManualEntryClearanceSettingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateManualEntryClearanceSettingMutation, UpdateManualEntryClearanceSettingMutationVariables>(UpdateManualEntryClearanceSettingDocument, options);
      }
export type UpdateManualEntryClearanceSettingMutationHookResult = ReturnType<typeof useUpdateManualEntryClearanceSettingMutation>;
export type UpdateManualEntryClearanceSettingMutationResult = Apollo.MutationResult<UpdateManualEntryClearanceSettingMutation>;
export type UpdateManualEntryClearanceSettingMutationOptions = Apollo.BaseMutationOptions<UpdateManualEntryClearanceSettingMutation, UpdateManualEntryClearanceSettingMutationVariables>;