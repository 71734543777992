import {
    Box,
    Card,
    CardContent, Chip,
    Grid,
    MenuItem, Pagination,
    Select, Skeleton, SxProps,
    TextField, Theme,
} from "@mui/material";
import {Link} from "react-router-dom";
import React from "react";
import {ImitationCompanyFilter} from "../../generated/graphql";
import {ContentHeader} from "../../components";
import {ErrorScreen} from "../../components/organisms/ErrorScreen";
import {CSSProperties} from "styled-components";
import {useImitationCompaniesQuery} from "./imitationCompanies.generated";
import {DateTime} from "luxon";
import {TRIAL_DAYS} from "../../utils/trial";

const gridItemStyle: SxProps<Theme> = (theme) => ({
    paddingTop: '10px',
    paddingBottom: '10px',
    textDecoration: 'none'
});

const linkNoDecorationStyle: CSSProperties = {textDecoration: 'none', color: '#333333'};

export const ImitationPage = () => {
    const itemsPerPage = 20;
    const [currentPage, setCurrentPage] = React.useState(1);
    const [filterOption, setFilterOption] = React.useState<ImitationCompanyFilter>('OnlyActive');
    const [searchQuery, setSearchQuery] = React.useState<string>('');

    const {data, error, loading} = useImitationCompaniesQuery({
        fetchPolicy: 'no-cache', variables: {
            imitationFilter: filterOption,
            searchQuery: searchQuery.length > 0 ? searchQuery : undefined,
            limit: itemsPerPage,
            offset: (currentPage - 1) * itemsPerPage,
        }
    });

    const companyRows = data?.imitation.imitationCompanies.companies ?? [];
    const totalCount = data?.imitation.imitationCompanies.totalCount ?? 0;

    const totalPages = Math.ceil(totalCount / itemsPerPage);

    if (error) {
        return <div>
            <ContentHeader title="Kunden"/>
            <Card>
                <ErrorScreen/>
            </Card>
        </div>;
    }

    return (
        <div>
            <ContentHeader title="Kunden"/>

            <Card>
                <CardContent>
                    <Grid container>
                        <Grid item md={6}></Grid>
                        <Grid item md={6} sx={{display: 'flex', gap: 2}}>
                            <TextField id="searchQuery"
                                       name='searchQuery'
                                       placeholder="Suchen..."
                                       variant='outlined'
                                       fullWidth
                                       size='small'
                                       value={searchQuery}
                                       onChange={(event) => setSearchQuery(event.target.value)}
                            />
                            <Select
                                id='filterOption'
                                name='filterOption'
                                labelId="filter-option-label"
                                size='small'
                                fullWidth
                                value={filterOption}
                                onChange={(event) => setFilterOption(event.target.value as ImitationCompanyFilter)}
                                sx={{backgroundColor: '#fff'}}
                            >
                                <MenuItem value='DisplayAll'>Alle anzeigen</MenuItem>
                                <MenuItem value='OnlyActive'>Nur aktive anzeigen</MenuItem>
                            </Select>
                        </Grid>
                    </Grid>
                </CardContent>
                <CardContent>
                    <Grid container spacing={1}
                          sx={{
                              marginBottom: '1rem',
                              minHeight: '50px',
                              alignItems: 'center',
                              borderRadius: '10px'
                          }}>
                        <Grid item xs={0.5} sx={gridItemStyle}></Grid>
                        <Grid item xs sx={gridItemStyle}><b>Name</b></Grid>
                        <Grid item xs sx={gridItemStyle}><b>Aktive MA</b></Grid>
                        <Grid item xs sx={gridItemStyle}><b>Tablets</b></Grid>
                        <Grid item xs sx={gridItemStyle}><b>Aktivität letzte 7 Tage</b></Grid>
                        <Grid item xs sx={gridItemStyle}><b>Status</b></Grid>
                        <Grid item xs={0.5}></Grid>
                    </Grid>
                    {companyRows && companyRows.length > 0 && <>
                        {companyRows.map((companyRow, index) => {
                            const status = () => {
                                switch (companyRow.subscription.__typename) {
                                    case 'ActiveSubscription':
                                        switch (companyRow.subscription.stripeSubscription) {
                                            case 'expert':
                                                return <Chip label="Expert" color="success" />;
                                            case 'professional':
                                                return <Chip label="Professional" color="success" />;
                                            case 'standard':
                                                return <Chip label="Standard" color="success" />;
                                            default:
                                                return <Chip label="Unbekannt" color="success" />;
                                        }
                                    case 'TrialSubscription':
                                        const startDate = DateTime.fromISO(companyRow.subscription.startDate);
                                        const diffDays = DateTime.now().diff(startDate, 'days').toObject().days ?? 0;
                                        const remainingDays = TRIAL_DAYS - Math.round(diffDays);
                                        if(remainingDays > 0) {
                                            return <Chip label={`Testphase (${remainingDays})`} color="primary" />;
                                        } else {
                                            return <Chip label={`Abgelaufen (${remainingDays})`} color="warning" />;
                                        }
                                    case 'CancelledSubscription':
                                        return <Chip label="Gekündigt" color="error" />;
                                    default:
                                        return 'Unbekannt';
                                }
                            }

                            return <Grid key={index} container spacing={1} sx={{
                                backgroundColor: '#F8FAFE',
                                marginBottom: '1rem',
                                minHeight: '50px',
                                alignItems: 'center',
                                borderRadius: '10px',
                                width: '100%',
                                marginLeft: 0,
                                ':hover': {
                                    backgroundColor: '#E5EDFA'
                                }
                            }}>
                                <Grid item xs={11} sm={11.5} sx={{paddingTop: '0 !important'}}>
                                    <Link to={`/imitation/${companyRow.companyId}`} style={linkNoDecorationStyle}>
                                        <Grid container sx={{alignItems: 'center'}}>
                                            <Grid item xs={0.5}></Grid>
                                            <Grid item xs sx={gridItemStyle}>{companyRow.name}</Grid>
                                            <Grid item xs sx={gridItemStyle}>{companyRow.activeEmployees}</Grid>
                                            <Grid item xs
                                                  sx={gridItemStyle}>{companyRow.terminalCount} ({companyRow.activeTerminalCount} aktiv)</Grid>
                                            <Grid item xs sx={gridItemStyle}>0</Grid>
                                            <Grid item xs sx={gridItemStyle}>{status()}</Grid>
                                        </Grid>
                                    </Link>
                                </Grid>
                            </Grid>;
                        })}
                        <Box sx={{display: 'flex', justifyContent: 'center', paddingTop: 2}}>
                            <Pagination page={currentPage} count={totalPages} color='primary'
                                        onChange={(event, page) => setCurrentPage(page)}/>
                        </Box>
                    </>}
                    {loading &&
                        Array.from(Array(5).keys()).map((_, index) => {
                            return <Skeleton key={index} variant="rounded" width='100%' height={50}
                                             sx={{marginTop: '10px'}}/>;
                        })
                    }
                </CardContent>
            </Card>
        </div>
    );
};
