import * as Types from '../generated/graphql';

import { gql } from '@apollo/client';
export type NewTrackingRowFragment = { __typename?: 'NewTrackingRowOutput', date: any, start: any, end?: any | null, workPlace?: string | null, comment?: string | null };

export const NewTrackingRowFragmentDoc = gql`
    fragment NewTrackingRow on NewTrackingRowOutput {
  date
  start
  end
  workPlace
  comment
}
    `;