import * as Types from '../generated/graphql';

import { gql } from '@apollo/client';
export type WorkingTimeFragment = { __typename?: 'WorkingTime', hours: any, minutes: any };

export const WorkingTimeFragmentDoc = gql`
    fragment WorkingTime on WorkingTime {
  hours
  minutes
}
    `;