import {Fragment, useState} from "react";
import {TrackingDetailDialog} from "./TrackingDetailDialog";
import DialogContext, {
    TrackingDetailDialogContext,
    TrackingDetailDialogContextProps
} from "./TrackingDetailDialogContext";

export interface TrackingDialogProviderProps {
    children: React.ReactNode;
}

const TrackingDialogProvider: React.FC<TrackingDialogProviderProps> = ({children}) => {
    const [props, setProps] = useState<TrackingDetailDialogContextProps  | undefined>(undefined);

    const value: TrackingDetailDialogContext = {dialog: props ?? {}, showTrackingDetailDialog: setProps};
    const handleClose = () => {
      setProps(undefined);
    };

    return <Fragment>
        <DialogContext.Provider value={value}>
            {children}
        </DialogContext.Provider>
        <TrackingDetailDialog props={props} onClose={handleClose}/>
    </Fragment>;
};

export default TrackingDialogProvider;
