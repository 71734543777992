import {Box, Button, Checkbox, Grid, Stack, Typography} from "@mui/material";
import vacationCalendar from "../../../../images/vacation-calendar.png";
import React, {useEffect, useState} from "react";
import {CreateHolidayDialog} from "../../../../components/organisms/CreateHolidayDialog/CreateHolidayDialog";
import {WorkPlaceFragment} from "../../../../fragments/workPlace.generated";
import {useWorkPlaceHolidayInformationLazyQuery} from "./workPlaceHolidayInformation.generated";
import {useTogglePredefinedHolidayMutation} from "./togglePredefinedHoliday.generated";
import {PredefinedHoliday} from "../../../../generated/graphql";
import {toast} from "react-toastify";
import LoopIcon from '@mui/icons-material/Loop';
import {DateTime} from "luxon";
import {useDeleteCustomHolidayMutation} from "./deleteCustomHoliday.generated";
import InfoIcon from '@mui/icons-material/Info';

interface WorkPlaceHolidaysProps {
    workPlace?: WorkPlaceFragment;
}

export const WorkPlaceHolidays = ({workPlace}: WorkPlaceHolidaysProps) => {
    const [createHolidayDialogOpen, setCreateHolidayDialogOpen] = useState(false);

    const [workPlaceHolidayInformationQuery, {data, refetch}] = useWorkPlaceHolidayInformationLazyQuery();

    const [togglePredefinedHolidayMutation] = useTogglePredefinedHolidayMutation();
    const [deleteCustomHolidayMutation] = useDeleteCustomHolidayMutation();

    const togglePredefinedHoliday = async (holiday: PredefinedHoliday) => {
        const {data, errors} = await togglePredefinedHolidayMutation({
            variables: {
                workPlaceId: workPlace?.workPlaceId ?? 0,
                holidayId: holiday.holidayId
            }
        });

        if (errors) {
            const error = errors[0];
            const reason = error.extensions?.['reason'];

            if (reason === 'AuthenticationRequired') {
                toast.error('Melde dich erneut an und versuche es nochmal.');
            } else if (reason === 'PermissionDenied') {
                toast.error('Du hast nicht die nötigen Berechtigungen');
            } else if (reason === 'WorkPlaceNotFound') {
                toast.error('Der Arbeitsort konnte nicht gefunden werden');
            } else if (reason === 'HolidayNotFound') {
                toast.error('Der Feiertag konnte nicht gefunden werden oder ist für diesen Standort nicht verfügbar');
            } else {
                toast.error('Ein unerwarteter Fehler ist aufgetreten');
            }
        } else if (data) {
            refetch();
            if (holiday.isSelected) {
                toast.success(`Der Feiertag ${holiday.name} wurde für diesen Standort erfolgreich deaktiviert`);
            } else {
                toast.success(`Der Feiertag ${holiday.name} wurde für diesen Standort erfolgreich aktiviert`);
            }
        } else {
            toast.error('Ein unbekannter Fehler ist aufgetreten');
        }
    };

    const deleteCustomHoliday = async (restPeriodId: number) => {
        const {data, errors} = await deleteCustomHolidayMutation({
            variables: {
                restPeriodId: restPeriodId
            }
        });

        if (errors) {
            const error = errors[0];
            const reason = error.extensions?.['reason'];

            if (reason === 'AuthenticationRequired') {
                toast.error('Melde dich erneut an und versuche es nochmal.');
            } else if (reason === 'PermissionDenied') {
                toast.error('Du hast nicht die nötigen Berechtigungen');
            } else if (reason === 'WorkPlaceNotFound') {
                toast.error('Der Arbeitsort konnte nicht gefunden werden');
            } else if (reason === 'HolidayNotFound') {
                toast.error('Der Feiertag konnte nicht gefunden werden oder ist für diesen Standort nicht verfügbar');
            } else {
                toast.error('Ein unerwarteter Fehler ist aufgetreten');
            }
        } else if (data) {
            refetch();
            toast.success('Der invdividuelle Feiertage wurde erolgreich gelöscht');
        } else {
            toast.error('Ein unbekannter Fehler ist aufgetreten');
        }
    }

    useEffect(() => {
        if (workPlace) {
            workPlaceHolidayInformationQuery({
                variables: {
                    workPlaceId: workPlace?.workPlaceId ?? 0
                }
            });
        }
    }, [workPlace, workPlaceHolidayInformationQuery]);

    // TODO: Remove later and replace with check if holidays are set
    const [isEdit, setEdit] = useState(true);

    const predefinedHolidays = data?.holiday.listPredefinedHolidays ?? [];
    const customHolidays = data?.holiday.listCustomHolidays ?? [];

    if (isEdit) {
        return <Box>
            <Typography>Die Feiertage werden je nach gewähltem Bundesland automatisch voreingestellt, können je nach
                Bedarf aber angepasst werden.</Typography>

            <Box sx={{marginTop: 4}}>
                <Typography variant='h6'>Gesetzliche Feiertage</Typography>

                {(predefinedHolidays.length === 0) &&
                    <Stack flexDirection='row' gap={1}>
                        <InfoIcon/>
                        <Typography>Für das Land dieses Standorts existieren noch keine vordefinierten Feiertage</Typography>
                    </Stack>
                }

                {predefinedHolidays.map((holiday) => {
                    return <Box key={holiday.holidayId} sx={{
                        display: 'flex',
                        alignItems: 'center',
                        backgroundColor: '#F8FAFE',
                        marginBottom: 1
                    }}>
                        <Checkbox checked={holiday.isSelected}
                                  onChange={() => togglePredefinedHoliday(holiday)}></Checkbox>
                        <Typography>{holiday.name}</Typography>
                    </Box>
                })}
            </Box>

            <Box sx={{marginTop: 4}}>
                <Typography variant='h6'>Individuelle Feiertage</Typography>
                {(customHolidays.length === 0) &&
                    <Stack flexDirection='row' gap={1}>
                        <InfoIcon/>
                        <Typography>Für diesen Standort existieren noch keine individuellen Feiertage</Typography>
                    </Stack>
                }
                {customHolidays.map((customHoliday, index) => {
                    const icon = () => {
                        switch (customHoliday.repeatPeriod) {
                            case 'yearly':
                                return <LoopIcon/>;
                            case 'oneTime':
                                return <></>;
                        }
                    }

                    return <Grid container key={index} sx={{
                        display: 'flex',
                        minHeight: '50px',
                        alignItems: 'center',
                        backgroundColor: '#F8FAFE',
                        marginBottom: 1
                    }}>
                        <Grid item sx={{display: 'flex', justifyContent: 'center'}} xs={0.5}>
                            {icon()}
                        </Grid>
                        <Grid item xs={2.5}>
                            {DateTime.fromISO(customHoliday.date).toFormat('dd. MMM yyyy', {locale: 'de'})}
                        </Grid>
                        <Grid item xs={8.2}>{customHoliday.name}</Grid>
                        <Grid item>
                            <Button color='error' onClick={() => deleteCustomHoliday(customHoliday.restPeriodId)}>Löschen</Button>
                        </Grid>
                    </Grid>;
                })}

                <Box sx={{marginTop: 3, display: 'flex', justifyContent: 'end'}}>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={() => setCreateHolidayDialogOpen(true)}
                    >
                        Neuer Feiertag
                    </Button>
                    {workPlace &&
                        <CreateHolidayDialog
                            workPlace={workPlace}
                            open={createHolidayDialogOpen}
                            onClose={() => {
                                setCreateHolidayDialogOpen(false);
                                refetch();
                            }}
                        />
                    }
                </Box>
            </Box>
        </Box>
    }

    return <Box
        sx={{display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: 5}}>
        <Box sx={{maxWidth: '60%', display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
            <Box component='img' sx={{maxHeight: '180px'}} src={vacationCalendar}/>
            <Typography sx={{marginTop: 5}}>Hier können Feiertage für diesen Standort festgelegt werden. Öffne den
                Assistenten, um die Feiertage für ein den Standort automatisch festzulegen oder konfiguriere die
                Feiertage manuell.</Typography>
            <Stack flexDirection='row' gap={2}>
                <Button variant='contained' color='inherit' sx={{mt: 4}} onClick={() => setEdit(true)}>Manuell
                    konfigurieren</Button>
                <Button variant='contained' sx={{mt: 4}}>Assistent öffnen</Button>
            </Stack>
        </Box>
    </Box>;
};
