import {Box, Stack, Typography} from "@mui/material";
import React from "react";

const AbsenceDayLabel = ({backgroundColor, text, value}: {backgroundColor: string, text: string, value: string}) => {
    return <Stack direction='row' spacing={2}>
        <Box sx={{backgroundColor: backgroundColor, borderRadius: '10px', paddingX: 1.5, color: '#fff'}}>{value}</Box>
        <Typography>{text}</Typography>
    </Stack>;
}

export default AbsenceDayLabel;
