import {FieldArray, FormikProvider, useFormik} from "formik";
import * as Yup from "yup";
import {ContentHeader} from "../../components";
import {
    Box,
    Card,
    CardContent,
    FormHelperText,
    Grid,
    IconButton,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import {EmployeeInformation} from '../../components/molecules/EmployeeInformation';
import {LoadingButton} from "@mui/lab";
import React, {useCallback, useEffect} from "react";
import {DatePicker, LocalizationProvider, TimePicker} from "@mui/x-date-pickers";
import {DateTime, Duration, Interval} from "luxon";
import {AdapterLuxon} from "@mui/x-date-pickers/AdapterLuxon";
import {useCreateAbsenceRequestMutation} from "./createAbsenceRequest.generated";
import {toast} from "react-toastify";
import {AbsencePeriod, AbsenceType} from "../../generated/graphql";
import {useNavigate, useParams} from "react-router-dom";
import {useActiveTimeScheduleQuery} from "./activeTimeSchedule.generated";
import EditIcon from '@mui/icons-material/Edit';
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";

type CreateAbsenceError =
    'AuthenticationRequired'
    | 'DepartmentNotFound'
    | 'NotEnoughVacationMinutes'
    | 'TrackingAlreadyExists'
    | 'AbsenceRequestAlreadyExists';

interface DateHours {
    readonly date: DateTime;
    readonly time: DateTime;
    readonly isEdit: boolean;
    readonly absencePeriod?: AbsencePeriod;
}

const CreateAbsencePage = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const user = useSelector((state: RootState) => state.auth.currentUser);

    const employeeId = id ? Number(id) : user!.userId;
    const isSelf = employeeId === user?.userId;

    const {data} = useActiveTimeScheduleQuery({
        variables: {
            employeeId: employeeId
        }
    });
    const activeTimeSchedule = data?.employee.timeSchedule.activeTimeSchedule;

    const [createAbsenceRequestMutation, {loading: createAbsenceLoading}] = useCreateAbsenceRequestMutation();

    const optionalTimeScheduleForDay = useCallback((date: DateTime) => {
        switch (date.weekday) {
            case 1:
                return activeTimeSchedule?.monday;
            case 2:
                return activeTimeSchedule?.tuesday;
            case 3:
                return activeTimeSchedule?.wednesday;
            case 4:
                return activeTimeSchedule?.thursday;
            case 5:
                return activeTimeSchedule?.friday;
            case 6:
                return activeTimeSchedule?.saturday;
            case 7:
                return activeTimeSchedule?.sunday;
        }
    }, [activeTimeSchedule]);

    const timeScheduleForDay = useCallback((date: DateTime) => {
        const timeSchedule = optionalTimeScheduleForDay(date);
        const isWeekend = date.weekday === 6 || date.weekday === 7;

        return DateTime.fromSeconds(0).set({
            hour: timeSchedule?.hours ?? (isWeekend ? 0 : 8),
            minute: timeSchedule?.minutes ?? 0
        });
    }, [optionalTimeScheduleForDay]);

    const absenceMap: Map<AbsenceType, string> = new Map([
        ['vacation', 'Urlaub'],
        ['illness', 'Krankheit'],
        ['childIllness', 'Kind krank'],
        ['parentalLeave', 'Elternzeit'],
        ['specialLeave', 'Sonderurlaub'],
        // ['flexDay', 'Gleittag'],
        ['holiday', 'Feiertag']
    ]);

    const formik = useFormik({
        initialValues: {
            absenceType: 'vacation',
            startDate: DateTime.now(),
            endDate: DateTime.now(),
            comment: '',
            absenceRows: [{
                date: DateTime.now(),
                isEdit: !activeTimeSchedule,
                time: timeScheduleForDay(DateTime.now()),
                absencePeriod: 'full_time' as AbsencePeriod
            }]
        },
        validationSchema: Yup.object({
            absenceType: Yup.string()
                .required('Gib die Art der Abwesenheit an')
                .oneOf(Array.from(absenceMap.keys()),
                    'Gib die Art der Abwesenheit an'
                ),
            startDate: Yup.date()
                .max(Yup.ref('endDate'), 'Das Start-Datum der Abwesenheit muss vor dem End-Datum liegen')
                .required('Bitte gib das Start-Datum der Abwesenheit an'),
            endDate: Yup.date()
                .min(Yup.ref('startDate'), 'Das End-Datum der Abwesenheit muss nach dem Start-Datum liegen')
                .required('Bitte gib das End-Datum der Abwesenheit an')
        }),
        onSubmit: async (values) => {
            const {data, errors} = await createAbsenceRequestMutation({
                variables: {
                    employeeId: employeeId,
                    startDate: values.startDate.toFormat('yyyy-MM-dd'),
                    endDate: values.endDate.toFormat('yyyy-MM-dd'),
                    comment: values.comment,
                    absenceType: values.absenceType as AbsenceType,
                    absenceRows: values.absenceRows.map((absenceRow) => {
                        const seconds = absenceRow.time.hour * 60 * 60 + absenceRow.time.minute * 60;
                        const timeSeconds = absenceRow.absencePeriod === 'half_time' ? seconds / 2 : seconds;

                        const duration = Duration.fromMillis(timeSeconds * 1000).shiftTo('hours', 'minutes').toObject();

                        const hours = duration.hours ? Math.floor(duration.hours) : 0;
                        const minutes = duration.minutes ? Math.floor(duration.minutes) : 0;

                        return {
                            date: absenceRow.date.toFormat('yyyy-MM-dd'),
                            hours: hours,
                            minutes: minutes,
                            absencePeriod: absenceRow.absencePeriod
                        };
                    })
                }
            });

            if (data) {
                toast.success(`Abwesenheit wurde erfolgreich ${user?.stripeSubscription === 'expert' ? 'beantragt' : 'erstellt'}`);
                const absenceRequestId = data.absence.request.createAbsenceRequest;
                navigate(`/absence/${absenceRequestId}`);
            } else if (errors) {
                const error = errors[0];
                const reason = error.extensions['reason'] as unknown as CreateAbsenceError;

                switch (reason) {
                    case "AuthenticationRequired":
                        toast.error('Melde dich erneut an und versuche es nochmal.');
                        return;
                    case "DepartmentNotFound":
                        toast.error('Die Abteilung des Mitarbeits konnte nicht gefunden werden');
                        return;
                    case "NotEnoughVacationMinutes":
                        toast.error('Du hast nicht mehr genügend Urlaubstage zur Verfügung');
                        return;
                    case "TrackingAlreadyExists":
                        toast.error('Du hast in diesem Zeitraum bereits Stunden gearbeitet und kannst daher keine Abwesenheit eintragen');
                        return;
                    case "AbsenceRequestAlreadyExists":
                        toast.error('Für diesen Zeitraum existiert bereits ein Abwesenheitsantrag');
                        return;
                    default:
                        toast.error('Ein unbekannter Fehler ist aufgetreten');
                        return;
                }
            } else {
                toast.error('Ein unerwarteter Fehler ist aufgetreten. Bitte versuche es später erneut.');
            }
        }
    });
    const {handleChange, values, handleSubmit, handleBlur, touched, errors, setFieldValue} = formik;

    const canEnterAbsenceHoursManually = data?.permission.absencePermission.canEnterAbsenceHoursManually ?? false;

    useEffect(() => {
        const dayDiff = values.endDate.diff(values.startDate, 'days').toObject().days;

        const intervals = Interval.fromDateTimes(
            values.startDate.startOf("day"),
            values.endDate.endOf("day"))
            .splitBy({day: 1}).map(d => d.start);

        const dateHours: DateHours[] = intervals.map((interval) => {
            return {
                date: interval,
                time: timeScheduleForDay(interval),
                isEdit: !activeTimeSchedule,
                absencePeriod: !canEnterAbsenceHoursManually ? 'full_time' : undefined
            }
        });

        if ((dayDiff ?? 0) >= 0) {
            setFieldValue('absenceRows', dateHours);
        }
    }, [values.startDate, values.endDate, setFieldValue, timeScheduleForDay, activeTimeSchedule, canEnterAbsenceHoursManually]);

    return (
        <LocalizationProvider dateAdapter={AdapterLuxon}>
            <ContentHeader
                title={`Neue Abwesenheit ${isSelf ? '' : `für ${data?.employee.byId.name} ${data?.employee.byId.surname}`}`}/>
            <section className="content">
                <Card>
                    <CardContent>
                        <Box component='form' onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                <EmployeeInformation label='Abwesenheit'>
                                    <Select
                                        id='absenceType'
                                        name='absenceType'
                                        labelId="absence-type-label"
                                        size='small'
                                        placeholder='Abwesenheit'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.absenceType}
                                        error={!!(errors.absenceType && touched.absenceType)}
                                        fullWidth
                                        displayEmpty
                                    >
                                        {Array.from(absenceMap.entries()).map(([key, value]) => (
                                            <MenuItem key={key} value={key}>{value}</MenuItem>
                                        ))}
                                    </Select>
                                    {errors.absenceType && (
                                        <FormHelperText error={!!(errors.absenceType && touched.absenceType)}>
                                            {errors.absenceType}
                                        </FormHelperText>
                                    )}
                                </EmployeeInformation>
                                <Grid item xs={0} md={12}></Grid>
                                <EmployeeInformation label='Startdatum' xs={12} md={6}>
                                    <DatePicker
                                        slotProps={{
                                            textField: {
                                                error: !!errors.startDate,
                                                fullWidth: true,
                                                size: 'small',
                                                name: 'startDate',
                                                id: 'startDate'
                                            }
                                        }}
                                        value={values.startDate}
                                        onChange={(value: DateTime | null) => {
                                            if (value?.isValid) {
                                                setFieldValue('startDate', value)

                                                const diff = value.diff(values.endDate);
                                                if (diff.milliseconds > 0) {
                                                    setFieldValue('endDate', value);
                                                }
                                            }
                                        }}
                                        format='dd.MM.yyyy'
                                    />
                                    {errors.startDate && (
                                        <FormHelperText error={!!errors.startDate}>
                                            {errors.startDate as string}
                                        </FormHelperText>
                                    )}
                                </EmployeeInformation>
                                <EmployeeInformation label='Enddatum' xs={12} md={6}>
                                    <DatePicker
                                        slotProps={{
                                            textField: {
                                                error: !!errors.endDate,
                                                fullWidth: true,
                                                size: 'small',
                                                name: 'endDate',
                                                id: 'endDate'
                                            }
                                        }}
                                        value={values.endDate}
                                        onChange={(value: DateTime | null) => {
                                            if (value?.isValid) {
                                                setFieldValue('endDate', value)
                                            }
                                        }}
                                        format='dd.MM.yyyy'
                                    />
                                    {errors.endDate && (
                                        <FormHelperText error={!!errors.endDate}>
                                            {errors.endDate as string}
                                        </FormHelperText>
                                    )}
                                </EmployeeInformation>
                                <EmployeeInformation label='Kommentar (optional)' xs={12} md={12}>
                                    <TextField
                                        id="comment"
                                        name='comment'
                                        multiline
                                        rows={2}
                                        placeholder="Gib einen Kommentar an"
                                        value={values.comment}
                                        error={!!(errors.comment && touched.comment)}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        variant='outlined'
                                        fullWidth
                                    />
                                    {errors.comment && (
                                        <FormHelperText error={!!(errors.comment && touched.comment)}>
                                            {errors.comment}
                                        </FormHelperText>
                                    )}
                                </EmployeeInformation>
                            </Grid>

                            <Typography sx={{my: 2}}
                                        variant='h6'>{!canEnterAbsenceHoursManually ? 'Dauer' : 'Angerechnete Stunden'}</Typography>

                            <FormikProvider value={formik}>
                                <FieldArray
                                    name="absenceRows"
                                    validateOnChange={false}
                                    render={arrayHelpers => {
                                        return <div>
                                            {values.absenceRows.map((absenceRow: DateHours, index) => {
                                                const isFirst = index === 0;
                                                const isLast = index === values.absenceRows.length - 1;
                                                const isFirstOrLast = isFirst || isLast;

                                                return <Box key={index} sx={{backgroundColor: '#F8FAFE', my: 2, py: 2}}>
                                                    <Grid container>
                                                        <Grid item xs={6} sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            paddingLeft: 3
                                                        }}>
                                                            <Typography>
                                                                {absenceRow.date.setLocale('de').toFormat('cccc, dd.MM.yyyy')}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6} sx={{
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'left',
                                                        }}>
                                                            {!canEnterAbsenceHoursManually &&
                                                                <Stack sx={{width: '100%', mr: 2}} direction='column'>
                                                                    <Typography>Dauer</Typography>
                                                                    {(!isFirstOrLast || absenceRow.time.toSeconds() <= 0) &&
                                                                        <p>Ganztags</p>
                                                                    }
                                                                    {isFirstOrLast && absenceRow.time.toSeconds() > 0 &&
                                                                        <Select
                                                                            id='absencePeriod'
                                                                            name={`absenceRows.${index}.absencePeriod`}
                                                                            labelId="absence-period"
                                                                            size='small'
                                                                            placeholder='Dauer'
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            fullWidth
                                                                            displayEmpty
                                                                            disabled={!isFirstOrLast}
                                                                            value={values.absenceRows[index].absencePeriod}
                                                                            sx={{backgroundColor: '#fff'}}
                                                                        >
                                                                            <MenuItem
                                                                                value='full_time'>Ganztags</MenuItem>
                                                                            <MenuItem
                                                                                value='half_time'>Halbtags</MenuItem>
                                                                        </Select>
                                                                    }
                                                                </Stack>
                                                            }
                                                            {canEnterAbsenceHoursManually &&
                                                                <>
                                                                    {!absenceRow.isEdit &&
                                                                        <Stack sx={{width: '100%', px: 1}}
                                                                               direction='row'
                                                                               justifyContent='space-between'
                                                                               alignItems='center'>
                                                                            <Typography>{absenceRow.time.hour}h {absenceRow.time.minute}min</Typography>
                                                                            <IconButton
                                                                                onClick={() => setFieldValue(`absenceRows.${index}.isEdit`, !absenceRow.isEdit)}>
                                                                                <EditIcon sx={{color: '#0854CF'}}/>
                                                                            </IconButton>
                                                                        </Stack>
                                                                    }
                                                                    {absenceRow.isEdit &&
                                                                        <Box sx={{
                                                                            display: 'flex',
                                                                            flexDirection: 'column',
                                                                            width: '100%',
                                                                            paddingRight: '25px',
                                                                            paddingBottom: '15px'
                                                                        }}>
                                                                            <Typography>Stunden</Typography>
                                                                            <TimePicker
                                                                                value={absenceRow.time}
                                                                                onChange={(value) => setFieldValue(`absenceRows.${index}.time`, value)}
                                                                                format='HH:mm'
                                                                                slotProps={{
                                                                                    textField: {
                                                                                        fullWidth: true,
                                                                                        variant: 'outlined',
                                                                                        size: 'small',
                                                                                        name: `absenceRows.${index}.time`,
                                                                                        id: `absenceRows.${index}.time`,
                                                                                        sx: {backgroundColor: '#fff'}
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </Box>
                                                                    }
                                                                </>
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            })}
                                        </div>;
                                    }}
                                />
                            </FormikProvider>

                            <Box sx={{marginTop: 1, display: 'flex', justifyContent: 'end', gap: 2}}>
                                <LoadingButton loading={createAbsenceLoading} variant='contained'
                                               type="submit">Absenden</LoadingButton>
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
            </section>
        </LocalizationProvider>
    );
};

export default CreateAbsencePage;
