import * as Types from '../../generated/graphql';

import { AbsenceRowFragment } from '../../fragments/absenceRow.generated';
import { TrackingRowFragment } from '../../fragments/trackingRow.generated';
import { gql } from '@apollo/client';
import { AbsenceRowFragmentDoc } from '../../fragments/absenceRow.generated';
import { TrackingRowFragmentDoc } from '../../fragments/trackingRow.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ManualEntryDateCheckQueryVariables = Types.Exact<{
  date: Types.Scalars['DateType'];
  userId: Types.Scalars['Int'];
}>;


export type ManualEntryDateCheckQuery = { __typename?: 'Query', absence: { __typename?: 'absenceQueries', absencesByDate: Array<(
      { __typename?: 'AbsenceRow' }
      & AbsenceRowFragment
    )> }, tracking: { __typename?: 'trackingQueries', trackingRowsByDate: Array<(
      { __typename?: 'TrackingRow' }
      & TrackingRowFragment
    )> }, permission: { __typename?: 'permissionQueries', trackingPermission: { __typename?: 'trackingPermissionQueries', canCreateManualEntryForDate: boolean, canStampOverNight: boolean } } };


export const ManualEntryDateCheckDocument = gql`
    query ManualEntryDateCheck($date: DateType!, $userId: Int!) {
  absence {
    absencesByDate(date: $date, employeeId: $userId) {
      ...AbsenceRow
    }
  }
  tracking {
    trackingRowsByDate(date: $date, employeeId: $userId) {
      ...TrackingRow
    }
  }
  permission {
    trackingPermission {
      canCreateManualEntryForDate(date: $date, userId: $userId)
      canStampOverNight(userId: $userId)
    }
  }
}
    ${AbsenceRowFragmentDoc}
${TrackingRowFragmentDoc}`;

/**
 * __useManualEntryDateCheckQuery__
 *
 * To run a query within a React component, call `useManualEntryDateCheckQuery` and pass it any options that fit your needs.
 * When your component renders, `useManualEntryDateCheckQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManualEntryDateCheckQuery({
 *   variables: {
 *      date: // value for 'date'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useManualEntryDateCheckQuery(baseOptions: Apollo.QueryHookOptions<ManualEntryDateCheckQuery, ManualEntryDateCheckQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ManualEntryDateCheckQuery, ManualEntryDateCheckQueryVariables>(ManualEntryDateCheckDocument, options);
      }
export function useManualEntryDateCheckLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ManualEntryDateCheckQuery, ManualEntryDateCheckQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ManualEntryDateCheckQuery, ManualEntryDateCheckQueryVariables>(ManualEntryDateCheckDocument, options);
        }
export type ManualEntryDateCheckQueryHookResult = ReturnType<typeof useManualEntryDateCheckQuery>;
export type ManualEntryDateCheckLazyQueryHookResult = ReturnType<typeof useManualEntryDateCheckLazyQuery>;
export type ManualEntryDateCheckQueryResult = Apollo.QueryResult<ManualEntryDateCheckQuery, ManualEntryDateCheckQueryVariables>;