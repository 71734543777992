import {Box, Skeleton, Typography} from "@mui/material";
import {AddressElement, PaymentElement, useElements, useStripe} from "@stripe/react-stripe-js";
import {LoadingButton} from "@mui/lab";
import React, {useState} from "react";
import {useSelector} from "react-redux";
import {toast} from "react-toastify";

const PaymentInformation = () => {
        const stripe = useStripe();
        const elements = useElements();

        const user = useSelector((state: any) => state.auth.currentUser);

        const [loading, setLoading] = useState(false);

        const handleSubmit = async (event: any) => {
            event.preventDefault();
            setLoading(true);

            if (!stripe || !elements) {
                return;
            }

            const {error} = await stripe.confirmPayment({
                //`Elements` instance that was used to create the Payment Element
                elements,
                confirmParams: {
                    return_url: process.env.REACT_APP_STRIPE_RETURN_URL ?? 'https://app.mytimetracker.de/select-subscription'
                }
            });

            if (error) {
                if (error.type === 'card_error' || error.type === 'validation_error') {
                    toast.error(error.message);
                } else {
                    toast.error('Ein unbekannter Fehler ist aufgetreten');
                }

            }

            setLoading(false);
        };

        return <Box component='form' onSubmit={handleSubmit}>
            <Typography sx={{my: 3}} variant='h5'>Rechnungsadresse</Typography>
            {stripe === null &&
                <Skeleton height='65px' width='100%'/>
            }
            <AddressElement options={{
                mode: 'billing',
                autocomplete: {mode: 'disabled'},
                defaultValues: {name: `${user?.name} ${user?.surname}`, address: {country: 'DE'}}
            }}/>

            <Typography sx={{my: 3}} variant='h5'>Zahlungsmethode</Typography>
            <PaymentElement/>

            <Box sx={{mt: 3, display: 'flex', justifyContent: 'right'}}>
                <LoadingButton loading={loading} type='submit' variant='contained'
                               color='primary'>Jetzt kostenpflichtig bestellen</LoadingButton>
            </Box>
        </Box>;
    }
;

export default PaymentInformation;
