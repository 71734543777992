import * as Types from '../../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteCustomHolidayMutationVariables = Types.Exact<{
  restPeriodId: Types.Scalars['Int'];
}>;


export type DeleteCustomHolidayMutation = { __typename?: 'Mutation', holiday: { __typename?: 'holidayMutations', deleteCustomHoliday: any } };


export const DeleteCustomHolidayDocument = gql`
    mutation DeleteCustomHoliday($restPeriodId: Int!) {
  holiday {
    deleteCustomHoliday(restPeriodId: $restPeriodId)
  }
}
    `;
export type DeleteCustomHolidayMutationFn = Apollo.MutationFunction<DeleteCustomHolidayMutation, DeleteCustomHolidayMutationVariables>;

/**
 * __useDeleteCustomHolidayMutation__
 *
 * To run a mutation, you first call `useDeleteCustomHolidayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomHolidayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomHolidayMutation, { data, loading, error }] = useDeleteCustomHolidayMutation({
 *   variables: {
 *      restPeriodId: // value for 'restPeriodId'
 *   },
 * });
 */
export function useDeleteCustomHolidayMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCustomHolidayMutation, DeleteCustomHolidayMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCustomHolidayMutation, DeleteCustomHolidayMutationVariables>(DeleteCustomHolidayDocument, options);
      }
export type DeleteCustomHolidayMutationHookResult = ReturnType<typeof useDeleteCustomHolidayMutation>;
export type DeleteCustomHolidayMutationResult = Apollo.MutationResult<DeleteCustomHolidayMutation>;
export type DeleteCustomHolidayMutationOptions = Apollo.BaseMutationOptions<DeleteCustomHolidayMutation, DeleteCustomHolidayMutationVariables>;