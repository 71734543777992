import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateReportMutationVariables = Types.Exact<{
  reportFormat: Types.ReportFormat;
  reportType: Types.ReportType;
  startDate: Types.Scalars['DateType'];
  endDate: Types.Scalars['DateType'];
  employeeIds: Array<Types.Scalars['Int']> | Types.Scalars['Int'];
}>;


export type CreateReportMutation = { __typename?: 'Mutation', reporting: { __typename?: 'reportingMutations', createReport: string } };


export const CreateReportDocument = gql`
    mutation CreateReport($reportFormat: ReportFormat!, $reportType: ReportType!, $startDate: DateType!, $endDate: DateType!, $employeeIds: [Int!]!) {
  reporting {
    createReport(
      reportFormat: $reportFormat
      reportType: $reportType
      startDate: $startDate
      endDate: $endDate
      employeeIds: $employeeIds
    )
  }
}
    `;
export type CreateReportMutationFn = Apollo.MutationFunction<CreateReportMutation, CreateReportMutationVariables>;

/**
 * __useCreateReportMutation__
 *
 * To run a mutation, you first call `useCreateReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReportMutation, { data, loading, error }] = useCreateReportMutation({
 *   variables: {
 *      reportFormat: // value for 'reportFormat'
 *      reportType: // value for 'reportType'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      employeeIds: // value for 'employeeIds'
 *   },
 * });
 */
export function useCreateReportMutation(baseOptions?: Apollo.MutationHookOptions<CreateReportMutation, CreateReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateReportMutation, CreateReportMutationVariables>(CreateReportDocument, options);
      }
export type CreateReportMutationHookResult = ReturnType<typeof useCreateReportMutation>;
export type CreateReportMutationResult = Apollo.MutationResult<CreateReportMutation>;
export type CreateReportMutationOptions = Apollo.BaseMutationOptions<CreateReportMutation, CreateReportMutationVariables>;