import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateCompanySettingMutationVariables = Types.Exact<{
  companySetting: Types.CompanySettingInput;
}>;


export type UpdateCompanySettingMutation = { __typename?: 'Mutation', company: { __typename?: 'companyMutations', updateCompanySetting: any } };


export const UpdateCompanySettingDocument = gql`
    mutation UpdateCompanySetting($companySetting: CompanySettingInput!) {
  company {
    updateCompanySetting(companySetting: $companySetting)
  }
}
    `;
export type UpdateCompanySettingMutationFn = Apollo.MutationFunction<UpdateCompanySettingMutation, UpdateCompanySettingMutationVariables>;

/**
 * __useUpdateCompanySettingMutation__
 *
 * To run a mutation, you first call `useUpdateCompanySettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanySettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanySettingMutation, { data, loading, error }] = useUpdateCompanySettingMutation({
 *   variables: {
 *      companySetting: // value for 'companySetting'
 *   },
 * });
 */
export function useUpdateCompanySettingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompanySettingMutation, UpdateCompanySettingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompanySettingMutation, UpdateCompanySettingMutationVariables>(UpdateCompanySettingDocument, options);
      }
export type UpdateCompanySettingMutationHookResult = ReturnType<typeof useUpdateCompanySettingMutation>;
export type UpdateCompanySettingMutationResult = Apollo.MutationResult<UpdateCompanySettingMutation>;
export type UpdateCompanySettingMutationOptions = Apollo.BaseMutationOptions<UpdateCompanySettingMutation, UpdateCompanySettingMutationVariables>;