import {Grid, Stack, SxProps, Theme, Typography} from "@mui/material";
import React from "react";
import {DateTime} from "luxon";
import {dateToWeekdayString} from "../../utils/display";
import {NewAbsenceOutput} from "../../generated/graphql";
import WorkingTimeText from "./WorkingTimeText";
import {useTranslation} from "react-i18next";

const gridItemStyle: SxProps<Theme> = (theme) => ({
    paddingTop: '0 !important',
    alignItems: 'center'
});

interface AbsenceTimeRowProps {
    absenceRow: NewAbsenceOutput;
}

export const AbsenceTimeRow = ({absenceRow}: AbsenceTimeRowProps) => {
    const {t} = useTranslation();

    const date = DateTime.fromISO(absenceRow.date);
    const dateString = date.toFormat('dd.MM.yyyy');
    const weekDayString = dateToWeekdayString(date);

    const absencePeriodToString = () => {
        switch(absenceRow.absencePeriod) {
            case 'full_time':
                return 'Ganztags';
            case 'half_time':
                return 'Halbtags';
        }
    }

    return <Grid container sx={{
        backgroundColor: '#F8FAFE',
        marginBottom: '0.5rem',
        minHeight: '50px',
        alignItems: 'center',
        borderRadius: '10px',
        marginLeft: 0,
        width: '100%',
        paddingLeft: 2
    }}>
        <Grid item xs sx={{...gridItemStyle, paddingY: 0.5}}>
            <Stack>
                <Typography fontSize={20}>{t(weekDayString)}</Typography>
                <Typography fontSize={12}>{dateString}</Typography>
            </Stack>
        </Grid>
        <Grid item xs display='flex' sx={gridItemStyle}>
            {absenceRow.absencePeriod &&
                <Typography>{absencePeriodToString()}</Typography>
            }
            {!absenceRow.absencePeriod &&
                <WorkingTimeText workingTime={{hours: absenceRow.hours, minutes: absenceRow.minutes}} />
            }
        </Grid>
    </Grid>
};
