import {Box, Grid, Typography} from "@mui/material";
import React from "react";
import WorkingTimeText from "./WorkingTimeText";
import SaldoTimeText from "./SaldoTimeText";
import {useTranslation} from "react-i18next";
import {TimeInformationFragment} from "../../fragments/timeInformation.generated";

interface CurrentTimePeriodInformationProps {
    timeInformation: TimeInformationFragment;
}

export const CurrentTimePeriodInformation = ({timeInformation}: CurrentTimePeriodInformationProps) => {
    const {t} = useTranslation();

    return <Box sx={{backgroundColor: '#F2F2F2', borderRadius: '10px', padding: 2}}>
        <Typography fontWeight='bold'>{t('components.currentTimePeriodInformation.title')}</Typography>
        <Grid container spacing={2} sx={{marginTop: 2}}>
            <Grid item xs={6} sm={3}>
                <Typography>{t('components.currentTimePeriodInformation.recordedTime')}</Typography>
                <WorkingTimeText workingTime={timeInformation.totalTime}/>
            </Grid>
            <Grid item xs={6} sm={3}>
                <Typography>{t('components.currentTimePeriodInformation.workingTime')}</Typography>
                <WorkingTimeText workingTime={timeInformation.totalWorkingTime}/>
            </Grid>
            <Grid item xs={6} sm={3}>
                <Typography>{t('components.currentTimePeriodInformation.absent')}</Typography>
                <WorkingTimeText workingTime={timeInformation.absenceTime}/>
            </Grid>
            {timeInformation.balance &&
                <Grid item xs={6} sm={3}>
                    <Typography>{t('components.currentTimePeriodInformation.balance')}</Typography>
                    <SaldoTimeText saldo={timeInformation.balance}/>
                </Grid>
            }
        </Grid>
    </Box>
};
