import {Box, Grid, Skeleton, SxProps, Theme, Typography} from "@mui/material";
import WorkingTimeByDayRow, {WorkingTimeByDayRowColumn} from "../molecules/WorkingTimeByDayRow";
import AbsenceRow from "../molecules/AbsenceRow";
import React from "react";
import {TimeUnionFragment} from "../../fragments/timeUnion.generated";
import noDataClipboard from "../../images/clippboards.png";
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {useTranslation} from "react-i18next";

const gridItemStyle: SxProps<Theme> = (theme) => ({
    paddingTop: '0 !important'
});

export interface EmployeeHoursTableProps {
    readonly hours?: TimeUnionFragment[];
    readonly employeeId?: number;
    readonly onChange?: () => void;
    readonly columns?: WorkingTimeByDayRowColumn[];
}

const EmployeeHoursTable = ({employeeId, hours, onChange, columns}: EmployeeHoursTableProps) => {
    const {t} = useTranslation();

    const user = useSelector((state: RootState) => state.auth.currentUser);

    const displayColumns = columns ?? ['date', 'startTime', 'endTime', 'balance', 'break', 'workingTime'];

    const isCompactRow = (columns?.length ?? 6) <= 4;

    const displayColumn = (column: WorkingTimeByDayRowColumn) => {
        return displayColumns?.indexOf(column) !== -1;
    };

    const hideMobileDisplay = (column: WorkingTimeByDayRowColumn) => {
        return displayColumn(column) ? {xs: "none", md: "flex"} : 'none';
    };

    // Should never happen anyway
    if (!user) {
        return <></>;
    }

    return <>
        <Grid container spacing={1}
              sx={{
                  marginBottom: '1rem',
                  minHeight: '50px',
                  alignItems: 'center',
                  borderRadius: '10px',
                  marginLeft: 0,
                  width: '100%',
              }}>
            <Grid item xs={isCompactRow ? 1 : 0.5} sx={gridItemStyle}></Grid>
            <Grid item xs sx={gridItemStyle}><b>{t('common.date')}</b></Grid>
            <Grid item xs display={hideMobileDisplay('startTime')} sx={gridItemStyle}><b>{t('common.startTime')}</b></Grid>
            <Grid item xs display={hideMobileDisplay('endTime')} sx={gridItemStyle}><b>{t('common.endTime')}</b></Grid>
            <Grid item xs sx={gridItemStyle}><b>{t('common.balance')}</b></Grid>
            <Grid item xs display={hideMobileDisplay('break')} sx={gridItemStyle}><b>{t('common.break')}</b></Grid>
            <Grid item xs sx={gridItemStyle}><b>{t('common.recordedTime')}</b></Grid>
            <Grid item xs={0.5} sx={gridItemStyle}></Grid>
        </Grid>
        {hours && hours.length > 0 && hours?.map((timeRow, index) => {
            if (timeRow.__typename === 'WorkingTimeByDay') {
                return <WorkingTimeByDayRow columns={displayColumns} key={timeRow.date}
                                            employeeId={employeeId ?? user.userId} workingTimeByDay={timeRow}
                                            onChange={onChange}/>;
            } else if (timeRow.__typename === 'AbsenceRow') {
                return <AbsenceRow key={timeRow.date} absenceRow={timeRow} columns={displayColumns}/>
            } else {
                return <></>;
            }
        })}
        {!hours &&
            Array.from(Array(5).keys()).map((_, index) => {
                return <Skeleton key={index} variant="rounded" width='100%' height={45} sx={{marginTop: '10px'}}/>;
            })
        }
        {hours && hours.length === 0 && (
            <Box sx={{display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: 5}}>
                <Box component='img' sx={{maxHeight: '200px'}} src={noDataClipboard}/>
                <Typography sx={{marginTop: 5}}>{t('noData.noHoursTracked')}</Typography>
            </Box>
        )}
    </>;
}

export default EmployeeHoursTable;
