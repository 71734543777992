import {Grid, Stack} from "@mui/material";
import {Doughnut} from "react-chartjs-2";
import React from "react";
import AbsenceDayLabel from "./AbsenceDayLabel";
import {VacationStatisticsFragment} from "../../fragments/vacationStatistics.generated";
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";

const VacationStatisticsChart = ({vacationStatistics}: { vacationStatistics?: VacationStatisticsFragment }) => {
    const user = useSelector((state: RootState) => state.auth.currentUser);

    const usedDays = (vacationStatistics?.usedMinutes ?? 0) / 60 / 8;
    const pendingDays = (vacationStatistics?.pendingMinutes ?? 0) / 60 / 8;
    const availableDays = (vacationStatistics?.availableMinutes ?? 0) / 60 / 8;

    const vacationDataEmpty = usedDays === 0 && pendingDays === 0 && availableDays === 0;

    const absenceNumberFormat = new Intl.NumberFormat('de-DE', {maximumFractionDigits: 1});

    const isExpertSubscription = user?.stripeSubscription === 'expert';

    const vacationDoughnutData = {
        labels: isExpertSubscription ? ['Aufgebrauchte', 'Beantragte', 'Verfügbare'] : ['Aufgebrauchte'],
        datasets: [
            {
                label: 'Urlaubstage',
                data: isExpertSubscription ? [vacationDataEmpty ? 1 : usedDays, availableDays, pendingDays] : [vacationDataEmpty ? 1 : usedDays],
                backgroundColor: [
                    '#777980',
                    '#6ED675',
                    '#0854CF'
                ],
                borderWidth: 0
            },
        ]
    };

    return <Grid container sx={{display: 'flex', justifyContent: 'center'}}>
        <Grid item>
            <Doughnut style={{maxHeight: '110px'}} data={vacationDoughnutData} options={{
                responsive: true,
                maintainAspectRatio: true,
                plugins: {
                    legend: {
                        display: false
                    },
                    tooltip: {
                        enabled: false
                    }
                }
            }}/>
        </Grid>
        <Grid item>
            <Stack spacing={2}>
                <AbsenceDayLabel backgroundColor='#777980' text='Aufgebrauchte Urlaubstage'
                                 value={absenceNumberFormat.format(usedDays)}/>
                {isExpertSubscription &&
                    <>
                        <AbsenceDayLabel backgroundColor='#0854CF' text='Beantragte Urlaubstage'
                                         value={absenceNumberFormat.format(pendingDays)}/>
                        <AbsenceDayLabel backgroundColor='#6ED675' text='Verfügbare Urlaubstage'
                                         value={absenceNumberFormat.format(availableDays)}/>
                    </>
                }
            </Stack>
        </Grid>
    </Grid>;
}

export default VacationStatisticsChart;
