import {Box, Chip, Skeleton, TextField, Typography} from "@mui/material";
import {Checkbox} from "rsuite";
import React, {useEffect, useState} from "react";
import {CompanySettingFragment} from "../../../../fragments/companySetting.generated";
import {useGetCompanySettingsQuery} from "../../getCompanySettings.generated";
import {useUpdateCompanySettingMutation} from "../../updateCompanySetting.generated";
import {CompanySettingInput} from "../../../../generated/graphql";
import {toast} from "react-toastify";
import {hasAtLeastSubscription} from "../../../../utils/subscription";
import {useSelector} from "react-redux";
import {RootState} from "../../../../store/store";
import {UpgradeSubscriptionScreen} from "../../../../components/organisms/UpgradeSubscriptionScreen";
import {ErrorScreen} from "../../../../components/organisms/ErrorScreen";
import {useFlag} from "@unleash/proxy-client-react";
import {InheritableTrackingSettings} from "../../../../components/settings/InheritableTrackingSettings";
import {useCompanySettingsQuery} from "./companySettings.generated";

const SettingsPage = () => {
    const isNewSettingEnabled = useFlag('MTT-235_new_settings');

    if (isNewSettingEnabled) {
        return <SettingsPageNew/>;
    } else {
        return <SettingsPageOld/>;
    }
}

const SettingsPageNew = () => {
    const user = useSelector((state: RootState) => state.auth.currentUser);

    const {data: newData, loading: newLoading, error: newError} = useCompanySettingsQuery({fetchPolicy: 'no-cache'});

    const {data} = useGetCompanySettingsQuery({fetchPolicy: 'no-cache'});

    const [settings, setSettings] = useState<CompanySettingFragment>();

    useEffect(() => {
        setSettings(data?.company.getCompanySettings);
    }, [data]);

    const [updateCompanySettingMutation] = useUpdateCompanySettingMutation();

    const updateCompanySetting = async (companySetting: CompanySettingInput) => {
        const oldSettings = settings;
        const newSettings = {
            commentsEnabled: companySetting.commentsEnabled,
            workingBalanceDisabled: companySetting.workingBalanceDisabled,
            workingPlaceEnabled: companySetting.workingPlaceEnabled,
            editAbsenceTimeEnabled: companySetting.editAbsenceTimeEnabled,
            workingPlaces: companySetting.workingPlaces
        };
        setSettings(newSettings);

        const {data, errors} = await updateCompanySettingMutation({
            variables: {
                companySetting: newSettings
            }
        });

        if (errors && !data) {
            setSettings(oldSettings);

            toast.error('Es ist ein Fehler aufgetreten');
        }
    };

    const [newWorkingPlace, setNewWorkingPlace] = useState<string>('');

    const createWorkingPlace = (e: React.FormEvent) => {
        e.preventDefault();

        if (settings != null && newWorkingPlace != null && newWorkingPlace !== '') {
            setNewWorkingPlace('');
            updateCompanySetting({
                commentsEnabled: settings.commentsEnabled,
                workingBalanceDisabled: settings.workingBalanceDisabled,
                workingPlaceEnabled: settings.workingPlaceEnabled,
                editAbsenceTimeEnabled: settings.editAbsenceTimeEnabled,
                workingPlaces: [...settings.workingPlaces, newWorkingPlace]
            });
        }
    }

    const deleteWorkingPlace = (workingPlace: string) => {
        if (settings != null) {
            updateCompanySetting({
                commentsEnabled: settings.commentsEnabled,
                workingBalanceDisabled: settings.workingBalanceDisabled,
                workingPlaceEnabled: settings.workingPlaceEnabled,
                editAbsenceTimeEnabled: settings.editAbsenceTimeEnabled,
                workingPlaces: settings.workingPlaces.filter((place) => place !== workingPlace)
            });
        }
    }

    if (!hasAtLeastSubscription(user, 'professional')) {
        return <UpgradeSubscriptionScreen/>;
    }

    return <>
        <InheritableTrackingSettings entity={{entityType: 'company'}}
                                     settings={newData?.setting.settingsForCompany.inheritableSettings}
                                     loading={newLoading}
                                     error={newError}/>

        {settings &&
            <>
                <Typography variant='h5' sx={{marginTop: 3, marginBottom: 1}}>Arbeitsorte</Typography>
                {settings?.workingPlaces &&
                    <Box>
                        <Box component='form' onSubmit={createWorkingPlace}>
                            <TextField value={newWorkingPlace}
                                       onChange={(e) => setNewWorkingPlace(e.target.value)}
                                       variant='outlined' placeholder='Neuer Arbeitsort'/>
                        </Box>
                        <Box sx={{marginTop: 1, display: 'flex', gap: 1}}>
                            {settings.workingPlaces.map((workingPlace, index) => {
                                return <Chip key={index} label={workingPlace} variant="filled"
                                             color='primary'
                                             size='medium'
                                             onDelete={() => deleteWorkingPlace(workingPlace)}/>
                            })}
                        </Box>
                    </Box>
                }
            </>
        }
    </>;
};

const SettingsPageOld = () => {
    const user = useSelector((state: RootState) => state.auth.currentUser);

    const {data, error} = useGetCompanySettingsQuery({fetchPolicy: 'no-cache'});

    const [settings, setSettings] = useState<CompanySettingFragment>();

    useEffect(() => {
        setSettings(data?.company.getCompanySettings);
    }, [data]);

    const [updateCompanySettingMutation] = useUpdateCompanySettingMutation();

    const updateCompanySetting = async (companySetting: CompanySettingInput) => {
        const oldSettings = settings;
        const newSettings = {
            commentsEnabled: companySetting.commentsEnabled,
            workingBalanceDisabled: companySetting.workingBalanceDisabled,
            workingPlaceEnabled: companySetting.workingPlaceEnabled,
            editAbsenceTimeEnabled: companySetting.editAbsenceTimeEnabled,
            workingPlaces: companySetting.workingPlaces
        };
        setSettings(newSettings);

        const {data, errors} = await updateCompanySettingMutation({
            variables: {
                companySetting: newSettings
            }
        });

        if (errors && !data) {
            setSettings(oldSettings);

            toast.error('Es ist ein Fehler aufgetreten');
        }
    };

    const [newWorkingPlace, setNewWorkingPlace] = useState<string>('');

    const createWorkingPlace = (e: React.FormEvent) => {
        e.preventDefault();

        if (settings != null && newWorkingPlace != null && newWorkingPlace !== '') {
            setNewWorkingPlace('');
            updateCompanySetting({
                commentsEnabled: settings.commentsEnabled,
                workingBalanceDisabled: settings.workingBalanceDisabled,
                workingPlaceEnabled: settings.workingPlaceEnabled,
                editAbsenceTimeEnabled: settings.editAbsenceTimeEnabled,
                workingPlaces: [...settings.workingPlaces, newWorkingPlace]
            });
        }
    }

    const deleteWorkingPlace = (workingPlace: string) => {
        if (settings != null) {
            updateCompanySetting({
                commentsEnabled: settings.commentsEnabled,
                workingBalanceDisabled: settings.workingBalanceDisabled,
                workingPlaceEnabled: settings.workingPlaceEnabled,
                editAbsenceTimeEnabled: settings.editAbsenceTimeEnabled,
                workingPlaces: settings.workingPlaces.filter((place) => place !== workingPlace)
            });
        }
    }

    if (!hasAtLeastSubscription(user, 'professional')) {
        return <UpgradeSubscriptionScreen/>;
    }

    if (error) {
        return <ErrorScreen/>;
    }

    return <>
        <p>
            Hier gewählte Einstellungen gelten für alle Mitarbeiter im
            Unternehmen
            <br/>
            Es kann bis zu 5 Minuten dauern, bis die neuen Einstellungen bei
            allen Nutzern sichtbar sind.
        </p>

        <Box sx={{display: 'flex', flexDirection: 'column', mt: 3}}>
            <Typography variant='h6'>Allgemein</Typography>
            {(settings && hasAtLeastSubscription(user, 'professional')) &&
                <Checkbox
                    checked={settings.commentsEnabled}
                    onChange={() => {
                        updateCompanySetting({
                            ...settings,
                            commentsEnabled: !settings.commentsEnabled
                        });
                    }}
                >
                    Kommentare bei Stundenbuchungen verpflichtend
                </Checkbox>
            }
            {(settings && hasAtLeastSubscription(user, 'professional')) &&
                <Checkbox
                    checked={settings.workingPlaceEnabled}
                    onChange={() => {
                        updateCompanySetting({
                            ...settings,
                            workingPlaceEnabled: !settings.workingPlaceEnabled,
                        });
                    }}
                >
                    Arbeitsort bei Stundenbuchungen verpflichtend
                </Checkbox>
            }
            {(settings && hasAtLeastSubscription(user, 'professional')) &&
                <Checkbox
                    checked={settings.workingBalanceDisabled}
                    onChange={() => {
                        updateCompanySetting({
                            ...settings,
                            workingBalanceDisabled: !settings.workingBalanceDisabled,
                        });
                    }}
                >
                    Modus "Stundenliste" (keine festen Arbeitszeiten und damit
                    auch keine Überstunden)
                </Checkbox>
            }
            {(settings && hasAtLeastSubscription(user, 'professional')) &&
                <Checkbox
                    checked={settings.editAbsenceTimeEnabled}
                    onChange={() => {
                        updateCompanySetting({
                            ...settings,
                            editAbsenceTimeEnabled: !settings.editAbsenceTimeEnabled,
                        });
                    }}
                >Mitarbeiter dürfen die Stunden beim Erstellen einer Abwesenheit bearbeiten
                    (standardmäßig werden diese automatisch aus der Arbeitszeit-Vorlage
                    gezogen)</Checkbox>
            }
            {!settings &&
                <>
                    <Skeleton height={38}/>
                    <Skeleton height={38}/>
                    <Skeleton height={38}/>
                </>
            }

            {(settings && hasAtLeastSubscription(user, 'professional')) &&
                <>
                    <Typography variant='h6' sx={{marginTop: 3, marginBottom: 1}}>Arbeitsorte</Typography>
                    {settings?.workingPlaces &&
                        <Box>
                            <Box component='form' onSubmit={createWorkingPlace}>
                                <TextField value={newWorkingPlace}
                                           onChange={(e) => setNewWorkingPlace(e.target.value)}
                                           variant='outlined' placeholder='Neuer Arbeitsort'/>
                            </Box>
                            <Box sx={{marginTop: 1, display: 'flex', gap: 1}}>
                                {settings.workingPlaces.map((workingPlace, index) => {
                                    return <Chip key={index} label={workingPlace} variant="filled"
                                                 color='primary'
                                                 size='medium'
                                                 onDelete={() => deleteWorkingPlace(workingPlace)}/>
                                })}
                            </Box>
                        </Box>
                    }
                </>
            }
            {!settings &&
                <>
                    <Skeleton height={56} width='20%'/>
                    <Box sx={{display: 'flex', width: '100%', gap: 1}}>
                        <Skeleton height={32} width='10%'/>
                        <Skeleton height={32} width='10%'/>
                    </Box>
                </>
            }
        </Box>
    </>;
};

export default SettingsPage;
