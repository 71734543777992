import {createContext} from "react";
import {DateTime} from "luxon";

export interface TrackingDetailDialogContextProps {
    readonly userId?: number;
    readonly date?: DateTime;
    readonly onEditSave?: () => void;
}

export interface TrackingDetailDialogContext {
    readonly dialog: TrackingDetailDialogContextProps;
    readonly showTrackingDetailDialog: (props: TrackingDetailDialogContextProps) => void;
}

export default createContext<TrackingDetailDialogContext>({
    dialog: {}, showTrackingDetailDialog: () => {
    }
});
