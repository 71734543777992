import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EditDayMutationVariables = Types.Exact<{
  employeeId: Types.Scalars['Int'];
  date: Types.Scalars['DateType'];
  trackingRows: Array<Types.NewTrackingRow> | Types.NewTrackingRow;
}>;


export type EditDayMutation = { __typename?: 'Mutation', tracking: { __typename?: 'trackingMutations', editDay: any } };


export const EditDayDocument = gql`
    mutation EditDay($employeeId: Int!, $date: DateType!, $trackingRows: [NewTrackingRow!]!) {
  tracking {
    editDay(employeeId: $employeeId, date: $date, trackingRows: $trackingRows)
  }
}
    `;
export type EditDayMutationFn = Apollo.MutationFunction<EditDayMutation, EditDayMutationVariables>;

/**
 * __useEditDayMutation__
 *
 * To run a mutation, you first call `useEditDayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditDayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editDayMutation, { data, loading, error }] = useEditDayMutation({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      date: // value for 'date'
 *      trackingRows: // value for 'trackingRows'
 *   },
 * });
 */
export function useEditDayMutation(baseOptions?: Apollo.MutationHookOptions<EditDayMutation, EditDayMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditDayMutation, EditDayMutationVariables>(EditDayDocument, options);
      }
export type EditDayMutationHookResult = ReturnType<typeof useEditDayMutation>;
export type EditDayMutationResult = Apollo.MutationResult<EditDayMutation>;
export type EditDayMutationOptions = Apollo.BaseMutationOptions<EditDayMutation, EditDayMutationVariables>;