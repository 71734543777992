import {Box, Grid, Skeleton, SxProps, Theme, Tooltip, Typography} from "@mui/material";
import React from "react";
import {EmployeeVacationTransfer} from "../../generated/graphql";
import {DateTime} from "luxon";
import noDataClipboard from "../../images/clippboards.png";

const gridItemStyle: SxProps<Theme> = (theme) => ({
    paddingTop: '0 !important',
    alignItems: 'center'
});

export const EmployeeVacationTransferTable = ({vacationTransferRows}: {
    vacationTransferRows?: EmployeeVacationTransfer[]
}) => {
    return <>
        <Grid container spacing={1}
              sx={{
                  marginBottom: '1rem',
                  minHeight: '50px',
                  alignItems: 'center',
                  borderRadius: '10px',
                  marginLeft: 0,
                  width: '100%',
              }}>
            <Grid item xs sx={gridItemStyle}><b>Ursprungsjahr</b></Grid>
            <Grid item xs sx={gridItemStyle}><b>Verbleibende Urlaubstage</b></Grid>
            <Grid item xs sx={gridItemStyle}><b>Verfallsdatum</b></Grid>
        </Grid>

        {!vacationTransferRows &&
            <Skeleton variant="rounded" width='100%' height={45} sx={{marginTop: '10px'}}/>
        }
        {vacationTransferRows && vacationTransferRows.length === 0 && (
            <Box sx={{display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: 5}}>
                <Box component='img' sx={{maxHeight: '50px'}} src={noDataClipboard}/>
                <Typography sx={{marginTop: 2}}>Es existiert kein Urlaubsübertrag</Typography>
            </Box>
        )}

        {(vacationTransferRows ?? []).map((transferRow, index) => {
            const vacationDays = transferRow.vacationMinutes / 60 / 8;
            const expirationDate = DateTime.fromISO(transferRow.expirationDate);
            const isExpired = DateTime.now() > expirationDate;

            return <Grid container key={`vacation-transfer-${index}`} spacing={1} sx={{
                backgroundColor: '#F8FAFE',
                marginBottom: '1rem',
                minHeight: '50px',
                alignItems: 'center',
                borderRadius: '10px',
                marginLeft: 0,
                width: '100%',
                ':hover': {
                    backgroundColor: '#E5EDFA'
                }
            }}>
                <Grid item xs sx={gridItemStyle}>{transferRow.year}</Grid>
                <Grid item xs sx={gridItemStyle}>{vacationDays}</Grid>
                <Grid item xs sx={{...gridItemStyle, paddingTop: '0 !important', color: isExpired ? '#EF3A3E' : '#3B3C40'}}>
                    <Tooltip title={isExpired ? 'Der Urlaub ist bereits verfallen' : ''}>
                        <p>{expirationDate.toFormat('dd.MM.yyyy')}</p>
                    </Tooltip>
                </Grid>
            </Grid>;
        })}
    </>;
};
