import * as Types from '../../../../generated/graphql';

import { WorkPlaceFragment } from '../../../../fragments/workPlace.generated';
import { gql } from '@apollo/client';
import { WorkPlaceFragmentDoc } from '../../../../fragments/workPlace.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ListWorkPlacesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ListWorkPlacesQuery = { __typename?: 'Query', workPlace: { __typename?: 'workPlaceQueries', listWorkPlaces: Array<(
      { __typename?: 'WorkPlace' }
      & WorkPlaceFragment
    )> } };


export const ListWorkPlacesDocument = gql`
    query ListWorkPlaces {
  workPlace {
    listWorkPlaces {
      ...WorkPlace
    }
  }
}
    ${WorkPlaceFragmentDoc}`;

/**
 * __useListWorkPlacesQuery__
 *
 * To run a query within a React component, call `useListWorkPlacesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListWorkPlacesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListWorkPlacesQuery({
 *   variables: {
 *   },
 * });
 */
export function useListWorkPlacesQuery(baseOptions?: Apollo.QueryHookOptions<ListWorkPlacesQuery, ListWorkPlacesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListWorkPlacesQuery, ListWorkPlacesQueryVariables>(ListWorkPlacesDocument, options);
      }
export function useListWorkPlacesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListWorkPlacesQuery, ListWorkPlacesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListWorkPlacesQuery, ListWorkPlacesQueryVariables>(ListWorkPlacesDocument, options);
        }
export type ListWorkPlacesQueryHookResult = ReturnType<typeof useListWorkPlacesQuery>;
export type ListWorkPlacesLazyQueryHookResult = ReturnType<typeof useListWorkPlacesLazyQuery>;
export type ListWorkPlacesQueryResult = Apollo.QueryResult<ListWorkPlacesQuery, ListWorkPlacesQueryVariables>;