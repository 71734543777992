import * as Types from '../generated/graphql';

import { gql } from '@apollo/client';
export type VacationStatisticsFragment = { __typename?: 'VacationStatistics', usedMinutes: number, pendingMinutes: number, availableMinutes: number };

export const VacationStatisticsFragmentDoc = gql`
    fragment VacationStatistics on VacationStatistics {
  usedMinutes
  pendingMinutes
  availableMinutes
}
    `;