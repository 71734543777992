import * as Types from '../../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EditDepartmentMutationVariables = Types.Exact<{
  departmentId: Types.Scalars['Int'];
  departmentName: Types.Scalars['String'];
  headEmployee: Types.Scalars['Int'];
  parentId: Types.Scalars['Int'];
}>;


export type EditDepartmentMutation = { __typename?: 'Mutation', department: { __typename?: 'departmentMutations', editDepartment: any } };


export const EditDepartmentDocument = gql`
    mutation EditDepartment($departmentId: Int!, $departmentName: String!, $headEmployee: Int!, $parentId: Int!) {
  department {
    editDepartment(
      departmentId: $departmentId
      departmentName: $departmentName
      headEmployee: $headEmployee
      parentId: $parentId
    )
  }
}
    `;
export type EditDepartmentMutationFn = Apollo.MutationFunction<EditDepartmentMutation, EditDepartmentMutationVariables>;

/**
 * __useEditDepartmentMutation__
 *
 * To run a mutation, you first call `useEditDepartmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditDepartmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editDepartmentMutation, { data, loading, error }] = useEditDepartmentMutation({
 *   variables: {
 *      departmentId: // value for 'departmentId'
 *      departmentName: // value for 'departmentName'
 *      headEmployee: // value for 'headEmployee'
 *      parentId: // value for 'parentId'
 *   },
 * });
 */
export function useEditDepartmentMutation(baseOptions?: Apollo.MutationHookOptions<EditDepartmentMutation, EditDepartmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditDepartmentMutation, EditDepartmentMutationVariables>(EditDepartmentDocument, options);
      }
export type EditDepartmentMutationHookResult = ReturnType<typeof useEditDepartmentMutation>;
export type EditDepartmentMutationResult = Apollo.MutationResult<EditDepartmentMutation>;
export type EditDepartmentMutationOptions = Apollo.BaseMutationOptions<EditDepartmentMutation, EditDepartmentMutationVariables>;