import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateAbsenceRequestMutationVariables = Types.Exact<{
  employeeId?: Types.InputMaybe<Types.Scalars['Int']>;
  absenceType: Types.AbsenceType;
  startDate: Types.Scalars['DateType'];
  endDate: Types.Scalars['DateType'];
  absenceRows: Array<Types.NewAbsence> | Types.NewAbsence;
  comment?: Types.InputMaybe<Types.Scalars['String']>;
  deputyUserId?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type CreateAbsenceRequestMutation = { __typename?: 'Mutation', absence: { __typename?: 'absenceMutations', request: { __typename?: 'requestMutations', createAbsenceRequest: number } } };


export const CreateAbsenceRequestDocument = gql`
    mutation CreateAbsenceRequest($employeeId: Int, $absenceType: AbsenceType!, $startDate: DateType!, $endDate: DateType!, $absenceRows: [NewAbsence!]!, $comment: String, $deputyUserId: Int) {
  absence {
    request {
      createAbsenceRequest(
        employeeId: $employeeId
        absenceType: $absenceType
        startDate: $startDate
        endDate: $endDate
        absenceRows: $absenceRows
        comment: $comment
        deputyUserId: $deputyUserId
      )
    }
  }
}
    `;
export type CreateAbsenceRequestMutationFn = Apollo.MutationFunction<CreateAbsenceRequestMutation, CreateAbsenceRequestMutationVariables>;

/**
 * __useCreateAbsenceRequestMutation__
 *
 * To run a mutation, you first call `useCreateAbsenceRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAbsenceRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAbsenceRequestMutation, { data, loading, error }] = useCreateAbsenceRequestMutation({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      absenceType: // value for 'absenceType'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      absenceRows: // value for 'absenceRows'
 *      comment: // value for 'comment'
 *      deputyUserId: // value for 'deputyUserId'
 *   },
 * });
 */
export function useCreateAbsenceRequestMutation(baseOptions?: Apollo.MutationHookOptions<CreateAbsenceRequestMutation, CreateAbsenceRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAbsenceRequestMutation, CreateAbsenceRequestMutationVariables>(CreateAbsenceRequestDocument, options);
      }
export type CreateAbsenceRequestMutationHookResult = ReturnType<typeof useCreateAbsenceRequestMutation>;
export type CreateAbsenceRequestMutationResult = Apollo.MutationResult<CreateAbsenceRequestMutation>;
export type CreateAbsenceRequestMutationOptions = Apollo.BaseMutationOptions<CreateAbsenceRequestMutation, CreateAbsenceRequestMutationVariables>;