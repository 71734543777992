import * as Types from '../generated/graphql';

import { gql } from '@apollo/client';
export type GrantUserTerminalAccessResponseType_GrantUserTerminalAccessEmailSent_Fragment = { __typename: 'GrantUserTerminalAccessEmailSent', success: boolean };

export type GrantUserTerminalAccessResponseType_GrantUserTerminalAccessInitialPin_Fragment = { __typename: 'GrantUserTerminalAccessInitialPin', initialPin: string };

export type GrantUserTerminalAccessResponseTypeFragment = GrantUserTerminalAccessResponseType_GrantUserTerminalAccessEmailSent_Fragment | GrantUserTerminalAccessResponseType_GrantUserTerminalAccessInitialPin_Fragment;

export const GrantUserTerminalAccessResponseTypeFragmentDoc = gql`
    fragment GrantUserTerminalAccessResponseType on GrantUserTerminalAccessResponseType {
  __typename
  ... on GrantUserTerminalAccessInitialPin {
    initialPin
  }
  ... on GrantUserTerminalAccessEmailSent {
    success
  }
}
    `;