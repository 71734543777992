import {WorkingTimeFragment} from "../../fragments/workingTime.generated";
import {Tooltip, Typography} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";

export interface WorkingTimeTextProps {
    readonly workingTime: WorkingTimeFragment | Duration;
    readonly color?: 'primary' | 'warning' | 'error';
    readonly errorMessage?: string;
}

const WorkingTimeText = (props: WorkingTimeTextProps) => {
    const {hours, minutes} = props.workingTime;
    let htmlColor;

    switch (props.color) {
        case "warning":
            htmlColor = '#FAC20A'
            break;
        case "error":
            htmlColor = '#EF3A3E'
    }

    const errorColor: string | undefined = htmlColor;

    return <Tooltip title={props.errorMessage}>
        <Typography sx={{color: errorColor, marginBottom: 0}}>
            <WorkingTimeTextSpan workingTime={{hours, minutes}}/>
        </Typography>
    </Tooltip>;
};

export const WorkingTimeTextSpan = (props: WorkingTimeTextProps) => {
    const {t} = useTranslation();

    const {hours, minutes} = props.workingTime;

    return <>
        {hours !== 0 &&
            <>
                <span>{hours} </span>
                <span style={{fontSize: '12px'}}>{t('common.hourShort')} </span>
            </>
        }
        <span>{minutes.toFixed(0)} </span>
        <span style={{fontSize: '12px'}}>{t('common.minuteShort')}</span>
    </>;
};

export default WorkingTimeText;
