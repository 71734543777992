import {Box, Button, Stack, Typography} from "@mui/material";
import infoCreatureImage from "../../images/info_creature.png";
import {ProductAdvantage} from "../molecules/ProductAdvantage";
import React from "react";
import {useNavigate} from "react-router-dom";

export const UpgradeSubscriptionScreen = () => {
    const navigate = useNavigate();

    return <Stack alignItems='center' justifyContent='center' sx={{mt: 2}}>
        <Typography variant='h4'>Jetzt neue Funktionen entdecken!</Typography>
        <Box component='img' src={infoCreatureImage} sx={{width: '30%'}}/>
        <Typography sx={{mt: 3}}>Die gewünschte Seite konnte nicht geöffnet werden, da sie nicht ein deinem gebuchten
            Paket enthalten ist!</Typography>
        <Typography variant='h5' sx={{mt: 4}}>Buche jetzt „Expert“, um Dein Unternehmen noch besser zu
            verwalten:</Typography>
        <Box sx={{mt: 2}}>
            <ProductAdvantage>
                <Typography>Teamkalendar für bessere Übersicht</Typography>
            </ProductAdvantage>
            <ProductAdvantage>
                <Typography>Urlaubskonto mit Urlaubsmodellen</Typography>
            </ProductAdvantage>
            <ProductAdvantage>
                <Typography>Abteilungen und Genehmigen für Abwesenheiten</Typography>
            </ProductAdvantage>
            <ProductAdvantage>
                <Typography>Automatischer Pausenabzug möglich</Typography>
            </ProductAdvantage>
        </Box>
        <Button sx={{mt: 3}} variant='contained' color='primary'
                onClick={() => navigate('/company-settings/subscription')}>ZUR BUCHUNG</Button>
    </Stack>;
};
