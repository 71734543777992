import {useParams} from "react-router-dom";
import {ContentHeader} from "../../components";
import {Box, Button, Card, CardContent, Grid, Stack, TextField, Typography} from "@mui/material";
import React, {useState} from "react";
import {useAbsenceRequestByIdQuery} from "./absenceRequestById.generated";
import {DateTime} from "luxon";
import {absenceTypeToString} from "../../utils/display";
import {useUpdateAbsenceRequestStatusMutation} from "./updateAbsenceRequestStatus.generated";
import {AbsenceRequestStatus} from "../../generated/graphql";
import {toast} from "react-toastify";
import {LoadingButton} from "@mui/lab";
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {useFormik} from "formik";
import * as Yup from "yup";
import {EmployeeInformation} from "../../components/molecules/EmployeeInformation";
import {UserAvatar} from "../../components/atoms/UserAvatar";
import {
    CancelAbsenceRequestDialog
} from "../../components/organisms/CancelAbsenceRequestDialog/CancelAbsenceRequestDialog";
import {AbsenceTimeRow} from "../../components/molecules/AbsenceTimeRow";
import {AbsenceRequestBigStatusComponent} from "../../components/molecules/AbsenceRequestBigStatusComponent";
import {hasAtLeastSubscription} from "../../utils/subscription";
import {useTranslation} from "react-i18next";

const ViewAbsenceRequestPage = () => {
    const {t} = useTranslation();

    const {id} = useParams();
    const user = useSelector((state: RootState) => state.auth.currentUser);

    const absenceRequestId = Number(id);

    const [cancelAbsenceRequestDialogOpen, setCancelAbsenceRequestDialogOpen] = useState(false);

    const [updateAbsenceRequestStatusMutation, {loading: updateAbsenceLoading}] = useUpdateAbsenceRequestStatusMutation();

    const {data, loading, refetch} = useAbsenceRequestByIdQuery({
        variables: {
            absenceRequestId: absenceRequestId
        }
    });

    const updateAbsenceRequestStatus = async (absenceRequestStatus: AbsenceRequestStatus, comment: string | undefined): Promise<void> => {
        const {data, errors} = await updateAbsenceRequestStatusMutation({
            variables: {
                absenceRequestId: absenceRequestId,
                status: absenceRequestStatus,
                comment: comment
            }
        });

        if (errors) {
            const error = errors[0];
            const reason = error.extensions?.['reason'];

            if (reason === 'AuthenticationRequired') {
                toast.error('Melde dich erneut an und versuche es nochmal.');
            } else if (reason === 'PermissionDenied') {
                toast.error('Du hast nicht die nötigen Berechtigungen');
            } else if (reason === 'AbsenceRequestNotFound') {
                toast.error('Der Antrag auf Abwesenheit wurde nicht gefunden');
            } else {
                toast.error('Ein unerwarteter Fehler ist aufgetreten');
            }
        } else if (data) {
            refetch();
            toast.success(`${user?.stripeSubscription === 'expert' ? 'Antrag auf Abwesenheit' : 'Abwesenheit'} wurde erfolgreich aktualisiert`);
        } else {
            toast.error('Ein unbekannter Fehler ist aufgetreten');
        }
    };

    const {handleChange, values, handleBlur, touched, errors} = useFormik({
        initialValues: {
            comment: '',
        },
        validationSchema: Yup.object({
            comment: Yup.string()
        }),
        onSubmit: (values) => {
        }
    });

    const absenceRequest = data?.absence.absenceRequestById;

    const isRequester = absenceRequest && user && absenceRequest?.employeeId === user?.userId;

    let dateRangeDays = 0;
    if (absenceRequest) {
        dateRangeDays = DateTime.fromISO(absenceRequest.endDate).diff(DateTime.fromISO(absenceRequest.startDate), ['days']).days + 1;
    }

    const onCloseCancelAbsenceRequestDialog = () => {
        setCancelAbsenceRequestDialogOpen(false);
        refetch();
    }

    if (!user) {
        return <></>;
    }

    const absenceRequestString = absenceRequest ? t(absenceTypeToString(absenceRequest.absenceType)) : ''

    return <>
        <ContentHeader
            title={user?.stripeSubscription === 'expert' ? `Antrag auf ${absenceRequestString}` : absenceRequestString}
        />

        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <Card>
                    <CardContent>
                        <Typography variant='h6'>Details</Typography>
                    </CardContent>
                    <CardContent>
                        {absenceRequest &&
                            <Box sx={{marginBottom: 4}}>
                                <AbsenceRequestBigStatusComponent absenceRequestStatus={absenceRequest.status}/>
                            </Box>
                        }

                        {!isRequester && absenceRequest &&
                            <Box sx={{mb: 4, display: 'flex', gap: 2, alignItems: 'center'}}>
                                <UserAvatar user={{
                                    name: absenceRequest?.employee.name ?? '',
                                    surname: absenceRequest?.employee.surname
                                }}/>
                                <Typography>{absenceRequest.employee.name} {absenceRequest.employee.surname}</Typography>
                            </Box>
                        }
                        <Grid container spacing={2}>
                            <EmployeeInformation xs={6} sm={4} md={6} label='Start-Datum' loading={loading}>
                                {DateTime.fromISO(absenceRequest?.startDate).toFormat('dd.MM.yyyy')}
                            </EmployeeInformation>
                            <EmployeeInformation xs={6} sm={4} md={6} label='End-Datum' loading={loading}>
                                {DateTime.fromISO(absenceRequest?.endDate).toFormat('dd.MM.yyyy')}
                            </EmployeeInformation>
                            <EmployeeInformation xs={6} sm={4} md={6} label='Dauer' loading={loading}>
                                {dateRangeDays} {dateRangeDays === 1 ? 'Tag' : 'Tage'}
                            </EmployeeInformation>
                            {absenceRequest?.comment &&
                                <EmployeeInformation xs={12} md={12} label='Kommentar' loading={loading}>
                                    {absenceRequest.comment}
                                </EmployeeInformation>
                            }
                            <Box sx={{marginX: 2, marginTop: 4, width: '100%'}}>
                                <Grid container spacing={1}>
                                    {absenceRequest?.rows.map((row, index) => {
                                        return <Grid key={`absence-row-${index}`} item xs={12} lg={6}>
                                            <AbsenceTimeRow absenceRow={row}/>
                                        </Grid>;
                                    })}
                                </Grid>
                            </Box>
                        </Grid>
                        <Box sx={{mt: 5}}>
                            {isRequester && absenceRequest?.status === 'pending' &&
                                <Box sx={{display: 'flex', justifyContent: 'end'}}>
                                    <LoadingButton loading={updateAbsenceLoading} color='error' variant='contained'
                                                   onClick={() => updateAbsenceRequestStatus('withdrawn', undefined)}>
                                        Zurückziehen
                                    </LoadingButton>
                                </Box>
                            }
                            {!isRequester && absenceRequest?.status === 'pending' &&
                                <>
                                    <EmployeeInformation xs={12} sm={12} md={12} label='Kommentar (optional)'>
                                        <TextField
                                            id="comment"
                                            name='comment'
                                            multiline
                                            fullWidth
                                            rows={4}
                                            placeholder="Gib einen Kommentar an"
                                            value={values.comment}
                                            error={!!(errors.comment && touched.comment)}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            variant='outlined'
                                        />
                                    </EmployeeInformation>
                                    <Box sx={{display: 'flex', justifyContent: 'end', gap: 2, mt: 2}}>
                                        <LoadingButton
                                            loading={updateAbsenceLoading}
                                            color='error'
                                            variant='contained'
                                            onClick={() => updateAbsenceRequestStatus('rejected', values.comment)}
                                        >Ablehnen</LoadingButton>
                                        <LoadingButton
                                            loading={updateAbsenceLoading}
                                            color='success'
                                            variant='contained'
                                            sx={{color: '#fff'}}
                                            onClick={() => updateAbsenceRequestStatus('approved', values.comment)}
                                        >Genehmigen</LoadingButton>
                                    </Box>
                                </>
                            }
                            {absenceRequest?.status === 'approved' &&
                                <Box sx={{display: 'flex', justifyContent: 'end'}}>
                                    <CancelAbsenceRequestDialog absenceRequest={absenceRequest}
                                                                open={cancelAbsenceRequestDialogOpen}
                                                                onClose={onCloseCancelAbsenceRequestDialog}/>
                                    <Button color='error' variant='contained'
                                            onClick={() => setCancelAbsenceRequestDialogOpen(true)}>
                                        {(isRequester && user?.userType !== 'admin') ? (hasAtLeastSubscription(user, 'expert') ? 'STORNIERUNG BEANTRAGEN' : 'LÖSCHEN') : (hasAtLeastSubscription(user, 'expert') ? 'STORNIEREN' : 'LÖSCHEN')}
                                    </Button>
                                </Box>
                            }
                            {!isRequester && absenceRequest?.status === 'cancellationPending' &&
                                <Box sx={{display: 'flex', justifyContent: 'end'}}>
                                    <Box sx={{display: 'flex', justifyContent: 'end', gap: 2, mt: 2}}>
                                        <LoadingButton
                                            loading={updateAbsenceLoading}
                                            color='error'
                                            variant='contained'
                                            onClick={() => updateAbsenceRequestStatus('approved', values.comment)}
                                        >Nicht stornieren</LoadingButton>
                                        <LoadingButton
                                            loading={updateAbsenceLoading}
                                            color='success'
                                            variant='contained'
                                            sx={{color: '#fff'}}
                                            onClick={() => updateAbsenceRequestStatus('rejected', values.comment)}
                                        >Stornieren</LoadingButton>
                                    </Box>
                                </Box>
                            }
                        </Box>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} md={6}>
                <Card>
                    <CardContent>
                        <Typography variant='h6'>Verlauf</Typography>
                    </CardContent>
                    <CardContent>
                        {absenceRequest?.logs.map(((row, index) => {
                            const displayText = () => {
                                switch (row.newStatus) {
                                    case 'approved':
                                        return 'hat den Antrag genehmigt';
                                    case "rejected":
                                        return 'hat den Antrag abgelehnt';
                                    case "withdrawn":
                                        return 'hat den Antrag zurückgezogen';
                                    case "pending":
                                        if (row.oldStatus === null) {
                                            return `hat einen Antrag auf ${t(absenceTypeToString(absenceRequest!.absenceType))} gestellt`;
                                        } else {
                                            return 'hat den Antrag angepasst';
                                        }
                                    case "cancellationPending":
                                        return "hat die Stornierung beantragt";
                                }
                            }

                            return <Grid key={index} container spacing={2} sx={{mb: 2}}>
                                <Grid item xs={1}>
                                    <UserAvatar user={{name: row.user.name ?? '', surname: row.user.surname}}/>
                                </Grid>
                                <Grid item xs={11}>
                                    <Box sx={{width: '100%', px: 1}}>
                                        <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                                            <Typography>{row.user.name} {row.user.surname}</Typography>
                                            <Typography>{DateTime.fromISO(row.createdAt).toFormat('dd.MM.yyyy')}</Typography>
                                        </Box>
                                        <Typography>{displayText()}</Typography>
                                        {row.comment &&
                                            <Stack direction='row'>
                                                <Typography fontStyle='italic'
                                                            sx={{mt: 1}}>Kommentar:</Typography>
                                                <Typography sx={{mt: 1, ml: 1}}>{row.comment}</Typography>
                                            </Stack>
                                        }
                                    </Box>
                                </Grid>
                            </Grid>;
                        }))}
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    </>;
};

export default ViewAbsenceRequestPage;
