import {Grid, Stack} from "@mui/material";
import {Doughnut} from "react-chartjs-2";
import React from "react";
import {PresenceStatisticsFragment} from "../../fragments/presenceStatistics.generated";
import AbsenceDayLabel from "./AbsenceDayLabel";

const PresenceStatisticsChart = ({presenceStatistics}: {presenceStatistics?: PresenceStatisticsFragment}) => {
    const totalMinutes: number = (presenceStatistics?.illnessMinutes ?? 0) + (presenceStatistics?.presenceMinutes ?? 0) + (presenceStatistics?.vacationMinutes ?? 0);
    const presenceDataEmpty = totalMinutes === 0;

    const illnessPercentage = presenceDataEmpty ? 0 : (presenceStatistics?.illnessMinutes ?? 0) / totalMinutes;
    const presencePercentage = presenceDataEmpty ? 0 : (presenceStatistics?.presenceMinutes ?? 0) / totalMinutes;
    const vacationPercentage = presenceDataEmpty ? 0 : (presenceStatistics?.vacationMinutes ?? 0) / totalMinutes;

    const presenceNumberFormat = new Intl.NumberFormat('de-DE', {maximumFractionDigits: 1, style: 'percent'});

    const presenceDoughnutData = {
        labels: ['Krankheit', 'Urlaub', 'Anwesend'],
        datasets: [
            {
                label: 'Anwesenheit',
                data: [illnessPercentage, vacationPercentage, presenceDataEmpty ? 1 : presencePercentage],
                backgroundColor: [
                    '#EF3A3E',
                    '#0854CF',
                    '#6ED675'
                ],
                borderWidth: 0
            },
        ]
    };

    return <Grid container sx={{display: 'flex', justifyContent: 'center'}}>
        <Grid item>
            <Doughnut style={{maxHeight: '110px'}} data={presenceDoughnutData} options={{
                responsive: true,
                maintainAspectRatio: true,
                plugins: {
                    legend: {
                        display: false
                    },
                    tooltip: {
                        enabled: false
                    }
                }
            }}/>
        </Grid>
        <Grid item>
            <Stack spacing={2}>
                <AbsenceDayLabel backgroundColor='#EF3A3E' text='Krankheit'
                                 value={presenceNumberFormat.format(illnessPercentage)}/>
                <AbsenceDayLabel backgroundColor='#0854CF' text='Urlaub'
                                 value={presenceNumberFormat.format(vacationPercentage)}/>
                <AbsenceDayLabel backgroundColor='#6ED675' text='Anwesend'
                                 value={presenceNumberFormat.format(presencePercentage)}/>
            </Stack>
        </Grid>
    </Grid>;
};

export default PresenceStatisticsChart;
