import {Navigate, useNavigate, useParams} from "react-router-dom";
import {ContentHeader} from "../../components";
import {Card, Link, SxProps, Tab, Tabs, Theme} from "@mui/material";
import {TabContext, TabPanel} from "@mui/lab";
import React from "react";
import {useRouteMatch} from "../../utils/useRouteMatch";
import {useDepartmentByIdQuery} from "./departmentById.generated";
import DepartmentAdministration from "./pages/DepartmentAdministration/DepartmentAdministration";
import { DepartmentSettings } from "./pages/DepartmentSettings/DepartmentSettings";
import {useFlag} from "@unleash/proxy-client-react";
import { DepartmentCalendar } from "./pages/DepartmentCalendar/DepartmentCalendar";

const tabStyle: SxProps<Theme> = (theme) => ({
    textDecoration: 'none',
    ':hover': {
        textDecoration: 'none'
    },
    ':focus': {
        textDecoration: 'none'
    }
});

const ViewDepartment = () => {
    const {id} = useParams();
    const navigate = useNavigate();

    const departmentId = Number(id);

    const isNewSettingEnabled = useFlag('MTT-235_new_settings');

    const {
        data,
        refetch: refetchDepartmentById
    } = useDepartmentByIdQuery({
        variables: {
            departmentId: departmentId
        }
    });

    const department = data?.department.departmentById;

    const routeMatch = useRouteMatch([
        `/department/:departmentId/administration`,
        `/department/:departmentId/settings`,
        `/department/:departmentId/calendar`,
    ]);

    const tabValue = routeMatch?.pattern?.path;

    if (!tabValue) {
        return <Navigate to={`/department/${departmentId}/administration`} replace={true}/>;
    }

    if(data && !department?.parentId) {
        return <Navigate to='/company-settings/settings' replace={true}/>
    }

    return (
        <>
            <ContentHeader
                title={`Abteilung ${department?.name}`}
            />

            <Card>
                <Tabs value={tabValue}>
                    <Tab
                        label="Verwaltung"
                        value="/department/:departmentId/administration"
                        component={Link}
                        onClick={() => navigate(`/department/${departmentId}/administration`)}
                        sx={tabStyle}
                    />
                    <Tab
                        label="Kalender"
                        value="/department/:departmentId/calendar"
                        component={Link}
                        onClick={() => navigate(`/department/${departmentId}/calendar`)}
                        sx={tabStyle}
                    />
                    {isNewSettingEnabled &&
                        <Tab
                            label="Einstellungen"
                            value="/department/:departmentId/settings"
                            component={Link}
                            onClick={() => navigate(`/department/${departmentId}/settings`)}
                            sx={tabStyle}
                        />
                    }
                </Tabs>
                <TabContext value={tabValue}>
                    <TabPanel value="/department/:departmentId/administration">
                        <DepartmentAdministration department={department} onEditComplete={refetchDepartmentById} />
                    </TabPanel>
                    <TabPanel value="/department/:departmentId/settings">
                        <DepartmentSettings department={department} />
                    </TabPanel>
                    <TabPanel value="/department/:departmentId/calendar">
                        <DepartmentCalendar department={department} />
                    </TabPanel>
                </TabContext>
            </Card>
        </>
    );
};

export default ViewDepartment;
