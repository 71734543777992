import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateAbsenceRequestStatusMutationVariables = Types.Exact<{
  absenceRequestId: Types.Scalars['Int'];
  status: Types.AbsenceRequestStatus;
  comment?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type UpdateAbsenceRequestStatusMutation = { __typename?: 'Mutation', absence: { __typename?: 'absenceMutations', request: { __typename?: 'requestMutations', updateAbsenceRequestStatus: any } } };


export const UpdateAbsenceRequestStatusDocument = gql`
    mutation UpdateAbsenceRequestStatus($absenceRequestId: Int!, $status: AbsenceRequestStatus!, $comment: String) {
  absence {
    request {
      updateAbsenceRequestStatus(
        absenceRequestId: $absenceRequestId
        status: $status
        comment: $comment
      )
    }
  }
}
    `;
export type UpdateAbsenceRequestStatusMutationFn = Apollo.MutationFunction<UpdateAbsenceRequestStatusMutation, UpdateAbsenceRequestStatusMutationVariables>;

/**
 * __useUpdateAbsenceRequestStatusMutation__
 *
 * To run a mutation, you first call `useUpdateAbsenceRequestStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAbsenceRequestStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAbsenceRequestStatusMutation, { data, loading, error }] = useUpdateAbsenceRequestStatusMutation({
 *   variables: {
 *      absenceRequestId: // value for 'absenceRequestId'
 *      status: // value for 'status'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useUpdateAbsenceRequestStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAbsenceRequestStatusMutation, UpdateAbsenceRequestStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAbsenceRequestStatusMutation, UpdateAbsenceRequestStatusMutationVariables>(UpdateAbsenceRequestStatusDocument, options);
      }
export type UpdateAbsenceRequestStatusMutationHookResult = ReturnType<typeof useUpdateAbsenceRequestStatusMutation>;
export type UpdateAbsenceRequestStatusMutationResult = Apollo.MutationResult<UpdateAbsenceRequestStatusMutation>;
export type UpdateAbsenceRequestStatusMutationOptions = Apollo.BaseMutationOptions<UpdateAbsenceRequestStatusMutation, UpdateAbsenceRequestStatusMutationVariables>;