import * as Types from '../../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EditWorkPlaceMutationVariables = Types.Exact<{
  workPlaceId: Types.Scalars['Int'];
  name: Types.Scalars['String'];
  country?: Types.InputMaybe<Types.Scalars['String']>;
  state?: Types.InputMaybe<Types.Scalars['String']>;
  city?: Types.InputMaybe<Types.Scalars['String']>;
  address?: Types.InputMaybe<Types.Scalars['String']>;
  postalCode?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type EditWorkPlaceMutation = { __typename?: 'Mutation', workPlace: { __typename?: 'workPlaceMutations', editWorkPlace: any } };


export const EditWorkPlaceDocument = gql`
    mutation EditWorkPlace($workPlaceId: Int!, $name: String!, $country: String, $state: String, $city: String, $address: String, $postalCode: String) {
  workPlace {
    editWorkPlace(
      workPlaceId: $workPlaceId
      name: $name
      country: $country
      state: $state
      city: $city
      address: $address
      postalCode: $postalCode
    )
  }
}
    `;
export type EditWorkPlaceMutationFn = Apollo.MutationFunction<EditWorkPlaceMutation, EditWorkPlaceMutationVariables>;

/**
 * __useEditWorkPlaceMutation__
 *
 * To run a mutation, you first call `useEditWorkPlaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditWorkPlaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editWorkPlaceMutation, { data, loading, error }] = useEditWorkPlaceMutation({
 *   variables: {
 *      workPlaceId: // value for 'workPlaceId'
 *      name: // value for 'name'
 *      country: // value for 'country'
 *      state: // value for 'state'
 *      city: // value for 'city'
 *      address: // value for 'address'
 *      postalCode: // value for 'postalCode'
 *   },
 * });
 */
export function useEditWorkPlaceMutation(baseOptions?: Apollo.MutationHookOptions<EditWorkPlaceMutation, EditWorkPlaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditWorkPlaceMutation, EditWorkPlaceMutationVariables>(EditWorkPlaceDocument, options);
      }
export type EditWorkPlaceMutationHookResult = ReturnType<typeof useEditWorkPlaceMutation>;
export type EditWorkPlaceMutationResult = Apollo.MutationResult<EditWorkPlaceMutation>;
export type EditWorkPlaceMutationOptions = Apollo.BaseMutationOptions<EditWorkPlaceMutation, EditWorkPlaceMutationVariables>;