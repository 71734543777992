import {useListWorkPlacesQuery} from "./listWorkPlaces.generated";
import {Box, Button, Grid, Skeleton, SxProps, Theme} from "@mui/material";
import React, {useState} from "react";
import {Link} from "react-router-dom";
import {CSSProperties} from "styled-components";
import {CreateWorkPlaceDialog} from "../../../../components/organisms/CreateWorkPlaceDialog/CreateWorkPlaceDialog";
import {WorkPlaceContextMenu} from "../../../../components/molecules/WorkPlaceContextMenu/WorkPlaceContextMenu";

const gridItemStyle: SxProps<Theme> = (theme) => ({
    paddingTop: '10px',
    paddingBottom: '10px',
    textDecoration: 'none'
});

const linkNoDecorationStyle: CSSProperties = {textDecoration: 'none', color: '#333333'};

export const WorkPlacesPage = () => {
    const [createWorkPlaceDialogOpen, setCreateWorkPlaceDialogOpen] = useState(false);
    const {data, loading, refetch} = useListWorkPlacesQuery();

    const workPlaces = data?.workPlace.listWorkPlaces ?? [];

    return <div>
        <Box sx={{display: 'flex', justifyContent: 'end'}}>
            <CreateWorkPlaceDialog open={createWorkPlaceDialogOpen}
                                   onClose={() => setCreateWorkPlaceDialogOpen(false)}/>
            <Button variant='contained' onClick={() => setCreateWorkPlaceDialogOpen(true)}>Neuer Standort</Button>
        </Box>
        <Grid container spacing={1}
              sx={{
                  marginTop: 2,
                  marginBottom: '1rem',
                  minHeight: '50px',
                  alignItems: 'center',
                  borderRadius: '10px'
              }}>
            <Grid item xs={0.5} sx={gridItemStyle}></Grid>
            <Grid item xs sx={gridItemStyle}><b>Name</b></Grid>
            <Grid item xs sx={gridItemStyle}><b>Adresse</b></Grid>
            <Grid item xs sx={gridItemStyle}><b>Land</b></Grid>
            <Grid item xs sx={gridItemStyle}><b>Bundesland</b></Grid>
            <Grid item xs={0.5}></Grid>
        </Grid>
        {!loading && workPlaces && workPlaces.length > 0 && (
            workPlaces.map((workPlace, index) => {
                return <Grid key={index} container spacing={1} sx={{
                    backgroundColor: '#F8FAFE',
                    marginBottom: '1rem',
                    minHeight: '50px',
                    alignItems: 'center',
                    borderRadius: '10px',
                    width: '100%',
                    marginLeft: 0,
                    ':hover': {
                        backgroundColor: '#E5EDFA'
                    }
                }}>
                    <Grid item xs={11} sm={11.5} sx={{paddingTop: '0 !important'}}>
                        <Link to={`/work-place/${workPlace.workPlaceId}`} style={linkNoDecorationStyle}>
                            <Grid container sx={{alignItems: 'center'}}>
                                <Grid item xs={0.5} sx={gridItemStyle}></Grid>
                                <Grid item xs sx={gridItemStyle}>{workPlace.name}</Grid>
                                <Grid item xs sx={gridItemStyle}>{workPlace.address}</Grid>
                                <Grid item xs sx={gridItemStyle}>{workPlace.country}</Grid>
                                <Grid item xs sx={gridItemStyle}>{workPlace.state}</Grid>
                            </Grid>
                        </Link>
                    </Grid>
                    <Grid item xs={1} sm={0.5}
                          sx={{...gridItemStyle, paddingLeft: '0 !important', paddingTop: '0 !important'}}>
                        <WorkPlaceContextMenu workPlace={workPlace} onUpdate={refetch} />
                    </Grid>
                </Grid>;
            })
        )}
        {loading &&
            Array.from(Array(5).keys()).map((_, index) => {
                return <Skeleton key={index} variant="rounded" width='100%' height={50}
                                 sx={{marginTop: '10px'}}/>;
            })
        }
    </div>;
};
