import * as Types from '../../generated/graphql';

import { AbsenceRequestFragment } from '../../fragments/absenceRequest.generated';
import { EmployeeFragment } from '../../fragments/employee.generated';
import { gql } from '@apollo/client';
import { AbsenceRequestFragmentDoc } from '../../fragments/absenceRequest.generated';
import { EmployeeFragmentDoc } from '../../fragments/employee.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AbsenceRequestByIdQueryVariables = Types.Exact<{
  absenceRequestId: Types.Scalars['Int'];
}>;


export type AbsenceRequestByIdQuery = { __typename?: 'Query', absence: { __typename?: 'absenceQueries', absenceRequestById: (
      { __typename?: 'AbsenceRequest', logs: Array<{ __typename?: 'AbsenceRequestLog', oldStatus?: Types.AbsenceRequestStatus | null, newStatus: Types.AbsenceRequestStatus, createdAt: any, comment?: string | null, user: (
          { __typename?: 'Employee' }
          & EmployeeFragment
        ) }> }
      & AbsenceRequestFragment
    ) } };


export const AbsenceRequestByIdDocument = gql`
    query AbsenceRequestById($absenceRequestId: Int!) {
  absence {
    absenceRequestById(absenceRequestId: $absenceRequestId) {
      ...AbsenceRequest
      logs {
        user {
          ...Employee
        }
        oldStatus
        newStatus
        createdAt
        comment
      }
    }
  }
}
    ${AbsenceRequestFragmentDoc}
${EmployeeFragmentDoc}`;

/**
 * __useAbsenceRequestByIdQuery__
 *
 * To run a query within a React component, call `useAbsenceRequestByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useAbsenceRequestByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAbsenceRequestByIdQuery({
 *   variables: {
 *      absenceRequestId: // value for 'absenceRequestId'
 *   },
 * });
 */
export function useAbsenceRequestByIdQuery(baseOptions: Apollo.QueryHookOptions<AbsenceRequestByIdQuery, AbsenceRequestByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AbsenceRequestByIdQuery, AbsenceRequestByIdQueryVariables>(AbsenceRequestByIdDocument, options);
      }
export function useAbsenceRequestByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AbsenceRequestByIdQuery, AbsenceRequestByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AbsenceRequestByIdQuery, AbsenceRequestByIdQueryVariables>(AbsenceRequestByIdDocument, options);
        }
export type AbsenceRequestByIdQueryHookResult = ReturnType<typeof useAbsenceRequestByIdQuery>;
export type AbsenceRequestByIdLazyQueryHookResult = ReturnType<typeof useAbsenceRequestByIdLazyQuery>;
export type AbsenceRequestByIdQueryResult = Apollo.QueryResult<AbsenceRequestByIdQuery, AbsenceRequestByIdQueryVariables>;