import {ContentHeader} from '../../components';
import React from 'react';
import {Navigate, useLocation, useNavigate} from 'react-router-dom';
import {
    Box,
    Card,
    CardContent,
    Link,
    SxProps,
    Tab,
    Tabs,
    Theme
} from '@mui/material';
import {TabContext, TabPanel} from '@mui/lab';
import SettingsPage from './pages/SettingsPage/SettingsPage';
import SubscriptionPage from "./pages/SubscriptionPage/SubscriptionPage";
import {useFlag} from "@unleash/proxy-client-react";
import {WorkPlacesPage} from './pages/WorkPlacesPage/WorkPlacesPage';
import {TerminalPage} from "./pages/TerminalPage/TerminalPage";

const tabStyle: SxProps<Theme> = (theme) => ({
    textDecoration: 'none',
    ':hover': {
        textDecoration: 'none'
    },
    ':focus': {
        textDecoration: 'none'
    }
});

const CompanySettingsPage = () => {
    const navigate = useNavigate();

    const isWorkPlacesEnabled = useFlag('company-work-places');

    const {pathname} = useLocation();

    if (pathname === '/company-settings') {
        return <Navigate to="/company-settings/settings"/>;
    }

    const tabValue = pathname ?? '/company-settings/settings';

    return (
        <Box>
            <ContentHeader title="Einstellungen"/>

            <Card>
                <Tabs value={tabValue}>
                    <Tab
                        label="Einstellungen"
                        value="/company-settings/settings"
                        component={Link}
                        onClick={() => navigate('/company-settings/settings')}
                        sx={tabStyle}
                    />
                    {isWorkPlacesEnabled &&
                        <Tab
                            label="Standorte"
                            value="/company-settings/work-places"
                            component={Link}
                            onClick={() => navigate('/company-settings/work-places')}
                            sx={tabStyle}
                        />
                    }
                    <Tab
                        label="Tablet-Terminal"
                        value="/company-settings/tablet-terminal"
                        component={Link}
                        onClick={() => navigate('/company-settings/tablet-terminal')}
                        sx={tabStyle}
                    />
                    <Tab
                        label="Abonnement"
                        value="/company-settings/subscription"
                        component={Link}
                        onClick={() => navigate('/company-settings/subscription')}
                        sx={tabStyle}
                    />
                </Tabs>
                <CardContent>
                    <TabContext value={tabValue}>
                        <TabPanel value="/company-settings/settings">
                            <SettingsPage/>
                        </TabPanel>
                        <TabPanel value="/company-settings/work-places">
                            <WorkPlacesPage/>
                        </TabPanel>
                        <TabPanel value="/company-settings/subscription">
                            <SubscriptionPage/>
                        </TabPanel>
                        <TabPanel value="/company-settings/tablet-terminal">
                            <TerminalPage/>
                        </TabPanel>
                    </TabContext>
                </CardContent>
            </Card>
        </Box>
    );
};

export default CompanySettingsPage;

declare module '@mui/material/styles' {
    interface Palette {
        professional: Palette['primary'];
    }

    interface PaletteOptions {
        professional?: PaletteOptions['primary'];
    }
}
