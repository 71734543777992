import React from 'react';
import {Navigate, Outlet} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {RootState} from "../store/store";
import {isSubscriptionTrialActive} from "../utils/trial";

const PrivateRoute = () => {
    const isLoggedIn = useSelector((state: any) => state.auth.isLoggedIn);

    const user = useSelector((state: RootState) => state.auth.currentUser);

    const accountNeedsSubscription = (user?.subscription.subscriptionCategory === 'trial' && !isSubscriptionTrialActive(user)) || user?.subscription.subscriptionCategory === 'cancelled';

    if (accountNeedsSubscription) {
        return user?.userType === 'admin' ? <Navigate to='/select-subscription'/> : <Navigate to="/login"/>;
    }

    return isLoggedIn ? <Outlet/> : <Navigate to="/login"/>;
};

export default PrivateRoute;
