import {getIn, useFormik} from "formik";
import * as Yup from "yup";
import {DateTime, Duration} from "luxon";
import {TimeSchedule, WorkingTimeInput} from "../../../../generated/graphql";
import {WorkingTimeFragment} from "../../../../fragments/workingTime.generated";
import {AdapterLuxon} from "@mui/x-date-pickers/AdapterLuxon";
import {Box, Button, Grid, Stack, Typography} from "@mui/material";
import {EmployeeInformation} from "../../../../components/molecules/EmployeeInformation";
import {TimeField} from "@mui/x-date-pickers/TimeField";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {LoadingButton} from "@mui/lab";
import React from "react";
import WorkingTimeText from "../../../../components/molecules/WorkingTimeText";

export interface TimeScheduleFormValues {
    startDate: DateTime;
    endDate?: DateTime;
    monday: WorkingTimeFragment;
    tuesday: WorkingTimeFragment;
    wednesday: WorkingTimeFragment;
    thursday: WorkingTimeFragment;
    friday: WorkingTimeFragment;
    saturday: WorkingTimeFragment;
    sunday: WorkingTimeFragment;
}

interface EmployeeTimeScheduleFormProps {
    timeSchedule?: TimeSchedule;
    onSubmit: (values: TimeScheduleFormValues) => void;
    onCancel?: () => void;
    loading?: boolean;
}

export const EmployeeTimeScheduleForm = ({
                                             timeSchedule,
                                             onSubmit,
                                             onCancel,
                                             loading
                                         }: EmployeeTimeScheduleFormProps) => {
    const initialValues = {
        startDate: timeSchedule ? DateTime.fromISO(timeSchedule.startDate) : DateTime.now().set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0
        }),
        endDate: timeSchedule?.endDate ? DateTime.fromISO(timeSchedule.endDate) : undefined,
        monday: DateTime.fromSeconds(0).set({
            hour: timeSchedule?.monday.hours ?? 0,
            minute: timeSchedule?.monday.minutes ?? 0
        }),
        tuesday: DateTime.fromSeconds(0).set({
            hour: timeSchedule?.tuesday.hours ?? 0,
            minute: timeSchedule?.tuesday.minutes ?? 0
        }),
        wednesday: DateTime.fromSeconds(0).set({
            hour: timeSchedule?.wednesday.hours ?? 0,
            minute: timeSchedule?.wednesday.minutes ?? 0
        }),
        thursday: DateTime.fromSeconds(0).set({
            hour: timeSchedule?.thursday.hours ?? 0,
            minute: timeSchedule?.thursday.minutes ?? 0
        }),
        friday: DateTime.fromSeconds(0).set({
            hour: timeSchedule?.friday.hours ?? 0,
            minute: timeSchedule?.friday.minutes ?? 0
        }),
        saturday: DateTime.fromSeconds(0).set({
            hour: timeSchedule?.saturday.hours ?? 0,
            minute: timeSchedule?.saturday.minutes ?? 0
        }),
        sunday: DateTime.fromSeconds(0).set({
            hour: timeSchedule?.sunday.hours ?? 0,
            minute: timeSchedule?.sunday.minutes ?? 0
        })
    }

    const timeToWorkingTime = (time: DateTime): WorkingTimeInput => ({
        hours: time.hour,
        minutes: time.minute
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: Yup.object({
            startDate: Yup.object().required('Bitte gib ein Start-Datum an')
        }),
        onSubmit: async (values) => {
            onSubmit({
                startDate: values.startDate,
                endDate: values.endDate,
                monday: timeToWorkingTime(values.monday),
                tuesday: timeToWorkingTime(values.tuesday),
                wednesday: timeToWorkingTime(values.wednesday),
                thursday: timeToWorkingTime(values.thursday),
                friday: timeToWorkingTime(values.friday),
                saturday: timeToWorkingTime(values.saturday),
                sunday: timeToWorkingTime(values.sunday)
            });
        }
    });
    const {values, handleSubmit, handleBlur, setFieldValue} = formik;

    const timeToMinutes = (time: DateTime): number => {
        if(time === null || !time.isValid) {
            return 0;
        }
        return time.hour * 60 + time.minute;
    };
    const weekMinutes = timeToMinutes(values.monday) +
        timeToMinutes(values.tuesday) +
        timeToMinutes(values.wednesday) +
        timeToMinutes(values.thursday) +
        timeToMinutes(values.friday) +
        timeToMinutes(values.saturday) +
        timeToMinutes(values.sunday);

    const weekDuration = Duration.fromMillis(weekMinutes * 60 * 1000).shiftTo('hours', 'minutes').toObject();
    const weekWorkingTime: WorkingTimeFragment = {hours: weekDuration.hours, minutes: weekDuration.minutes};

    return <LocalizationProvider dateAdapter={AdapterLuxon}>
        <Box component='form' onSubmit={handleSubmit}>
            <Grid container spacing={2}>
                {[{key: 'monday', label: 'Montag'},
                    {key: 'tuesday', label: 'Dienstag'},
                    {key: 'wednesday', label: 'Mittwoch'},
                    {key: 'thursday', label: 'Donnerstag'},
                    {key: 'friday', label: 'Freitag'},
                    {key: 'saturday', label: 'Samstag'},
                    {key: 'sunday', label: 'Sonntag'}]
                    .map((weekDay, index) => (
                        <EmployeeInformation key={index} label={weekDay.label} xs={3} sm={3} md={3}
                                             lg={1.71428}>
                            <TimeField
                                value={getIn(values, weekDay.key)}
                                format='HH:mm'
                                onChange={(value) => setFieldValue(weekDay.key, value, false)}
                                slotProps={{
                                    textField: {
                                        variant: 'outlined',
                                        size: 'small'
                                    }
                                }}
                            />
                        </EmployeeInformation>
                    ))}
                <EmployeeInformation label='Gültig ab'>
                    <DatePicker
                        value={values.startDate}
                        onChange={(value: DateTime | null) => {
                            if (value?.isValid) {
                                setFieldValue('startDate', value)
                            }
                        }}
                        slotProps={{
                            textField: {
                                size: 'small',
                                fullWidth: true,
                                name: 'startDate',
                                id: 'startDate',
                                onBlur: handleBlur
                            }
                        }}
                        format='dd.MM.yyyy'
                    ></DatePicker>
                </EmployeeInformation>
                <EmployeeInformation label='Gültig bis (optional)' helperTooltip='Wenn das Feld leer bleibt, gelten die Zeiten unbegrenzt'>
                    <DatePicker
                        value={values.endDate}
                        onChange={(value: DateTime | null) => {
                            if (value === null) {
                                setFieldValue('endDate', null);
                            } else if (value?.isValid) {
                                setFieldValue('endDate', value);
                            }
                        }}
                        slotProps={{
                            textField: {
                                size: 'small',
                                fullWidth: true,
                                name: 'endDate',
                                id: 'endDate',
                                onBlur: handleBlur
                            }
                        }}
                        format='dd.MM.yyyy'
                    ></DatePicker>
                </EmployeeInformation>
            </Grid>
            <Box>
                <Stack sx={{mt: 4}} flexDirection='row' alignItems='center' gap={2}>
                    <Typography>Berechnete Wochenarbeitszeit: </Typography>
                    <WorkingTimeText workingTime={weekWorkingTime}/>
                </Stack>
                {/*values.startDate.diff(DateTime.now(), 'days').days <= 0 &&
                    <Stack direction='row' spacing={2} sx={{mt: 2}}>
                        <WarningIcon color='warning'/>
                        <Typography color='warning'>Dieser Arbeitsplan beginnt in der Vergangenheit. Beim Erstellen werden Überstunden und Salden des Mitarbeiters im betroffenen Zeitraum verändert</Typography>
                    </Stack>
                */}
            </Box>
            <Box sx={{display: 'flex', justifyContent: 'end', gap: 2, mt: 3}}>
                <Button color='inherit' variant='contained' onClick={onCancel}>Abbrechen</Button>
                <LoadingButton loading={loading} type='submit' color='primary'
                               variant='contained'>Speichern</LoadingButton>
            </Box>
        </Box>
    </LocalizationProvider>;
};
