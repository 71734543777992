import {EmployeeVacation, EmployeeVacationTransfer} from "../../../generated/graphql";
import {
    Box, Button,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    FormHelperText,
    Grid,
    IconButton, MenuItem,
    Select,
    TextField, Typography
} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {EmployeeInformation} from "../../molecules/EmployeeInformation";
import React from "react";
import {useFormik} from "formik";
import {DateTime} from "luxon";
import * as Yup from "yup";

export interface EmployeeVacationDialogProps {
    employeeVacation?: EmployeeVacation;
    employeeVacationTransfer?: EmployeeVacationTransfer;
    open?: boolean;
    onClose?: () => void;
    onSubmit: (values: EmployeeVacationFormValues) => void;
}

export interface EmployeeVacationFormValues {
    startYear: number;
    vacationDays: number;
    remainingVacationDays: number;
    transferVacationDays: number;
    automaticTransfer: string;
}

export const EmployeeVacationDialog = ({
                                           employeeVacation,
                                           employeeVacationTransfer,
                                           open,
                                           onClose,
                                           onSubmit
                                       }: EmployeeVacationDialogProps) => {
    const {handleChange, values, handleSubmit, handleBlur, touched, errors} = useFormik<EmployeeVacationFormValues>({
        initialValues: {
            startYear: employeeVacation?.startYear ?? DateTime.now().year,
            vacationDays: (employeeVacation?.vacationMinutes ?? 30 * 60 * 8) / 60 / 8,
            remainingVacationDays: (employeeVacation?.remainingVacationMinutes ?? 30 * 60 * 8) / 60 / 8,
            transferVacationDays: (employeeVacationTransfer?.vacationMinutes ?? 0) / 60 / 8,
            automaticTransfer: employeeVacation ? (employeeVacation.automaticTransfer ? 'true' : 'false') : 'true'
        },
        validationSchema: Yup.object({
            startYear: Yup.number().required('Bitte gib an, ab wann die Urlaubstage gültig sind').min(DateTime.now().year, 'Das Jahr muss größer als das aktuelle Jahr sein'),
            vacationDays: Yup.number().required('Bitte gib an, wie viele Urlaubstage der Mitarbeiter hat').min(0, 'Die Anzahl der Urlaubstage muss größer als 0 sein'),
            remainingVacationDays: Yup.number().required('Bitte gib an, wie viele Urlaubstage der Mitarbeiter noch hat').min(0, 'Die Anzahl der Urlaubstage muss größer als 0 sein'),
            transferVacationDays: Yup.number().min(0, 'Die Anzahl der Transfer Urlaubstage muss größer als 0 sein').optional(),
            automaticTransfer: Yup.boolean().required('Bitte gib an, ob die Urlaubstage automatisch in das Folgejahr übertragen werden'),
            comment: Yup.string().optional()
        }),
        onSubmit: onSubmit
    });

    return <Dialog
        open={open ?? false}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        onClose={onClose}
        maxWidth="md"
        key={`edit-${employeeVacation?.startYear}` ?? 'new'}
    >
        <Grid container justifyContent='space-between'>
            <Grid item>
                <DialogTitle id="alert-dialog-title">
                    Urlaubsplan erstellen
                </DialogTitle>
            </Grid>
            <Grid item sx={{marginRight: '1rem', marginTop: '0.5rem'}}>
                <IconButton onClick={onClose}>
                    <FontAwesomeIcon icon={solid('xmark')} color='#333333' size="lg"/>
                </IconButton>
            </Grid>
        </Grid>
        <DialogContent>
            <Box component='form' onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <EmployeeInformation
                        label='Für welches Jahr soll das Urlaubskonto gelten? (Aktuell ist nur das aktuelle Jahr möglich)' xs={12}
                        md={12}>
                        <TextField id="startYear"
                                   name='startYear'
                                   placeholder="Gültig ab"
                                   type="number"
                                   value={values.startYear}
                                   error={!!(errors.startYear && touched.startYear)}
                                   onChange={handleChange}
                                   onBlur={handleBlur}
                                   variant='outlined'
                                   fullWidth
                                   size='small'
                                   disabled
                        />
                        {errors.startYear && (
                            <FormHelperText error={!!(errors.startYear && touched.startYear)}>
                                {errors.startYear}
                            </FormHelperText>
                        )}
                    </EmployeeInformation>
                    <EmployeeInformation label='Wie viel Jahresurlaub ist vertraglich vereinbart?'
                                         helperTooltip='Hier die Tage für ein volles Jahr angeben, nicht anteilig'
                                         xs={12} md={12}>
                        <TextField id="vacationDays"
                                   name='vacationDays'
                                   type="number"
                                   placeholder="30"
                                   value={values.vacationDays}
                                   error={!!(errors.vacationDays && touched.vacationDays)}
                                   onChange={handleChange}
                                   onBlur={handleBlur}
                                   variant='outlined'
                                   fullWidth
                                   size='small'
                        />
                        {errors.vacationDays && (
                            <FormHelperText error={!!(errors.vacationDays && touched.vacationDays)}>
                                {errors.vacationDays}
                            </FormHelperText>
                        )}
                    </EmployeeInformation>
                    <EmployeeInformation label={`Wie viele verbleibende Urlaubstage stehen dem Mitarbeiter für das Jahr ${values.startYear} noch zur Verüfung?`}
                                         helperTooltip='Trage hier einen anderen Wert als den vertraglich vereinbarten Jahresurlaub ein, wenn der Mitarbeiter bereits Urlaub genommen hat.'
                                         xs={12} md={12}>
                        <TextField id="remainingVacationDays"
                                   name='remainingVacationDays'
                                   type="number"
                                   placeholder="30"
                                   value={values.remainingVacationDays}
                                   error={!!(errors.remainingVacationDays && touched.remainingVacationDays)}
                                   onChange={handleChange}
                                   onBlur={handleBlur}
                                   variant='outlined'
                                   fullWidth
                                   size='small'
                        />
                        {errors.remainingVacationDays && (
                            <FormHelperText error={!!(errors.remainingVacationDays && touched.remainingVacationDays)}>
                                {errors.remainingVacationDays}
                            </FormHelperText>
                        )}
                    </EmployeeInformation>
                    <EmployeeInformation
                        label={`Gibt es noch zusätzliche Urlaubstage, die der Mitarbeiter noch zur Verfügung hat?`}
                        helperTooltip={`z.B. Urlaubstage aus dem Jahr ${values.startYear - 1}`} xs={12} md={12}>
                        <TextField id="transferVacationDays"
                                   name='transferVacationDays'
                                   type="number"
                                   placeholder="2"
                                   value={values.transferVacationDays}
                                   error={!!(errors.transferVacationDays && touched.transferVacationDays)}
                                   onChange={handleChange}
                                   onBlur={handleBlur}
                                   variant='outlined'
                                   fullWidth
                                   size='small'
                        />
                        {errors.transferVacationDays && (
                            <FormHelperText error={!!(errors.transferVacationDays && touched.transferVacationDays)}>
                                {errors.transferVacationDays}
                            </FormHelperText>
                        )}
                    </EmployeeInformation>
                    <EmployeeInformation
                        label='Sollen nicht genommene Urlaubstage in das nächste Jahr automatisch übertragen werden?'
                        xs={12} md={12}>
                        <Select
                            id='automaticTransfer'
                            name='automaticTransfer'
                            labelId="automatic-transfer-label"
                            size='small'
                            placeholder='Übertrag in das Folgejahr'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.automaticTransfer}
                            error={!!(errors.automaticTransfer && touched.automaticTransfer)}
                            fullWidth
                            displayEmpty
                        >
                            <MenuItem value='true'>Ja</MenuItem>
                            <MenuItem value='false'>Nein</MenuItem>
                        </Select>
                        {errors.automaticTransfer && (
                            <FormHelperText error={!!(errors.automaticTransfer && touched.automaticTransfer)}>
                                {errors.automaticTransfer}
                            </FormHelperText>
                        )}
                    </EmployeeInformation>
                </Grid>

                <Box sx={{marginTop: 4}}>
                    <Typography variant='h6'>Jahr {values.startYear}</Typography>
                    <Grid container sx={{marginTop: 2}}>
                        <Grid item xs={12} sm={4}>
                            <Typography sx={{color: '#82848B'}}>Vertragliche Urlaubstage</Typography>
                            <Typography>{values.vacationDays} Tage</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography sx={{color: '#82848B'}}>Resturlaub aus {values.startYear - 1}</Typography>
                            <Typography>{values.transferVacationDays} Tage, Verfall zum
                                31.03.{values.startYear}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography sx={{color: '#82848B'}}>Noch nicht genommene Tage</Typography>
                            <Typography>{values.remainingVacationDays} Tage + {values.transferVacationDays} Tage
                                Übertrag</Typography>
                        </Grid>
                    </Grid>
                </Box>

                <Box sx={{marginTop: 4}}>
                    <Typography variant='h6'>Zukünftige Jahre</Typography>
                    <Box sx={{marginTop: 2}}>
                        <Typography sx={{color: '#82848B'}}>Vertragliche Urlaubstage</Typography>
                        <Typography>{values.vacationDays} Tage</Typography>
                    </Box>
                </Box>

                <Box sx={{display: 'flex', justifyContent: 'end', marginTop: 4}}>
                    <DialogActions sx={{gap: 2, mt: 2}}>
                        <Button variant='contained' color='inherit' type='button' onClick={onClose}>ABBRECHEN</Button>
                        <Button variant='contained' type='submit'>SPEICHERN</Button>
                    </DialogActions>
                </Box>
            </Box>
        </DialogContent>
    </Dialog>;
};
