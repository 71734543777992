import {useNavigate, useParams} from "react-router-dom";
import LoggedOutScreen from "../../components/LoggedOutScreen";
import {Box, Button, CircularProgress, FormHelperText, Grid, Link, Stack, TextField, Typography} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import React, {useState} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useIsSetPasswordTokenValidQuery} from "./isSetPasswordTokenValid.generated";
import WarningIcon from '@mui/icons-material/Warning';
import {useSetPasswordMutation} from "./setPassword.generated";
import {toast} from "react-toastify";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const VerifyPage = () => {
    const navigate = useNavigate();
    const {token} = useParams();

    const {data, error, loading} = useIsSetPasswordTokenValidQuery({
        variables: {
            token: token ?? 'undefined'
        }
    });

    const [isAlreadyVerified, setAlreadyVerified] = useState(false);

    const [setPasswordMutation, {loading: setPasswordLoading}] = useSetPasswordMutation();

    const [backendError, setBackendError] = useState<string | undefined>(undefined);

    const setPassword = async (password: string) => {
        const {data, errors} = await setPasswordMutation({
            variables: {
                token: token!,
                password: password
            }
        });

        if (data?.employee.verify === null && !errors) {
            navigate('/login');
            toast.success('Passwort erfolgreich gesetzt');
        } else {
            const error = errors?.[0];
            if (error?.extensions['reason'] !== undefined && error.extensions['reason'] !== null) {
                const reason: string = error.extensions['reason'] as unknown as string;

                if (reason === 'UserNotFound') {
                    setBackendError('Der Nutzer wurde nicht gefunden');
                } else if (reason === 'TokenInvalid') {
                    setBackendError('Der Registrierungslink ist ungültig oder abgelaufen. Kontaktiere deinen Vorgesetzten oder den Support');
                } else if (reason === 'AlreadyVerified') {
                    setAlreadyVerified(true);
                    setBackendError('Du hast dich bereits erfolgreich verifiziert. Gehe zum Login um dich anzumelden');
                } else {
                    setBackendError('Ein unerwarteter Fehler ist aufgetreten');
                }
            } else {
                setBackendError('Ein unerwarteter Fehler ist aufgetreten');
            }
        }
    };


    const {
        handleChange,
        handleBlur,
        values,
        handleSubmit,
        touched,
        errors
    } = useFormik({
        initialValues: {
            password: '',
            passwordRetype: ''
        },
        validationSchema: Yup.object({
            password: Yup.string()
                .min(5, 'Muss mindestens 5 Zeichen enthalten')
                .max(30, 'Darf maximal 30 Zeichen enthalten')
                .required('Passwort darf nicht leer sein'),
            passwordRetype: Yup.string()
                .min(5, 'Muss mindestens 5 Zeichen enthalten')
                .max(30, 'Darf maximal 30 Zeichen enthalten')
                .required('Passwort wiederholen darf nicht leer sein')
                .when('password', {
                    is: (val: string) => !!(val && val.length > 0),
                    then: Yup.string().oneOf(
                        [Yup.ref('password')],
                        'Die Passwörter stimmen nicht überein'
                    )
                })
        }),
        onSubmit: (values) => {
            setPassword(values.password);
        }
    });

    if (loading) {
        return <LoggedOutScreen type='forgot-password'>
            <Box sx={{display: 'flex', justifyContent: 'center', alignContent: 'center'}}>
                <CircularProgress/>
            </Box>
        </LoggedOutScreen>;
    }

    if (error || !data?.token.isEmployeeSetPasswordTokenValid) {
        return <LoggedOutScreen type='forgot-password'>
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                textAlign: 'center',
                gap: 1
            }}>
                <WarningIcon sx={{fontSize: '50px'}} color='error'/>
                <Typography variant='h5' color='error'>Verifizierung fehlgeschlagen</Typography>
                <Typography color='error'>Der Link ist abgelaufen. Bitte wende dich an deinen Vorgesetzten</Typography>
                <Stack spacing={1} sx={{my: 3}} direction='row' alignItems='center' justifyContent='center'>
                    <Typography>Bereits registriert?</Typography>
                    <Button onClick={() => navigate('/login')}><ArrowForwardIcon/>Login</Button>
                </Stack>
            </Box>
        </LoggedOutScreen>;
    }

    return <LoggedOutScreen type='forgot-password'>
        <Typography component='h1' variant='h4' fontWeight='bold'>Passwort festlegen</Typography>
        <Typography sx={{marginTop: 1}} color='#696579'>Gib ein Passwort an, um dich auch weiterhin
            in deinem Account
            anmelden zu können</Typography>
        <Box component='form' onSubmit={handleSubmit} sx={{mt: 5}}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        sx={{marginTop: 0.5}}
                        id="password"
                        name='password'
                        className='sentry-mask'
                        placeholder="Passwort"
                        value={values.password}
                        error={!!(errors.password && touched.password)}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        variant='outlined'
                        fullWidth
                        size='small'
                        type='password'
                    />
                    {errors.password && (
                        <FormHelperText
                            error={!!(errors.password && touched.password)}>
                            {errors.password}
                        </FormHelperText>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        sx={{marginTop: 0.5}}
                        id="passwordRetype"
                        name='passwordRetype'
                        className='sentry-mask'
                        placeholder="Passwort wiederholen"
                        value={values.passwordRetype}
                        error={!!(errors.passwordRetype && touched.passwordRetype)}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        variant='outlined'
                        fullWidth
                        size='small'
                        type='password'
                    />
                    {errors.passwordRetype && (
                        <FormHelperText
                            error={!!(errors.passwordRetype && touched.passwordRetype)}>
                            {errors.passwordRetype}
                        </FormHelperText>
                    )}
                </Grid>
                {backendError &&
                    <Grid item xs={12} sx={{display: 'flex', gap: 1, paddingLeft: 2, mt: 2}}>
                        <WarningIcon color='error'/>
                        <Typography color='error'>{backendError}</Typography>
                    </Grid>
                }
                {isAlreadyVerified &&
                    <Link sx={{paddingLeft: 2}} href='/login'>Jetzt hier anmelden</Link>
                }
                <Grid item xs={12} sx={{paddingLeft: 2, marginTop: 2}}>
                    <LoadingButton
                        loading={setPasswordLoading}
                        id="step2-click-button"
                        variant='contained'
                        type="submit"
                        fullWidth
                    >
                        Registrierung abschliessen
                    </LoadingButton>
                </Grid>
            </Grid>
        </Box>
    </LoggedOutScreen>;
};

export default VerifyPage;
