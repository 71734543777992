import React, {useEffect} from "react";
import {
    AppBar,
    Avatar,
    Box,
    Button,
    Container,
    CssBaseline,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    SxProps,
    Theme,
    Toolbar,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import HomeIcon from '@mui/icons-material/Home';
import ScheduleIcon from '@mui/icons-material/Schedule';
import PeopleIcon from '@mui/icons-material/People';
import MenuIcon from "@mui/icons-material/Menu";
import FileCopyIcon from '@mui/icons-material/FileCopy';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import timeTrackerLogo from '../../images/timetracker-logo.png';
import {Link, Outlet, useLocation, useNavigate} from "react-router-dom";
import {Logout} from "@mui/icons-material";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {loginUser, logoutUser} from "../../store/reducers/auth";
import SettingsIcon from "@mui/icons-material/Settings";
import {isSubscriptionTrialActive, remainingTrialDays} from "../../utils/trial";
import {useUnleashContext} from "@unleash/proxy-client-react";
import {hasAtLeastSubscription} from "../../utils/subscription";
import TrackingDialogProvider from "../../dynamic-dialog/TrackingDialogProvider";
import {useTranslation} from "react-i18next";
import Cookies from "js-cookie";
import {toast} from "react-toastify";
import {useCreateAccessTokenMutation} from "../../createAccessToken.generated";
import VisibilityIcon from '@mui/icons-material/Visibility';

const Main = () => {
    const {t} = useTranslation();

    const [refreshTokenMutation] = useCreateAccessTokenMutation();

    useLocation();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
    const updateContext = useUnleashContext();

    const user = useSelector((state: RootState) => state.auth.currentUser);

    const subscription = user?.subscription;
    const isTrialActive = subscription?.subscriptionCategory === 'trial' && isSubscriptionTrialActive(user);
    const remainingDays = remainingTrialDays(subscription);

    const showDrawerPermanent = isDesktop && !(user?.subscription.subscriptionCategory === 'trial' && !isSubscriptionTrialActive(user))

    const [open, setOpen] = React.useState(false);

    const toggleDrawer = () => {
        setOpen(!open);
    };

    const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);
    const accountMenuOpen = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const logOut = (event: any) => {
        event.preventDefault();
        dispatch(logoutUser());
        navigate('/login');
    };

    const userShortText = user ? `${Array.from(user.name)[0]}${Array.from(user.surname)[0]}` : undefined;

    const showTrialDialog = isTrialActive && user?.userType === 'admin';
    const showImitationDialog = user?.sessionType === 'imitation';

    const marginTop = showTrialDialog ? (showImitationDialog ? '100px' : '50px') : (showImitationDialog ? '50px' : 0);

    useEffect(() => {
        if (user?.userId) {
            updateContext({userId: user.userId.toString(), properties: {companyName: user.companyName}});
        }
    }, [user, updateContext]);

    const stopImitation = async () => {
        const {data, errors} = await refreshTokenMutation({
            variables: {
                refreshToken: localStorage.getItem('refreshToken')!
            }
        });

        if (!errors && data) {

            const information = data.employee.createAccessToken;

            localStorage.setItem('token', information.accessToken);
            localStorage.setItem('refreshToken', information.refreshToken);
            Cookies.set('token', information.accessToken, {expires: 1});

            dispatch(loginUser(information.accessToken));

            toast.success('Imitation erfolgreich beendet');
        } else {
            toast.error('Ein unbekannter Fehler ist aufgetreten');
        }
    };

    return (
        <>
            {showImitationDialog &&
                <Box sx={{
                    height: '50px',
                    backgroundColor: '#ED4545',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    top: 0,
                    position: 'fixed',
                    width: '100%',
                    paddingX: 4,
                    zIndex: 1000
                }}>
                    <Typography sx={{color: '#fff'}}>Du imitierst gerade {user?.name} {user?.surname} ({user?.companyName})</Typography>
                    <Button
                        sx={{marginLeft: 2, backgroundColor: '#fff', color: '#ED4545'}}
                        variant='contained'
                        color='inherit'
                        onClick={stopImitation}
                    >BEENDEN</Button>
                </Box>
            }
            {showTrialDialog &&
                <Box sx={{
                    height: '50px',
                    backgroundColor: '#6ED675',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    top: showImitationDialog ? '50px' : 0,
                    position: 'fixed',
                    width: '100%',
                    paddingX: 4,
                    zIndex: 1000
                }}>
                    <Box sx={{width: '160px'}}></Box>
                    <Typography sx={{color: '#fff'}}>Ihre Testphase endet in <span
                        style={{fontWeight: 'bold'}}>{remainingDays} Tagen</span></Typography>
                    <Button
                        sx={{float: 'right', backgroundColor: '#fff', color: '#6ED675'}}
                        variant='contained'
                        color='inherit'
                        onClick={() => navigate('/select-subscription')}
                    >Jetzt upgraden</Button>
                </Box>
            }
            <div style={{display: 'flex'}}>
                <CssBaseline/>
                <Drawer
                    variant={showDrawerPermanent ? "permanent" : "temporary"}
                    anchor="left"
                    open={open}
                    onClose={toggleDrawer}
                    PaperProps={{
                        sx: {
                            boxShadow: '0 3px 10px rgba(0,0,0,.161)',
                            borderRight: 'none',
                            marginTop: marginTop
                        }
                    }}
                >
                    <Box
                        sx={{width: 250}}
                        role="presentation"
                        onClick={() => setOpen(false)}
                        onKeyDown={() => setOpen(false)}
                    >
                        <Box sx={{height: '64px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <img
                                src={timeTrackerLogo}
                                alt="MyTimeTracker Logo"
                                className="brand-image"
                                style={{maxHeight: '40px'}}
                            />
                        </Box>
                        <Divider/>
                        <List>
                            <ListItemLink name={t('navigation.dashboard')}
                                          to='/'
                                          icon={<HomeIcon/>}
                                          isActive={window.location.pathname.startsWith('/dashboard') || window.location.pathname === '/'}
                            />
                            <ListItemLink name={t('navigation.hours')}
                                          to='/hours'
                                          icon={<ScheduleIcon/>}
                                          isActive={window.location.pathname.startsWith('/hours')}
                            />
                            <ListItemLink name={t('navigation.absences')}
                                          to='/absences'
                                          icon={<BeachAccessIcon/>}
                                          isActive={window.location.pathname.startsWith('/absences') || window.location.pathname.startsWith('/absence')}
                            />
                            {hasAtLeastSubscription(user, 'expert') &&
                                <ListItemLink name={t('navigation.calendar')}
                                              to='/calendar'
                                              icon={<CalendarMonthIcon/>}
                                              isActive={window.location.pathname.startsWith('/calendar')}
                                />
                            }
                            {user?.userType === 'admin' &&
                                <>
                                    <ListItemLink name={t('navigation.employees')}
                                                  to='/employees'
                                                  icon={<PeopleIcon/>}
                                                  isActive={window.location.pathname.startsWith('/employees') || window.location.pathname.startsWith('/employee')}
                                    />
                                    <ListItemLink name={t('navigation.reporting')}
                                                  to='/report/create'
                                                  icon={<FileCopyIcon/>}
                                                  isActive={window.location.pathname.startsWith('/reports') || window.location.pathname.startsWith('/report')}
                                    />
                                    <ListItemLink name={t('navigation.company')}
                                                  to='/company'
                                                  icon={<BusinessCenterIcon/>}
                                                  isActive={(window.location.pathname.startsWith('/company') || window.location.pathname.startsWith('/department')) && !window.location.pathname.startsWith('/company-settings')}
                                    />
                                    <ListItemLink name={t('navigation.settings')}
                                                  to='/company-settings'
                                                  icon={<SettingsIcon/>}
                                                  isActive={window.location.pathname.startsWith('/company-settings')}
                                    />
                                </>
                            }
                        </List>
                    </Box>
                </Drawer>
                <main
                    style={{
                        flexGrow: 1,
                        marginLeft: showDrawerPermanent ? '250px' : 0,
                        marginTop: marginTop
                    }}>
                    <AppBar position="fixed" elevation={1} sx={{
                        marginLeft: showDrawerPermanent ? '250px' : 0,
                        backgroundColor: '#fff',
                        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 10px',
                        marginTop: marginTop
                    }}>
                        <Toolbar sx={{display: 'flex', justifyContent: isDesktop ? 'end' : 'space-between'}}>
                            {!isDesktop &&
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    edge="start"
                                    onClick={toggleDrawer}
                                >
                                    <MenuIcon sx={{color: '#333333'}}/>
                                </IconButton>
                            }
                            <Tooltip title="Account-Einstellungen">
                                <Button
                                    sx={{color: '#3b3c40'}}
                                    variant="text"
                                    startIcon={<Avatar sx={{
                                        width: 35,
                                        height: 35,
                                        fontSize: '18px !important'
                                    }}>{userShortText}</Avatar>}
                                    endIcon={<ArrowDropDownIcon/>}
                                    onClick={handleClick}
                                    aria-controls={accountMenuOpen ? 'account-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={accountMenuOpen ? 'true' : undefined}
                                >
                                    {user?.companyName}
                                </Button>
                            </Tooltip>
                            <Menu
                                anchorEl={anchorEl}
                                id="account-menu"
                                open={accountMenuOpen}
                                onClose={handleClose}
                                onClick={handleClose}
                                PaperProps={{
                                    elevation: 0,
                                    sx: {
                                        overflow: 'visible',
                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                        mt: 1.5,
                                        '& .MuiAvatar-root': {
                                            width: 32,
                                            height: 32,
                                            ml: -0.5,
                                            mr: 1,
                                        },
                                        '&:before': {
                                            content: '""',
                                            display: 'block',
                                            position: 'absolute',
                                            top: 0,
                                            right: 14,
                                            width: 10,
                                            height: 10,
                                            bgcolor: 'background.paper',
                                            transform: 'translateY(-50%) rotate(45deg)',
                                            zIndex: 0,
                                        },
                                    },
                                }}
                                transformOrigin={{horizontal: 'right', vertical: 'top'}}
                                anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                            >
                                <MenuItem onClick={logOut}>
                                    <ListItemIcon>
                                        <Logout fontSize="small"/>
                                    </ListItemIcon>
                                    Logout
                                </MenuItem>
                                {(user?.userId === 1 || user?.userId === 119 || user?.userId === 120) &&
                                    <MenuItem onClick={() => navigate('/imitation')}>
                                        <ListItemIcon>
                                            <VisibilityIcon fontSize='small' />
                                        </ListItemIcon>
                                        Imitieren
                                    </MenuItem>
                                }
                            </Menu>
                        </Toolbar>
                    </AppBar>
                    <TrackingDialogProvider>
                        <Container maxWidth={false} sx={{marginBottom: '2rem'}}>
                            <Box sx={{marginTop: '100px'}}>
                                <Outlet/>
                            </Box>
                        </Container>
                    </TrackingDialogProvider>
                </main>
            </div>
        </>
    );
};

export interface ListItemLinkProps {
    name: string;
    to: string;
    icon: JSX.Element;
    isActive?: boolean;
    isNew?: boolean;
}

const ListItemLink = ({name, to, icon, isActive, isNew}: ListItemLinkProps) => {
    const linkStyle: SxProps<Theme> = (theme) => ({
        textDecoration: 'none',
        ':hover': {
            textDecoration: 'none'
        },
        ':focus': {
            textDecoration: 'none'
        }
    });

    const colorStyle: SxProps<Theme> = (theme) => (isActive ? {color: '#fff'} : {color: '#333333'});

    return <ListItem disablePadding sx={isActive ? {backgroundColor: '#0854CF', color: '#fff'} : undefined}>
        <ListItemButton component={Link} to={to} sx={linkStyle}>
            <ListItemIcon sx={colorStyle}>
                {icon}
            </ListItemIcon>
            <ListItemText sx={colorStyle} primary={name}/>
            {isNew && <Box sx={{backgroundColor: '#0854CF', padding: '0px 8px', color: '#fff', borderRadius: '5px'}}>
                NEU
            </Box>}
        </ListItemButton>
    </ListItem>;
}

export default Main;
