import React from 'react';
import {MTTProduct} from './MTTProduct';
import {SxProps, Theme, Typography} from '@mui/material';

export const MTTProductStarter = ({
                                      selected,
                                      isFirstSelect,
                                      onSelect,
                                      loading
                                  }: {
    selected?: boolean;
    isFirstSelect?: boolean;
    onSelect?: () => void;
    loading?: boolean
}) => {
    const textStyle: SxProps<Theme> = () => ({
        color: '#3B3C40',
        marginBottom: '0.5rem',
        fontSize: '18px'
    });

    return (
        <MTTProduct
            stripeSubscription="standard"
            name="STARTER"
            price="1,19"
            selected={selected}
            isFirstSelect={isFirstSelect}
            onSelect={onSelect}
            loading={loading}
        >
            <Typography sx={textStyle}>∞ Aufgezeichnete Stunden</Typography>
            <Typography sx={textStyle}>∞ Erstellte Reports</Typography>
            <Typography sx={textStyle}>∞ Abwesenheiten</Typography>
            <Typography sx={textStyle}>App für Android / iOS</Typography>
            <Typography sx={textStyle}>Web-App inklusive</Typography>
            <Typography sx={textStyle}>Support inklusive</Typography>
        </MTTProduct>
    );
};
