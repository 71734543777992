import {Box, Button, Grid, Typography} from "@mui/material";
import {EmployeeFragment} from "../../../../fragments/employee.generated";
import {useFutureAbsencesLazyQuery} from "../../../absences/futureAbsenceRequests.generated";
import {DateTime} from "luxon";
import React, {useEffect} from "react";
import PresenceStatisticsChart from "../../../../components/molecules/PresenceStatisticsChart";
import VacationStatisticsChart from "../../../../components/molecules/VacationStatisticsChart";
import AbsencesList from "../../../../components/molecules/AbsencesList/AbsencesList";
import {useNavigate} from "react-router-dom";

const EmployeeAbsences = ({employee}: { employee?: EmployeeFragment }) => {
    const navigate = useNavigate();

    const [futureAbsencesQuery, {data}] = useFutureAbsencesLazyQuery();

    useEffect(() => {
        if (employee) {
            futureAbsencesQuery({
                variables: {
                    employeeId: employee.userId,
                    year: DateTime.now().year
                },
                fetchPolicy: 'no-cache'
            });
        }
    }, [employee, futureAbsencesQuery]);

    return <Box>
        <Box sx={{display: 'flex', justifyContent: 'end', pb: 2}}>
            <Button variant='contained' onClick={() => navigate(`/absence/create/${employee?.userId}`)}>Abwesenheit erstellen</Button>
        </Box>
        <Typography sx={{mb: 2}} variant='h6'>Statistiken</Typography>
        <Grid container>
            <Grid item xs={12} md={6}>
                <VacationStatisticsChart vacationStatistics={data?.absence.vacationStatistics}/>
            </Grid>
            <Grid item xs={12} md={6}>
                <PresenceStatisticsChart presenceStatistics={data?.time.presenceStatistics}/>
            </Grid>
        </Grid>
        <Box sx={{mt: 3}}>
            <Typography sx={{mb: 2}} variant='h6'>Zukünftige Abwesenheiten</Typography>
            <AbsencesList userId={employee?.userId ?? 0} futureAbsences={data?.absence.futureAbsenceRequests}/>
        </Box>
    </Box>;
}

export default EmployeeAbsences;
