import * as Types from '../../../../generated/graphql';

import { InheritableSettingsFragment } from '../../../../fragments/inheritableSettings.generated';
import { gql } from '@apollo/client';
import { InheritableSettingsFragmentDoc } from '../../../../fragments/inheritableSettings.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateAbsenceDurationManualEntryMutationVariables = Types.Exact<{
  settingEntityType: Types.SettingEntityType;
  entityId?: Types.InputMaybe<Types.Scalars['Int']>;
  isEnabled: Types.Scalars['Boolean'];
}>;


export type UpdateAbsenceDurationManualEntryMutation = { __typename?: 'Mutation', setting: { __typename?: 'settingMutations', absenceSetting: { __typename?: 'absenceSettingMutations', updateAbsenceDurationManualEntry: (
        { __typename?: 'InheritableSettings' }
        & InheritableSettingsFragment
      ) } } };


export const UpdateAbsenceDurationManualEntryDocument = gql`
    mutation UpdateAbsenceDurationManualEntry($settingEntityType: SettingEntityType!, $entityId: Int, $isEnabled: Boolean!) {
  setting {
    absenceSetting {
      updateAbsenceDurationManualEntry(
        settingEntityType: $settingEntityType
        entityId: $entityId
        isEnabled: $isEnabled
      ) {
        ...InheritableSettings
      }
    }
  }
}
    ${InheritableSettingsFragmentDoc}`;
export type UpdateAbsenceDurationManualEntryMutationFn = Apollo.MutationFunction<UpdateAbsenceDurationManualEntryMutation, UpdateAbsenceDurationManualEntryMutationVariables>;

/**
 * __useUpdateAbsenceDurationManualEntryMutation__
 *
 * To run a mutation, you first call `useUpdateAbsenceDurationManualEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAbsenceDurationManualEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAbsenceDurationManualEntryMutation, { data, loading, error }] = useUpdateAbsenceDurationManualEntryMutation({
 *   variables: {
 *      settingEntityType: // value for 'settingEntityType'
 *      entityId: // value for 'entityId'
 *      isEnabled: // value for 'isEnabled'
 *   },
 * });
 */
export function useUpdateAbsenceDurationManualEntryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAbsenceDurationManualEntryMutation, UpdateAbsenceDurationManualEntryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAbsenceDurationManualEntryMutation, UpdateAbsenceDurationManualEntryMutationVariables>(UpdateAbsenceDurationManualEntryDocument, options);
      }
export type UpdateAbsenceDurationManualEntryMutationHookResult = ReturnType<typeof useUpdateAbsenceDurationManualEntryMutation>;
export type UpdateAbsenceDurationManualEntryMutationResult = Apollo.MutationResult<UpdateAbsenceDurationManualEntryMutation>;
export type UpdateAbsenceDurationManualEntryMutationOptions = Apollo.BaseMutationOptions<UpdateAbsenceDurationManualEntryMutation, UpdateAbsenceDurationManualEntryMutationVariables>;