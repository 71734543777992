import * as Types from '../../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PaymentInformationQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type PaymentInformationQuery = { __typename?: 'Query', payment: { __typename?: 'paymentQueries', paymentInformation?: { __typename?: 'PaymentInformation', currentPeriodEnd: any, canceledAt?: any | null } | null } };


export const PaymentInformationDocument = gql`
    query PaymentInformation {
  payment {
    paymentInformation {
      currentPeriodEnd
      canceledAt
    }
  }
}
    `;

/**
 * __usePaymentInformationQuery__
 *
 * To run a query within a React component, call `usePaymentInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentInformationQuery({
 *   variables: {
 *   },
 * });
 */
export function usePaymentInformationQuery(baseOptions?: Apollo.QueryHookOptions<PaymentInformationQuery, PaymentInformationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PaymentInformationQuery, PaymentInformationQueryVariables>(PaymentInformationDocument, options);
      }
export function usePaymentInformationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaymentInformationQuery, PaymentInformationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PaymentInformationQuery, PaymentInformationQueryVariables>(PaymentInformationDocument, options);
        }
export type PaymentInformationQueryHookResult = ReturnType<typeof usePaymentInformationQuery>;
export type PaymentInformationLazyQueryHookResult = ReturnType<typeof usePaymentInformationLazyQuery>;
export type PaymentInformationQueryResult = Apollo.QueryResult<PaymentInformationQuery, PaymentInformationQueryVariables>;