import {Navigate, Outlet} from "react-router-dom";
import {useSelector} from "react-redux";
import {RootState} from "../store/store";
import React from "react";

export const InternalRoute = () => {
    const user = useSelector((state: RootState) => state.auth.currentUser);

    return (user?.userId === 119 || user?.userId === 120 || user?.userId === 1) ? <Outlet/> : <Navigate to="/"/>;
};
