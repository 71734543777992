import {Box, Button, Typography} from "@mui/material";
import vacationCalendar from "../../../../images/vacation-calendar.png";
import React, {useState} from "react";
import { CreateCompanyVacationDialog } from "../../../../components/organisms/CreateCompanyVacationDialog/CreateCompanyVacationDialog";

export const WorkPlaceCompanyVacation = () => {
    const [createCompanyVacationDialogOpen, setCreateCompanyVacationDialogOpen] = useState(false);

    return <Box
        sx={{display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: 5}}>
        <Box sx={{maxWidth: '60%', display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
            <Box component='img' sx={{maxHeight: '180px'}} src={vacationCalendar}/>
            <Typography sx={{marginTop: 5}}>Hier kann Betriebsurlaub für diesen Standort eingerichtet werden. Der
                jeweilige Betriebsurlaub wird für jeden Mitarbeiter an diesem Standort verbindlich gebucht und von den
                Urlaubstagen abgezogen.</Typography>
            <Box>
                <Button variant='contained' sx={{mt: 4}} onClick={() => setCreateCompanyVacationDialogOpen(true)}>Neuer Betriebsurlaub</Button>
                <CreateCompanyVacationDialog open={createCompanyVacationDialogOpen} onClose={() => setCreateCompanyVacationDialogOpen(false)} />
            </Box>
        </Box>
    </Box>;
};
