import {AbsenceRequestStatus} from "../../generated/graphql";
import PauseIcon from '@mui/icons-material/Pause';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import {Box, Grid, SxProps, Theme, Typography} from "@mui/material";

export interface AbsenceRequestStatusProps {
    absenceRequestStatus: AbsenceRequestStatus;
}

export const AbsenceRequestSmallStatusComponent = ({absenceRequestStatus}: AbsenceRequestStatusProps) => {
    return <Box sx={{
        display: 'flex',
        gap: 1,
        color: color(absenceRequestStatus),
        alignItems: 'center',
        justifyContent: {xs: 'center', sm: 'start'}
    }}>
        <Box sx={{
            backgroundColor: color(absenceRequestStatus),
            borderRadius: '50%',
            width: {xs: '20px', sm: '30px'},
            height: {xs: '20px', sm: '30px'},
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            {icon(absenceRequestStatus, {color: '#fff', fontSize: '25px'})}
        </Box>
        <Typography sx={{display: {xs: 'none', sm: 'block'}}}>
            {text(absenceRequestStatus)}
        </Typography>
    </Box>;
}

export const AbsenceRequestBigStatusComponent = ({absenceRequestStatus}: AbsenceRequestStatusProps) => {
    return <Grid
        sx={{width: '100%', minHeight: '70px', backgroundColor: color(absenceRequestStatus), borderRadius: '10px', alignItems: 'center'}}
        container>
        <Grid item xs={0.5}></Grid>
        <Grid item xs={1.5}>
            <Box sx={{
                backgroundColor: '#fff',
                borderRadius: '50%',
                width: '35px',
                height: '35px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                {icon(absenceRequestStatus, {color: color(absenceRequestStatus), fontSize: '25px'})}
            </Box>
        </Grid>
        <Grid item xs={9}>
            <Typography sx={{color: '#fff'}}>{text(absenceRequestStatus)}</Typography>
        </Grid>
    </Grid>;
};

const color = (absenceRequestStatus: AbsenceRequestStatus) => {
    switch (absenceRequestStatus) {
        case "pending":
            return '#F1C444';
        case "withdrawn":
            return '#EA474A';
        case "rejected":
            return '#EA474A';
        case "approved":
            return '#6ED675';
        case "cancellationPending":
            return '#F1C444';
        default:
            return '#000';
    }
}

const icon = (absenceRequestStatus: AbsenceRequestStatus, iconStyle: SxProps<Theme>) => {
    switch (absenceRequestStatus) {
        case "pending":
            return <PauseIcon sx={iconStyle}/>;
        case "approved":
            return <CheckIcon sx={iconStyle}/>;
        case "rejected":
            return <ClearIcon sx={iconStyle}/>;
        case "withdrawn":
            return <ClearIcon sx={iconStyle}/>;
        case "cancellationPending":
            return <PauseIcon sx={iconStyle}/>;
        default:
            return <QuestionMarkIcon/>;
    }
};

const text = (absenceRequestStatus: AbsenceRequestStatus) => {
    switch (absenceRequestStatus) {
        case "pending":
            return "Genehmigung ausstehend";
        case "rejected":
            return 'Antrag abgelehnt';
        case "withdrawn":
            return 'Antrag zurückgezogen';
        case "approved":
            return 'Antrag genehmigt';
        case "cancellationPending":
            return "Stornierung ausstehend";
        default:
            return "Unbekannt";
    }
}
