import {EntityInformation, SettingUpdateResponse} from "../../types";
import {Checkbox, FormControlLabel} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useUpdateAbsenceDurationManualEntryMutation} from "./updateAbsenceDurationManualEntry.generated";

export interface AbsenceDurationManualEntrySettingProps {
    entity: EntityInformation;
    setting?: boolean;
    onUpdate?: (response: Promise<SettingUpdateResponse>) => void;
}

export const AbsenceDurationManualEntrySetting = ({entity, setting, onUpdate}: AbsenceDurationManualEntrySettingProps) => {
    const [isEnabled, setEnabled] = useState(setting ?? false);

    const [updateAbsenceDurationManualEntryMutation] = useUpdateAbsenceDurationManualEntryMutation();

    useEffect(() => {
        setEnabled(setting ?? false);
    }, [setting]);

    const handleChange = () => {
        const oldValue = isEnabled;
        setEnabled(!isEnabled);

        const responsePromise: Promise<SettingUpdateResponse> = updateAbsenceDurationManualEntryMutation({
            variables: {
                settingEntityType: entity.entityType,
                entityId: entity.entityId,
                isEnabled: !oldValue
            }
        }).then(({data, errors}) => ({
            updatedSetting: data?.setting.absenceSetting.updateAbsenceDurationManualEntry, errors: errors
        }));

        if (onUpdate) {
            onUpdate(responsePromise);
        }
    }

    return <FormControlLabel control={<Checkbox
        checked={isEnabled}
        onChange={handleChange}
    />} label="Dauer von Abwesenheiten kann manuell eingegeben werden (anstatt halbtags und ganztags)"/>;
}
