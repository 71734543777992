import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import React from "react";
import {DateTime} from "luxon";
import {toast} from "react-toastify";
import {useDeleteDayMutation} from "../WorkingTimeByDayDialog/deleteDay.generated";
import {LoadingButton} from "@mui/lab";

export interface DeleteTrackingDialogProps {
    readonly open: boolean;
    readonly onClose: (deleted: boolean) => void;
    readonly date: DateTime;
    readonly employeeId: number;
}

const DeleteTrackingDialog = ({open, onClose, date, employeeId}: DeleteTrackingDialogProps) => {
    const [deleteDayMutation, {loading}] = useDeleteDayMutation();

    const deleteDay = async () => {
        const {data, errors} = await deleteDayMutation({
            variables: {
                employeeId: employeeId,
                date: date.toFormat('yyyy-MM-dd')
            }
        });

        if (data?.tracking.deleteTrackingForDay === null && !errors) {
            toast.success(`Stundenbuchungen für ${date.toFormat('dd.MM.yyyy')} erfolgreich gelöscht`);
            if(onClose) {
                onClose(true);
            }
        } else {
            const error = errors?.[0];
            if (error?.extensions['reason'] !== undefined && error.extensions['reason'] !== null) {
                const reason: string = error.extensions['reason'] as unknown as string;

                if (reason === 'AuthenticationRequired') {
                    toast.error('Versuche dich neu anzumelden, um die Stunden zu löschen');
                } else if (reason === 'PermissionDenied') {
                    toast.error('Du hast nicht die notwendigen Berechtigungen');
                } else {
                    toast.error('Ein unerwarteter Fehler ist aufgetreten');
                }
            } else {
                toast.error('Ein unerwarteter Fehler ist aufgetreten');
            }
        }
    };

    return <>
        <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            onClose={() => onClose(false)}
        >
            <DialogTitle id="alert-dialog-title">
                Bist du dir sicher?
            </DialogTitle>
            <DialogContent>
                Möchtest du die Stundenbuchungen für den {date.toFormat('dd.MM.yyyy')} wirklich unwiederruflich löschen?
            </DialogContent>
            <DialogActions sx={{gap: 2, mb: 2, mr: 2}}>
                <Button variant='contained' color='inherit' onClick={() => onClose(false)}>Abbrechen</Button>
                <LoadingButton loading={loading} variant='contained' color='error'
                               onClick={() => deleteDay()}>Löschen</LoadingButton>
            </DialogActions>
        </Dialog></>;
}

export default DeleteTrackingDialog;
