import * as Types from '../../generated/graphql';

import { EmployeeFragment } from '../../fragments/employee.generated';
import { WorkingTimeFragment } from '../../fragments/workingTime.generated';
import { gql } from '@apollo/client';
import { EmployeeFragmentDoc } from '../../fragments/employee.generated';
import { WorkingTimeFragmentDoc } from '../../fragments/workingTime.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ActiveTimeScheduleQueryVariables = Types.Exact<{
  employeeId: Types.Scalars['Int'];
}>;


export type ActiveTimeScheduleQuery = { __typename?: 'Query', employee: { __typename?: 'employeeQueries', byId: (
      { __typename?: 'Employee' }
      & EmployeeFragment
    ), timeSchedule: { __typename?: 'timeScheduleQueries', activeTimeSchedule?: { __typename?: 'TimeSchedule', startDate: any, endDate?: any | null, monday: (
          { __typename?: 'WorkingTime' }
          & WorkingTimeFragment
        ), tuesday: (
          { __typename?: 'WorkingTime' }
          & WorkingTimeFragment
        ), wednesday: (
          { __typename?: 'WorkingTime' }
          & WorkingTimeFragment
        ), thursday: (
          { __typename?: 'WorkingTime' }
          & WorkingTimeFragment
        ), friday: (
          { __typename?: 'WorkingTime' }
          & WorkingTimeFragment
        ), saturday: (
          { __typename?: 'WorkingTime' }
          & WorkingTimeFragment
        ), sunday: (
          { __typename?: 'WorkingTime' }
          & WorkingTimeFragment
        ) } | null } }, permission: { __typename?: 'permissionQueries', absencePermission: { __typename?: 'absencePermissionQueries', canEnterAbsenceHoursManually: boolean } } };


export const ActiveTimeScheduleDocument = gql`
    query ActiveTimeSchedule($employeeId: Int!) {
  employee {
    byId(employeeId: $employeeId) {
      ...Employee
    }
    timeSchedule {
      activeTimeSchedule(employeeId: $employeeId) {
        startDate
        endDate
        monday {
          ...WorkingTime
        }
        tuesday {
          ...WorkingTime
        }
        wednesday {
          ...WorkingTime
        }
        thursday {
          ...WorkingTime
        }
        friday {
          ...WorkingTime
        }
        saturday {
          ...WorkingTime
        }
        sunday {
          ...WorkingTime
        }
      }
    }
  }
  permission {
    absencePermission {
      canEnterAbsenceHoursManually
    }
  }
}
    ${EmployeeFragmentDoc}
${WorkingTimeFragmentDoc}`;

/**
 * __useActiveTimeScheduleQuery__
 *
 * To run a query within a React component, call `useActiveTimeScheduleQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveTimeScheduleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveTimeScheduleQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *   },
 * });
 */
export function useActiveTimeScheduleQuery(baseOptions: Apollo.QueryHookOptions<ActiveTimeScheduleQuery, ActiveTimeScheduleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActiveTimeScheduleQuery, ActiveTimeScheduleQueryVariables>(ActiveTimeScheduleDocument, options);
      }
export function useActiveTimeScheduleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActiveTimeScheduleQuery, ActiveTimeScheduleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActiveTimeScheduleQuery, ActiveTimeScheduleQueryVariables>(ActiveTimeScheduleDocument, options);
        }
export type ActiveTimeScheduleQueryHookResult = ReturnType<typeof useActiveTimeScheduleQuery>;
export type ActiveTimeScheduleLazyQueryHookResult = ReturnType<typeof useActiveTimeScheduleLazyQuery>;
export type ActiveTimeScheduleQueryResult = Apollo.QueryResult<ActiveTimeScheduleQuery, ActiveTimeScheduleQueryVariables>;