import * as Types from '../../generated/graphql';

import { CompanySettingFragment } from '../../fragments/companySetting.generated';
import { gql } from '@apollo/client';
import { CompanySettingFragmentDoc } from '../../fragments/companySetting.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateCompanyMutationVariables = Types.Exact<{
  personEmail: Types.Scalars['String'];
  personName: Types.Scalars['String'];
  personSurname: Types.Scalars['String'];
  companyName: Types.Scalars['String'];
  phoneNumber?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type CreateCompanyMutation = { __typename?: 'Mutation', admin: { __typename?: 'adminMutations', createCompany: { __typename?: 'LoginInformation', accessToken: string, refreshToken: string, companySetting: (
        { __typename?: 'CompanySetting' }
        & CompanySettingFragment
      ) } } };


export const CreateCompanyDocument = gql`
    mutation CreateCompany($personEmail: String!, $personName: String!, $personSurname: String!, $companyName: String!, $phoneNumber: String) {
  admin {
    createCompany(
      personEmail: $personEmail
      personName: $personName
      personSurname: $personSurname
      companyName: $companyName
      phoneNumber: $phoneNumber
    ) {
      accessToken
      refreshToken
      companySetting {
        ...CompanySetting
      }
    }
  }
}
    ${CompanySettingFragmentDoc}`;
export type CreateCompanyMutationFn = Apollo.MutationFunction<CreateCompanyMutation, CreateCompanyMutationVariables>;

/**
 * __useCreateCompanyMutation__
 *
 * To run a mutation, you first call `useCreateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyMutation, { data, loading, error }] = useCreateCompanyMutation({
 *   variables: {
 *      personEmail: // value for 'personEmail'
 *      personName: // value for 'personName'
 *      personSurname: // value for 'personSurname'
 *      companyName: // value for 'companyName'
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function useCreateCompanyMutation(baseOptions?: Apollo.MutationHookOptions<CreateCompanyMutation, CreateCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCompanyMutation, CreateCompanyMutationVariables>(CreateCompanyDocument, options);
      }
export type CreateCompanyMutationHookResult = ReturnType<typeof useCreateCompanyMutation>;
export type CreateCompanyMutationResult = Apollo.MutationResult<CreateCompanyMutation>;
export type CreateCompanyMutationOptions = Apollo.BaseMutationOptions<CreateCompanyMutation, CreateCompanyMutationVariables>;