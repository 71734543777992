import {Box, Grid, Paper, Typography} from '@mui/material';
import React, {PropsWithChildren} from 'react';
import timeTrackerLogo from '../images/timetracker-logo-white.png';
import welcomeIllustration from '../images/login_welcome.png';
import forgotPasswordIllustration from '../images/forgot_password.png';

export type LoggedOutScreenProps = PropsWithChildren & {
    title?: string;
    subTitle?: string;
    type: 'forgot-password' | 'login';
};

const LoggedOutScreen = (props: LoggedOutScreenProps) => {
    return (
        <Grid container component="main" sx={{height: '100vh'}}>
            <Grid item xs={12} md={8} component={Paper} elevation={6} square
                  sx={{display: 'flex', justifyContent: 'center'}}>
                <Box
                    sx={{
                        width: '70%',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center'
                    }}
                >
                    {props.children}
                </Box>
            </Grid>
            <Grid
                item
                xs={false}
                sm={false}
                md={4}
                sx={{
                    py: 10,
                    backgroundColor: '#0854CF',
                    display: {xs: 'none', sm: 'none', md: 'flex'},
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: props.type === 'login' ? 'space-between' : 'space-evenly'
                }}
            >
                <Box component='img' src={timeTrackerLogo} sx={{width: '30%'}}/>
                {props.type === 'login' &&
                    <>
                        <Box component='img' src={welcomeIllustration} sx={{width: '70%', mt: 10}}/>
                        <Typography color='#fff' variant='h4'>Herzlich Willkommen!</Typography>
                    </>
                }
                {props.type === 'forgot-password' &&
                    <Box component='img' src={forgotPasswordIllustration} sx={{width: '70%', mt: 10}}/>
                }
            </Grid>
        </Grid>
    );
};

export default LoggedOutScreen;
