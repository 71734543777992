import React from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {toast} from 'react-toastify';
import {useNavigate} from 'react-router-dom';
import {useCreateEmployeeMutation} from './createEmployee.generated';
import {ContractType} from '../../generated/graphql';
import {ContentHeader} from '../../components';
import {
    Box,
    Button,
    Card,
    CardContent,
    FormHelperText,
    Grid,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import flagIcon from "../../images/flag_icon.png";

type CreateEmployeeError =
    'AuthenticationRequired'
    | 'PermissionDenied'
    | 'UserNotFound'
    | 'UserExistsAlready'
    | 'MissingPaymentData'
    | 'DepartmentNotFound';

const CreateEmployee = () => {
    const navigate = useNavigate();

    const [createEmployeeMutation, {loading}] = useCreateEmployeeMutation();

    const createEmployee = async (
        email: string,
        name: string,
        surname: string,
        contractType: ContractType
    ): Promise<void> => {
        const {data, errors} = await createEmployeeMutation({
            variables: {
                email: email,
                name: name,
                surname: surname,
                contractType: contractType
            }
        });

        if (data) {
            toast.success('Mitarbeiter wurde erfolgreich erstellt');
            navigate('/employees');
        } else if (errors) {
            const error = errors[0];
            const reason = error.extensions['reason'] as unknown as CreateEmployeeError;

            switch (reason) {
                case "AuthenticationRequired":
                    toast.error('Melde dich erneut an und versuche es nochmal.');
                    return;
                case "PermissionDenied":
                    toast.error('Du hast nicht die benötigten Rechte um einen Mitarbeiter zu erstellen');
                    return;
                case "UserNotFound":
                    toast.error('Ihr Mitarbeiter-Account konnte nicht gefunden werden');
                    return;
                case "DepartmentNotFound":
                    toast.error('Die standardmäßige Abteilung für Ihr Unternehmen konnte nicht gefunden werden');
                    return;
                case "MissingPaymentData":
                    toast.error('Das Abonemment für Ihr Unternehmen ist ungültig');
                    return;
                case "UserExistsAlready":
                    toast.error('Es existiert bereits ein Mitarbeiter mit dieser E-Mail Adresse');
                    return;
            }
        } else {
            toast.error('Unknown error');
        }
    };

    const {handleChange, values, handleSubmit, handleBlur, touched, errors} = useFormik({
        initialValues: {
            email: '',
            name: '',
            surname: '',
            contractType: ''
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .email('Gib eine gültige E-Mail Adresse an')
                .required('Gib eine E-Mail Adresse an'),
            name: Yup.string().required('Gib den Vornamen des Mitarbeiters an'),
            surname: Yup.string().required('Gib den Nachnamen des Mitarbeiters an'),
            contractType: Yup.string()
                .required('Gib die Vertrasgsart für diesen Mitarbeiter an')
                .oneOf(
                    [
                        'full_time',
                        'part_time',
                        'working_student',
                        'intern',
                        'hourly_basis'
                    ],
                    'Gib die Vertragsart für diesen Mitarbeiter an'
                )
        }),
        onSubmit: (values) => {
            createEmployee(
                values.email,
                values.name,
                values.surname,
                values.contractType as ContractType
            );
        }
    });

    return (
        <>
            <ContentHeader title="Neuer Mitarbeiter"/>
            <section className="content">
                <Card>
                    <CardContent>
                        <Box component='form' onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item md={6} xs={12}>
                                    <TextField id="name"
                                               name='name'
                                               multiline
                                               className='sentry-mask'
                                               placeholder="Vorname"
                                               value={values.name}
                                               error={!!(errors.name && touched.name)}
                                               onChange={handleChange}
                                               onBlur={handleBlur}
                                               variant='outlined'
                                               fullWidth
                                               size='small'
                                    />
                                    {errors.name && (
                                        <FormHelperText error={!!(errors.name && touched.name)}>
                                            {errors.name}
                                        </FormHelperText>
                                    )}
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <TextField id="surname"
                                               name='surname'
                                               multiline
                                               className='sentry-mask'
                                               placeholder="Nachname"
                                               value={values.surname}
                                               error={!!(errors.surname && touched.surname)}
                                               onChange={handleChange}
                                               onBlur={handleBlur}
                                               variant='outlined'
                                               fullWidth
                                               size='small'
                                    />
                                    {errors.surname && (
                                        <FormHelperText error={!!(errors.surname && touched.surname)}>
                                            {errors.surname}
                                        </FormHelperText>
                                    )}
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <TextField id="email"
                                               name='email'
                                               multiline
                                               className='sentry-mask'
                                               placeholder="E-Mail"
                                               value={values.email}
                                               error={!!(errors.email && touched.email)}
                                               onChange={handleChange}
                                               onBlur={handleBlur}
                                               variant='outlined'
                                               fullWidth
                                               size='small'
                                    />
                                    {errors.email && (
                                        <FormHelperText error={!!(errors.email && touched.email)}>
                                            {errors.email}
                                        </FormHelperText>
                                    )}
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Select
                                        id='contractType'
                                        name='contractType'
                                        labelId="contract-type-label"
                                        size='small'
                                        placeholder='Vertragsart'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.contractType}
                                        error={!!(errors.contractType && touched.contractType)}
                                        fullWidth
                                        displayEmpty
                                    >
                                        <MenuItem value="">Vertragsart</MenuItem>
                                        <MenuItem value='full_time'>Vollzeit</MenuItem>
                                        <MenuItem value='part_time'>Teilzeit</MenuItem>
                                        <MenuItem value='working_student'>Werkstudent</MenuItem>
                                        <MenuItem value='intern'>Praktikant</MenuItem>
                                        <MenuItem value='hourly_basis'>Stundenbasis</MenuItem>
                                    </Select>
                                    {errors.contractType && (
                                        <FormHelperText error={!!(errors.contractType && touched.contractType)}>
                                            {errors.contractType}
                                        </FormHelperText>
                                    )}
                                </Grid>
                            </Grid>
                            <Stack sx={{marginY: 4}} direction='row' alignItems='center'>
                                <Box component='img' src={flagIcon} sx={{maxHeight: '110px'}}/>
                                <Box>
                                    <Typography variant='h6'>Hinweis</Typography>
                                    <Typography>Jeder Mitarbeiter erhält automatisch eine Email mit persönlichen
                                        Zugangsdaten. Damit kann sich der Mitarbeiter dann in App und Web anmelden und
                                        die Stunden erfassen.</Typography>
                                </Box>
                            </Stack>
                            <Box sx={{marginTop: 1, display: 'flex', justifyContent: 'end', gap: 2}}>
                                <Button variant='contained' color='inherit' style={{width: 150}}
                                        onClick={() => navigate('/')}>Abbrechen</Button>
                                <LoadingButton loading={loading} variant='contained'
                                               type="submit">Speichern</LoadingButton>
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
            </section>
        </>
    );
};

export default CreateEmployee;
