import {Box, Stack, Typography} from "@mui/material";
import errorImage from "../../images/error_creature.png";
import React from "react";

export interface ErrorScreenProps {
    message?: string;
}

export const ErrorScreen = (props: ErrorScreenProps) => {
    const errorMessage = props.message ?? 'Beim Laden der Seite ist ein Fehler aufgetreten';

    return <Stack alignItems='center' justifyContent='center' sx={{my: 2}}>
        <Typography variant='h4'>Es tut uns leid!</Typography>
        <Box component='img' src={errorImage} sx={{width: '30%'}}/>
        <Typography sx={{mt: 3}}>{errorMessage}</Typography>
    </Stack>;
};
