import * as Types from '../generated/graphql';

import { gql } from '@apollo/client';
export type InheritableSettingsFragment = { __typename?: 'InheritableSettings', tracking: { __typename?: 'TrackingSettings', automaticBreakCorrectionEnabled: boolean, overNightStampingEnabled: boolean, manualEntry: (
      { __typename?: 'AllowedTrackingClearance' }
      & TrackingClearance_AllowedTrackingClearance_Fragment
    ) | (
      { __typename?: 'ForbiddenTrackingClearance' }
      & TrackingClearance_ForbiddenTrackingClearance_Fragment
    ) | (
      { __typename?: 'LimitedTrackingClearance' }
      & TrackingClearance_LimitedTrackingClearance_Fragment
    ), editDelete: (
      { __typename?: 'AllowedTrackingClearance' }
      & TrackingClearance_AllowedTrackingClearance_Fragment
    ) | (
      { __typename?: 'ForbiddenTrackingClearance' }
      & TrackingClearance_ForbiddenTrackingClearance_Fragment
    ) | (
      { __typename?: 'LimitedTrackingClearance' }
      & TrackingClearance_LimitedTrackingClearance_Fragment
    ), comment: (
      { __typename?: 'AdditionalFieldSetting' }
      & AdditionalFieldSettingFragment
    ), workPlace: (
      { __typename?: 'AdditionalFieldSetting' }
      & AdditionalFieldSettingFragment
    ), stamping: { __typename?: 'StampingSettings', platforms: Array<Types.Platform> } }, absence: { __typename?: 'AbsenceSettings', absenceDurationManualEntryEnabled: boolean } };

export type TrackingClearance_AllowedTrackingClearance_Fragment = { __typename: 'AllowedTrackingClearance' };

export type TrackingClearance_ForbiddenTrackingClearance_Fragment = { __typename: 'ForbiddenTrackingClearance' };

export type TrackingClearance_LimitedTrackingClearance_Fragment = { __typename: 'LimitedTrackingClearance', dayLimit: number };

export type TrackingClearanceFragment = TrackingClearance_AllowedTrackingClearance_Fragment | TrackingClearance_ForbiddenTrackingClearance_Fragment | TrackingClearance_LimitedTrackingClearance_Fragment;

export type AdditionalFieldSettingFragment = { __typename?: 'AdditionalFieldSetting', additionalFieldEnforcement: Types.AdditionalFieldEnforcement };

export const TrackingClearanceFragmentDoc = gql`
    fragment TrackingClearance on TrackingClearance {
  __typename
  ... on LimitedTrackingClearance {
    dayLimit
  }
}
    `;
export const AdditionalFieldSettingFragmentDoc = gql`
    fragment AdditionalFieldSetting on AdditionalFieldSetting {
  additionalFieldEnforcement
}
    `;
export const InheritableSettingsFragmentDoc = gql`
    fragment InheritableSettings on InheritableSettings {
  tracking {
    manualEntry {
      ...TrackingClearance
    }
    editDelete {
      ...TrackingClearance
    }
    comment {
      ...AdditionalFieldSetting
    }
    workPlace {
      ...AdditionalFieldSetting
    }
    stamping {
      platforms
    }
    automaticBreakCorrectionEnabled
    overNightStampingEnabled
  }
  absence {
    absenceDurationManualEntryEnabled
  }
}
    ${TrackingClearanceFragmentDoc}
${AdditionalFieldSettingFragmentDoc}`;