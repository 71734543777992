import {contractTypeToString} from "../../../../utils/display";
import React, {useState} from "react";
import {AccountStatus, ContractType} from "../../../../generated/graphql";
import {useFormik} from "formik";
import * as Yup from "yup";
import {toast} from "react-toastify";
import {useEditEmployeeMutation} from "../../editEmployee.generated";
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    FormHelperText,
    Grid,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import CachedIcon from '@mui/icons-material/Cached';
import {EmployeeInformation} from "../../../../components/molecules/EmployeeInformation";
import {useResendVerificationLinkMutation} from "./resendVerificationLink.generated";
import {DetailedEmployeeFragment} from "../../../../fragments/employeeWithTerminalAccess.generated";
import {useGrantUserTerminalAccessMutation} from "./grantUserTerminalAccess.generated";
import {useRevokeUserTerminalAccessMutation} from "./revokeUserTerminalAccess.generated";
import {WorkPlaceFragment} from "../../../../fragments/workPlace.generated";
import {useFlag} from "@unleash/proxy-client-react";

type EditEmployeeError = 'AuthenticationRequired' | 'PermissionDenied' | 'UserNotFound';

type ResendVerificationLinkError = 'AuthenticationRequired' | 'PermissionDenied' | 'UserNotFound' | 'CompanyNotFound';

type GrantUserTerminalAccessError = 'AuthenticationRequired' | 'PermissionDenied' | 'UserNotFound';
type RevokeUserTerminalAccessError = 'AuthenticationRequired' | 'PermissionDenied';

interface WorkPlaceInformation {
    readonly workPlaceId: number;
    readonly name: string;
}

interface EmployeeAdministrationForm {
    readonly email: string;
    readonly name: string;
    readonly surname: string;
    readonly contractType?: ContractType;
    readonly workPlaceId: number;
}

export interface EmployeeAdministrationProps {
    readonly employeeId: number;
    readonly employee?: DetailedEmployeeFragment;
    readonly workPlaces: WorkPlaceFragment[];
    readonly onAfterEdit?: () => void;
    readonly canUseTerminal: boolean;
}

const EmployeeAdministration = ({employee, employeeId, ...props}: EmployeeAdministrationProps) => {
    const [isEditing, setEditing] = useState(false);

    const [editEmployeeMutation, {loading}] = useEditEmployeeMutation();

    const employeeLoading = !employee;

    const workPlaceInformationList: WorkPlaceInformation[] = props.workPlaces.map((workPlace) => ({
        workPlaceId: workPlace.workPlaceId,
        name: workPlace.name
    }));

    const isWorkPlacesEnabled = useFlag('company-work-places');

    const editEmployee = async (
        email: string,
        name: string,
        surname: string,
        contractType: ContractType,
        workPlaceId: number | undefined
    ): Promise<void> => {
        const {data, errors} = await editEmployeeMutation({
            variables: {
                employeeId: employeeId,
                email: email,
                name: name,
                surname: surname,
                contractType: contractType,
                workPlaceId: workPlaceId
            }
        });

        if (errors) {
            const error = errors[0];
            const reason = error.extensions['reason'] as unknown as EditEmployeeError;

            switch (reason) {
                case "AuthenticationRequired":
                    toast.error('Melde dich erneut an und versuche es nochmal.');
                    return;
                case 'PermissionDenied':
                    toast.error('Melde dich erneut an und versuche es nochmal.');
                    return;
                case "UserNotFound":
                    toast.error('Der Nutzer konnte nicht gefunden werden.');
                    return;
                default:
                    toast.error('Ein unbekannter Fehler ist aufgetreten');
                    return;
            }
        } else if (data) {
            setEditing(false);
            toast.success('Mitarbeiter wurde erfolgreich bearbeitet');

            if (props.onAfterEdit) {
                props.onAfterEdit();
            }
        } else {
            toast.error('Ein unbekannter Fehler ist aufgetreten');
        }
    };

    const [resendVerificationMutation, {loading: resendVerificationLoading}] = useResendVerificationLinkMutation();

    const resendVerificationLink = async () => {
        const {data, errors} = await resendVerificationMutation({
            variables: {
                employeeId: employeeId
            }
        });

        if (errors) {
            const error = errors[0];
            const reason = error.extensions['reason'] as unknown as ResendVerificationLinkError;

            switch (reason) {
                case "AuthenticationRequired":
                    toast.error('Melde dich erneut an und versuche es nochmal.');
                    return;
                case 'PermissionDenied':
                    toast.error('Melde dich erneut an und versuche es nochmal.');
                    return;
                case "UserNotFound":
                    toast.error('Der Nutzer konnte nicht gefunden werden.');
                    return;
                case "CompanyNotFound":
                    toast.error('Das Unternehmen des Nutzers konnte nicht gefunden werden.');
                    return;
                default:
                    toast.error('Ein unbekannter Fehler ist aufgetreten');
                    return;
            }
        } else if (data) {
            toast.success('Dem Mitarbeiter wurde ein neuer Verifizierungslink gesendet');

            if (props.onAfterEdit) {
                props.onAfterEdit();
            }
        } else {
            toast.error('Ein unbekannter Fehler ist aufgetreten');
        }
    };

    const [initialPinDialogOpen, setInitialPinDialogOpen] = useState(false);
    const [grantUserTerminalAccessMutation, {
        loading: grantUserTerminalAccessLoading,
        data: grantUserTerminalAccessData
    }] = useGrantUserTerminalAccessMutation();

    let initialPin: string | undefined = undefined;
    if (grantUserTerminalAccessData?.terminal.grantUserTerminalAccess.__typename === 'GrantUserTerminalAccessInitialPin') {
        initialPin = grantUserTerminalAccessData.terminal.grantUserTerminalAccess.initialPin;
    }

    const grantUserTerminalAccess = async () => {
        const {data, errors} = await grantUserTerminalAccessMutation({
            variables: {
                userId: employeeId
            }
        });

        if (errors) {
            const error = errors[0];
            const reason = error.extensions['reason'] as unknown as GrantUserTerminalAccessError;

            switch (reason) {
                case "AuthenticationRequired":
                    toast.error('Melde dich erneut an und versuche es nochmal.');
                    return;
                case 'PermissionDenied':
                    toast.error('Melde dich erneut an und versuche es nochmal.');
                    return;
                case "UserNotFound":
                    toast.error('Der Nutzer konnte nicht gefunden werden.');
                    return;
                default:
                    toast.error('Ein unbekannter Fehler ist aufgetreten');
                    return;
            }
        } else if (data) {
            if (data.terminal.grantUserTerminalAccess.__typename === 'GrantUserTerminalAccessEmailSent') {
                toast.success('Der Mitarbeiter wurde für das Tablet freigeschalten. Er hat eine E-Mail mit genaueren Anweisungen zum Login erhalten');
            } else if (data.terminal.grantUserTerminalAccess.__typename === 'GrantUserTerminalAccessInitialPin') {
                setInitialPinDialogOpen(true);
            }

            if (props.onAfterEdit) {
                props.onAfterEdit();
            }
        } else {
            toast.error('Ein unbekannter Fehler ist aufgetreten');
        }
    };

    const [revokeUserTerminalAccessMutation, {
        loading: revokeUserTerminalAccessLoading,
    }] = useRevokeUserTerminalAccessMutation();

    const revokeUserTerminalAccess = async () => {
        const {data, errors} = await revokeUserTerminalAccessMutation({
            variables: {
                userId: employeeId
            }
        });

        if (errors) {
            const error = errors[0];
            const reason = error.extensions['reason'] as unknown as RevokeUserTerminalAccessError;

            switch (reason) {
                case "AuthenticationRequired":
                    toast.error('Melde dich erneut an und versuche es nochmal.');
                    return;
                case 'PermissionDenied':
                    toast.error('Melde dich erneut an und versuche es nochmal.');
                    return;
                default:
                    toast.error('Ein unbekannter Fehler ist aufgetreten');
                    return;
            }
        } else if (data) {
            toast.success('Dem Mitarbeiter wurde der Terminal-Zugang erfolgreich entzogen');

            if (props.onAfterEdit) {
                props.onAfterEdit();
            }
        } else {
            toast.error('Ein unbekannter Fehler ist aufgetreten');
        }
    };

    const {handleChange, values, handleSubmit, handleBlur, touched, errors} = useFormik<EmployeeAdministrationForm>({
        enableReinitialize: true,
        initialValues: {
            email: employee?.email ?? '',
            name: employee?.name ?? '',
            surname: employee?.surname ?? '',
            contractType: employee?.contractType ?? undefined,
            workPlaceId: employee?.workPlaceId ?? 0
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .email('Invalid email address')
                .required('Pflichtfeld'),
            name: Yup.string()
                .min(1, 'Vorname darf nicht leer sein')
                .required('Pflichtfeld'),
            surname: Yup.string()
                .min(1, 'Nachname darf nicht leer sein')
                .required('Pflichtfeld'),
            contractType: Yup.string()
                .min(1, 'Vertragsart darf nicht leer sein')
                .required('Pflichtfeld')
        }),
        onSubmit: (values) => {
            editEmployee(
                values.email,
                values.name,
                values.surname,
                values.contractType as ContractType,
                values.workPlaceId
            );
        }
    });

    return <>
        <Box component='form' onSubmit={handleSubmit}>
            <Grid container spacing={2}>
                <EmployeeInformation label='Vorname' loading={employeeLoading}>
                    {isEditing ?
                        (<>
                            <TextField
                                sx={{marginTop: 0.5}}
                                id="name"
                                name='name'
                                placeholder="Vorname"
                                value={values.name}
                                error={!!(errors.name && touched.name)}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                variant='outlined'
                                fullWidth
                                size='small'
                            />
                            {errors.name && (
                                <FormHelperText error={!!(errors.name && touched.name)}>
                                    {errors.name}
                                </FormHelperText>
                            )}
                        </>)
                        : (<p>{employee?.name}</p>)
                    }
                </EmployeeInformation>
                <EmployeeInformation label='Nachname' loading={employeeLoading}>
                    {isEditing ?
                        (<>
                            <TextField
                                sx={{marginTop: 0.5}}
                                id="surname"
                                name='surname'
                                placeholder="Nachname"
                                value={values.surname}
                                error={!!(errors.surname && touched.surname)}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                variant='outlined'
                                fullWidth
                                size='small'
                            />
                            {errors.surname && (
                                <FormHelperText error={!!(errors.surname && touched.surname)}>
                                    {errors.surname}
                                </FormHelperText>
                            )}
                        </>)
                        : (<p>{employee?.surname}</p>)
                    }
                </EmployeeInformation>
                <EmployeeInformation label='E-Mail' loading={employeeLoading}>
                    {isEditing ?
                        (<>
                            <TextField
                                sx={{marginTop: 0.5}}
                                id="email"
                                name='email'
                                placeholder="E-Mail"
                                value={values.email}
                                error={!!(errors.email && touched.email)}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                variant='outlined'
                                fullWidth
                                size='small'
                            />
                            {errors.email && (
                                <FormHelperText error={!!(errors.email && touched.email)}>
                                    {errors.email}
                                </FormHelperText>
                            )}
                        </>)
                        : (<p>{employee?.email}</p>)
                    }
                </EmployeeInformation>
                <EmployeeInformation label='Anstellung' loading={employeeLoading}>
                    {isEditing ?
                        (<>
                            <Select
                                id='contractType'
                                name='contractType'
                                labelId="contract-type-label"
                                size='small'
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.contractType}
                                error={!!(errors.contractType && touched.contractType)}
                                fullWidth
                            >
                                <MenuItem value='full_time'>Vollzeit</MenuItem>
                                <MenuItem value='part_time'>Teilzeit</MenuItem>
                                <MenuItem value='working_student'>Werkstudent</MenuItem>
                                <MenuItem value='intern'>Praktikant</MenuItem>
                                <MenuItem value='hourly_basis'>Stundenbasis</MenuItem>
                            </Select>
                        </>)
                        : (<p>{employee ? contractTypeToString(employee.contractType) : ''}</p>)
                    }
                </EmployeeInformation>
                {isWorkPlacesEnabled &&
                    <EmployeeInformation label='Arbeitsort' loading={employeeLoading}>
                        {isEditing ?
                            (<>
                                <Select
                                    id='workPlaceId'
                                    name='workPlaceId'
                                    labelId="work-place-label"
                                    size='small'
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.workPlaceId}
                                    error={!!(errors.workPlaceId && touched.workPlaceId)}
                                    fullWidth
                                >
                                    {workPlaceInformationList.map((workPlace) => {
                                        return <MenuItem key={workPlace.workPlaceId}
                                                         value={workPlace.workPlaceId}>{workPlace.name}</MenuItem>;
                                    })}
                                </Select>
                            </>)
                            : (<p>{employee?.workPlace ? employee.workPlace.name : ''}</p>)
                        }
                    </EmployeeInformation>
                }
                {!isEditing && employee &&
                    <EmployeeInformation label='Status' loading={employeeLoading}>
                        <Stack direction='row' spacing={4} alignItems='center'>
                            <Typography>{accountStatusToString(employee?.accountStatus)}</Typography>
                            {employee?.accountStatus === 'notVerified' &&
                                <LoadingButton loading={resendVerificationLoading} variant='text'
                                               onClick={resendVerificationLink}>
                                    <CachedIcon sx={{color: '#0854CF', marginRight: 1}}/>
                                    Link erneut senden
                                </LoadingButton>
                            }
                        </Stack>
                    </EmployeeInformation>
                }
                {!isEditing && employee && props.canUseTerminal &&
                    <>
                        <EmployeeInformation label='Zugang Tablet-Terminal' loading={employeeLoading}>
                            <Stack direction='row' spacing={4} alignItems='center'>
                                <Typography>{employee.hasTerminalAccess ? 'aktiv' : 'nicht aktiv'}</Typography>
                                {!employee.hasTerminalAccess &&
                                    <LoadingButton loading={grantUserTerminalAccessLoading} variant='text'
                                                   onClick={grantUserTerminalAccess}>
                                        <CachedIcon sx={{color: '#0854CF', marginRight: 1}}/>
                                        Aktivieren
                                    </LoadingButton>
                                }
                                {employee.hasTerminalAccess &&
                                    <LoadingButton loading={revokeUserTerminalAccessLoading} variant='text'
                                                   onClick={revokeUserTerminalAccess} sx={{color: '#ED4545'}}>
                                        <CachedIcon sx={{color: '#ED4545', marginRight: 1}}/>
                                        Deaktivieren
                                    </LoadingButton>
                                }
                            </Stack>
                        </EmployeeInformation>
                    </>
                }
            </Grid>
            <Box sx={{mt: 2, display: 'flex', justifyContent: 'right'}}>
                {isEditing ? (
                    <>
                        <Button style={{marginRight: '1rem'}} color='inherit' variant='contained'
                                onClick={() => setEditing(false)}>Abbrechen</Button>
                        <LoadingButton loading={loading} variant='contained' type='submit'>Speichern</LoadingButton>
                    </>
                ) : (
                    <Button variant='contained' onClick={() => setEditing(true)}>Bearbeiten</Button>
                )}
            </Box>
        </Box>
        <Dialog
            open={initialPinDialogOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            onClose={() => setInitialPinDialogOpen(false)}
        >
            <DialogTitle id="alert-dialog-title">
                Initiale PIN für {employee?.name} {employee?.surname}
            </DialogTitle>
            <DialogContent>
                <Typography>Die initiale PIN
                    für {employee?.name} {employee?.surname} lautet: <b>{initialPin}</b></Typography>
                <Typography sx={{mt: 2}}>Notiere diese PIN und teile Sie dem Mitarbeiter mit. Bei der ersten Anmeldung
                    muss er diese
                    PIN angeben und wird dann aufgefordert eine neue PIN zu setzen.</Typography>
            </DialogContent>
        </Dialog>
    </>;
}

const accountStatusToString = (status: AccountStatus) => {
    switch (status) {
        case "active":
            return 'Aktiv';
        case "archived":
            return 'Archiviert';
        case 'deleted':
            return 'Gelöscht';
        case "notVerified":
            return 'Nicht verifiziert';
    }
};

export default EmployeeAdministration;
