import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateDepartmentParentMutationVariables = Types.Exact<{
  departmentId: Types.Scalars['Int'];
  parentId: Types.Scalars['Int'];
}>;


export type UpdateDepartmentParentMutation = { __typename?: 'Mutation', department: { __typename?: 'departmentMutations', updateDepartmentParent: any } };


export const UpdateDepartmentParentDocument = gql`
    mutation UpdateDepartmentParent($departmentId: Int!, $parentId: Int!) {
  department {
    updateDepartmentParent(departmentId: $departmentId, parentId: $parentId)
  }
}
    `;
export type UpdateDepartmentParentMutationFn = Apollo.MutationFunction<UpdateDepartmentParentMutation, UpdateDepartmentParentMutationVariables>;

/**
 * __useUpdateDepartmentParentMutation__
 *
 * To run a mutation, you first call `useUpdateDepartmentParentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDepartmentParentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDepartmentParentMutation, { data, loading, error }] = useUpdateDepartmentParentMutation({
 *   variables: {
 *      departmentId: // value for 'departmentId'
 *      parentId: // value for 'parentId'
 *   },
 * });
 */
export function useUpdateDepartmentParentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDepartmentParentMutation, UpdateDepartmentParentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDepartmentParentMutation, UpdateDepartmentParentMutationVariables>(UpdateDepartmentParentDocument, options);
      }
export type UpdateDepartmentParentMutationHookResult = ReturnType<typeof useUpdateDepartmentParentMutation>;
export type UpdateDepartmentParentMutationResult = Apollo.MutationResult<UpdateDepartmentParentMutation>;
export type UpdateDepartmentParentMutationOptions = Apollo.BaseMutationOptions<UpdateDepartmentParentMutation, UpdateDepartmentParentMutationVariables>;