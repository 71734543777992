import * as Types from '../../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RevokeUserTerminalAccessMutationVariables = Types.Exact<{
  userId: Types.Scalars['Int'];
}>;


export type RevokeUserTerminalAccessMutation = { __typename?: 'Mutation', terminal: { __typename?: 'terminalMutations', revokeUserTerminalAccess: any } };


export const RevokeUserTerminalAccessDocument = gql`
    mutation RevokeUserTerminalAccess($userId: Int!) {
  terminal {
    revokeUserTerminalAccess(userId: $userId)
  }
}
    `;
export type RevokeUserTerminalAccessMutationFn = Apollo.MutationFunction<RevokeUserTerminalAccessMutation, RevokeUserTerminalAccessMutationVariables>;

/**
 * __useRevokeUserTerminalAccessMutation__
 *
 * To run a mutation, you first call `useRevokeUserTerminalAccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeUserTerminalAccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeUserTerminalAccessMutation, { data, loading, error }] = useRevokeUserTerminalAccessMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useRevokeUserTerminalAccessMutation(baseOptions?: Apollo.MutationHookOptions<RevokeUserTerminalAccessMutation, RevokeUserTerminalAccessMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RevokeUserTerminalAccessMutation, RevokeUserTerminalAccessMutationVariables>(RevokeUserTerminalAccessDocument, options);
      }
export type RevokeUserTerminalAccessMutationHookResult = ReturnType<typeof useRevokeUserTerminalAccessMutation>;
export type RevokeUserTerminalAccessMutationResult = Apollo.MutationResult<RevokeUserTerminalAccessMutation>;
export type RevokeUserTerminalAccessMutationOptions = Apollo.BaseMutationOptions<RevokeUserTerminalAccessMutation, RevokeUserTerminalAccessMutationVariables>;