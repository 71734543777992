import * as Types from '../../generated/graphql';

import { EmployeeFragment } from '../../fragments/employee.generated';
import { gql } from '@apollo/client';
import { EmployeeFragmentDoc } from '../../fragments/employee.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EmployeeOverviewListingQueryVariables = Types.Exact<{
  filterOption: Types.EmployeeListFilterOption;
  searchQuery?: Types.InputMaybe<Types.Scalars['String']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type EmployeeOverviewListingQuery = { __typename?: 'Query', employee: { __typename?: 'employeeQueries', employeeOverviewListing: { __typename?: 'EmployeeListingResult', totalCount: number, employeeRows: Array<{ __typename?: 'EmployeeListingRow', remainingVacationMinutes?: number | null, balanceSeconds?: number | null, employee: (
          { __typename?: 'Employee' }
          & EmployeeFragment
        ) }> } } };


export const EmployeeOverviewListingDocument = gql`
    query EmployeeOverviewListing($filterOption: EmployeeListFilterOption!, $searchQuery: String, $limit: Int, $offset: Int) {
  employee {
    employeeOverviewListing(
      filterOption: $filterOption
      searchQuery: $searchQuery
      limit: $limit
      offset: $offset
    ) {
      totalCount
      employeeRows {
        employee {
          ...Employee
        }
        remainingVacationMinutes
        balanceSeconds
      }
    }
  }
}
    ${EmployeeFragmentDoc}`;

/**
 * __useEmployeeOverviewListingQuery__
 *
 * To run a query within a React component, call `useEmployeeOverviewListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeOverviewListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeOverviewListingQuery({
 *   variables: {
 *      filterOption: // value for 'filterOption'
 *      searchQuery: // value for 'searchQuery'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useEmployeeOverviewListingQuery(baseOptions: Apollo.QueryHookOptions<EmployeeOverviewListingQuery, EmployeeOverviewListingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmployeeOverviewListingQuery, EmployeeOverviewListingQueryVariables>(EmployeeOverviewListingDocument, options);
      }
export function useEmployeeOverviewListingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmployeeOverviewListingQuery, EmployeeOverviewListingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmployeeOverviewListingQuery, EmployeeOverviewListingQueryVariables>(EmployeeOverviewListingDocument, options);
        }
export type EmployeeOverviewListingQueryHookResult = ReturnType<typeof useEmployeeOverviewListingQuery>;
export type EmployeeOverviewListingLazyQueryHookResult = ReturnType<typeof useEmployeeOverviewListingLazyQuery>;
export type EmployeeOverviewListingQueryResult = Apollo.QueryResult<EmployeeOverviewListingQuery, EmployeeOverviewListingQueryVariables>;