import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type StopTrackingMutationVariables = Types.Exact<{
  comment?: Types.InputMaybe<Types.Scalars['String']>;
  newWorkPlace?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type StopTrackingMutation = { __typename?: 'Mutation', tracking: { __typename?: 'trackingMutations', stopTracking: any } };


export const StopTrackingDocument = gql`
    mutation StopTracking($comment: String, $newWorkPlace: String) {
  tracking {
    stopTracking(comment: $comment, newWorkPlace: $newWorkPlace)
  }
}
    `;
export type StopTrackingMutationFn = Apollo.MutationFunction<StopTrackingMutation, StopTrackingMutationVariables>;

/**
 * __useStopTrackingMutation__
 *
 * To run a mutation, you first call `useStopTrackingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStopTrackingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stopTrackingMutation, { data, loading, error }] = useStopTrackingMutation({
 *   variables: {
 *      comment: // value for 'comment'
 *      newWorkPlace: // value for 'newWorkPlace'
 *   },
 * });
 */
export function useStopTrackingMutation(baseOptions?: Apollo.MutationHookOptions<StopTrackingMutation, StopTrackingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StopTrackingMutation, StopTrackingMutationVariables>(StopTrackingDocument, options);
      }
export type StopTrackingMutationHookResult = ReturnType<typeof useStopTrackingMutation>;
export type StopTrackingMutationResult = Apollo.MutationResult<StopTrackingMutation>;
export type StopTrackingMutationOptions = Apollo.BaseMutationOptions<StopTrackingMutation, StopTrackingMutationVariables>;