import * as Types from '../../../generated/graphql';

import { EmployeeFragment } from '../../../fragments/employee.generated';
import { gql } from '@apollo/client';
import { EmployeeFragmentDoc } from '../../../fragments/employee.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ImitationCompanyDetailsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['Int'];
  searchQuery?: Types.InputMaybe<Types.Scalars['String']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type ImitationCompanyDetailsQuery = { __typename?: 'Query', imitation: { __typename?: 'imitationQueries', imitationCompanyDetails: { __typename?: 'ImitationCompanyDetails', companyName: string, totalEmployeeCount: number, employeeList: Array<(
        { __typename?: 'Employee', userType: Types.UserType }
        & EmployeeFragment
      )> } } };


export const ImitationCompanyDetailsDocument = gql`
    query ImitationCompanyDetails($companyId: Int!, $searchQuery: String, $limit: Int, $offset: Int) {
  imitation {
    imitationCompanyDetails(
      companyId: $companyId
      searchQuery: $searchQuery
      limit: $limit
      offset: $offset
    ) {
      companyName
      employeeList {
        ...Employee
        userType
      }
      totalEmployeeCount
    }
  }
}
    ${EmployeeFragmentDoc}`;

/**
 * __useImitationCompanyDetailsQuery__
 *
 * To run a query within a React component, call `useImitationCompanyDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useImitationCompanyDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImitationCompanyDetailsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      searchQuery: // value for 'searchQuery'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useImitationCompanyDetailsQuery(baseOptions: Apollo.QueryHookOptions<ImitationCompanyDetailsQuery, ImitationCompanyDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ImitationCompanyDetailsQuery, ImitationCompanyDetailsQueryVariables>(ImitationCompanyDetailsDocument, options);
      }
export function useImitationCompanyDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ImitationCompanyDetailsQuery, ImitationCompanyDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ImitationCompanyDetailsQuery, ImitationCompanyDetailsQueryVariables>(ImitationCompanyDetailsDocument, options);
        }
export type ImitationCompanyDetailsQueryHookResult = ReturnType<typeof useImitationCompanyDetailsQuery>;
export type ImitationCompanyDetailsLazyQueryHookResult = ReturnType<typeof useImitationCompanyDetailsLazyQuery>;
export type ImitationCompanyDetailsQueryResult = Apollo.QueryResult<ImitationCompanyDetailsQuery, ImitationCompanyDetailsQueryVariables>;