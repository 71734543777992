import {WorkPlaceForm, WorkPlaceFormValues} from "../../../../components/molecules/WorkPlaceForm";
import React, {useState} from "react";
import {WorkPlaceFragment} from "../../../../fragments/workPlace.generated";
import {useEditWorkPlaceMutation} from "./editWorkPlace.generated";
import {EmployeeInformation} from "../../../../components/molecules/EmployeeInformation";
import {Box, Button, Grid, Typography} from "@mui/material";
import {toast} from "react-toastify";

interface WorkPlaceSettingsProps {
    workPlace?: WorkPlaceFragment;
    onAfterEdit?: () => void;
}

export const WorkPlaceSettings = ({workPlace, onAfterEdit}: WorkPlaceSettingsProps) => {
    const [isEdit, setEdit] = useState(false);

    const [editWorkPlaceMutation, {loading: editWorkPlaceLoading}] = useEditWorkPlaceMutation();

    const onEdit = async (values: WorkPlaceFormValues) => {
        if (!workPlace) {
            return;
        }

        const {data, errors} = await editWorkPlaceMutation({
            variables: {
                workPlaceId: workPlace.workPlaceId,
                name: values.name,
                country: values.country,
                state: values.state,
                city: values.city,
                address: values.address,
                postalCode: values.postalCode
            }
        });

        if (data) {
            setEdit(false);
            toast.success('Standort erfolgreich editiert');
            if(onAfterEdit) {
                onAfterEdit();
            }
        } else if (errors) {
            const error = errors[0];
            const reason = error.extensions?.['reason'];

            switch (reason) {
                case "AuthenticationRequired":
                    toast.error('Melde dich erneut an und versuche es nochmal.');
                    return;
                case "PermissionDenied":
                    toast.error('Du hast keine Berechtigung einen Standort zu erstellen');
                    return;
                case "UserNotFound":
                    toast.error('Der Mitarbeiter-Account konnte nicht gefunden werden');
                    return;
                case "WorkPlaceNotFound":
                    toast.error('Der Standort konnte nicht gefunden werden');
                    return;
            }
        } else {
            toast.error('Ein unerwarteter Fehler ist aufgetreten. Bitte versuche es später erneut.');
        }
    };

    if (!workPlace) {
        return <p>Loading</p>;
    }

    return <>
        {(workPlace && !isEdit) &&
            <>
                <Grid container spacing={2}>
                    <EmployeeInformation label='Name des Standorts' xs={12} md={12}>
                        <Typography>{workPlace.name}</Typography>
                    </EmployeeInformation>
                    <EmployeeInformation label='Land' xs={12} md={12}>
                        <Typography>{workPlace.country}</Typography>
                    </EmployeeInformation>
                    <EmployeeInformation label='Bundesland' xs={12} md={12}>
                        <Typography>{workPlace.state}</Typography>
                    </EmployeeInformation>
                    <EmployeeInformation label='Postleitzahl' xs={12} md={12}>
                        <Typography>{workPlace.postalCode}</Typography>
                    </EmployeeInformation>
                    <EmployeeInformation label='Straße und Hausnummer' xs={12} md={12}>
                        <Typography>{workPlace.address}</Typography>
                    </EmployeeInformation>
                </Grid>
                <Box sx={{mt: 2, display: 'flex', justifyContent: 'right'}}>
                    <Button variant='contained' onClick={() => setEdit(true)}>Bearbeiten</Button>
                </Box>
            </>
        }
        {(workPlace && isEdit) &&
            <WorkPlaceForm
                saveButtonLoading={editWorkPlaceLoading}
                onSubmit={onEdit}
                onAbort={() => setEdit(false)}
                initialValues={{
                    name: workPlace.name,
                    country: workPlace.country != null ? workPlace.country : undefined,
                    state: workPlace.state != null ? workPlace.state : undefined,
                    city: workPlace.city != null ? workPlace.city : undefined,
                    address: workPlace.address != null ? workPlace.address : undefined,
                    postalCode: workPlace.postalCode != null ? workPlace.postalCode : undefined
                }}/>
        }
    </>;
};
