import {useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {useNavigate} from "react-router-dom";
import useTrackingDetailDialog from "../../dynamic-dialog/useTrackingDetailDialog";
import {ResourceInput, ResourceLabelContentArg} from "@fullcalendar/resource";
import {CalendarEventFragment} from "../../pages/calendar/calendarEvents.generated";
import {DateTime} from "luxon";
import React from "react";
import FullCalendar from "@fullcalendar/react";
import {DatesSetArg, EventClickArg, EventContentArg, SlotLabelContentArg} from "@fullcalendar/core";
import {Avatar, Box, Stack, Tooltip, Typography} from "@mui/material";
import {absenceTypeToString, trackingNotesToText} from "../../utils/display";
import WorkingTimeText from "../molecules/WorkingTimeText";
import {VerboseFormattingArg} from "@fullcalendar/core/internal";
import {hasAtLeastSubscription} from "../../utils/subscription";
import {UpgradeSubscriptionScreen} from "./UpgradeSubscriptionScreen";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import {EmployeeFragment} from "../../fragments/employee.generated";
import {useTranslation} from "react-i18next";

interface CalendarWidgetProps {
    readonly employees: EmployeeFragment[];
    readonly calendarEvents: CalendarEventFragment[];
    readonly onEditSave?: () => void;
    readonly onChangeDates: (startDate: DateTime, endDate: DateTime) => void;
}

export const CalendarWidget = ({employees, calendarEvents, onEditSave, onChangeDates}: CalendarWidgetProps) => {
    const {t} = useTranslation();

    const user = useSelector((state: RootState) => state.auth.currentUser);
    const navigate = useNavigate();
    const {showTrackingDetailDialog} = useTrackingDetailDialog();

    const employeeResources: ResourceInput[] = employees.map(employee => ({
        id: `${employee.userId}`,
        title: `${employee.name} ${employee.surname}`,
        name: employee.name,
        surname: employee.surname
    }));

    const calendarEventData = calendarEvents.map((event, index) => ({
        id: `${index}`,
        resourceId: `${event.userId}`,
        start: event.startDate,
        end: DateTime.fromISO(event.endDate).plus({day: 1}).toISODate(),
        title: '',
        event: event
    }));

    const calendarRef = React.createRef<FullCalendar>();

    const renderSlotLabel = (arg: SlotLabelContentArg) => {
        const date = DateTime.fromJSDate(arg.date);
        if (arg.level === 0) {
            return <Typography
                sx={{color: '#3B3C40', fontWeight: 'bold'}}>Kalenderwoche {date.toFormat('WW')}</Typography>
        } else {
            return <Tooltip title={date.toFormat('EEEE, dd.MM.yyyy', {locale: 'de'})}>
                <Typography
                    sx={{color: '#3B3C40', fontWeight: 'bold'}}>{date.toFormat('EEEE', {locale: 'de'})}</Typography>
            </Tooltip>;
        }
    }

    const onDatesSet = (arg: DatesSetArg) => {
        const startDate = DateTime.fromJSDate(arg.start);
        const endDate = DateTime.fromJSDate(arg.end);
        onChangeDates(startDate, endDate);
    };

    const renderResourceLabelContent = (arg: ResourceLabelContentArg) => {
        const name = arg.resource._resource.extendedProps.name;
        const surname = arg.resource._resource.extendedProps.surname;
        const userShortText = `${Array.from(name)[0]}${Array.from(surname)[0]}`;

        return <Stack flexDirection='row' alignItems='center' gap={1}>
            <Avatar sx={{width: 30, height: 30, fontSize: 14}}>{userShortText}</Avatar>
            <Typography>{name} {surname}</Typography>
        </Stack>;
    };

    const renderEventContent = (arg: EventContentArg) => {
        const graphqlEvent: CalendarEventFragment = arg.event._def.extendedProps.event;

        const backgroundColor = () => {
            switch (graphqlEvent.__typename) {
                case 'NoWorkingDayCalendarEvent':
                    return '#00000029';
                default:
                    return '#fff';
            }
        }

        const pointerColor = () => {
            switch (graphqlEvent.__typename) {
                case 'AbsenceCalendarEvent':
                    if (graphqlEvent.absenceRequestStatus === 'pending') {
                        return '#F1C444';
                    } else if (graphqlEvent.absenceRequestStatus === 'rejected' || graphqlEvent.absenceRequestStatus === 'withdrawn') {
                        return '#DB534C';
                    } else {
                        return '#6ED675';
                    }
                case 'WorkingTimeCalendarEvent':
                    const trackingNoteErrors = trackingNotesToText(graphqlEvent.trackingNotes);

                    if(trackingNoteErrors.some(error => error.color === 'error')) {
                        return '#DB534C';
                    } else if(trackingNoteErrors.some(error => error.color === 'warning')) {
                        return '#F1C444';
                    } else {
                        return '#0854CF';
                    }

                case 'NoWorkingDayCalendarEvent':
                    return undefined;
            }
        };

        const borderColor = () => {
            switch (graphqlEvent.__typename) {
                case 'AbsenceCalendarEvent':
                    if (graphqlEvent.absenceRequestStatus === 'pending') {
                        return '#F1C444';
                    } else {
                        return undefined;
                    }
                case "WorkingTimeCalendarEvent":
                    const trackingNoteErrors = trackingNotesToText(graphqlEvent.trackingNotes);

                    if (trackingNoteErrors.length > 0 && trackingNoteErrors.some((error) => error.color === 'error')) {
                        return '#DB534C';
                    } else {
                        return undefined;
                    }
                default:
                    return undefined;
            }
        }

        const tooltipText = () => {
            switch (graphqlEvent.__typename) {
                case 'AbsenceCalendarEvent':
                    if (graphqlEvent.absenceRequestStatus === 'pending') {
                        return 'Diese Abwesenheit muss noch genehmigt werden';
                    } else {
                        return undefined;
                    }
                case "WorkingTimeCalendarEvent":
                    const trackingNoteErrors = trackingNotesToText(graphqlEvent.trackingNotes);
                    if (trackingNoteErrors.length > 0) {
                        return trackingNoteErrors.map(error => error.message).join('\n');
                    } else {
                        return undefined;
                    }
            }
        }

        const text = () => {
            switch (graphqlEvent.__typename) {
                case 'AbsenceCalendarEvent':
                    if (graphqlEvent.absenceType) {
                        return t(absenceTypeToString(graphqlEvent.absenceType));
                    } else {
                        return 'Abwesend';
                    }
                case 'WorkingTimeCalendarEvent':
                    if (graphqlEvent.workingTime) {
                        return <WorkingTimeText workingTime={graphqlEvent.workingTime}/>;
                    } else {
                        return 'Arbeitstag';
                    }
                case 'NoWorkingDayCalendarEvent':
                    return 'Kein Arbeitstag';
            }
        };

        /**
         * Normale Arbeitstage: blauer Bobbel
         * Problemtage: Orange => automatischer Pausenabzug, Rot => zu wenig Pause, keine Arbeitszeit (Bobbel + Border)
         *
         * Abwesenheit: grüner Bobbel
         * nicht genehmigte Abwesenheiten: oranger Bobbel + orange Border
         *
         * Geplante Arbeitstage: Der komplett Container grau
         */

        const colorOfPointer = pointerColor();

        return <Stack flexDirection='row' alignItems='center' justifyContent='center' gap={0.5} sx={{
            backgroundColor: backgroundColor(),
            boxShadow: colorOfPointer ? '0 3px 10px #00000029' : 'none',
            borderRadius: '10px',
            margin: '6px 4px',
            border: `2px solid ${borderColor()}`,
            padding: '5px',
            marginTop: '10px',
            marginBottom: '0'
        }}>
            {colorOfPointer &&
                <Box sx={{
                    height: '10px',
                    width: '10px',
                    backgroundColor: pointerColor(),
                    borderRadius: '50%'
                }}></Box>
            }
            <Tooltip title={tooltipText()}>
                <Typography sx={{color: '#3B3C40'}}>{text()}</Typography>
            </Tooltip>
        </Stack>
    };

    const renderTitleFormat = (arg: VerboseFormattingArg) => {
        const startDate = DateTime.fromJSDate(arg.start.marker);
        const endDate = DateTime.fromJSDate(arg.end!.marker);
        return `${startDate.toFormat('dd.MM.yyyy')} - ${endDate.toFormat('dd.MM.yyyy')}`;
    };

    const onEventClick = (arg: EventClickArg) => {
        const graphqlEvent: CalendarEventFragment = arg.event._def.extendedProps.event;

        switch (graphqlEvent.__typename) {
            case "AbsenceCalendarEvent":
                if (graphqlEvent.absenceRequestId) {
                    return navigate(`/absence/${graphqlEvent.absenceRequestId}`);
                } else {
                    return null;
                }
            case "WorkingTimeCalendarEvent":
                if (graphqlEvent.workingTime) {
                    return showTrackingDetailDialog({
                        userId: graphqlEvent.userId,
                        date: DateTime.fromISO(graphqlEvent.startDate),
                        onEditSave: onEditSave
                    });
                } else {
                    return null;
                }
            case "NoWorkingDayCalendarEvent":
                return null;
        }
    };

    // Custom behaviour for today button, as we always want to display the whole calendar week
    const onTodayClick = () => {
        const date = DateTime.now();
        calendarRef.current?.getApi().gotoDate(date.set({weekday: 1}).toJSDate());
    };

    if (!hasAtLeastSubscription(user, 'expert')) {
        return <UpgradeSubscriptionScreen/>;
    }

    return <FullCalendar
        ref={calendarRef}
        firstDay={1}
        customButtons={{today: {text: 'Heute', click: onTodayClick}}}
        buttonText={{today: 'Heute'}}
        schedulerLicenseKey="0283030614-fcs-1698414087"
        plugins={[resourceTimelinePlugin]}
        initialView="week"
        resourceAreaWidth="25%"
        initialDate={DateTime.now().set({weekday: 1}).toJSDate()}
        // week-view is the only one used right now. We might want to also use the month-view later.
        views={{
            week: {type: 'resourceTimeline', duration: {days: 7}},
            month: {type: 'resourceTimeline', duration: {days: 30}}
        }}
        slotDuration={{days: 1}}
        slotLabelContent={renderSlotLabel}
        slotLabelFormat={[{week: 'long'}, {weekday: 'short'}]}
        resources={employeeResources}
        resourceAreaHeaderContent={() => <></>}
        resourceLabelContent={renderResourceLabelContent}
        events={calendarEventData}
        datesSet={onDatesSet}
        eventContent={renderEventContent}
        eventClassNames={'fc-mtt-event'}
        titleFormat={renderTitleFormat}
        eventClick={onEventClick}
    />;
};
