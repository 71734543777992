import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetEmployeeDepartmentMutationVariables = Types.Exact<{
  employeeId: Types.Scalars['Int'];
  departmentId: Types.Scalars['Int'];
}>;


export type SetEmployeeDepartmentMutation = { __typename?: 'Mutation', department: { __typename?: 'departmentMutations', setEmployeeDepartment: any } };


export const SetEmployeeDepartmentDocument = gql`
    mutation SetEmployeeDepartment($employeeId: Int!, $departmentId: Int!) {
  department {
    setEmployeeDepartment(employeeId: $employeeId, departmentId: $departmentId)
  }
}
    `;
export type SetEmployeeDepartmentMutationFn = Apollo.MutationFunction<SetEmployeeDepartmentMutation, SetEmployeeDepartmentMutationVariables>;

/**
 * __useSetEmployeeDepartmentMutation__
 *
 * To run a mutation, you first call `useSetEmployeeDepartmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetEmployeeDepartmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setEmployeeDepartmentMutation, { data, loading, error }] = useSetEmployeeDepartmentMutation({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      departmentId: // value for 'departmentId'
 *   },
 * });
 */
export function useSetEmployeeDepartmentMutation(baseOptions?: Apollo.MutationHookOptions<SetEmployeeDepartmentMutation, SetEmployeeDepartmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetEmployeeDepartmentMutation, SetEmployeeDepartmentMutationVariables>(SetEmployeeDepartmentDocument, options);
      }
export type SetEmployeeDepartmentMutationHookResult = ReturnType<typeof useSetEmployeeDepartmentMutation>;
export type SetEmployeeDepartmentMutationResult = Apollo.MutationResult<SetEmployeeDepartmentMutation>;
export type SetEmployeeDepartmentMutationOptions = Apollo.BaseMutationOptions<SetEmployeeDepartmentMutation, SetEmployeeDepartmentMutationVariables>;