import * as Types from '../../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteTimeScheduleMutationVariables = Types.Exact<{
  timeScheduleId: Types.Scalars['Int'];
}>;


export type DeleteTimeScheduleMutation = { __typename?: 'Mutation', employee: { __typename?: 'employeeMutations', timeSchedule: { __typename?: 'timeScheduleMutations', deleteTimeSchedule: any } } };


export const DeleteTimeScheduleDocument = gql`
    mutation DeleteTimeSchedule($timeScheduleId: Int!) {
  employee {
    timeSchedule {
      deleteTimeSchedule(timeScheduleId: $timeScheduleId)
    }
  }
}
    `;
export type DeleteTimeScheduleMutationFn = Apollo.MutationFunction<DeleteTimeScheduleMutation, DeleteTimeScheduleMutationVariables>;

/**
 * __useDeleteTimeScheduleMutation__
 *
 * To run a mutation, you first call `useDeleteTimeScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTimeScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTimeScheduleMutation, { data, loading, error }] = useDeleteTimeScheduleMutation({
 *   variables: {
 *      timeScheduleId: // value for 'timeScheduleId'
 *   },
 * });
 */
export function useDeleteTimeScheduleMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTimeScheduleMutation, DeleteTimeScheduleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTimeScheduleMutation, DeleteTimeScheduleMutationVariables>(DeleteTimeScheduleDocument, options);
      }
export type DeleteTimeScheduleMutationHookResult = ReturnType<typeof useDeleteTimeScheduleMutation>;
export type DeleteTimeScheduleMutationResult = Apollo.MutationResult<DeleteTimeScheduleMutation>;
export type DeleteTimeScheduleMutationOptions = Apollo.BaseMutationOptions<DeleteTimeScheduleMutation, DeleteTimeScheduleMutationVariables>;