import {useParams} from "react-router-dom";
import React from "react";
import {EmployeeListFilterOption} from "../../../generated/graphql";
import {ContentHeader} from "../../../components";
import {
    Box,
    Card,
    CardContent, Chip,
    Grid,
    MenuItem,
    Pagination,
    Select,
    Skeleton,
    SxProps,
    TextField,
    Theme
} from "@mui/material";
import {ErrorScreen} from "../../../components/organisms/ErrorScreen";
import {
    useImitationCompanyDetailsQuery
} from "./imitationCompanyDetails.generated";
import VisibilityIcon from '@mui/icons-material/Visibility';
import {LoadingButton} from "@mui/lab";
import {useImitateUserMutation} from "./imitateUser.generated";
import {toast} from "react-toastify";
import {removeWindowClass} from "../../../utils/helpers";
import {loginUser} from "../../../store/reducers/auth";
import {useDispatch} from "react-redux";

const gridItemStyle: SxProps<Theme> = (theme) => ({
    paddingTop: '10px',
    paddingBottom: '10px',
    textDecoration: 'none'
});

export const ImitationCompanyPage = () => {
    const dispatch = useDispatch();
    const {id} = useParams();

    const [imitateUserMutation] = useImitateUserMutation();

    const itemsPerPage = 20;
    const [currentPage, setCurrentPage] = React.useState(1);
    const [filterOption, setFilterOption] = React.useState<EmployeeListFilterOption>('all');
    const [searchQuery, setSearchQuery] = React.useState<string>('');

    const {data, error, loading} = useImitationCompanyDetailsQuery({
        fetchPolicy: 'no-cache', variables: {
            companyId: Number(id) ?? -1,
            searchQuery: searchQuery.length > 0 ? searchQuery : undefined,
            limit: itemsPerPage,
            offset: (currentPage - 1) * itemsPerPage,
        }
    });

    const companyDetails = data?.imitation.imitationCompanyDetails;
    const employeeRows = companyDetails?.employeeList ?? [];
    const totalEmployeeCount = companyDetails?.totalEmployeeCount ?? 0;

    const totalPages = Math.ceil(totalEmployeeCount / itemsPerPage);

    const imitateUser = async (userId: number) => {
        const {data, errors} = await imitateUserMutation({variables: {userId}});

        if(data && !errors) {
            const accessToken = data.imitation.imitateUser.accessToken;

            toast.success('Du imitierst nun einen Mitarbeiter');
            removeWindowClass('login-page');
            removeWindowClass('hold-transition');

            dispatch(loginUser(accessToken));
        }
    };

    if (error) {
        return <div>
            <ContentHeader title="Kunde Unbekannt"/>
            <Card>
                <ErrorScreen/>
            </Card>
        </div>;
    }

    return (
        <div>
            <ContentHeader title={`Kunde ${companyDetails?.companyName ?? ''}`}/>

            <Card>
                <CardContent>
                    <Grid container>
                        <Grid item md={6}></Grid>
                        <Grid item md={6} sx={{display: 'flex', gap: 2}}>
                            <TextField id="searchQuery"
                                       name='searchQuery'
                                       placeholder="Suchen..."
                                       variant='outlined'
                                       fullWidth
                                       size='small'
                                       value={searchQuery}
                                       onChange={(event) => setSearchQuery(event.target.value)}
                            />
                            <Select
                                id='filterOption'
                                name='filterOption'
                                labelId="filter-option-label"
                                size='small'
                                fullWidth
                                value={filterOption}
                                onChange={(event) => setFilterOption(event.target.value as EmployeeListFilterOption)}
                                sx={{backgroundColor: '#fff'}}
                            >
                                <MenuItem value='all'>Alle anzeigen</MenuItem>
                                <MenuItem value='onlyActive'>Nur aktive anzeigen</MenuItem>
                            </Select>
                        </Grid>
                    </Grid>
                </CardContent>
                <CardContent>
                    <Grid container spacing={1}
                          sx={{
                              marginBottom: '1rem',
                              minHeight: '50px',
                              alignItems: 'center',
                              borderRadius: '10px'
                          }}>
                        <Grid item xs={0.5} sx={gridItemStyle}></Grid>
                        <Grid item xs sx={gridItemStyle}><b>Name</b></Grid>
                        <Grid item xs sx={gridItemStyle}><b>Rolle</b></Grid>
                        <Grid item xs sx={gridItemStyle}><b>Aktivität letzte 7 Tage</b></Grid>
                        <Grid item xs sx={gridItemStyle}><b>Status</b></Grid>
                        <Grid item xs={1}></Grid>
                    </Grid>
                    {employeeRows && employeeRows.length > 0 && <>
                        {employeeRows.map((employeeRow, index) => {
                            const status = () => {
                              switch(employeeRow.accountStatus) {
                                  case 'archived':
                                  case 'deleted':
                                      return <Chip label='Archiviert' color='info' />;
                                  case 'active':
                                      return <Chip label='Aktiv' color='success' />;
                                  case 'notVerified':
                                      return <Chip label='Nicht verifiziert' color='warning' />;
                              }
                            };

                            return <Grid key={index} container spacing={1} sx={{
                                backgroundColor: '#F8FAFE',
                                marginBottom: '1rem',
                                minHeight: '50px',
                                alignItems: 'center',
                                borderRadius: '10px',
                                width: '100%',
                                marginLeft: 0,
                                ':hover': {
                                    backgroundColor: '#E5EDFA'
                                }
                            }}>
                                <Grid item xs={11} sm={11} sx={{paddingTop: '0 !important'}}>
                                    <Grid container sx={{alignItems: 'center'}}>
                                        <Grid item xs={0.5} sx={gridItemStyle}></Grid>
                                        <Grid item xs sx={gridItemStyle}>{employeeRow.name} {employeeRow.surname}</Grid>
                                        <Grid item xs
                                              sx={gridItemStyle}>{employeeRow.userType === 'Admin' ? 'Admin' : ''}</Grid>
                                        <Grid item xs sx={gridItemStyle}>0 Buchungen, 0 Abwesenheiten</Grid>
                                        <Grid item xs sx={gridItemStyle}>{status()}</Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={1} sm={1}>
                                    <LoadingButton loading={false} variant='contained' color='primary'
                                                   onClick={() => imitateUser(employeeRow.userId)}>
                                        <VisibilityIcon/>
                                    </LoadingButton>
                                </Grid>
                            </Grid>;
                        })}
                        <Box sx={{display: 'flex', justifyContent: 'center', paddingTop: 2}}>
                            <Pagination page={currentPage} count={totalPages} color='primary'
                                        onChange={(event, page) => setCurrentPage(page)}/>
                        </Box>
                    </>}
                    {loading &&
                        Array.from(Array(5).keys()).map((_, index) => {
                            return <Skeleton key={index} variant="rounded" width='100%' height={50}
                                             sx={{marginTop: '10px'}}/>;
                        })
                    }
                </CardContent>
            </Card>
        </div>
    );
};
