import {InheritableTrackingSettings} from "../../../../components/settings/InheritableTrackingSettings";
import {DepartmentFragment} from "../../../../fragments/department.generated";
import {useDepartmentSettingsLazyQuery} from "./departmentSettings.generated";
import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../../../store/store";
import {hasAtLeastSubscription} from "../../../../utils/subscription";
import {UpgradeSubscriptionScreen} from "../../../../components/organisms/UpgradeSubscriptionScreen";

export const DepartmentSettings = ({department}: { department?: DepartmentFragment }) => {
    const user = useSelector((state: RootState) => state.auth.currentUser);

    const [futureDepartmentSettingsQuery, {
        data,
        loading,
        error
    }] = useDepartmentSettingsLazyQuery({fetchPolicy: 'no-cache'});

    useEffect(() => {
        if (department) {
            futureDepartmentSettingsQuery({
                variables: {
                    departmentId: department.departmentId
                },
                fetchPolicy: 'no-cache'
            });
        }
    }, [department, futureDepartmentSettingsQuery]);

    if (!hasAtLeastSubscription(user, 'professional')) {
        return <UpgradeSubscriptionScreen/>;
    }

    return <InheritableTrackingSettings entity={{entityType: 'department', entityId: department?.departmentId ?? 0}}
                                        settings={data?.setting.settingsForDepartment.inheritableSettings}
                                        loading={loading}
                                        error={error}/>;
};
