import React, {ReactNode} from 'react';
import {Box, Typography} from "@mui/material";

const ContentHeader = ({title, child}: { title: string, child?: ReactNode }) => {
    return (
        <Box component='section' className="content-header" sx={{marginBottom: 4, display: 'flex', justifyContent: 'space-between'}}>
            <Typography variant='h4' style={{color: '#3B3C40'}}>{title}</Typography>
            {child}
        </Box>
    );
};

export default ContentHeader;
