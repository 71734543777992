import * as Types from '../generated/graphql';

import { gql } from '@apollo/client';
export type PresenceStatisticsFragment = { __typename?: 'PresenceStatistics', presenceMinutes: any, vacationMinutes: any, illnessMinutes: any };

export const PresenceStatisticsFragmentDoc = gql`
    fragment PresenceStatistics on PresenceStatistics {
  presenceMinutes
  vacationMinutes
  illnessMinutes
}
    `;