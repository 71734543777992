import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateCustomHolidayMutationVariables = Types.Exact<{
  workPlaceId: Types.Scalars['Int'];
  name: Types.Scalars['String'];
  date: Types.Scalars['DateType'];
  repeatPeriod: Types.RestRepeatPeriod;
}>;


export type CreateCustomHolidayMutation = { __typename?: 'Mutation', holiday: { __typename?: 'holidayMutations', createCustomHoliday: any } };


export const CreateCustomHolidayDocument = gql`
    mutation CreateCustomHoliday($workPlaceId: Int!, $name: String!, $date: DateType!, $repeatPeriod: RestRepeatPeriod!) {
  holiday {
    createCustomHoliday(
      workPlaceId: $workPlaceId
      name: $name
      date: $date
      repeatPeriod: $repeatPeriod
    )
  }
}
    `;
export type CreateCustomHolidayMutationFn = Apollo.MutationFunction<CreateCustomHolidayMutation, CreateCustomHolidayMutationVariables>;

/**
 * __useCreateCustomHolidayMutation__
 *
 * To run a mutation, you first call `useCreateCustomHolidayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomHolidayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomHolidayMutation, { data, loading, error }] = useCreateCustomHolidayMutation({
 *   variables: {
 *      workPlaceId: // value for 'workPlaceId'
 *      name: // value for 'name'
 *      date: // value for 'date'
 *      repeatPeriod: // value for 'repeatPeriod'
 *   },
 * });
 */
export function useCreateCustomHolidayMutation(baseOptions?: Apollo.MutationHookOptions<CreateCustomHolidayMutation, CreateCustomHolidayMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCustomHolidayMutation, CreateCustomHolidayMutationVariables>(CreateCustomHolidayDocument, options);
      }
export type CreateCustomHolidayMutationHookResult = ReturnType<typeof useCreateCustomHolidayMutation>;
export type CreateCustomHolidayMutationResult = Apollo.MutationResult<CreateCustomHolidayMutation>;
export type CreateCustomHolidayMutationOptions = Apollo.BaseMutationOptions<CreateCustomHolidayMutation, CreateCustomHolidayMutationVariables>;