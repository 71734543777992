import {Box, Card, CardContent, Link, SxProps, Tab, Tabs, Theme} from "@mui/material";
import {ContentHeader} from "../../components";
import {TabContext, TabPanel} from "@mui/lab";
import React from "react";
import {Navigate, useNavigate, useParams} from "react-router-dom";
import {useRouteMatch} from "../../utils/useRouteMatch";
import {useWorkPlaceByIdQuery} from "./workPlaceById.generated";
import {WorkPlaceSettings} from "./pages/WorkPlaceSettings/WorkPlaceSettings";
import { WorkPlaceHolidays } from "./pages/WorkPlaceHolidays/WorkPlaceHolidays";
import { WorkPlaceCompanyVacation } from "./pages/WorkPlaceCompanyVacation/WorkPlaceCompanyVacation";

const tabStyle: SxProps<Theme> = (theme) => ({
    textDecoration: 'none',
    ':hover': {
        textDecoration: 'none'
    },
    ':focus': {
        textDecoration: 'none'
    }
});

export const ViewWorkPlace = () => {
    const {id} = useParams();
    const workPlaceId = Number(id);
    const navigate = useNavigate();

    const {data, refetch} = useWorkPlaceByIdQuery({
        variables: {
            workPlaceId: workPlaceId
        }
    });
    const workPlace = data?.workPlace.workPlaceById;

    const routeMatch = useRouteMatch([
        `/work-place/:workPlaceId/settings`,
        `/work-place/:workPlaceId/holidays`,
        `/work-place/:workPlaceId/company-vacation`
    ]);

    const tabValue = routeMatch?.pattern?.path;

    if (!tabValue) {
        return <Navigate to={`/work-place/${workPlaceId}/settings`} replace={true}/>;
    }

    return (
        <Box>
            <ContentHeader title={`Standort ${workPlace?.name ?? ''}`}/>

            <Card>
                <Tabs value={tabValue}>
                    <Tab
                        label="Stammdaten"
                        value="/work-place/:workPlaceId/settings"
                        component={Link}
                        onClick={() => navigate(`/work-place/${workPlaceId}/settings`)}
                        sx={tabStyle}
                    />
                    <Tab
                        label="Feiertage"
                        value="/work-place/:workPlaceId/holidays"
                        component={Link}
                        onClick={() => navigate(`/work-place/${workPlaceId}/holidays`)}
                        sx={tabStyle}
                    />
                    {/*<Tab
                        label="Betriebsurlaub"
                        value="/work-place/:workPlaceId/company-vacation"
                        component={Link}
                        onClick={() => navigate(`/work-place/${workPlaceId}/company-vacation`)}
                        sx={tabStyle}
                    />*/}
                </Tabs>
                <CardContent>
                    <TabContext value={tabValue}>
                        <TabPanel value="/work-place/:workPlaceId/settings">
                            <WorkPlaceSettings workPlace={workPlace} onAfterEdit={refetch}/>
                        </TabPanel>
                        <TabPanel value="/work-place/:workPlaceId/holidays">
                            <WorkPlaceHolidays workPlace={workPlace} />
                        </TabPanel>
                        <TabPanel value="/work-place/:workPlaceId/company-vacation">
                            <WorkPlaceCompanyVacation />
                        </TabPanel>
                    </TabContext>
                </CardContent>
            </Card>
        </Box>
    );
}
