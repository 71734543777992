import * as Types from '../generated/graphql';

import { EmployeeFragment } from './employee.generated';
import { gql } from '@apollo/client';
import { EmployeeFragmentDoc } from './employee.generated';
export type AbsenceRequestFragment = { __typename?: 'AbsenceRequest', absenceRequestId: number, absenceType: Types.AbsenceType, startDate: any, endDate: any, comment?: string | null, status: Types.AbsenceRequestStatus, employeeId: number, employee: (
    { __typename?: 'Employee' }
    & EmployeeFragment
  ), rows: Array<{ __typename?: 'NewAbsenceOutput', date: any, hours: number, minutes: number, absencePeriod?: Types.AbsencePeriod | null }> };

export const AbsenceRequestFragmentDoc = gql`
    fragment AbsenceRequest on AbsenceRequest {
  absenceRequestId
  absenceType
  startDate
  endDate
  comment
  status
  employeeId
  employee {
    ...Employee
  }
  rows {
    date
    hours
    minutes
    absencePeriod
  }
}
    ${EmployeeFragmentDoc}`;