import {Box, Grid, IconButton, Link, Menu, MenuItem, Skeleton, SxProps, Theme, Typography} from "@mui/material";
import React from "react";
import noDataClipboard from "../../../images/clippboards.png";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {EmployeeVacation} from "../../../generated/graphql";
import {useDeleteEmployeeVacationMutation} from "./deleteEmployeeVacation.generated";
import {
    EmployeeVacationManagementDialog
} from "../../organisms/EmployeeVacationManagementDialog/EmployeeVacationManagementDialog";
import {toast} from "react-toastify";
import {DateTime} from "luxon";

const gridItemStyle: SxProps<Theme> = (theme) => ({
    paddingTop: '0 !important',
    alignItems: 'center'
});

const linkNoDecorationStyle: SxProps<Theme> = {
    textDecoration: 'none',
    color: '#333333',
    ':hover': {
        textDecoration: 'none',
        color: '#333333',
    },
    ':focus': {
        textDecoration: 'none',
        color: '#333333',
    }
};

export const EmployeeVacationTable = ({userId, employeeVacationRows, onPossibleUpdate}: {
    userId: number,
    employeeVacationRows?: EmployeeVacation[],
    onPossibleUpdate?: () => void
}) => {
    const [editEmployeeDialogOpen, setEditEmployeeDialogOpen] = React.useState(false);

    const handleCloseEditEmployeeDialog = () => {
        setEditEmployeeDialogOpen(false);
        if (onPossibleUpdate) {
            onPossibleUpdate();
        }
    };

    return <>
        <Grid container spacing={1}
              sx={{
                  minHeight: '50px',
                  borderRadius: '10px',
                  marginLeft: 0,
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center'
              }}>
            <Grid item xs sx={gridItemStyle}><b>Zeitraum</b></Grid>
            <Grid item xs sx={gridItemStyle}><b>Urlaubstage</b></Grid>
            <Grid item xs sx={gridItemStyle}><b>Verbleibend</b></Grid>
            <Grid item xs sx={gridItemStyle}><b>Autom. Übertrag</b></Grid>
            <Grid item xs sx={gridItemStyle}><b>Kommentar</b></Grid>
            <Grid item xs={0.5} sx={gridItemStyle}></Grid>
        </Grid>

        {!employeeVacationRows &&
            Array.from(Array(3).keys()).map((_, index) => {
                return <Skeleton key={index} variant="rounded" width='100%' height={45} sx={{marginTop: '10px'}}/>;
            })
        }
        {employeeVacationRows && employeeVacationRows.length === 0 && (
            <Box sx={{display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: 5}}>
                <Box component='img' sx={{maxHeight: '50px'}} src={noDataClipboard}/>
                <Typography sx={{marginTop: 2}}>Es existiert noch kein Urlaubsplan</Typography>
            </Box>
        )}

        {(employeeVacationRows ?? []).map((employeeVacationRow, index) => {
            const vacationDays = employeeVacationRow.vacationMinutes / 60 / 8;
            const remainingVacationDays = employeeVacationRow.remainingVacationMinutes / 60 / 8;

            const openEditDialog = () => {
                if(employeeVacationRow.isDeleted) {
                    return;
                }
                setEditEmployeeDialogOpen(true)
            }

            return <Grid key={`employee-vacation-${index}`} container spacing={1} sx={{
                backgroundColor: '#F8FAFE',
                marginTop: '0.5rem',
                minHeight: '50px',
                borderRadius: '10px',
                marginLeft: 0,
                width: '100%',
                ':hover': {
                    backgroundColor: '#E5EDFA'
                },
                display: 'flex',
                alignItems: 'center'
            }}>
                <Grid item xs={11} md={11.5} sx={{paddingTop: '0 !important'}}>
                    <Link href='#' sx={linkNoDecorationStyle} onClick={() => openEditDialog()}>
                        <Grid container sx={{display: 'flex', alignItems: 'center'}}>
                            <Grid item xs sx={gridItemStyle}>
                                {employeeVacationRow.endYear &&
                                    <>{employeeVacationRow.startYear}</>
                                }
                                {!employeeVacationRow.endYear &&
                                    <>ab {employeeVacationRow.startYear}</>
                                }
                            </Grid>
                            <Grid item xs sx={gridItemStyle}>{vacationDays}</Grid>
                            <Grid item xs sx={gridItemStyle}>{remainingVacationDays}</Grid>
                            <Grid item xs
                                  sx={gridItemStyle}>{employeeVacationRow.automaticTransfer ? 'Ja' : 'Nein'}</Grid>
                            <Grid item xs sx={gridItemStyle}>
                                {employeeVacationRow.isDeleted &&
                                    <span style={{color: '#EA474A'}}>Abgelaufen</span>}
                                {!employeeVacationRow.isDeleted &&
                                    <span>{employeeVacationRow.comment ?? ''}</span>
                                }
                            </Grid>
                        </Grid>
                    </Link>
                </Grid>
                <Grid item xs={1} md={0.5}
                      sx={{...gridItemStyle, display: 'flex', paddingLeft: '0 !important', paddingTop: '0 !important'}}>
                    {!employeeVacationRow.isDeleted &&
                        <EmployeeVacationContextMenu userId={userId} employeeVacation={employeeVacationRow}
                                                     onPossibleUpdate={onPossibleUpdate}
                                                     onOpenEdit={() => openEditDialog()}/>
                    }
                </Grid>
                <EmployeeVacationManagementDialog userId={userId} open={editEmployeeDialogOpen}
                                                  onClose={handleCloseEditEmployeeDialog}
                                                  employeeVacation={employeeVacationRow}/>
            </Grid>;
        })}
    </>;
};

const EmployeeVacationContextMenu = ({userId, employeeVacation, onPossibleUpdate, onOpenEdit}: {
    userId: number,
    employeeVacation: EmployeeVacation,
    onPossibleUpdate?: () => void,
    onOpenEdit: () => void
}) => {
    const [deleteEmployeeVacationMutation] = useDeleteEmployeeVacationMutation();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const deleteEmployeeVacation = async () => {
        const {data, errors} = await deleteEmployeeVacationMutation({
            variables: {
                userId: userId,
                startYear: employeeVacation.startYear
            }
        });

        if (data) {
            toast.success('Urlaubsplan erfolgreich gelöscht');
            if (onPossibleUpdate) {
                onPossibleUpdate();
            }
        } else if (errors) {
            const error = errors[0];
            const reason = error.extensions?.['reason'];

            switch (reason) {
                case "AuthenticationRequired":
                    toast.error('Melde dich erneut an und versuche es nochmal.');
                    return;
                case "PermissionDenied":
                    toast.error('Du kannst für diesen Mitarbeiter keinen Urlaubsplan erstellen');
                    return;
            }
        } else {
            toast.error('Ein unerwarteter Fehler ist aufgetreten. Bitte versuche es später erneut.');
        }
    };

    const now = DateTime.now();

    return (
        <>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                sx={{paddingLeft: '2px', paddingRight: '2px', color: '#212529'}}
            >
                <MoreVertIcon/>
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={onOpenEdit}>Bearbeiten</MenuItem>
                {employeeVacation.startYear > now.year &&
                    <MenuItem onClick={() => {
                        handleClose();
                        deleteEmployeeVacation();
                    }}>Löschen</MenuItem>
                }
            </Menu>
        </>
    );
};
