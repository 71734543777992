import * as Types from '../../generated/graphql';

import { WorkPlaceFragment } from '../../fragments/workPlace.generated';
import { gql } from '@apollo/client';
import { WorkPlaceFragmentDoc } from '../../fragments/workPlace.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WorkPlaceByIdQueryVariables = Types.Exact<{
  workPlaceId: Types.Scalars['Int'];
}>;


export type WorkPlaceByIdQuery = { __typename?: 'Query', workPlace: { __typename?: 'workPlaceQueries', workPlaceById: (
      { __typename?: 'WorkPlace' }
      & WorkPlaceFragment
    ) } };


export const WorkPlaceByIdDocument = gql`
    query WorkPlaceById($workPlaceId: Int!) {
  workPlace {
    workPlaceById(workPlaceId: $workPlaceId) {
      ...WorkPlace
    }
  }
}
    ${WorkPlaceFragmentDoc}`;

/**
 * __useWorkPlaceByIdQuery__
 *
 * To run a query within a React component, call `useWorkPlaceByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkPlaceByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkPlaceByIdQuery({
 *   variables: {
 *      workPlaceId: // value for 'workPlaceId'
 *   },
 * });
 */
export function useWorkPlaceByIdQuery(baseOptions: Apollo.QueryHookOptions<WorkPlaceByIdQuery, WorkPlaceByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkPlaceByIdQuery, WorkPlaceByIdQueryVariables>(WorkPlaceByIdDocument, options);
      }
export function useWorkPlaceByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkPlaceByIdQuery, WorkPlaceByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkPlaceByIdQuery, WorkPlaceByIdQueryVariables>(WorkPlaceByIdDocument, options);
        }
export type WorkPlaceByIdQueryHookResult = ReturnType<typeof useWorkPlaceByIdQuery>;
export type WorkPlaceByIdLazyQueryHookResult = ReturnType<typeof useWorkPlaceByIdLazyQuery>;
export type WorkPlaceByIdQueryResult = Apollo.QueryResult<WorkPlaceByIdQuery, WorkPlaceByIdQueryVariables>;