import React, {PropsWithChildren} from "react";
import {RegularBreakpoints} from "@mui/material/Grid/Grid";
import {Box, Grid, InputLabel, Skeleton, Tooltip} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

export type EmployeeInformationProps = PropsWithChildren & {
    label?: string;
    loading?: boolean;
    helperTooltip?: string;
} & RegularBreakpoints;

export const EmployeeInformation = (props: EmployeeInformationProps) => {
    const {label, loading, helperTooltip, children, ...gridProps} = props;

    return <Grid item xs={12} md={6} {...gridProps}>
        {loading ? (
            <Skeleton width='100%'/>
        ) : (
            <Box sx={{display: 'flex', gap: 1}}>
                <InputLabel>{label}</InputLabel>
                {helperTooltip && <Tooltip title={helperTooltip}><InfoIcon fontSize='small'
                                                                           sx={{color: 'rgba(0, 0, 0, 0.6)'}}/></Tooltip>}
            </Box>
        )}
        {loading ? <Skeleton width='70%' height='40px'/> : children}
    </Grid>;
}
