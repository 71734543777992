import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EditEmployeeVacationMutationVariables = Types.Exact<{
  userId: Types.Scalars['Int'];
  startYear: Types.Scalars['Int'];
  vacationMinutes: Types.Scalars['Int'];
  remainingVacationMinutes: Types.Scalars['Int'];
  transferVacationMinutes?: Types.InputMaybe<Types.Scalars['Int']>;
  automaticTransfer: Types.Scalars['Boolean'];
  comment?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type EditEmployeeVacationMutation = { __typename?: 'Mutation', employee: { __typename?: 'employeeMutations', vacation: { __typename?: 'vacationMutations', editEmployeeVacation: any } } };


export const EditEmployeeVacationDocument = gql`
    mutation EditEmployeeVacation($userId: Int!, $startYear: Int!, $vacationMinutes: Int!, $remainingVacationMinutes: Int!, $transferVacationMinutes: Int, $automaticTransfer: Boolean!, $comment: String) {
  employee {
    vacation {
      editEmployeeVacation(
        userId: $userId
        startYear: $startYear
        vacationMinutes: $vacationMinutes
        remainingVacationMinutes: $remainingVacationMinutes
        transferVacationMinutes: $transferVacationMinutes
        automaticTransfer: $automaticTransfer
        comment: $comment
      )
    }
  }
}
    `;
export type EditEmployeeVacationMutationFn = Apollo.MutationFunction<EditEmployeeVacationMutation, EditEmployeeVacationMutationVariables>;

/**
 * __useEditEmployeeVacationMutation__
 *
 * To run a mutation, you first call `useEditEmployeeVacationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditEmployeeVacationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editEmployeeVacationMutation, { data, loading, error }] = useEditEmployeeVacationMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      startYear: // value for 'startYear'
 *      vacationMinutes: // value for 'vacationMinutes'
 *      remainingVacationMinutes: // value for 'remainingVacationMinutes'
 *      transferVacationMinutes: // value for 'transferVacationMinutes'
 *      automaticTransfer: // value for 'automaticTransfer'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useEditEmployeeVacationMutation(baseOptions?: Apollo.MutationHookOptions<EditEmployeeVacationMutation, EditEmployeeVacationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditEmployeeVacationMutation, EditEmployeeVacationMutationVariables>(EditEmployeeVacationDocument, options);
      }
export type EditEmployeeVacationMutationHookResult = ReturnType<typeof useEditEmployeeVacationMutation>;
export type EditEmployeeVacationMutationResult = Apollo.MutationResult<EditEmployeeVacationMutation>;
export type EditEmployeeVacationMutationOptions = Apollo.BaseMutationOptions<EditEmployeeVacationMutation, EditEmployeeVacationMutationVariables>;