import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EditEmployeeMutationVariables = Types.Exact<{
  employeeId: Types.Scalars['Int'];
  name: Types.Scalars['String'];
  surname: Types.Scalars['String'];
  email: Types.Scalars['String'];
  contractType: Types.ContractType;
  workPlaceId?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type EditEmployeeMutation = { __typename?: 'Mutation', admin: { __typename?: 'adminMutations', editEmployee: any } };


export const EditEmployeeDocument = gql`
    mutation EditEmployee($employeeId: Int!, $name: String!, $surname: String!, $email: String!, $contractType: ContractType!, $workPlaceId: Int) {
  admin {
    editEmployee(
      employeeId: $employeeId
      name: $name
      surname: $surname
      email: $email
      contractType: $contractType
      workPlaceId: $workPlaceId
    )
  }
}
    `;
export type EditEmployeeMutationFn = Apollo.MutationFunction<EditEmployeeMutation, EditEmployeeMutationVariables>;

/**
 * __useEditEmployeeMutation__
 *
 * To run a mutation, you first call `useEditEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editEmployeeMutation, { data, loading, error }] = useEditEmployeeMutation({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      name: // value for 'name'
 *      surname: // value for 'surname'
 *      email: // value for 'email'
 *      contractType: // value for 'contractType'
 *      workPlaceId: // value for 'workPlaceId'
 *   },
 * });
 */
export function useEditEmployeeMutation(baseOptions?: Apollo.MutationHookOptions<EditEmployeeMutation, EditEmployeeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditEmployeeMutation, EditEmployeeMutationVariables>(EditEmployeeDocument, options);
      }
export type EditEmployeeMutationHookResult = ReturnType<typeof useEditEmployeeMutation>;
export type EditEmployeeMutationResult = Apollo.MutationResult<EditEmployeeMutation>;
export type EditEmployeeMutationOptions = Apollo.BaseMutationOptions<EditEmployeeMutation, EditEmployeeMutationVariables>;