import {ApolloError} from '@apollo/client';
import {useEffect, useState} from 'react';
import {useGetCompanySettingsQuery} from "../pages/CompanySettings/getCompanySettings.generated";
import {CompanySettingFragment} from "../fragments/companySetting.generated";


export interface CompanySettingHook {
    readonly settings?: CompanySettingFragment;

    readonly loading: boolean;

    readonly error?: ApolloError;
}

export const useCompanySettings = (): CompanySettingHook => {
    const {data, error, loading} = useGetCompanySettingsQuery({fetchPolicy: 'cache-first'});

    const [settings, setSettings] = useState<CompanySettingHook>({loading: true});

    useEffect(() => {
        if (data && !error) {
            setSettings({settings: data.company.getCompanySettings, error, loading});
        } else {
            setSettings({error, loading});
        }
    }, [data, error, loading]);

    return settings;
};
