import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IsSetPasswordTokenValidQueryVariables = Types.Exact<{
  token: Types.Scalars['String'];
}>;


export type IsSetPasswordTokenValidQuery = { __typename?: 'Query', token: { __typename?: 'tokenQueries', isEmployeeSetPasswordTokenValid: boolean } };


export const IsSetPasswordTokenValidDocument = gql`
    query IsSetPasswordTokenValid($token: String!) {
  token {
    isEmployeeSetPasswordTokenValid(token: $token)
  }
}
    `;

/**
 * __useIsSetPasswordTokenValidQuery__
 *
 * To run a query within a React component, call `useIsSetPasswordTokenValidQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsSetPasswordTokenValidQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsSetPasswordTokenValidQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useIsSetPasswordTokenValidQuery(baseOptions: Apollo.QueryHookOptions<IsSetPasswordTokenValidQuery, IsSetPasswordTokenValidQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsSetPasswordTokenValidQuery, IsSetPasswordTokenValidQueryVariables>(IsSetPasswordTokenValidDocument, options);
      }
export function useIsSetPasswordTokenValidLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsSetPasswordTokenValidQuery, IsSetPasswordTokenValidQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsSetPasswordTokenValidQuery, IsSetPasswordTokenValidQueryVariables>(IsSetPasswordTokenValidDocument, options);
        }
export type IsSetPasswordTokenValidQueryHookResult = ReturnType<typeof useIsSetPasswordTokenValidQuery>;
export type IsSetPasswordTokenValidLazyQueryHookResult = ReturnType<typeof useIsSetPasswordTokenValidLazyQuery>;
export type IsSetPasswordTokenValidQueryResult = Apollo.QueryResult<IsSetPasswordTokenValidQuery, IsSetPasswordTokenValidQueryVariables>;