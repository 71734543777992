import React from 'react';
import {ContentHeader} from '../../components';
import {subDays} from 'rsuite/cjs/utils/dateUtils';
import {CheckTreePicker, DateRangePicker} from 'rsuite';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {DateTime} from 'luxon';
import {useListEmployeesQuery} from '../employees/listEmployees.generated';
import {contractTypeToString, isNotNull} from '../../utils/display';
import {CreateReportMutationVariables, useCreateReportMutation} from './create-report.generated';
import {toast} from 'react-toastify';
import {Box, Card, CardContent, CircularProgress, FormHelperText, Grid, MenuItem, Select} from "@mui/material";
import {ReportType} from "../../generated/graphql";
import {LoadingButton} from "@mui/lab";

interface TreeRow {
    readonly label: string;

    readonly value: any;

    readonly children?: TreeRow[];
}

/**
 * Define groupBy method to group an array by a key.
 * @param list The array/list to be grouped.
 * @param keyGetter Method to define the key in the object.
 */
const groupBy = <K, V>(
    list: Array<V>,
    keyGetter: (input: V) => K
): Map<K, Array<V>> => {
    const map = new Map<K, Array<V>>();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
};

const CreateReport = () => {
        const {data: employeeData} = useListEmployeesQuery({fetchPolicy: 'no-cache'});

        const employees = employeeData?.employee.listEmployees;

        const [createReportMutation, {loading}] = useCreateReportMutation();

        const defaultDateRange: [Date, Date] = [
            DateTime.now().minus({day: 30}).toJSDate(),
            DateTime.now().toJSDate()
        ];

        const {values, handleSubmit, handleChange, handleBlur, errors, touched, setFieldValue} = useFormik({
            initialValues: {
                dateRange: defaultDateRange,
                employeeIds: [],
                reportType: 'HoursOverview',
                reportFormat: 'pdf'
            },
            validationSchema: Yup.object({
                reportType: Yup.string().required('Bitte gib den Typ für diesen Report an.'),
                employeeIds: Yup.array().min(
                    1,
                    'Bitte wähle mindestens einen Mitarbeiter für diesen Report.'
                ),
                dateRange: Yup.array()
                    .min(2, 'Min 2')
                    .max(2, 'Max 2')
                    .required('Required')
            }),
            onSubmit: (values) => {
                createReport();
            }
        });

        /**
         * Returns the variables from the specified values.
         */
        const variablesFromValues = (
            reportType: string,
            employeeIds: never[],
            dateRange: [Date, Date]
        ): CreateReportMutationVariables => {
            const selectedContractTypes = employeeIds.filter(
                (el: string | number) => typeof el === 'string'
            );
            const selectedUserIds: number[] = employeeIds.filter(
                (el: string | number) => typeof el === 'number'
            );

            const userIdsByContractType = selectedContractTypes.flatMap(
                (contractType) =>
                    employees
                        ?.filter((employee) => employee.contractType === contractType)
                        .map((employee) => employee.userId)
            );

            const userIds: number[] = [
                ...selectedUserIds,
                ...userIdsByContractType
            ].filter(isNotNull);

            const startDate = DateTime.fromJSDate(dateRange[0]);
            const endDate = DateTime.fromJSDate(dateRange[1]);

            return {
                reportType: values.reportType as ReportType,
                reportFormat: 'pdf',
                employeeIds: userIds,
                startDate: startDate.toFormat('yyyy-MM-dd'),
                endDate: endDate.toFormat('yyyy-MM-dd')
            };
        };

        if (!employees) {
            return <CircularProgress/>;
        }

        const treePickerRows: TreeRow[] = Array.from(
            groupBy(employees, (el) => el.contractType)
        ).map(([contractType, rows]) => {
            return {
                label: contractTypeToString(contractType),
                value: contractType,
                children: rows.map((employee) => {
                    return {
                        label: `${employee.name} ${employee.surname}`,
                        value: employee.userId
                    };
                })
            };
        });

        const createReport = async () => {
            const {data, errors} = await createReportMutation({
                variables: variablesFromValues(
                    values.reportType,
                    values.employeeIds,
                    values.dateRange
                )
            });

            if (!errors && data) {
                const reportId = data.reporting.createReport;
                const url = (process.env.REACT_APP_REPORT_URL ?? '') + `/${reportId}`;
                window.open(url, '_blank')?.focus();
            } else {
                toast.error('Beim Speichern des Reports ist ein Fehler aufgetreten');
            }
        };

        return (
            <div>
                <ContentHeader title="Report erstellen"/>
                <section className="content">
                    <Card>
                        <CardContent>
                            <Box component='form' onSubmit={handleSubmit}>
                                <Grid container spacing={2}>
                                    <Grid item md={6} xs={12}>
                                        <Select
                                            id='reportType'
                                            name='reportType'
                                            labelId="report-type-label"
                                            size='small'
                                            placeholder='Report-Typ'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.reportType}
                                            error={!!(errors.reportType && touched.reportType)}
                                            fullWidth
                                            displayEmpty
                                        >
                                            <MenuItem value='HoursOverview'>Stundenübersicht</MenuItem>
                                            <MenuItem value='BookingDetailsByEmployee'>Buchungen nach Tagen</MenuItem>
                                            <MenuItem value='AllBookings'>Auszug aller Buchungen</MenuItem>
                                            <MenuItem value='AbsenceOverview'>Übersicht über Abwesenheiten aller Mitarbeiter</MenuItem>
                                        </Select>
                                        {errors.reportType && (
                                            <FormHelperText error={!!(errors.reportType && touched.reportType)}>
                                                {errors.reportType}
                                            </FormHelperText>
                                        )}
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <DateRangePicker
                                            block
                                            id="dateRange"
                                            name="dateRange"
                                            format="dd.MM.yyyy"
                                            character=" - "
                                            ranges={[
                                                {
                                                    label: 'Letzte 7 Tage',
                                                    value: [subDays(new Date(), 6), new Date()]
                                                },
                                                {
                                                    label: 'Letzte 30 Tage',
                                                    value: [subDays(new Date(), 29), new Date()]
                                                },
                                                {
                                                    label: 'Aktueller Monat',
                                                    value: [DateTime.now().startOf('month').toJSDate(), DateTime.now().endOf('month').toJSDate()]
                                                }
                                            ]}
                                            onChange={(value, event) => {
                                                setFieldValue('dateRange', value);
                                            }}
                                            defaultValue={values.dateRange}
                                            size='lg'
                                        />
                                        {errors.dateRange && (
                                            <FormHelperText error={!!(errors.dateRange && touched.dateRange)}>
                                                Bitte gib einen gültigen Zeitraum an
                                            </FormHelperText>
                                        )}
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <CheckTreePicker
                                            block
                                            defaultExpandAll
                                            data={treePickerRows}
                                            placeholder="Mitarbeiter auswählen"
                                            defaultValue={values.employeeIds}
                                            onChange={(value, event) => {
                                                setFieldValue('employeeIds', value);
                                            }}
                                            size='lg'
                                        />
                                        {errors.employeeIds && (
                                            <FormHelperText error={!!(errors.employeeIds && touched.employeeIds)}>
                                                {errors.employeeIds}
                                            </FormHelperText>
                                        )}
                                    </Grid>
                                </Grid>
                                <Box sx={{display: 'flex', justifyContent: 'end', gap: 2}}>
                                    <LoadingButton loading={loading} variant="contained" type="submit">Erstellen</LoadingButton>
                                </Box>
                            </Box>
                        </CardContent>
                    </Card>
                </section>
            </div>
        )
            ;
    }
;

export default CreateReport;
