import * as Types from '../generated/graphql';

import { WorkingTimeFragment } from './workingTime.generated';
import { gql } from '@apollo/client';
import { WorkingTimeFragmentDoc } from './workingTime.generated';
export type TimeScheduleFragment = { __typename?: 'TimeSchedule', timeScheduleId: number, startDate: any, endDate?: any | null, monday: (
    { __typename?: 'WorkingTime' }
    & WorkingTimeFragment
  ), tuesday: (
    { __typename?: 'WorkingTime' }
    & WorkingTimeFragment
  ), wednesday: (
    { __typename?: 'WorkingTime' }
    & WorkingTimeFragment
  ), thursday: (
    { __typename?: 'WorkingTime' }
    & WorkingTimeFragment
  ), friday: (
    { __typename?: 'WorkingTime' }
    & WorkingTimeFragment
  ), saturday: (
    { __typename?: 'WorkingTime' }
    & WorkingTimeFragment
  ), sunday: (
    { __typename?: 'WorkingTime' }
    & WorkingTimeFragment
  ) };

export const TimeScheduleFragmentDoc = gql`
    fragment TimeSchedule on TimeSchedule {
  timeScheduleId
  startDate
  endDate
  monday {
    ...WorkingTime
  }
  tuesday {
    ...WorkingTime
  }
  wednesday {
    ...WorkingTime
  }
  thursday {
    ...WorkingTime
  }
  friday {
    ...WorkingTime
  }
  saturday {
    ...WorkingTime
  }
  sunday {
    ...WorkingTime
  }
}
    ${WorkingTimeFragmentDoc}`;