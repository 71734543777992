import React, {useState} from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {useCreateCompanyMutation} from './createCompany.generated';
import registerBackground from '../../images/register_background.png';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {Backdrop, Box, Button, FormHelperText, Grid, Stack, TextField, Typography} from "@mui/material";
import {EmployeeInformation} from '../../components/molecules/EmployeeInformation';
import CheckIcon from '@mui/icons-material/Check';
import {toast} from "react-toastify";
import {loginUser, stateFromToken} from "../../store/reducers/auth";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {LoadingButton} from "@mui/lab";
import WarningIcon from "@mui/icons-material/Warning";
import {useCreateFirstStepUserMutation} from "./createFirstStepUser.generated";
import ReactGA from "react-ga4";

const Register = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [createCompanyMutation, {loading}] = useCreateCompanyMutation();
    const [createFirstStepUser] = useCreateFirstStepUserMutation();

    const [backendError, setBackendError] = useState<string>();

    const register = async (
        email: string,
        name: string,
        surname: string,
        companyName: string,
        phoneNumber: string | undefined
    ) => {
        const {data, errors} = await createCompanyMutation({
            variables: {
                personEmail: email,
                personName: name,
                personSurname: surname,
                companyName: companyName,
                phoneNumber: phoneNumber
            }
        });

        if (errors) {
            const error = errors[0];
            if (
                error.extensions['reason'] !== undefined &&
                error.extensions['reason'] !== null
            ) {
                const reason: string = error.extensions['reason'] as unknown as string;

                if (reason === 'EmailAlreadyExists') {
                    setBackendError('Es existiert bereits ein Account mit dieser E-Mail');
                } else {
                    setBackendError('Ein unerwarteter Fehler ist aufgetreten');
                }
            } else {
                setBackendError('Ein unerwarteter Fehler ist aufgetreten');
            }
        } else if (data) {
            const accessToken = data.admin.createCompany.accessToken;
            localStorage.setItem('refreshToken', data.admin.createCompany.refreshToken);

            toast.success('Du hast dich erfolgreich registriert!');

            dispatch(loginUser(accessToken));

            const authState = stateFromToken(accessToken);
            if (authState.isLoggedIn) {
                ReactGA.event('register-success', {
                    companyName: authState.currentUser?.companyName ?? 'unknown'
                });
            }

            navigate('/');
        }
    };

    const {handleChange, values, handleSubmit, handleBlur, touched, errors, validateForm} = useFormik({
        initialValues: {
            email: '',
            name: '',
            surname: '',
            companyName: '',
            phoneNumber: ''
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .email('Gib eine gültig E-Mail Adresse an.')
                .required('E-Mail Adresse darf nicht leer sein'),
            name: Yup.string().required('Vorname darf nicht leer sein'),
            companyName: Yup.string().required(
                'Gib den Namen des Unternehmens an, welches du registrieren möchtest.'
            ),
            surname: Yup.string().required('Nachname darf nicht leer sein')
        }),
        onSubmit: (values) => {
            ReactGA.gtag('set', 'user_properties', {
                companyName: values.companyName
            });

            register(values.email, values.name, values.surname, values.companyName, values.phoneNumber);
        }
    });

    const [stepIndex, setStepIndex] = useState(0);

    const handleSubmitWorkaround = (event: any) => {
        handleSubmit(event.preventDefault());
    };

    const handleStep1Click = () => {
        validateForm().then((errors) => {
            if (!errors.email) {
                ReactGA.gtag('set', 'user_properties', {
                    email: values.email
                });

                setStepIndex(1);

                createFirstStepUser({
                    variables: {
                        personEmail: values.email
                    }
                });
            }
        });
    };

    return <Box sx={{
        width: '100%',
        height: 'auto',
        minHeight: '100vh',
        backgroundImage: `url(${registerBackground})`,
        backgroundPosition: '0 0',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
    }}>
        <Backdrop
            sx={{zIndex: (theme) => theme.zIndex.drawer + 1}}
            open={true}
            onClick={() => {
            }}
        >
            <Box sx={{
                backgroundColor: '#fff',
                paddingX: 2,
                paddingY: 2,
                width: {
                    lg: stepIndex === 0 ? '30%' : '60%',
                    md: '50%',
                    xs: '90%'
                }
            }}>
                {stepIndex === 0 && (
                    <>
                        <Box sx={{paddingX: 4}}>
                            <Stack spacing={2} justifyContent='center'>
                                <Typography textAlign='center' variant='h5'>Jetzt kostenlos testen</Typography>
                                <Typography textAlign='center'>MyTimeTracker jetzt 14 Tage kostenlos testen</Typography>
                                <EmployeeInformation label='E-Mail'>
                                    <TextField
                                        id="email"
                                        name='email'
                                        placeholder="E-Mail Adresse"
                                        variant='outlined'
                                        fullWidth
                                        value={values.email}
                                        error={!!errors.email}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {errors.email && (
                                        <FormHelperText error={!!errors.email}>
                                            {errors.email}
                                        </FormHelperText>
                                    )}
                                </EmployeeInformation>
                                <Button onClick={handleStep1Click} variant='contained' color='primary'>Absenden</Button>
                            </Stack>
                            <Stack sx={{mt: 3}} spacing={1}>
                                <AdvantageListItem text='Keine Angabe von Zahlungsinformationen'/>
                                <AdvantageListItem text='Keine versteckten Kosten'/>
                                <AdvantageListItem text='Direkt startklar'/>
                            </Stack>
                            <Stack spacing={1} sx={{my: 3}} direction='row' alignItems='center' justifyContent='center'>
                                <Typography>Bereits registriert?</Typography>
                                <Button onClick={() => navigate('/login')}><ArrowForwardIcon/>Login</Button>
                            </Stack>
                        </Box>
                        <Typography variant='caption' fontSize='10px'>Durch Absenden des Formulars bestätige ich, die <a
                            target='_blank' rel='noreferrer'
                            href='https://www.mytimetracker.de/privacy/'>Datenschutzerklärung</a> gelesen zu haben und
                            erkläre mich mit der Verarbeitung der personenbezogenen Daten zum genannten Zweck
                            einverstanden. Im Falle der Einwilligung kann ich meine Zustimmung hierzu jederzeit
                            widerrufen. Zudem erkläre ich mich mit den <a target='_blank' rel='noreferrer'
                                                                          href='https://www.mytimetracker.de/agb/'>Allgemeinen
                                Geschäftsbedingungen</a> einverstanden.</Typography>
                    </>
                )}
                {stepIndex === 1 && (
                    <>
                        <Box sx={{paddingX: 4}}>
                            <Typography textAlign='center' variant='h5'>Bitte vervollständige deine
                                Informationen</Typography>
                            <Box component='form' sx={{mt: 2}} onSubmit={handleSubmitWorkaround} id="step1-form">
                                <Grid container spacing={2}>
                                    <EmployeeInformation label='Vorname'>
                                        <TextField
                                            id="name"
                                            name='name'
                                            placeholder="Vorname"
                                            variant='outlined'
                                            fullWidth
                                            value={values.name}
                                            error={!!(errors.name && touched.name)}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        {(errors.name && touched.name) && (
                                            <FormHelperText error={!!(errors.name && touched.name)}>
                                                {errors.name}
                                            </FormHelperText>
                                        )}
                                    </EmployeeInformation>
                                    <EmployeeInformation label='Nachname'>
                                        <TextField
                                            id="surname"
                                            name='surname'
                                            placeholder="Nachname"
                                            variant='outlined'
                                            fullWidth
                                            value={values.surname}
                                            error={!!(errors.surname && touched.surname)}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        {(errors.surname && touched.surname) && (
                                            <FormHelperText error={!!(errors.surname && touched.surname)}>
                                                {errors.surname}
                                            </FormHelperText>
                                        )}
                                    </EmployeeInformation>
                                    <EmployeeInformation label='Unternehmen'>
                                        <TextField
                                            id="companyName"
                                            name='companyName'
                                            placeholder="Unternehmen"
                                            variant='outlined'
                                            fullWidth
                                            value={values.companyName}
                                            error={!!(errors.companyName && touched.companyName)}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        {(errors.companyName && touched.companyName) && (
                                            <FormHelperText error={!!(errors.companyName && touched.companyName)}>
                                                {errors.companyName}
                                            </FormHelperText>
                                        )}
                                    </EmployeeInformation>
                                    <EmployeeInformation label='Telefonnummer (optional)'>
                                        <TextField
                                            id="phoneNumber"
                                            name='phoneNumber'
                                            placeholder="Telefonnummer"
                                            variant='outlined'
                                            fullWidth
                                            value={values.phoneNumber}
                                            error={!!(errors.phoneNumber && touched.phoneNumber)}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                    </EmployeeInformation>
                                </Grid>
                                {backendError &&
                                    <Grid item xs={12} sx={{display: 'flex', gap: 1, paddingLeft: 2, mt: 2}}>
                                        <WarningIcon color='error'/>
                                        <Typography color='error'>{backendError}</Typography>
                                    </Grid>
                                }
                                <Box sx={{display: 'flex', justifyContent: 'end', mt: 2}}>
                                    <LoadingButton loading={loading} type='submit' variant='contained'
                                                   color='primary'>Absenden</LoadingButton>
                                </Box>
                            </Box>
                        </Box>
                    </>
                )}
            </Box>
        </Backdrop>
    </Box>;
};

export default Register;

const AdvantageListItem = ({text}: { text: string }) => {
    return <Stack direction="row" spacing={2}>
        <Box sx={{
            backgroundColor: '#6ED675',
            width: '25px',
            height: '25px',
            color: '#fff',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <CheckIcon fontSize='small'/>
        </Box>
        <Typography>{text}</Typography>
    </Stack>;
};
