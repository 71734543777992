import {Box, Button, Stack, Typography} from "@mui/material";
import tabletTerminalImage from "../../../../images/tablet_terminal.png";
import React from "react";
import {useRequestTerminalAccessMutation} from "./requestTerminalAccess.generated";
import {toast} from "react-toastify";

export const TerminalPage = () => {
    const [requestTerminalAccessMutation] = useRequestTerminalAccessMutation();

    const requestTerminalAccess = async () => {
        const {data, errors} = await requestTerminalAccessMutation();

        if (data && !errors) {
            toast.success('Deine Anfrage wurde erfolgreich übermittelt. Der Support wird sich in Kürze bei dir melden.');
        } else {
            toast.error('Ein Fehler ist aufgetreten. Bitte versuche es später erneut.');
        }

    }

    return <Stack alignItems='center' justifyContent='center' sx={{my: 2}}>
        <Box component='img' src={tabletTerminalImage} sx={{width: '30%'}}/>
        <Typography sx={{mt: 3, width: '80%', textAlign: 'center'}}>
            Mit der Terminal-App kann ein gewöhnliches Android Tablet oder Apple iPad zum Zeiterfassungs-Terminal
            aufgerüstet werden. Um das Terminal zu buchen oder einfach mal zu testen, klicke den folgenden Button. Der
            Support wird sich dann melden.
        </Typography>
        <Button variant="contained" color="primary" sx={{mt: 4}} onClick={requestTerminalAccess}>
            Terminal-App testen
        </Button>
    </Stack>;
};
