import * as Types from '../generated/graphql';

import { WorkPlaceFragment } from './workPlace.generated';
import { gql } from '@apollo/client';
import { WorkPlaceFragmentDoc } from './workPlace.generated';
export type DetailedEmployeeFragment = { __typename?: 'Employee', name: string, surname: string, email: string, contractType: Types.ContractType, weeklyHours: number, accountStatus: Types.AccountStatus, userId: number, departmentRole: Types.DepartmentRole, hasTerminalAccess: boolean, workPlaceId: number, workPlace?: (
    { __typename?: 'WorkPlace' }
    & WorkPlaceFragment
  ) | null };

export const DetailedEmployeeFragmentDoc = gql`
    fragment DetailedEmployee on Employee {
  name
  surname
  email
  contractType
  weeklyHours
  accountStatus
  userId
  departmentRole
  hasTerminalAccess
  workPlaceId
  workPlace {
    ...WorkPlace
  }
}
    ${WorkPlaceFragmentDoc}`;