import * as Types from '../../generated/graphql';

import { EmployeeFragment } from '../../fragments/employee.generated';
import { WorkingTimeFragment } from '../../fragments/workingTime.generated';
import { gql } from '@apollo/client';
import { EmployeeFragmentDoc } from '../../fragments/employee.generated';
import { WorkingTimeFragmentDoc } from '../../fragments/workingTime.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CalendarEventsQueryVariables = Types.Exact<{
  startDate: Types.Scalars['DateType'];
  endDate: Types.Scalars['DateType'];
}>;


export type CalendarEventsQuery = { __typename?: 'Query', calendar: { __typename?: 'calendarQueries', companyEvents: Array<(
      { __typename?: 'AbsenceCalendarEvent' }
      & CalendarEvent_AbsenceCalendarEvent_Fragment
    ) | (
      { __typename?: 'NoWorkingDayCalendarEvent' }
      & CalendarEvent_NoWorkingDayCalendarEvent_Fragment
    ) | (
      { __typename?: 'WorkingTimeCalendarEvent' }
      & CalendarEvent_WorkingTimeCalendarEvent_Fragment
    )> }, employee: { __typename?: 'employeeQueries', listEmployees: Array<(
      { __typename?: 'Employee' }
      & EmployeeFragment
    )> } };

export type CalendarEvent_AbsenceCalendarEvent_Fragment = { __typename: 'AbsenceCalendarEvent', absenceType?: Types.AbsenceType | null, absenceRequestId?: number | null, absenceRequestStatus?: Types.AbsenceRequestStatus | null, userId: number, startDate: any, endDate: any };

export type CalendarEvent_NoWorkingDayCalendarEvent_Fragment = { __typename: 'NoWorkingDayCalendarEvent', userId: number, startDate: any, endDate: any };

export type CalendarEvent_WorkingTimeCalendarEvent_Fragment = { __typename: 'WorkingTimeCalendarEvent', trackingNotes: Array<Types.TrackingNote>, userId: number, startDate: any, endDate: any, workingTime?: (
    { __typename?: 'WorkingTime' }
    & WorkingTimeFragment
  ) | null };

export type CalendarEventFragment = CalendarEvent_AbsenceCalendarEvent_Fragment | CalendarEvent_NoWorkingDayCalendarEvent_Fragment | CalendarEvent_WorkingTimeCalendarEvent_Fragment;

export const CalendarEventFragmentDoc = gql`
    fragment CalendarEvent on CalendarEventInterface {
  __typename
  userId
  startDate
  endDate
  ... on AbsenceCalendarEvent {
    absenceType
    absenceRequestId
    absenceRequestStatus
  }
  ... on WorkingTimeCalendarEvent {
    workingTime {
      ...WorkingTime
    }
    trackingNotes
  }
}
    ${WorkingTimeFragmentDoc}`;
export const CalendarEventsDocument = gql`
    query CalendarEvents($startDate: DateType!, $endDate: DateType!) {
  calendar {
    companyEvents(startDate: $startDate, endDate: $endDate) {
      ...CalendarEvent
    }
  }
  employee {
    listEmployees {
      ...Employee
    }
  }
}
    ${CalendarEventFragmentDoc}
${EmployeeFragmentDoc}`;

/**
 * __useCalendarEventsQuery__
 *
 * To run a query within a React component, call `useCalendarEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarEventsQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useCalendarEventsQuery(baseOptions: Apollo.QueryHookOptions<CalendarEventsQuery, CalendarEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CalendarEventsQuery, CalendarEventsQueryVariables>(CalendarEventsDocument, options);
      }
export function useCalendarEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CalendarEventsQuery, CalendarEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CalendarEventsQuery, CalendarEventsQueryVariables>(CalendarEventsDocument, options);
        }
export type CalendarEventsQueryHookResult = ReturnType<typeof useCalendarEventsQuery>;
export type CalendarEventsLazyQueryHookResult = ReturnType<typeof useCalendarEventsLazyQuery>;
export type CalendarEventsQueryResult = Apollo.QueryResult<CalendarEventsQuery, CalendarEventsQueryVariables>;