import * as Types from '../../../../generated/graphql';

import { InheritableSettingsFragment } from '../../../../fragments/inheritableSettings.generated';
import { gql } from '@apollo/client';
import { InheritableSettingsFragmentDoc } from '../../../../fragments/inheritableSettings.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CompanySettingsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CompanySettingsQuery = { __typename?: 'Query', setting: { __typename?: 'settingQueries', settingsForCompany: { __typename?: 'CompanySettings', inheritableSettings: (
        { __typename?: 'InheritableSettings' }
        & InheritableSettingsFragment
      ) } } };


export const CompanySettingsDocument = gql`
    query CompanySettings {
  setting {
    settingsForCompany {
      inheritableSettings {
        ...InheritableSettings
      }
    }
  }
}
    ${InheritableSettingsFragmentDoc}`;

/**
 * __useCompanySettingsQuery__
 *
 * To run a query within a React component, call `useCompanySettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanySettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanySettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompanySettingsQuery(baseOptions?: Apollo.QueryHookOptions<CompanySettingsQuery, CompanySettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanySettingsQuery, CompanySettingsQueryVariables>(CompanySettingsDocument, options);
      }
export function useCompanySettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanySettingsQuery, CompanySettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanySettingsQuery, CompanySettingsQueryVariables>(CompanySettingsDocument, options);
        }
export type CompanySettingsQueryHookResult = ReturnType<typeof useCompanySettingsQuery>;
export type CompanySettingsLazyQueryHookResult = ReturnType<typeof useCompanySettingsLazyQuery>;
export type CompanySettingsQueryResult = Apollo.QueryResult<CompanySettingsQuery, CompanySettingsQueryVariables>;