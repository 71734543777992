import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {CompanySettingFragment} from "../../fragments/companySetting.generated";

export interface CompanySettingState {
    settings: CompanySettingFragment | undefined;
}

const initialState = (): CompanySettingState => ({
    settings: undefined
})

export const companySettingsSlice = createSlice({
    name: 'companySetting',
    initialState: initialState,
    reducers: {
        updateSettings: (state, action: PayloadAction<CompanySettingFragment | undefined>) => {
            return {settings: action.payload};
        }
    }
});

export const {updateSettings} = companySettingsSlice.actions;

export default companySettingsSlice.reducer;
