import {
    Box,
    Button,
    Collapse,
    FormHelperText,
    Grid,
    ListItemButton,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import {DateTime} from "luxon";
import React from "react";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import {TimeField} from '@mui/x-date-pickers/TimeField';
import {AdapterLuxon} from "@mui/x-date-pickers/AdapterLuxon";
import DeleteIcon from '@mui/icons-material/Delete';
import WarningIcon from '@mui/icons-material/Warning';
import {LocalizationProvider} from "@mui/x-date-pickers";
import {CompanySettingFragment} from "../../../fragments/companySetting.generated";
import {
    FieldHelperProps,
    FieldInputProps,
    FieldMetaProps,
    FormikErrors,
    FormikState,
    FormikTouched
} from "formik/dist/types";
import {EmployeeInformation} from "../../molecules/EmployeeInformation";
import NightlightIcon from '@mui/icons-material/Nightlight';
import LightbulbIcon from '@mui/icons-material/Lightbulb';

export interface FormikEditRow {
    uuid: string;
    collapsed: boolean;
    startDate: DateTime;
    endDate: DateTime | undefined;
    comment: string | null | undefined;
    workPlace: string | null | undefined;
    isStampedIn: boolean;
}

export interface WorkingTimeFormikValues {
    date?: DateTime;
    trackingRows: FormikEditRow[];
    validationErrors: string[];
}

interface FormikResponse {
    initialValues: WorkingTimeFormikValues;
    initialErrors: FormikErrors<unknown>;
    initialTouched: FormikTouched<unknown>;
    initialStatus: any;
    handleBlur: {
        (e: React.FocusEvent<any>): void;
        <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
    };
    handleChange: {
        (e: React.ChangeEvent<any>): void;
        <T_1 = string | React.ChangeEvent<any>>(field: T_1): T_1 extends React.ChangeEvent<any> ? void : (e: string | React.ChangeEvent<any>) => void;
    };
    handleReset: (e: any) => void;
    handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
    resetForm: (nextState?: Partial<FormikState<WorkingTimeFormikValues>> | undefined) => void;
    setErrors: (errors: FormikErrors<WorkingTimeFormikValues>) => void;
    setFormikState: (stateOrCb: FormikState<WorkingTimeFormikValues> | ((state: FormikState<WorkingTimeFormikValues>) => FormikState<WorkingTimeFormikValues>)) => void;
    setFieldTouched: (field: string, touched?: boolean, shouldValidate?: boolean | undefined) => Promise<FormikErrors<WorkingTimeFormikValues>> | Promise<void>;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => Promise<FormikErrors<WorkingTimeFormikValues>> | Promise<void>;
    setFieldError: (field: string, value: string | undefined) => void;
    setStatus: (status: any) => void;
    setSubmitting: (isSubmitting: boolean) => void;
    setTouched: (touched: FormikTouched<WorkingTimeFormikValues>, shouldValidate?: boolean | undefined) => Promise<FormikErrors<WorkingTimeFormikValues>> | Promise<void>;
    setValues: (values: React.SetStateAction<WorkingTimeFormikValues>, shouldValidate?: boolean | undefined) => Promise<FormikErrors<WorkingTimeFormikValues>> | Promise<void>;
    submitForm: () => Promise<any>;
    validateForm: (values?: WorkingTimeFormikValues) => Promise<FormikErrors<WorkingTimeFormikValues>>;
    validateField: (name: string) => Promise<void> | Promise<string | undefined>;
    isValid: boolean;
    dirty: boolean;
    unregisterField: (name: string) => void;
    registerField: (name: string, {validate}: any) => void;
    getFieldProps: (nameOrOptions: any) => FieldInputProps<any>;
    getFieldMeta: (name: string) => FieldMetaProps<any>;
    getFieldHelpers: (name: string) => FieldHelperProps<any>;
    validateOnBlur: boolean;
    validateOnChange: boolean;
    validateOnMount: boolean;
    values: WorkingTimeFormikValues;
    errors: FormikErrors<WorkingTimeFormikValues>;
    touched: FormikTouched<WorkingTimeFormikValues>;
    isSubmitting: boolean;
    isValidating: boolean;
    status?: any;
    submitCount: number;
}

interface TrackingRowBlockProps {
    /**
     * startDate of the first tracking row, so we can check if this row is on the next day.
     */
    startDate?: DateTime;
    trackingRow: FormikEditRow;
    companySettings: CompanySettingFragment;
    index: number;
    isEditing: boolean;
    onDelete?: () => void;
    canBeDeleted?: boolean;
    formik: FormikResponse;
    canStampOverNight: boolean;
}

export const TrackingRowBlock = ({
                                     startDate: bookingStartDate,
                                     trackingRow,
                                     companySettings,
                                     index,
                                     isEditing,
                                     onDelete,
                                     formik,
                                     canBeDeleted,
                                     canStampOverNight
                                 }: TrackingRowBlockProps) => {
    const startDate = trackingRow.startDate;
    const endDate = trackingRow.endDate;

    const isOnNextDay = bookingStartDate?.day !== startDate?.day;
    const endsOnNextDay = endDate && startDate.day !== endDate?.day;

    const diffMinutes = (endDate?.isValid ? endDate : startDate).diff(startDate, ['hours', 'minutes']);

    const {handleChange, handleBlur, touched, errors: formikErrors, setFieldValue} = formik;

    const errors = formikErrors.trackingRows as FormikErrors<{
        comment?: string;
        workPlace?: string;
        startDate?: string;
        endDate?: string;
    }>[];

    return <>
        <LocalizationProvider dateAdapter={AdapterLuxon}>
            <Grid sx={{marginBottom: '1rem', marginTop: '1rem', backgroundColor: '#F2F2F2', borderRadius: '10px'}}>
                <ListItemButton onClick={() => setFieldValue(`trackingRows.${index}.collapsed`, !trackingRow.collapsed)}
                                sx={{paddingLeft: 0, paddingRight: 0}}>
                    <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%', paddingX: 2}}>
                        <Stack direction='row' alignItems='center' spacing={2}>
                            {trackingRow.endDate && errors?.[index] &&
                                <WarningIcon color='error'/>
                            }
                            <Typography>Block {index + 1}</Typography>
                            <p>Zeit: {diffMinutes.hours}h {diffMinutes.minutes.toFixed(0)}min</p>
                        </Stack>
                        <Box>
                            {trackingRow.collapsed ? <ExpandLess/> : <ExpandMore/>}
                        </Box>
                    </Box>
                </ListItemButton>
                <Collapse in={trackingRow.collapsed} timeout="auto" unmountOnExit sx={{paddingBottom: 2, paddingX: 1}}>
                    {(endsOnNextDay || isOnNextDay) &&
                        <Box sx={{display: 'flex', paddingLeft: 1, marginY: 0.5}}>
                            <Stack flexDirection='row'
                                   sx={{backgroundColor: '#3B3C40', padding: 0.5, color: '#fff', borderRadius: '5px'}}>
                                <NightlightIcon sx={{fontSize: 20}}/>
                                {endsOnNextDay &&
                                    <Typography fontSize='small'>Diese Buchung endet am nächsten Tag</Typography>
                                }
                                {isOnNextDay &&
                                    <Typography fontSize='small'>Nächster Tag ({startDate.toFormat('dd.MM.yyyy')})</Typography>
                                }
                            </Stack>
                        </Box>
                    }
                    <Grid container sx={{paddingLeft: 1, paddingRight: 1, paddingTop: 1}} spacing={1}>
                        <EmployeeInformation label='Beginn' sm={6} xs={12}>
                            {(isEditing && !trackingRow.isStampedIn) ? (
                                <>
                                    <TimeField
                                        disabled={trackingRow.isStampedIn}
                                        value={trackingRow.startDate}
                                        onChange={(value: DateTime | null) => {
                                            if (value?.isValid) {
                                                const time = value.set({second: 0, millisecond: 0});
                                                setFieldValue(`trackingRows.${index}.startDate`, time)
                                            }
                                        }}
                                        name={`trackingRows.${index}.startDate`}
                                        format='HH:mm'
                                        size='small'
                                        fullWidth
                                        slotProps={{
                                            textField: {
                                                fullWidth: true,
                                                variant: 'outlined',
                                                size: 'small',
                                                name: `trackingRows.${index}.startDate`,
                                                id: `trackingRows.${index}.startDate`,
                                                sx: {backgroundColor: '#fff'},
                                                error: !!errors?.[index]?.startDate
                                            }
                                        }}
                                    />
                                    {errors?.[index]?.startDate && (
                                        <FormHelperText
                                            error={!!errors?.[index]?.startDate}>
                                            {errors?.[index]?.startDate}
                                        </FormHelperText>
                                    )}
                                </>
                            ) : (<p>{startDate.toFormat('HH:mm')}</p>)}
                        </EmployeeInformation>
                        <EmployeeInformation label='Ende' sm={6} xs={12}>
                            {(isEditing && !trackingRow.isStampedIn) ? (
                                <>
                                    <TimeField
                                        disabled={trackingRow.isStampedIn}
                                        value={trackingRow.endDate}
                                        onChange={(value: DateTime | null) => {
                                            const endDate = value?.set({
                                                year: startDate.year,
                                                month: startDate.month,
                                                day: startDate.day,
                                                millisecond: 0,
                                                second: 0
                                            });

                                            if (endDate?.isValid) {
                                                setFieldValue(`trackingRows.${index}.endDate`, endDate)
                                            }
                                        }}
                                        name={`trackingRows.${index}.endDate`}
                                        format='HH:mm'
                                        size='small'
                                        fullWidth
                                        slotProps={{
                                            textField: {
                                                fullWidth: true,
                                                variant: 'outlined',
                                                size: 'small',
                                                name: `trackingRows.${index}.endDate`,
                                                id: `trackingRows.${index}.endDate`,
                                                sx: {backgroundColor: '#fff'},
                                                error: !!errors?.[index]?.endDate
                                            }
                                        }}
                                    />
                                    {errors?.[index]?.endDate && (
                                        <FormHelperText
                                            error={!!errors?.[index]?.endDate}>
                                            {errors?.[index]?.endDate}
                                        </FormHelperText>
                                    )}
                                </>
                            ) : (<p>{endDate?.isValid ? endDate.toFormat('HH:mm') : 'Aktuell eingestempelt'}</p>)}
                        </EmployeeInformation>
                        {(canStampOverNight && isEditing && errors?.[index]?.endDate && errors?.[index]?.startDate) &&
                            <Stack flexDirection='row' justifyContent='space-between' sx={{backgroundColor: '#fff', width: '100%', marginLeft: 4, marginRight: 3, marginY: 2, borderRadius: '10px', padding: 2}}>
                                <Stack flexDirection='row' gap={1.5}>
                                    <Box sx={{backgroundColor: '#6ED675', height: '28px', width: '28px', borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                        <LightbulbIcon fontSize='small' sx={{color: '#fff'}}/>
                                    </Box>
                                    <Typography>Geht diese Buchung über den aktuellen Tag hinaus in den nächsten Tag?</Typography>
                                </Stack>
                                <Button variant='contained' color='success' sx={{color: '#fff'}} onClick={() => {
                                    const newEndDate = endDate?.set({
                                        day: endDate?.day + 1
                                    });

                                    if (endDate?.isValid) {
                                        setFieldValue(`trackingRows.${index}.endDate`, newEndDate);
                                    }
                                }}>JA</Button>
                            </Stack>
                        }
                        {companySettings.workingPlaceEnabled &&
                            <EmployeeInformation label='Ort' xs={12} md={12}>
                                {(isEditing && !trackingRow.isStampedIn) ? (
                                    <>
                                        <Select
                                            disabled={trackingRow.isStampedIn}
                                            id='workPlace'
                                            name={`trackingRows.${index}.workPlace`}
                                            labelId="work-place-label"
                                            size='small'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={trackingRow.workPlace ?? ''}
                                            // error={!!(errors?.[index]?.workPlace && touched.trackingRows?.[index]?.workPlace)}
                                            fullWidth
                                            sx={{backgroundColor: '#fff'}}
                                        >
                                            {companySettings.workingPlaces.map((workingPlace, index) => {
                                                return <MenuItem key={index}
                                                                 value={workingPlace}>{workingPlace}</MenuItem>;
                                            })}
                                        </Select>
                                        {errors?.[index]?.workPlace && (
                                            <FormHelperText
                                                error={!!(errors?.[index]?.workPlace && touched.trackingRows?.[index]?.workPlace)}>
                                                {errors?.[index]?.workPlace}
                                            </FormHelperText>
                                        )}
                                    </>
                                ) : (<p>{trackingRow.workPlace}</p>)}
                            </EmployeeInformation>
                        }
                        {companySettings.commentsEnabled &&
                            <EmployeeInformation label='Kommentar' xs={12} md={12}>
                                {(isEditing && !trackingRow.isStampedIn) ? (
                                    <>
                                        <TextField
                                            disabled={trackingRow.isStampedIn}
                                            id="comment"
                                            name={`trackingRows.${index}.comment`}
                                            multiline
                                            rows={2}
                                            placeholder="Gib einen Kommentar an"
                                            value={trackingRow.comment ?? ''}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={!!(errors?.[index]?.comment && touched.trackingRows?.[index]?.comment)}
                                            variant='outlined'
                                            fullWidth
                                            sx={{backgroundColor: '#fff'}}
                                        />
                                        {errors?.[index]?.comment && (
                                            <FormHelperText
                                                error={!!(errors?.[index]?.comment && touched.trackingRows?.[index]?.comment)}>
                                                {errors?.[index]?.comment}
                                            </FormHelperText>
                                        )}
                                    </>
                                ) : (<p>{trackingRow.comment}</p>)
                                }
                            </EmployeeInformation>
                        }
                        {isEditing && (canBeDeleted ?? true) &&
                            <Grid item xs={12} sx={{display: 'flex', justifyContent: 'end'}}>
                                <Button key='deleteBlock' type='button' onClick={onDelete} color='error'
                                        startIcon={<DeleteIcon/>}>Block löschen</Button>
                            </Grid>}
                    </Grid>
                </Collapse>
            </Grid>
        </LocalizationProvider>
    </>;
};
