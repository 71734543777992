import {Tooltip} from "@mui/material";
import React from "react";
import MuiRestoreIcon from '@mui/icons-material/Restore';

export interface WarningIconProps {
    readonly tooltip?: string;
    readonly color?: 'primary' | 'warning' | 'error';
}

export const RestoreIcon = ({tooltip, color}: WarningIconProps) => {
    const propsColor = color ?? 'primary';

    let htmlColor;

    switch(propsColor) {
        case "primary":
            htmlColor = '#0854CF';
            break;
        case "warning":
            htmlColor = '#FAC20A'
            break;
        case "error":
            htmlColor = '#EF3A3E'

    }

    if (tooltip) {
        return <Tooltip title={tooltip}>
            <MuiRestoreIcon sx={{color: htmlColor, fontSize: 25}}/>
        </Tooltip>
    } else {
        return <MuiRestoreIcon sx={{color: htmlColor, fontSize: 25}}/>;
    }
}
