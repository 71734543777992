import {Subscription, User} from "../store/reducers/auth";
import {DateTime} from "luxon";

export const isSubscriptionTrialActive = (user?: User) => {
    switch (user?.subscription.subscriptionCategory) {
        case 'trial':
            return remainingTrialDays(user.subscription) > 0;
        default:
            return false;
    }
};

export const TRIAL_DAYS = 14;

export const remainingTrialDays = (subscription?: Subscription): number => {

    switch(subscription?.subscriptionCategory) {
        case "trial":
            const diffDays = DateTime.now().diff(subscription.startDate, 'days').toObject().days ?? 0;
            return TRIAL_DAYS - Math.round(diffDays);
        default:
            return 0;
    }
}
