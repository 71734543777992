import {EntityInformation, SettingUpdateResponse} from "../../types";
import {Checkbox, FormControlLabel} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useUpdateOverNightStampingEnabledMutation} from "./updateOverNightStampingEnabled.generated";

export interface OverNightStampingSettingProps {
    entity: EntityInformation;
    setting?: boolean;
    onUpdate?: (response: Promise<SettingUpdateResponse>) => void;
}

export const OverNightStampingSetting = ({
                                             entity,
                                             setting,
                                             onUpdate
                                         }: OverNightStampingSettingProps) => {
    const [isEnabled, setEnabled] = useState(setting ?? false);

    const [updateOverNightStampingSettingMutation] = useUpdateOverNightStampingEnabledMutation();

    useEffect(() => {
        setEnabled(setting ?? false);
    }, [setting]);

    const handleChange = () => {
        const oldValue = isEnabled;
        setEnabled(!isEnabled);

        const responsePromise: Promise<SettingUpdateResponse> = updateOverNightStampingSettingMutation({
            variables: {
                settingEntityType: entity.entityType,
                entityId: entity.entityId,
                isEnabled: !oldValue
            }
        }).then(({data, errors}) => ({
            updatedSetting: data?.setting.trackingSetting.updateOverNightStampingEnabled, errors: errors
        }));

        if (onUpdate) {
            onUpdate(responsePromise);
        }
    }

    return <FormControlLabel control={<Checkbox
        checked={isEnabled}
        onChange={handleChange}
    />} label="Über Nacht stempeln aktiviert"/>;
}
