import * as Types from '../../generated/graphql';

import { EmployeeFragment } from '../../fragments/employee.generated';
import { gql } from '@apollo/client';
import { EmployeeFragmentDoc } from '../../fragments/employee.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ListEmployeesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ListEmployeesQuery = { __typename?: 'Query', employee: { __typename?: 'employeeQueries', listEmployees: Array<(
      { __typename?: 'Employee' }
      & EmployeeFragment
    )> } };


export const ListEmployeesDocument = gql`
    query ListEmployees {
  employee {
    listEmployees {
      ...Employee
    }
  }
}
    ${EmployeeFragmentDoc}`;

/**
 * __useListEmployeesQuery__
 *
 * To run a query within a React component, call `useListEmployeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListEmployeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListEmployeesQuery({
 *   variables: {
 *   },
 * });
 */
export function useListEmployeesQuery(baseOptions?: Apollo.QueryHookOptions<ListEmployeesQuery, ListEmployeesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListEmployeesQuery, ListEmployeesQueryVariables>(ListEmployeesDocument, options);
      }
export function useListEmployeesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListEmployeesQuery, ListEmployeesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListEmployeesQuery, ListEmployeesQueryVariables>(ListEmployeesDocument, options);
        }
export type ListEmployeesQueryHookResult = ReturnType<typeof useListEmployeesQuery>;
export type ListEmployeesLazyQueryHookResult = ReturnType<typeof useListEmployeesLazyQuery>;
export type ListEmployeesQueryResult = Apollo.QueryResult<ListEmployeesQuery, ListEmployeesQueryVariables>;