import * as Types from '../../../generated/graphql';

import { CompanySettingFragment } from '../../../fragments/companySetting.generated';
import { gql } from '@apollo/client';
import { CompanySettingFragmentDoc } from '../../../fragments/companySetting.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ImitateUserMutationVariables = Types.Exact<{
  userId: Types.Scalars['Int'];
}>;


export type ImitateUserMutation = { __typename?: 'Mutation', imitation: { __typename?: 'imitationMutations', imitateUser: { __typename?: 'LoginInformation', accessToken: string, companySetting: (
        { __typename?: 'CompanySetting' }
        & CompanySettingFragment
      ) } } };


export const ImitateUserDocument = gql`
    mutation ImitateUser($userId: Int!) {
  imitation {
    imitateUser(userId: $userId) {
      accessToken
      companySetting {
        ...CompanySetting
      }
    }
  }
}
    ${CompanySettingFragmentDoc}`;
export type ImitateUserMutationFn = Apollo.MutationFunction<ImitateUserMutation, ImitateUserMutationVariables>;

/**
 * __useImitateUserMutation__
 *
 * To run a mutation, you first call `useImitateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImitateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [imitateUserMutation, { data, loading, error }] = useImitateUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useImitateUserMutation(baseOptions?: Apollo.MutationHookOptions<ImitateUserMutation, ImitateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImitateUserMutation, ImitateUserMutationVariables>(ImitateUserDocument, options);
      }
export type ImitateUserMutationHookResult = ReturnType<typeof useImitateUserMutation>;
export type ImitateUserMutationResult = Apollo.MutationResult<ImitateUserMutation>;
export type ImitateUserMutationOptions = Apollo.BaseMutationOptions<ImitateUserMutation, ImitateUserMutationVariables>;