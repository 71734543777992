import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type StartTrackingMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type StartTrackingMutation = { __typename?: 'Mutation', tracking: { __typename?: 'trackingMutations', startTracking: any } };


export const StartTrackingDocument = gql`
    mutation StartTracking {
  tracking {
    startTracking
  }
}
    `;
export type StartTrackingMutationFn = Apollo.MutationFunction<StartTrackingMutation, StartTrackingMutationVariables>;

/**
 * __useStartTrackingMutation__
 *
 * To run a mutation, you first call `useStartTrackingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartTrackingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startTrackingMutation, { data, loading, error }] = useStartTrackingMutation({
 *   variables: {
 *   },
 * });
 */
export function useStartTrackingMutation(baseOptions?: Apollo.MutationHookOptions<StartTrackingMutation, StartTrackingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartTrackingMutation, StartTrackingMutationVariables>(StartTrackingDocument, options);
      }
export type StartTrackingMutationHookResult = ReturnType<typeof useStartTrackingMutation>;
export type StartTrackingMutationResult = Apollo.MutationResult<StartTrackingMutation>;
export type StartTrackingMutationOptions = Apollo.BaseMutationOptions<StartTrackingMutation, StartTrackingMutationVariables>;