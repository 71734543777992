import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import React from "react";
import {toast} from "react-toastify";
import {LoadingButton} from "@mui/lab";
import {useDeleteDepartmentMutation} from "./deleteDepartment.generated";

export interface DeleteDepartmentDialogProps {
    readonly open: boolean;
    readonly onClose: (deleted: boolean) => void;
    readonly departmentId: number;
    readonly departmentName: string;
}

const DeleteDepartmentDialog = ({open, onClose, departmentId, departmentName}: DeleteDepartmentDialogProps) => {
    const [deleteDepartmentMutation, {loading}] = useDeleteDepartmentMutation();

    const deleteDay = async () => {
        const {data, errors} = await deleteDepartmentMutation({
            variables: {
                departmentId: departmentId
            }
        });

        if (data?.department.deleteDepartment === null && !errors) {
            toast.success(`Abteilung ${departmentName} erfolgreich gelöscht`);
            if (onClose) {
                onClose(true);
            }
        } else {
            const error = errors?.[0];
            if (error?.extensions['reason'] !== undefined && error.extensions['reason'] !== null) {
                const reason: string = error.extensions['reason'] as unknown as string;

                if (reason === 'AuthenticationRequired') {
                    toast.error('Versuche dich neu anzumelden, um die Abteilung zu löschen');
                } else if (reason === 'PermissionDenied') {
                    toast.error('Du hast nicht die notwendigen Berechtigungen');
                } else if (reason === 'DepartmentNotFound') {
                    toast.error('Die Abteilung wurde nicht gefunden');
                } else {
                    toast.error('Ein unerwarteter Fehler ist aufgetreten');
                }
            } else {
                toast.error('Ein unerwarteter Fehler ist aufgetreten');
            }
        }
    };

    return <>
        <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            onClose={() => onClose(false)}
        >
            <DialogTitle id="alert-dialog-title">
                Bist du dir sicher?
            </DialogTitle>
            <DialogContent>
                Möchtest du die Abteilung {departmentName} wirklich löschen?
            </DialogContent>
            <DialogActions sx={{gap: 2, mb: 2, mr: 2}}>
                <Button variant='contained' color='inherit' onClick={() => onClose(false)}>Abbrechen</Button>
                <LoadingButton loading={loading} variant='contained' color='error'
                               onClick={() => deleteDay()}>Löschen</LoadingButton>
            </DialogActions>
        </Dialog></>;
}

export default DeleteDepartmentDialog;
