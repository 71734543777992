import {User} from "../store/reducers/auth";
import {StripeSubscription} from "../generated/graphql";

export const hasAtLeastSubscription = (user: User | undefined, stripeSubscription: StripeSubscription): boolean => {
    if(!user) {
        return false;
    }

    switch (stripeSubscription) {
        case "expert":
            return user.stripeSubscription === 'expert';
        case "professional":
            return user.stripeSubscription === 'professional' || user.stripeSubscription === 'expert';
        case 'standard':
            return user.stripeSubscription === 'standard' || user.stripeSubscription === 'professional' || user.stripeSubscription === 'expert';
    }
}
