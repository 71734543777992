import * as Types from '../../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RequestTerminalAccessMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type RequestTerminalAccessMutation = { __typename?: 'Mutation', company: { __typename?: 'companyMutations', requestTerminalAccess: any } };


export const RequestTerminalAccessDocument = gql`
    mutation RequestTerminalAccess {
  company {
    requestTerminalAccess
  }
}
    `;
export type RequestTerminalAccessMutationFn = Apollo.MutationFunction<RequestTerminalAccessMutation, RequestTerminalAccessMutationVariables>;

/**
 * __useRequestTerminalAccessMutation__
 *
 * To run a mutation, you first call `useRequestTerminalAccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestTerminalAccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestTerminalAccessMutation, { data, loading, error }] = useRequestTerminalAccessMutation({
 *   variables: {
 *   },
 * });
 */
export function useRequestTerminalAccessMutation(baseOptions?: Apollo.MutationHookOptions<RequestTerminalAccessMutation, RequestTerminalAccessMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestTerminalAccessMutation, RequestTerminalAccessMutationVariables>(RequestTerminalAccessDocument, options);
      }
export type RequestTerminalAccessMutationHookResult = ReturnType<typeof useRequestTerminalAccessMutation>;
export type RequestTerminalAccessMutationResult = Apollo.MutationResult<RequestTerminalAccessMutation>;
export type RequestTerminalAccessMutationOptions = Apollo.BaseMutationOptions<RequestTerminalAccessMutation, RequestTerminalAccessMutationVariables>;