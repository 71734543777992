import {useStripe} from "@stripe/react-stripe-js";
import React, {useState} from "react";
import {PaymentIntent} from "@stripe/stripe-js/types/api/payment-intents";
import {Box, CircularProgress, Typography} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import {useCreateAccessTokenMutation} from "../../../createAccessToken.generated";
import { LoadingButton } from "@mui/lab";
import {loginUser} from "../../../store/reducers/auth";
import {toast} from "react-toastify";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import WarningIcon from '@mui/icons-material/Warning';
import Cookies from "js-cookie";

const PaymentResult = ({clientSecret}: { clientSecret: string }) => {
    const stripe = useStripe();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [createAccessTokenMutation, {loading}] = useCreateAccessTokenMutation();

    const [response, setResponse] = useState<PaymentIntent.Status | undefined>(undefined);

    if (stripe === null) {
        return <>Loading Stripe...</>;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({paymentIntent}) => {
        // Inspect the PaymentIntent `status` to indicate the status of the payment
        // to your customer.
        //
        // Some payment methods will [immediately succeed or fail][0] upon
        // confirmation, while others will first enter a `processing` state.
        //
        // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
        setResponse(paymentIntent?.status);
    });

    const loadDashboard = async () => {
        const {data, errors} = await createAccessTokenMutation({
            variables: {
                refreshToken: localStorage.getItem('refreshToken')!
            }
        });

        if (!errors && data) {
            const accessToken = data.employee.createAccessToken.accessToken;
            dispatch(loginUser(accessToken));

            const information = data.employee.createAccessToken;

            localStorage.setItem('token', information.accessToken);
            localStorage.setItem('refreshToken', information.refreshToken);
            Cookies.set('token', information.accessToken, { expires: 1 });

            navigate('/dashboard');
        } else {
            toast.error('Ein unbekannter Fehler ist aufgetreten');
        }
    };

    switch (response) {
        case 'succeeded':
        case 'processing':
            return <Box
                sx={{mt: 4, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                <Box sx={{
                    width: '60px',
                    height: '60px',
                    backgroundColor: '#6ED675',
                    borderRadius: '50%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: '#fff'
                }}>
                    <CheckIcon sx={{fontSize: '40px'}}/>
                </Box>
                <Typography sx={{mt: 2}} variant='h5'>Willkommen bei MyTimeTracker</Typography>
                <LoadingButton loading={loading} variant='contained' color='primary' onClick={loadDashboard} sx={{mt: 4}}>Zum Dashboard</LoadingButton>
            </Box>;
        case undefined:
            return <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <CircularProgress />
            </Box>;
        default:
            return <Box
                sx={{mt: 4, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                <Box sx={{
                    width: '60px',
                    height: '60px',
                    backgroundColor: '#EA474A',
                    borderRadius: '50%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: '#fff'
                }}>
                    <WarningIcon sx={{fontSize: '40px'}}/>
                </Box>
                <Typography sx={{mt: 2}} variant='h5'>Etwas ist schiefgelaufen</Typography>
                <Typography sx={{mt: 1}}>Versuche es später erneut oder wende dich an unseren Support</Typography>
                <LoadingButton loading={loading} variant='contained' color='primary' onClick={loadDashboard} sx={{mt: 4}}>Zum Dashboard</LoadingButton>
            </Box>;
    }
}

export default PaymentResult;
