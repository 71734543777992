import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InsertManuallyDynamicMutationVariables = Types.Exact<{
  employeeId?: Types.InputMaybe<Types.Scalars['Int']>;
  date: Types.Scalars['DateType'];
  trackingRows: Array<Types.NewTrackingRow> | Types.NewTrackingRow;
}>;


export type InsertManuallyDynamicMutation = { __typename?: 'Mutation', tracking: { __typename?: 'trackingMutations', insertManuallyDynamic: any } };


export const InsertManuallyDynamicDocument = gql`
    mutation InsertManuallyDynamic($employeeId: Int, $date: DateType!, $trackingRows: [NewTrackingRow!]!) {
  tracking {
    insertManuallyDynamic(
      employeeId: $employeeId
      date: $date
      trackingRows: $trackingRows
    )
  }
}
    `;
export type InsertManuallyDynamicMutationFn = Apollo.MutationFunction<InsertManuallyDynamicMutation, InsertManuallyDynamicMutationVariables>;

/**
 * __useInsertManuallyDynamicMutation__
 *
 * To run a mutation, you first call `useInsertManuallyDynamicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertManuallyDynamicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertManuallyDynamicMutation, { data, loading, error }] = useInsertManuallyDynamicMutation({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      date: // value for 'date'
 *      trackingRows: // value for 'trackingRows'
 *   },
 * });
 */
export function useInsertManuallyDynamicMutation(baseOptions?: Apollo.MutationHookOptions<InsertManuallyDynamicMutation, InsertManuallyDynamicMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertManuallyDynamicMutation, InsertManuallyDynamicMutationVariables>(InsertManuallyDynamicDocument, options);
      }
export type InsertManuallyDynamicMutationHookResult = ReturnType<typeof useInsertManuallyDynamicMutation>;
export type InsertManuallyDynamicMutationResult = Apollo.MutationResult<InsertManuallyDynamicMutation>;
export type InsertManuallyDynamicMutationOptions = Apollo.BaseMutationOptions<InsertManuallyDynamicMutation, InsertManuallyDynamicMutationVariables>;