import {DateTime} from "luxon";
import {Grid, IconButton, Link, Menu, MenuItem, Stack, SxProps, Theme, Typography} from "@mui/material";
import {WarningIcon} from "../atoms/WarningIcon";
import SaldoTimeText from "./SaldoTimeText";
import WorkingTimeText from "./WorkingTimeText";
import React, {useState} from "react";
import {WorkingTimeByDayFragment} from "../../fragments/workingTimeByDay.generated";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteTrackingDialog from "../organisms/DeleteTrackingDialog/DeleteTrackingDialog";
import {dateToWeekdayString, trackingNotesToText} from "../../utils/display";
import useTrackingDetailDialog from "../../dynamic-dialog/useTrackingDetailDialog";
import {RestoreIcon} from "../atoms/RestoreIcon";
import {useTranslation} from "react-i18next";

const gridItemStyle: SxProps<Theme> = (theme) => ({
    paddingTop: '0 !important',
    alignItems: 'center'
});

const linkNoDecorationStyle: SxProps<Theme> = {
    textDecoration: 'none',
    color: '#333333',
    ':hover': {
        textDecoration: 'none',
        color: '#333333',
    },
    ':focus': {
        textDecoration: 'none',
        color: '#333333',
    }
};

export interface WorkingTimeByDayRowProps {
    readonly workingTimeByDay: WorkingTimeByDayFragment;
    readonly employeeId: number;
    readonly onChange?: () => void;
    readonly columns?: WorkingTimeByDayRowColumn[];
}

export type WorkingTimeByDayRowColumn = 'date' | 'startTime' | 'endTime' | 'balance' | 'break' | 'workingTime';

const WorkingTimeByDayRow = ({workingTimeByDay, employeeId, onChange, columns}: WorkingTimeByDayRowProps) => {
    const {t} = useTranslation();

    const startTime = workingTimeByDay.rows.length > 0 ? DateTime.fromISO(workingTimeByDay.rows[0].start).toFormat('HH:mm') : undefined;
    const endDate = workingTimeByDay.rows.length > 0 ? workingTimeByDay.rows[workingTimeByDay.rows.length - 1].end : undefined;
    const endTime = endDate ? DateTime.fromISO(endDate).toFormat('HH:mm') : undefined;

    const hasError = workingTimeByDay.trackingNotes.length > 0;

    const errors = trackingNotesToText(workingTimeByDay.trackingNotes);

    const date = DateTime.fromISO(workingTimeByDay.date);
    const dateString = date.toFormat('dd.MM.yyyy');
    const weekDayString = dateToWeekdayString(date);

    const tooltipMessage = errors.map(error => error.message).join('\n');

    let icon;
    let errorColor: 'error' | 'warning' | 'primary';
    if (errors.some(error => error.color === 'error')) {
        errorColor = 'error';
        icon = <WarningIcon tooltip={tooltipMessage} color='error'/>;
    } else if (errors.some(error => error.color === 'warning')) {
        errorColor = 'warning';
        icon = <RestoreIcon tooltip={tooltipMessage} color='warning'/>;
    } else {
        errorColor = 'primary';
        icon = <WarningIcon tooltip={tooltipMessage} color='primary'/>;
    }

    const {showTrackingDetailDialog} = useTrackingDetailDialog();

    const displayColumn = (column: WorkingTimeByDayRowColumn) => {
        return columns?.indexOf(column) !== -1;
    };

    const hideMobileDisplay = (column: WorkingTimeByDayRowColumn) => {
        return displayColumn(column) ? {xs: "none", md: "flex"} : 'none';
    };

    const isCompactRow = (columns?.length ?? 6) <= 4;

    return <>
        <Grid container spacing={1} sx={{
            backgroundColor: '#F8FAFE',
            marginBottom: '1rem',
            minHeight: '50px',
            alignItems: 'center',
            borderRadius: '10px',
            marginLeft: 0,
            width: '100%',
            ':hover': {
                backgroundColor: '#E5EDFA'
            }
        }}>
            <Grid item xs={11} md={isCompactRow ? 11 : 11.5} sx={{paddingTop: '0 !important'}}>
                <Link href='#' sx={linkNoDecorationStyle}
                      onClick={() => showTrackingDetailDialog({userId: employeeId, date: date, onEditSave: onChange})}>
                    <Grid container>
                        <Grid item sm={1} md={isCompactRow ? 1 : 0.5} display={{xs: 'none', sm: 'flex'}}
                              sx={{...gridItemStyle, justifyContent: 'center', alignItems: 'center'}}>
                            {hasError &&
                                <>{icon}</>
                            }
                        </Grid>
                        <Grid item xs sx={{...gridItemStyle, paddingY: 0.5}}>
                            <Stack>
                                <Typography fontSize={20}>{t(weekDayString)}</Typography>
                                <Typography fontSize={12}>{dateString}</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs display={hideMobileDisplay('startTime')} sx={gridItemStyle}>{startTime}</Grid>
                        <Grid item xs display={hideMobileDisplay('endTime')}
                              sx={gridItemStyle}>{workingTimeByDay.rows.length === 0 || endTime ? endTime : 'Eingestempelt'}</Grid>
                        <Grid item xs display='flex' sx={gridItemStyle}>
                            {workingTimeByDay.saldo &&
                                <SaldoTimeText saldo={workingTimeByDay.saldo}/>
                            }
                        </Grid>
                        <Grid item xs display={hideMobileDisplay('break')} sx={gridItemStyle}>
                            {workingTimeByDay.rows.length > 0 &&
                                <WorkingTimeText workingTime={workingTimeByDay.breakingTime} color={errorColor}
                                                 errorMessage={tooltipMessage}/>
                            }
                        </Grid>
                        <Grid item xs display='flex' sx={gridItemStyle}>
                            <WorkingTimeText workingTime={workingTimeByDay.workingTime}/>
                        </Grid>
                    </Grid>
                </Link>
            </Grid>
            <Grid item xs={1} md={isCompactRow ? 1 : 0.5}
                  sx={{...gridItemStyle, display: 'flex', paddingLeft: '0 !important', paddingTop: '0 !important'}}>
                <ContextMenu employeeId={employeeId} date={date} onEdit={() => showTrackingDetailDialog({
                    userId: employeeId,
                    date: date,
                    onEditSave: onChange
                })}
                             onChange={onChange}/>
            </Grid>
        </Grid>
    </>;
};

const ContextMenu = ({
                         onEdit,
                         employeeId,
                         date,
                         onChange
                     }: { date: DateTime, employeeId: number, onEdit: () => void, onChange?: () => void }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [isDeleteDayOpen, setDeleteDayOpen] = useState(false);

    return (
        <>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                sx={{paddingLeft: '2px', paddingRight: '2px', color: '#212529'}}
            >
                <MoreVertIcon/>
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={() => {
                    handleClose();
                    onEdit();
                }
                }>
                    Ansehen
                </MenuItem>
                <MenuItem onClick={() => {
                    handleClose();
                    setDeleteDayOpen(true);
                }
                }>
                    Löschen
                </MenuItem>
            </Menu>
            <DeleteTrackingDialog
                open={isDeleteDayOpen}
                onClose={(deleted) => {
                    setDeleteDayOpen(false);
                    if (deleted && onChange) {
                        onChange();
                    }
                }}
                date={date}
                employeeId={employeeId}
            />
        </>
    );
};

export default WorkingTimeByDayRow;
