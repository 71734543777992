import {matchPath, PathMatch, useLocation} from "react-router-dom";

export const useRouteMatch = (patterns: readonly string[]): PathMatch | null => {
    const {pathname} = useLocation();

    for (let i = 0; i < patterns.length; i += 1) {
        const pattern = patterns[i];
        const possibleMatch = matchPath(pattern, pathname);
        if (possibleMatch !== null) {
            return possibleMatch;
        }
    }

    return null;
};
