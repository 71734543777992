import {
    Box,
    Button, Chip, Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton, Menu, MenuItem,
    Skeleton,
    Stack,
    Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {DateTime, Duration} from "luxon";
import {toast} from "react-toastify";
import {EmployeeFragment} from "../../../../fragments/employee.generated";
import {CreateTimeScheduleMutationVariables, useCreateTimeScheduleMutation} from "./createTimeSchedule.generated";
import {useTimeScheduleByUserIdLazyQuery} from "./timeScheduleByUserId.generated";
import {WorkingTimeFragment} from "../../../../fragments/workingTime.generated";
import {numberWithLeadingZero} from "../../../../utils/display";
import {EmployeeTimeScheduleForm, TimeScheduleFormValues} from "./EmployeeTimeScheduleForm";
import {TimeScheduleFragment} from "../../../../fragments/timeSchedule.generated";
import {EditTimeScheduleMutationVariables, useEditTimeScheduleMutation} from "./editTimeSchedule.generated";
import workingHoursClock from "../../../../images/working_hours_clock.png";
import {LoadingButton} from "@mui/lab";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {useDeleteTimeScheduleMutation} from "./deleteTimeSchedule.generated";

const EmployeeTimeSchedule = ({employee}: { employee?: EmployeeFragment }) => {
    const [isCreate, setCreate] = useState(false);
    const [editSchedule, setEditSchedule] = useState<TimeScheduleFragment | undefined>(undefined);
    const [deleteSchedule, setDeleteSchedule] = useState<TimeScheduleFragment | undefined>(undefined);

    const [editMutationArgs, setEditMutationArgs] = useState<EditTimeScheduleMutationVariables | undefined>(undefined);
    const [createMutationArgs, setCreateMutationArgs] = useState<CreateTimeScheduleMutationVariables | undefined>(undefined);

    const [timeScheduleByUserId, {data, loading, refetch}] = useTimeScheduleByUserIdLazyQuery();

    const [createTimeScheduleMutation, {loading: createTimeScheduleLoading}] = useCreateTimeScheduleMutation();
    const [editTimeScheduleMutation, {loading: editTimeScheduleLoading}] = useEditTimeScheduleMutation();
    const [deleteTimeScheduleMutation, {loading: deleteTimeScheduleLoading}] = useDeleteTimeScheduleMutation();

    const timeSchedules = data?.employee.timeSchedule.timeScheduleByUserId;
    const lastSchedule = timeSchedules?.[0];

    const sortedSchedules = timeSchedules?.slice().sort((a, b) => {
        const startDate: DateTime = DateTime.fromISO(a.startDate);
        const endDate: DateTime | undefined = a.endDate ? DateTime.fromISO(a.endDate) : undefined;

        const isActive = endDate === undefined || (startDate.diffNow().toMillis() < 0 && endDate.diffNow().toMillis() > 0);

        return isActive ? -1 : 0;
    });

    const createSubmit = async (values: TimeScheduleFormValues) => {
        if (!employee) {
            return;
        }

        const createArgs = {
            userId: employee?.userId ?? 0,
            startDate: values.startDate.toFormat('yyyy-MM-dd'),
            endDate: values.endDate?.toFormat('yyyy-MM-dd'),
            monday: values.monday,
            tuesday: values.tuesday,
            wednesday: values.wednesday,
            thursday: values.thursday,
            friday: values.friday,
            saturday: values.saturday,
            sunday: values.sunday
        };
        // Show warning if the start date is in the past.
        if (values.startDate.diff(DateTime.now().minus({days: 1})).toMillis() < 0) {
            setCreateMutationArgs(createArgs);
        } else {
            performCreateMutation(createArgs);
        }
    }

    const performCreateMutation = async (variables: CreateTimeScheduleMutationVariables) => {
        const {data, errors} = await createTimeScheduleMutation({
            variables: variables
        });

        if (errors) {
            const error = errors[0];
            const reason = error.extensions?.['reason'];

            if (reason === 'AuthenticationRequired') {
                toast.error('Melde dich erneut an und versuche es nochmal.');
            } else if (reason === 'UserNotFound') {
                toast.error('Der Nutzer konnte nicht gefunden werden');
            } else if (reason === 'PermissionDenied') {
                toast.error('Du darfst für diesen Nutzer keinen Arbeitsplan erstellten');
            } else if (reason === 'OverlappingTimeSchedule') {
                toast.error('Die Zeitpläne eines Mitarbeiters dürfen sich nicht überschneiden');
            } else {
                toast.error('Ein unerwarteter Fehler ist aufgetreten');
            }
        } else if (data) {
            setCreate(false);
            setCreateMutationArgs(undefined);
            refetch();
            toast.success('Neuer Arbeitsplan wurde erfolgreich erstellt');
        } else {
            toast.error('Ein unbekannter Fehler ist aufgetreten');
        }
    };

    const editSubmit = (values: TimeScheduleFormValues) => {
        if (!employee || !editSchedule) {
            return;
        }

        const editArgs = {
            userId: employee?.userId ?? 0,
            timeScheduleId: editSchedule.timeScheduleId,
            startDate: values.startDate.toFormat('yyyy-MM-dd'),
            endDate: values.endDate?.toFormat('yyyy-MM-dd'),
            monday: values.monday,
            tuesday: values.tuesday,
            wednesday: values.wednesday,
            thursday: values.thursday,
            friday: values.friday,
            saturday: values.saturday,
            sunday: values.sunday
        };
        setEditMutationArgs(editArgs);
    }

    const performEditMutation = async (variables: EditTimeScheduleMutationVariables) => {
        const {data, errors} = await editTimeScheduleMutation({
            variables: variables
        });

        if (errors) {
            const error = errors[0];
            const reason = error.extensions?.['reason'];

            if (reason === 'AuthenticationRequired') {
                toast.error('Melde dich erneut an und versuche es nochmal.');
            } else if (reason === 'UserNotFound') {
                toast.error('Der Nutzer konnte nicht gefunden werden');
            } else if (reason === 'PermissionDenied') {
                toast.error('Du darfst für diesen Nutzer keinen Arbeitsplan erstellten');
            } else if (reason === 'OverlappingTimeSchedule') {
                toast.error('Die Zeitpläne eines Mitarbeiters dürfen sich nicht überschneiden');
            } else {
                toast.error('Ein unerwarteter Fehler ist aufgetreten');
            }
        } else if (data) {
            setEditSchedule(undefined);
            setEditMutationArgs(undefined);
            refetch();
            toast.success('Der Arbeitsplan wurde erfolgreich editiert');
        } else {
            toast.error('Ein unbekannter Fehler ist aufgetreten');
        }
    };

    const deleteTimeSchedule = async (timeSchedule: TimeScheduleFragment) => {
        const {data, errors} = await deleteTimeScheduleMutation({
            variables: {
                timeScheduleId: timeSchedule.timeScheduleId
            }
        });

        if (errors) {
            const error = errors[0];
            const reason = error.extensions?.['reason'];

            if (reason === 'AuthenticationRequired') {
                toast.error('Melde dich erneut an und versuche es nochmal.');
            } else if (reason === 'UserNotFound') {
                toast.error('Der Nutzer konnte nicht gefunden werden');
            } else if (reason === 'PermissionDenied') {
                toast.error('Du darfst für diesen Nutzer keinen Arbeitsplan erstellten');
            } else {
                toast.error('Ein unerwarteter Fehler ist aufgetreten');
            }
        } else if (data) {
            setDeleteSchedule(undefined);
            refetch();
            toast.success('Der Arbeitsplan wurde erfolgreich gelöscht');
        } else {
            toast.error('Ein unbekannter Fehler ist aufgetreten');
        }
    };

    useEffect(() => {
        if (employee) {
            timeScheduleByUserId({variables: {userId: employee.userId}});
        }
    }, [employee, timeScheduleByUserId]);

    const title = () => {
        if (isCreate) {
            return 'Zeitplan erstellen';
        } else if (!!editSchedule) {
            return 'Zeitplan editieren';
        } else {
            return 'Arbeitspläne';
        }
    }

    return <>
        <Typography variant='h6'>{title()}</Typography>
        {editMutationArgs &&
            <Dialog
                open={!!editMutationArgs}
                aria-labelledby="edit-confirm-dialog-title"
                aria-describedby="edit-confirm-dialog-description"
                fullWidth
                onClose={() => setEditMutationArgs(undefined)}
            >
                <DialogTitle>Warnung</DialogTitle>
                <DialogContent>
                    Durch das Editieren des Arbeitsplans kann sich das Überstundenkonto der Mitarbeiter ändern.
                    Alle Stundenbuchungen im angegebenen Zeitraum werden neu berechnet.
                </DialogContent>
                <DialogActions sx={{gap: 2, mb: 2, mr: 2}}>
                    <Button variant='contained' color='inherit'
                            onClick={() => setEditMutationArgs(undefined)}>Abbrechen</Button>
                    <LoadingButton loading={editTimeScheduleLoading} variant='contained' color='primary'
                                   onClick={() => performEditMutation(editMutationArgs)}>Speichern</LoadingButton>
                </DialogActions>
            </Dialog>
        }
        {createMutationArgs &&
            <Dialog
                open={!!createMutationArgs}
                aria-labelledby="create-confirm-dialog-title"
                aria-describedby="create-confirm-dialog-description"
                fullWidth
                onClose={() => setCreateMutationArgs(undefined)}
            >
                <DialogTitle>Warnung</DialogTitle>
                <DialogContent>
                    Der Zeitplan beginnt in der Vergangenheit. Alle Stundenbuchungen bis zum Startdatum werden neu
                    berechnet.
                    Dadurch kann sich das Überstundenkonto des Mitarbeiters ändern.
                </DialogContent>
                <DialogActions sx={{gap: 2, mb: 2, mr: 2}}>
                    <Button variant='contained' color='inherit'
                            onClick={() => setCreateMutationArgs(undefined)}>Abbrechen</Button>
                    <LoadingButton loading={createTimeScheduleLoading} variant='contained' color='primary'
                                   onClick={() => performCreateMutation(createMutationArgs)}>Speichern</LoadingButton>
                </DialogActions>
            </Dialog>
        }
        {deleteSchedule &&
            <Dialog
                open={!!deleteSchedule}
                aria-labelledby="delete-dialog-title"
                aria-describedby="delete-dialog-description"
                fullWidth
                onClose={() => setCreateMutationArgs(undefined)}
            >
                <DialogTitle>Arbeitsplan löschen?</DialogTitle>
                <DialogContent>
                    Bist du sicher, dass du diesen Arbeitsplan löschen möchtest?
                    Dadurch werden alle Überstunden im Zeitraum des Arbeitsplans zurückgesetzt.
                </DialogContent>
                <DialogActions sx={{gap: 2, mb: 2, mr: 2}}>
                    <Button variant='contained' color='inherit'
                            onClick={() => setDeleteSchedule(undefined)}>Abbrechen</Button>
                    <LoadingButton loading={deleteTimeScheduleLoading} variant='contained' color='error'
                                   onClick={() => deleteTimeSchedule(deleteSchedule)}>Löschen</LoadingButton>
                </DialogActions>
            </Dialog>
        }
        {(timeSchedules?.length === 0 && !isCreate) &&
            <Box
                sx={{display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: 5}}>
                <Box sx={{maxWidth: '60%', display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <Box component='img' sx={{maxHeight: '180px'}} src={workingHoursClock}/>
                    <Typography sx={{marginTop: 5}}>Hier werden die Soll-Stunden für das Überstundenkonto angegeben.
                        Sollen keine Überstunden erfasst werden oder wird der Mitarbeiter auf Stundenbasis bezahlt,
                        sollte hier nichts angegeben werden.</Typography>
                    <Button variant='contained' sx={{mt: 4}} onClick={() => setCreate(true)}>ARBEITSZEITEN
                        EINRICHTEN</Button>
                </Box>
            </Box>
        }
        {!isCreate && (loading || !employee) && <Stack spacing={-5}>
            <Skeleton height={128}/>
            <Skeleton height={128}/>
        </Stack>
        }
        {(!isCreate && !editSchedule) &&
            <>
                {sortedSchedules?.map((timeSchedule, index) => {
                    const startDate: DateTime = DateTime.fromISO(timeSchedule.startDate);
                    const endDate: DateTime | undefined = timeSchedule.endDate ? DateTime.fromISO(timeSchedule.endDate) : undefined;

                    const isActive = endDate === undefined || (startDate.diffNow().toMillis() < 0 && endDate.diffNow().toMillis() > 0);

                    const displayText = (workingTime: WorkingTimeFragment): string => {
                        return `${numberWithLeadingZero(workingTime.hours)}h ${numberWithLeadingZero(workingTime.minutes)}min`;
                    }

                    const workingMinutes = (workingTime: WorkingTimeFragment): number => {
                        return workingTime.hours * 60 + workingTime.minutes;
                    }

                    const workingTimeMinutes = workingMinutes(timeSchedule.monday) +
                        workingMinutes(timeSchedule.tuesday) +
                        workingMinutes(timeSchedule.wednesday) +
                        workingMinutes(timeSchedule.thursday) +
                        workingMinutes(timeSchedule.friday) +
                        workingMinutes(timeSchedule.saturday) +
                        workingMinutes(timeSchedule.sunday);

                    const workingDuration = Duration.fromMillis(workingTimeMinutes * 60 * 1000).shiftTo('hours', 'minutes').toObject();

                    return <Grid container key={index}
                                 sx={{backgroundColor: '#F8FAFE', borderRadius: '10px', padding: 2, mt: 2}}>
                        <Grid item xs={10}>
                            <Stack flexDirection='row' spacing={2} sx={{display: 'flex', alignItems: 'center', gap: 1, '& .MuiChip-root': {marginTop: 0}}}>
                                <Typography>{endDate ? `Gültig vom ${startDate.toFormat('dd.MM.yyyy')} bis ${endDate.toFormat('dd.MM.yyyy')}` : `Gültig ab ${startDate.toFormat('dd.MM.yyyy')}`}</Typography>
                                {isActive && <Chip sx={{marginTop: 0, padding: '2px', color: '#fff'}} size='small' color='success' label='Aktiv' />}
                            </Stack>
                            <Grid container spacing={2} sx={{mt: 1}}>
                                <Grid item>
                                    <Typography sx={{color: '#82848B'}}>Montag</Typography>
                                    <Typography>{displayText(timeSchedule.monday)}</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography sx={{color: '#82848B'}}>Dienstag</Typography>
                                    <Typography>{displayText(timeSchedule.tuesday)}</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography sx={{color: '#82848B'}}>Mittwoch</Typography>
                                    <Typography>{displayText(timeSchedule.wednesday)}</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography sx={{color: '#82848B'}}>Donnerstag</Typography>
                                    <Typography>{displayText(timeSchedule.thursday)}</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography sx={{color: '#82848B'}}>Freitag</Typography>
                                    <Typography>{displayText(timeSchedule.friday)}</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography sx={{color: '#82848B'}}>Samstag</Typography>
                                    <Typography>{displayText(timeSchedule.saturday)}</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography sx={{color: '#82848B'}}>Sonntag</Typography>
                                    <Typography>{displayText(timeSchedule.sunday)}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={2}>
                            <Box sx={{
                                display: 'flex',
                                height: '100%',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                alignItems: 'end'
                            }}>
                                <EmployeeTimeScheduleContextMenu onEdit={() => setEditSchedule(timeSchedule)}
                                                                 onDelete={() => setDeleteSchedule(timeSchedule)}/>
                                <Typography>{workingDuration.hours} Wochenstunden</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                })}
                {timeSchedules?.length !== 0 &&
                    <Box sx={{mt: 3, display: 'flex', justifyContent: 'end'}}>
                        <Button color='primary' variant='contained' onClick={() => setCreate(true)}>Neuer Plan</Button>
                    </Box>
                }
            </>
        }
        {isCreate &&
            <Box sx={{mt: 2}}>
                <EmployeeTimeScheduleForm timeSchedule={lastSchedule} onCancel={() => setCreate(false)}
                                          loading={createTimeScheduleLoading} onSubmit={createSubmit}/>
            </Box>
        }
        {editSchedule &&
            <Box sx={{mt: 2}}>
                <EmployeeTimeScheduleForm timeSchedule={editSchedule} onCancel={() => setEditSchedule(undefined)}
                                          loading={editTimeScheduleLoading} onSubmit={editSubmit}/>
            </Box>
        }
    </>;
};

interface EmployeeTimeScheduleContextMenuProps {
    onEdit: () => void;
    onDelete: () => void;
}

const EmployeeTimeScheduleContextMenu = ({onEdit, onDelete}: EmployeeTimeScheduleContextMenuProps) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                sx={{paddingLeft: '2px', paddingRight: '2px', color: '#212529'}}
            >
                <MoreVertIcon/>
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={() => {
                    handleClose();
                    onEdit();
                }}>Bearbeiten</MenuItem>
                <MenuItem onClick={() => {
                    handleClose();
                    onDelete();
                }}>Löschen</MenuItem>
            </Menu>
        </>
    );
};

export default EmployeeTimeSchedule;
