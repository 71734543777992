import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormHelperText,
    Grid, MenuItem, Select,
    TextField, Typography
} from "@mui/material";
import React, {useEffect} from "react";
import {useFormik} from "formik";
import {DateTime} from "luxon";
import * as Yup from "yup";
import {EmployeeInformation} from "../../molecules/EmployeeInformation";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import LoadingButton from "@mui/lab/LoadingButton";
import {AdapterLuxon} from "@mui/x-date-pickers/AdapterLuxon";
import SaldoTimeText from "../../molecules/SaldoTimeText";
import {BalanceCompensationType} from "../../../generated/graphql";
import {useCreateBalanceCompensationMutation} from "./createBalanceCompensation.generated";
import {toast} from "react-toastify";
import {WorkingTimeFragment} from "../../../fragments/workingTime.generated";

interface CreateOvertimeCompensationFormValues {
    readonly date: DateTime;
    readonly compensationType: BalanceCompensationType;
    readonly hours: number;
    readonly minutes: number;
}

interface OvertimeCompensationDialogProps {
    totalBalance: WorkingTimeFragment;
    userId: number;
    open: boolean;
    onClose?: (updateDone: boolean) => void;
}

export const OvertimeCompensationDialog = ({userId, totalBalance, open, onClose}: OvertimeCompensationDialogProps) => {
    const [createBalanceCompensationMutation, {loading}] = useCreateBalanceCompensationMutation();

    const close = (updateDone: boolean) => {
        if (onClose) {
            onClose(updateDone);
        }
    };

    const createBalanceCompensation = async (values: CreateOvertimeCompensationFormValues) => {
        const {data, errors} = await createBalanceCompensationMutation({
            variables: {
                userId: userId,
                date: values.date.toISODate(),
                balanceCompensationType: values.compensationType,
                hours: values.hours,
                minutes: values.minutes
            }
        });

        if (data) {
            toast.success('Überstundenausgleich wurde erfolgreich eingetragen');
            close(true);
        } else if (errors) {
            const error = errors[0];
            const reason = error.extensions?.['reason'];

            switch (reason) {
                case "AuthenticationRequired":
                    toast.error('Melde dich erneut an und versuche es nochmal.');
                    return;
                case "PermissionDenied":
                    toast.error('Du hast keine Berechtigung einen Standort zu erstellen');
                    return;
                case "UserNotFound":
                    toast.error('Der Mitarbeiter-Account konnte nicht gefunden werden');
                    return;
                case "AbsenceFoundForDate":
                    toast.error('Für dieses Datum existiert bereits eine Abwesenheit. Bitte wähle ein anderes Datum');
                    return;
                default:
                    toast.error('Ein unbekannter Fehler ist aufgetreten');
            }
        } else {
            toast.error('Ein unerwarteter Fehler ist aufgetreten. Bitte versuche es später erneut.');
        }
    };

    const {
        handleChange,
        values,
        handleSubmit,
        setFieldValue,
        handleBlur,
        touched,
        errors
    } = useFormik<CreateOvertimeCompensationFormValues>({
        initialValues: {
            date: DateTime.now(),
            compensationType: 'AddHours',
            hours: 0,
            minutes: 0
        },
        validationSchema: Yup.object({
            date: Yup.date().required('Gib ein Datum für den Feiertag an'),
            compensationType: Yup.string().required('Gib eine Wiederholungsrate für den Feiertag an'),
            hours: Yup.number().required('Gib die Stunden an').min(0, 'Die Stunden müssen positiv sein'),
            minutes: Yup.number().required('Gib die Minuten an').min(0, 'Die Minuten müssen positiv sein').max(59, 'Die Minuten müssen kleiner als 60 sein')
        }),
        onSubmit: (values) => {
            createBalanceCompensation(values);
        }
    });

    const newBalance = () => {
        const oldMinutes = totalBalance.hours * 60 + totalBalance.minutes;
        const addOrRemoveMinutes = values.compensationType === 'AddHours' ? values.hours * 60 + values.minutes : -values.hours * 60 - values.minutes;

        const newMinutes = oldMinutes + addOrRemoveMinutes;

        return {
            hours: Math.floor(newMinutes / 60),
            minutes: newMinutes % 60
        };
    }

    // Update hours if minutes are greater than 59
    useEffect(() => {
        if (values.minutes > 59) {
            setFieldValue('hours', values.hours + Math.floor(values.minutes / 60));
            setFieldValue('minutes', values.minutes % 60);
        }
    }, [values.minutes, values.hours, setFieldValue]);

    return <LocalizationProvider dateAdapter={AdapterLuxon}>
        <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            onClose={() => close(false)}
        >
            <DialogTitle id="alert-dialog-title">
                Neuer Überstundenausgleich
            </DialogTitle>
            <DialogContent>
                <Box component='form' onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <EmployeeInformation label='Datum' xs={12} md={12}>
                            <DatePicker
                                value={values.date}
                                onChange={(value: DateTime | null) => {
                                    if (value?.isValid) {
                                        setFieldValue('date', value)
                                    }
                                }}
                                slotProps={{
                                    textField: {
                                        size: 'small',
                                        fullWidth: true,
                                        name: 'startDate',
                                        id: 'startDate',
                                        onBlur: handleBlur
                                    }
                                }}
                                format='dd.MM.yyyy'
                            ></DatePicker>
                        </EmployeeInformation>
                        <EmployeeInformation label='Typ' xs={12} md={12}>
                            <Select
                                id='compensationType'
                                name='compensationType'
                                labelId="compensation-type-label"
                                size='small'
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.compensationType}
                                error={!!(errors.compensationType && touched.compensationType)}
                                fullWidth
                                sx={{backgroundColor: '#fff'}}
                            >
                                <MenuItem value='AddHours'>Stunden hinzubuchen (Ausgleich Minusstunden)</MenuItem>
                                <MenuItem value='RemoveHours'>Stunden abziehen (Ausgleich Überstunden)</MenuItem>
                            </Select>
                            {errors.compensationType && (
                                <FormHelperText
                                    error={!!(errors.compensationType && touched.compensationType)}>
                                    {errors.compensationType}
                                </FormHelperText>
                            )}
                        </EmployeeInformation>
                        <EmployeeInformation label='Stunden' xs={12} md={6}>
                            <TextField id="hours"
                                       name='hours'
                                       placeholder="Stunden"
                                       type="number"
                                       value={values.hours}
                                       error={!!(errors.hours && touched.hours)}
                                       onChange={handleChange}
                                       onBlur={handleBlur}
                                       variant='outlined'
                                       fullWidth
                                       size='small'
                            />
                            {errors.hours && (
                                <FormHelperText error={!!(errors.hours && touched.hours)}>
                                    {errors.hours}
                                </FormHelperText>
                            )}
                        </EmployeeInformation>
                        <EmployeeInformation label='Minuten' xs={12} md={6}>
                            <TextField id="minutes"
                                       name='minutes'
                                       placeholder="Minuten"
                                       type="number"
                                       value={values.minutes}
                                       error={!!(errors.minutes && touched.minutes)}
                                       onChange={handleChange}
                                       onBlur={handleBlur}
                                       variant='outlined'
                                       fullWidth
                                       size='small'
                            />
                            {errors.minutes && (
                                <FormHelperText error={!!(errors.minutes && touched.minutes)}>
                                    {errors.minutes}
                                </FormHelperText>
                            )}
                        </EmployeeInformation>
                    </Grid>
                    <Grid container gap={8} sx={{mt: 3}}>
                        <Grid item>
                            <Typography fontWeight='bold'>Aktuelles Saldo: </Typography>
                            <SaldoTimeText saldo={totalBalance}/>
                        </Grid>
                        <Grid item>
                            <Typography fontWeight='bold'>Neues Saldo: </Typography>
                            <SaldoTimeText saldo={newBalance()}/>
                        </Grid>
                    </Grid>
                    <Box sx={{display: 'flex', justifyContent: 'end'}}>
                        <DialogActions sx={{gap: 2, mt: 2}}>
                            <Button variant='contained' color='inherit' type='button'
                                    onClick={() => close(false)}>ABBRECHEN</Button>
                            <LoadingButton loading={loading} variant='contained' type='submit'>SPEICHERN</LoadingButton>
                        </DialogActions>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    </LocalizationProvider>;
};
