import * as Types from '../../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EditTimeScheduleMutationVariables = Types.Exact<{
  userId: Types.Scalars['Int'];
  timeScheduleId: Types.Scalars['Int'];
  startDate: Types.Scalars['DateType'];
  endDate?: Types.InputMaybe<Types.Scalars['DateType']>;
  monday: Types.WorkingTimeInput;
  tuesday: Types.WorkingTimeInput;
  wednesday: Types.WorkingTimeInput;
  thursday: Types.WorkingTimeInput;
  friday: Types.WorkingTimeInput;
  saturday: Types.WorkingTimeInput;
  sunday: Types.WorkingTimeInput;
}>;


export type EditTimeScheduleMutation = { __typename?: 'Mutation', employee: { __typename?: 'employeeMutations', timeSchedule: { __typename?: 'timeScheduleMutations', editTimeSchedule: any } } };


export const EditTimeScheduleDocument = gql`
    mutation EditTimeSchedule($userId: Int!, $timeScheduleId: Int!, $startDate: DateType!, $endDate: DateType, $monday: WorkingTimeInput!, $tuesday: WorkingTimeInput!, $wednesday: WorkingTimeInput!, $thursday: WorkingTimeInput!, $friday: WorkingTimeInput!, $saturday: WorkingTimeInput!, $sunday: WorkingTimeInput!) {
  employee {
    timeSchedule {
      editTimeSchedule(
        userId: $userId
        timeScheduleId: $timeScheduleId
        startDate: $startDate
        endDate: $endDate
        monday: $monday
        tuesday: $tuesday
        wednesday: $wednesday
        thursday: $thursday
        friday: $friday
        saturday: $saturday
        sunday: $sunday
      )
    }
  }
}
    `;
export type EditTimeScheduleMutationFn = Apollo.MutationFunction<EditTimeScheduleMutation, EditTimeScheduleMutationVariables>;

/**
 * __useEditTimeScheduleMutation__
 *
 * To run a mutation, you first call `useEditTimeScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTimeScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTimeScheduleMutation, { data, loading, error }] = useEditTimeScheduleMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      timeScheduleId: // value for 'timeScheduleId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      monday: // value for 'monday'
 *      tuesday: // value for 'tuesday'
 *      wednesday: // value for 'wednesday'
 *      thursday: // value for 'thursday'
 *      friday: // value for 'friday'
 *      saturday: // value for 'saturday'
 *      sunday: // value for 'sunday'
 *   },
 * });
 */
export function useEditTimeScheduleMutation(baseOptions?: Apollo.MutationHookOptions<EditTimeScheduleMutation, EditTimeScheduleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditTimeScheduleMutation, EditTimeScheduleMutationVariables>(EditTimeScheduleDocument, options);
      }
export type EditTimeScheduleMutationHookResult = ReturnType<typeof useEditTimeScheduleMutation>;
export type EditTimeScheduleMutationResult = Apollo.MutationResult<EditTimeScheduleMutation>;
export type EditTimeScheduleMutationOptions = Apollo.BaseMutationOptions<EditTimeScheduleMutation, EditTimeScheduleMutationVariables>;