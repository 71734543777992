import {useCreateWorkPlaceMutation} from "./createWorkPlace.generated";
import {toast} from "react-toastify";
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import React from "react";
import {useNavigate} from "react-router-dom";
import {WorkPlaceForm, WorkPlaceFormValues} from "../../molecules/WorkPlaceForm";

interface CreateWorkPlaceDialogProps {
    open?: boolean;
    onClose?: () => void;
}

export const CreateWorkPlaceDialog = ({open, onClose}: CreateWorkPlaceDialogProps) => {
    const navigate = useNavigate();
    const [createWorkPlaceMutation, {loading}] = useCreateWorkPlaceMutation();

    const onSubmit = async (values: WorkPlaceFormValues) => {
        const {data, errors} = await createWorkPlaceMutation({
            variables: {
                name: values.name,
                country: values.country,
                state: values.state,
                city: values.city,
                address: values.address,
                postalCode: values.postalCode
            }
        });

        if (data) {
            const workPlaceId = data.workPlace.createWorkPlace;
            navigate(`/work-place/${workPlaceId}`);
            toast.success('Standort erfolgreich erstellt');
            if (onClose) {
                onClose();
            }
        } else if (errors) {
            const error = errors[0];
            const reason = error.extensions?.['reason'];

            switch (reason) {
                case "AuthenticationRequired":
                    toast.error('Melde dich erneut an und versuche es nochmal.');
                    return;
                case "PermissionDenied":
                    toast.error('Du hast keine Berechtigung einen Standort zu erstellen');
                    return;
                case "UserNotFound":
                    toast.error('Der Mitarbeiter-Account konnte nicht gefunden werden');
                    return;
            }
        } else {
            toast.error('Ein unerwarteter Fehler ist aufgetreten. Bitte versuche es später erneut.');
        }
    }

    return <Dialog
        open={open ?? false}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        onClose={onClose}
        maxWidth="md"
    >
        <Grid container justifyContent='space-between'>
            <Grid item>
                <DialogTitle id="alert-dialog-title">
                    Standort erstellen
                </DialogTitle>
            </Grid>
            <Grid item sx={{marginRight: '1rem', marginTop: '0.5rem'}}>
                <IconButton onClick={onClose}>
                    <FontAwesomeIcon icon={solid('xmark')} color='#333333' size="lg"/>
                </IconButton>
            </Grid>
        </Grid>
        <DialogContent>
            <WorkPlaceForm onAbort={() => {
                if (onClose) {
                    onClose();
                }
            }} onSubmit={onSubmit} saveButtonLoading={loading} />
        </DialogContent>
    </Dialog>;
};
