import React, {useEffect, useState} from "react";
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    Link, Skeleton,
    Stack,
    SxProps,
    Tab,
    Tabs,
    Theme,
    Typography
} from "@mui/material";
import {TrackingDetailDialogContextProps} from "./TrackingDetailDialogContext";
import {useTrackingChangelogLazyQuery} from "./trackingChangelog.generated";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import WorkingTimeText, {WorkingTimeTextSpan} from "../components/molecules/WorkingTimeText";
import WarningIcon from "@mui/icons-material/Warning";
import {FieldArray, FormikProvider, useFormik} from "formik";
import {DateTime, Duration} from "luxon";
import AddIcon from "@mui/icons-material/Add";
import {LoadingButton, TabContext, TabPanel} from "@mui/lab";
import DeleteTrackingDialog from "../components/organisms/DeleteTrackingDialog/DeleteTrackingDialog";
import {
    FormikEditRow,
    TrackingRowBlock,
    WorkingTimeFormikValues
} from "../components/organisms/WorkingTimeByDayDialog/WorkingTimeByDayDialog";
import {useEditDayMutation} from "../components/organisms/WorkingTimeByDayDialog/editDay.generated";
import {useCompanySettings} from "../store/companySettings";
import {v4} from "uuid";
import * as Yup from "yup";
import {toast} from "react-toastify";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import HistoryIcon from '@mui/icons-material/History';
import {TrackingChangelog} from "./TrackingChangelog";
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {AbsenceRowFragment} from "../fragments/absenceRow.generated";
import {absenceTypeToString} from "../utils/display";
import {useTranslation} from "react-i18next";

const tabStyle: SxProps<Theme> = (theme) => ({
    textDecoration: 'none',
    ':hover': {
        textDecoration: 'none'
    },
    ':focus': {
        textDecoration: 'none'
    },
    display: 'flex',
    flexDirection: 'row',
    gap: 0.5
});

type EditTrackingError = 'AuthenticationRequired' | 'PermissionDenied';

export const TrackingDetailDialog = ({props, onClose}: {
    props?: TrackingDetailDialogContextProps,
    onClose: () => void
}) => {
    const isOpen = !!props;

    const [trackingChangelogQuery, {data, loading, refetch}] = useTrackingChangelogLazyQuery();
    // const changelogEntries = data?.tracking.trackingChangelogEntries;
    const trackingRows = data?.tracking.trackingRowsByDate;

    useEffect(() => {
        if (props?.userId && props?.date) {
            trackingChangelogQuery({
                variables: {
                    employeeId: props.userId!,
                    date: props.date.toISODate()!
                },
                fetchPolicy: 'no-cache'
            });
        }
    }, [props, trackingChangelogQuery]);

    const [editDayMutation, {loading: editDayLoading}] = useEditDayMutation();

    const companySettings = useCompanySettings();

    const date = props?.date;
    const dateString = date?.toFormat('dd.MM.yyyy');

    const [isEditing, setEditing] = useState(false);

    const [isDeleteDayOpen, setDeleteDayOpen] = useState(false);

    const formik = useFormik<WorkingTimeFormikValues>({
        initialValues: {
            date: date,
            trackingRows: [{
                uuid: v4(),
                collapsed: false,
                startDate: date ?? DateTime.now(),
                endDate: date,
                comment: '',
                workPlace: '',
                isStampedIn: false
            }],
            validationErrors: []
        },
        validationSchema: Yup.object().shape({
            trackingRows: Yup.array()
                .of(Yup.object().shape({
                    startDate: Yup.date().when('isStampedIn', {
                        is: false,
                        then: Yup.date()
                            .nullable()
                            .default(null)
                            .max(Yup.ref('endDate'), 'Die Start-Zeit muss vor der End-Zeit liegen')
                            .required('Bitte gib eine Start-Zeit an'),
                        otherwise: Yup.date().notRequired()
                    }),
                    endDate: Yup.date().when('isStampedIn', {
                        is: false,
                        then: Yup.date()
                            .nullable()
                            .default(null)
                            .min(Yup.ref('startDate'), 'Die End-Zeit muss nach der Start-Zeit liegen')
                            .required('Bitte gib eine End-Zeit an'),
                        otherwise: Yup.date().notRequired()
                    }),
                    comment: Yup.string().when('isStampedIn', {
                        is: (isStampedIn: boolean) => {
                            return !isStampedIn && companySettings.settings?.commentsEnabled;
                        },
                        then: Yup.string().required('Bitte gib einen Kommentar an'),
                        otherwise: Yup.string().notRequired()
                    }),
                    workPlace: Yup.string().when('isStampedIn', {
                        is: (isStampedIn: boolean) => {
                            return !isStampedIn && companySettings.settings?.workingPlaceEnabled;
                        },
                        then: Yup.string()
                            .required('Bitte gib einen Arbeitsort an')
                            .oneOf(companySettings.settings?.workingPlaces ?? [], 'Bitt gib einen gültigen Arbeitsort an'),
                        otherwise: Yup.string().notRequired()
                    })
                }))
        }),
        validate: (values) => {
            let validationErrors: string[] = [];
            let trackingRowErrors: { startDate?: string, endDate?: string }[] = [];
            values.trackingRows.map((trackingRow, index) => {
                const isLastRow = index + 1 === values.trackingRows.length;

                if (!isLastRow) {
                    const nextRow = values.trackingRows[index + 1];
                    const endDate = trackingRow.endDate ?? props!.date!;
                    const diff = endDate.diff(nextRow.startDate, 'minutes').minutes;
                    if (diff > 0) {
                        validationErrors[index] = 'Du darfst keine negative Pause angeben';
                        trackingRowErrors[index] = {endDate: 'End-Zeit muss vor der Start-Zeit des nächsten Eintrags sein'};
                        trackingRowErrors[index + 1] = {startDate: 'Start-Zeit muss vor der End-Zeit des vorherigen Eintrags liegen'};
                    }
                }
                return {};
            });

            return (validationErrors.length === 0 && trackingRowErrors.length === 0) ? undefined : {
                validationErrors: validationErrors,
                trackingRows: trackingRowErrors
            };
        },
        onSubmit: async (values) => {
            const {data, errors} = await editDayMutation({
                variables: {
                    employeeId: props!.userId!,
                    date: date!.toFormat('yyyy-MM-dd'),
                    trackingRows: values.trackingRows.map((formikEditRow) => {
                        return {
                            date: values.date!.toFormat('yyyy-MM-dd'),
                            start: formikEditRow.startDate.toISO(),
                            end: formikEditRow.endDate?.toISO(),
                            comment: formikEditRow.comment,
                            newWorkPlace: formikEditRow.workPlace
                        };
                    })
                }
            });

            if (data) {
                toast.success('Stundenbuchungen erfolgreich editiert');
                setEditing(false);

                // Refresh the dialog to include the new entry in the history
                refetch();

                if (props?.onEditSave) {
                    props?.onEditSave();
                }
            } else if (errors) {
                const error = errors[0];
                const reason = error.extensions['reason'] as unknown as EditTrackingError;

                switch (reason) {
                    case "AuthenticationRequired":
                        toast.error('Melde dich erneut an und versuche es nochmal.');
                        return;
                    case "PermissionDenied":
                        toast.error('Du hast nicht die benötigten Rechte um einen Mitarbeiter zu erstellen');
                        return;
                }
            } else {
                toast.error('Ein unbekannter Fehler ist aufgetreten');
            }
        }
    });
    const {values, handleSubmit, errors, setFieldValue} = formik;

    const startEditing = () => {
        const collapsedValues = values.trackingRows.filter((row) => row.collapsed);
        if (collapsedValues.length === 0 && values.trackingRows.length > 0) {
            const firstRow = values.trackingRows[0];
            const otherRows = values.trackingRows.slice(1);
            formik.setFieldValue('trackingRows', [{...firstRow, collapsed: true}, ...otherRows]);
        }
        setEditing(true);
    };

    useEffect(() => {
        const formikEditRows: FormikEditRow[] = (trackingRows ?? []).map((trackingRow) => {
            return {
                uuid: v4(),
                collapsed: false,
                startDate: DateTime.fromISO(trackingRow.start),
                endDate: trackingRow.end !== null ? DateTime.fromISO(trackingRow.end) : undefined,
                comment: trackingRow.comment ?? '',
                workPlace: trackingRow.workPlace ?? '',
                isStampedIn: trackingRow.end === null
            };
        });

        setFieldValue('trackingRows', formikEditRows);

        // Set date as we initially have the wrong date here
        if (props?.date) {
            setFieldValue('date', props!.date!);
        }
    }, [trackingRows, setFieldValue, props]);

    const addTrackingRow = () => {
        setFieldValue('trackingRows', [
            ...values.trackingRows,
            {
                uuid: v4(),
                startDate: values.trackingRows[values.trackingRows.length - 1].endDate ?? date,
                endDate: undefined,
                comment: '',
                workPlace: ''
            }
        ]);
    }

    const absenceMinutes = data?.absence.absencesByDate.map((absenceRow) => {
        return absenceRow.workingTime.hours * 60 + absenceRow.workingTime.minutes;
    }).reduce((prev, current) => prev + current, 0);

    const workingTimeMinutes = values.trackingRows.map((trackingRow) => {
        const startDate = trackingRow.startDate;
        const endDate = trackingRow.endDate ?? trackingRow.startDate;

        const diff = endDate.diff(startDate, ['minutes']);

        return diff.minutes;
    }).reduce((prev, current) => prev + current, 0);

    const breakingMinutes = values.trackingRows.map((trackingRow, index) => {
        const isLastRow = index + 1 === values.trackingRows.length;

        if (isLastRow) {
            return 0;
        } else {
            const nextRow = values.trackingRows[index + 1];

            const endDateThisRow = trackingRow.endDate ?? nextRow.startDate;
            const startDateNextRow = nextRow.startDate;
            const diff = startDateNextRow.diff(endDateThisRow, ['minutes']);

            return diff.minutes;
        }
    }).reduce((prev, current) => prev + current, 0);

    const breakingDuration = Duration.fromMillis((isNaN(breakingMinutes) ? 0 : breakingMinutes) * 60 * 1000).shiftTo('hours', 'minutes').toObject();
    const absenceDuration = absenceMinutes ? Duration.fromMillis((isNaN(absenceMinutes) ? 0 : absenceMinutes) * 60 * 1000).shiftTo('hours', 'minutes').toObject() : undefined;
    const workingDuration = Duration.fromMillis((isNaN(workingTimeMinutes + absenceMinutes) ? 0 : workingTimeMinutes + absenceMinutes) * 60 * 1000).shiftTo('hours', 'minutes').toObject();

    const [tabValue, setTabValue] = useState("hours");

    const hasEditPermission = data?.permission.trackingPermission.canEditOrDelete ?? false;
    const canStampOverNight = data?.permission.trackingPermission.canStampOverNight ?? false;

    if (!companySettings.settings) {
        return <></>;
    }

    return <Dialog
        open={isOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        onClose={onClose}
        maxWidth="md"
    >
        <Grid container justifyContent='space-between'>
            <Grid item>
                <DialogTitle id="alert-dialog-title">
                    Stunden am {dateString}
                </DialogTitle>
            </Grid>
            <Grid item sx={{marginRight: '1rem', marginTop: '0.5rem', display: 'flex', flexDirection: 'row'}}>
                <IconButton onClick={onClose}>
                    <FontAwesomeIcon icon={solid('xmark')} color='#333333' size="lg"/>
                </IconButton>
            </Grid>
        </Grid>
        <DialogContent>
            <Grid
                container
                spacing={10}
            >
                <Grid item>
                    <p>
                        <b>Erfasste Zeit: </b>
                        <WorkingTimeText workingTime={workingDuration}/>
                        {absenceDuration &&
                            <>davon <WorkingTimeTextSpan workingTime={absenceDuration}/> abwesend</>
                        }
                    </p>
                </Grid>
                <Grid item>
                    <p><b>Pause: </b><WorkingTimeText workingTime={breakingDuration}/></p>
                </Grid>
            </Grid>
            <Stack flexDirection='row' justifyContent='end' sx={{gap: 2}}>
                <Tabs value={tabValue} sx={{
                    height: '50px', '& .MuiTabs-indicator': {
                        backgroundColor: 'transparent',
                    }
                }}>
                    <Tab
                        icon={<AccessTimeIcon/>}
                        label="Stunden"
                        value="hours"
                        component={Link}
                        onClick={() => setTabValue('hours')}
                        sx={tabStyle}
                    />
                    <Tab
                        icon={<HistoryIcon/>}
                        label="Historie"
                        value="history"
                        component={Link}
                        onClick={() => setTabValue('history')}
                        sx={tabStyle}
                    />
                </Tabs>
            </Stack>
            <TabContext value={tabValue}>
                <TabPanel value='hours'>
                    <Box>
                        {data?.absence.absencesByDate.map((absenceRow) => <TrackingDialogAbsenceBox
                            absenceRow={absenceRow}/>)}
                    </Box>

                    {(loading && !data) &&
                        <Box>
                            <Skeleton height={45}/>
                            <Skeleton height={45}/>
                            <Skeleton height={45}/>
                        </Box>
                    }

                    {(values.trackingRows.length === 0 && !loading) &&
                        <Stack direction='row' spacing={2} sx={{paddingTop: 4}}>
                            <WarningIcon color='error'/>
                            <Typography color='error'>Es wurde keine Arbeitszeit für diesen Tag erfasst</Typography>
                        </Stack>
                    }

                    <Box component='form' onSubmit={handleSubmit} sx={{paddingTop: 4}}>
                        {formik &&
                            <FormikProvider value={formik}>
                                <FieldArray
                                    name="trackingRows"
                                    validateOnChange={false}
                                    render={arrayHelpers => (
                                        <div>
                                            {values.trackingRows.map((trackingRow, index) => {
                                                const isLastRow = index + 1 === values.trackingRows.length;

                                                const onDelete = () => {
                                                    setFieldValue('trackingRows', values.trackingRows.filter((trackingRow, rowIndex) => rowIndex !== index));
                                                };

                                                if (isLastRow) {
                                                    return <TrackingRowBlock
                                                        startDate={date}
                                                        companySettings={companySettings.settings!}
                                                        key={trackingRow.uuid}
                                                        trackingRow={trackingRow}
                                                        index={index}
                                                        isEditing={isEditing}
                                                        onDelete={onDelete}
                                                        formik={formik}
                                                        canStampOverNight={canStampOverNight}
                                                    />
                                                } else {
                                                    const nextRow = values.trackingRows[index + 1];

                                                    const endDateThisRow = trackingRow.endDate ?? DateTime.now();
                                                    const startDateNextRow = nextRow.startDate;
                                                    const diff = startDateNextRow.diff(endDateThisRow, ['hours', 'minutes']);

                                                    return <div key={trackingRow.uuid}>
                                                        <TrackingRowBlock
                                                            startDate={date}
                                                            companySettings={companySettings.settings!}
                                                            trackingRow={trackingRow}
                                                            index={index}
                                                            isEditing={isEditing}
                                                            onDelete={onDelete}
                                                            formik={formik}
                                                            canStampOverNight={canStampOverNight}
                                                        />
                                                        <Stack sx={{my: 1}} direction='row' justifyContent='center'
                                                               alignItems='center' spacing={2}>
                                                            {errors.validationErrors?.[index] &&
                                                                <WarningIcon color='error'/>
                                                            }
                                                            <Divider
                                                                sx={{
                                                                    backgroundColor: 'rgba(0, 0, 0, 0.87)',
                                                                    width: '1px'
                                                                }}
                                                                flexItem={true} orientation='vertical'/>
                                                            <Typography
                                                                sx={{py: 1.5}}>Pause: {diff.hours}h {diff.minutes.toFixed(0)}min</Typography>
                                                        </Stack>
                                                    </div>;
                                                }
                                            })}
                                        </div>
                                    )}
                                />
                            </FormikProvider>
                        }
                        {isEditing && <>
                            <Box sx={{display: 'flex', justifyContent: 'center', marginTop: 1}}>
                                <IconButton type='button' onClick={addTrackingRow}
                                            sx={{
                                                backgroundColor: '#0854CF',
                                                color: '#fff',
                                                '&:hover': {backgroundColor: '#84868E'}
                                            }}>
                                    <AddIcon/>
                                </IconButton>
                            </Box>
                        </>
                        }

                        <Box sx={{display: 'flex', justifyContent: 'end', marginTop: 1, gap: 3}}>
                            {isEditing ? (
                                <>
                                    <Button type='button' variant='contained' color='inherit'
                                            onClick={() => setEditing(false)}>Abbrechen</Button>
                                    <LoadingButton loading={editDayLoading} type='submit'
                                                   variant='contained'>Speichern</LoadingButton>
                                </>
                            ) : (
                                <>
                                    {(props && props.date && props.userId) &&
                                        <>
                                            <DeleteTrackingDialog open={isDeleteDayOpen}
                                                                  onClose={(deleted: boolean) => {
                                                                      setDeleteDayOpen(false)
                                                                      if (deleted) {
                                                                          if (onClose) {
                                                                              onClose();
                                                                          }
                                                                          if (props?.onEditSave) {
                                                                              props?.onEditSave();
                                                                          }
                                                                      }
                                                                  }}
                                                                  date={props!.date!}
                                                                  employeeId={props!.userId!}/>
                                            {(data?.absence.absencesByDate.length === 0 && hasEditPermission) &&
                                                <Button key={`${props!.date!.toString()}-deleteButton`} type='button'
                                                        onClick={() => setDeleteDayOpen(true)} variant='contained'
                                                        color='error'>Löschen</Button>
                                            }
                                            {(values.trackingRows.length !== 0 && hasEditPermission) &&
                                                <Button key={`${props!.date!.toString()}-editButton`} type='button'
                                                        variant='contained'
                                                        onClick={startEditing}>Bearbeiten</Button>
                                            }
                                        </>
                                    }
                                </>
                            )}
                        </Box>
                    </Box>
                </TabPanel>
                <TabPanel value='history'>
                    <TrackingChangelog changelogEntries={data?.tracking.trackingChangelogEntries ?? []}/>
                </TabPanel>
            </TabContext>
        </DialogContent>
    </Dialog>;
}

export const TrackingDialogAbsenceBox = ({absenceRow}: { absenceRow: AbsenceRowFragment }) => {
    const {t} = useTranslation();

    const absenceTypeString = absenceTypeToString(absenceRow.absenceType);
    const absencePeriod = absenceRow.absencePeriod;

    const absencePeriodToString = () => {
        switch (absencePeriod) {
            case 'full_time':
                return 'Ganztags';
            case 'half_time':
                return 'Halbtags';
        }
    };

    return <Box sx={{backgroundColor: '#3B3C40', borderRadius: '10px', color: '#fff', padding: 2}}>
        <Grid container sx={{display: 'flex', alignItems: 'center'}}>
            <Grid xs={1.5} item>
                <MeetingRoomIcon sx={{fontSize: 35}}/>
            </Grid>
            <Grid xs={4.1} item>
                <Typography>{t(absenceTypeString)}</Typography>
            </Grid>
            <Grid xs={3}>
                {absencePeriod &&
                    <Typography>{absencePeriodToString()}</Typography>
                }
                {!absencePeriod &&
                    <WorkingTimeText workingTime={absenceRow.workingTime}/>
                }
            </Grid>
            {!!absenceRow.originAbsenceRequestId &&
                <Grid xs={3.4} sx={{display: 'flex', justifyContent: 'end'}}>
                    <Link href={`/absence/${absenceRow.originAbsenceRequestId}`}
                          sx={{
                              color: '#fff',
                              textDecoration: 'none',
                              ':hover': {color: '#2453C8', textDecoration: 'none'}
                          }}>
                        <Stack flexDirection='row' sx={{alignItems: 'center'}} gap={2}>
                            <OpenInNewIcon/>
                            zur Abwesenheit
                        </Stack>
                    </Link>
                </Grid>
            }
        </Grid>
    </Box>;
}
