import * as Types from '../../../generated/graphql';

import { AbsenceRequestFragment } from '../../../fragments/absenceRequest.generated';
import { gql } from '@apollo/client';
import { AbsenceRequestFragmentDoc } from '../../../fragments/absenceRequest.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PastAbsenceRequestsQueryVariables = Types.Exact<{
  userId: Types.Scalars['Int'];
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type PastAbsenceRequestsQuery = { __typename?: 'Query', absence: { __typename?: 'absenceQueries', pastAbsenceRequests: { __typename?: 'PastAbsenceData', totalCount: number, absenceRequests: Array<(
        { __typename?: 'AbsenceRequest' }
        & AbsenceRequestFragment
      )> } } };


export const PastAbsenceRequestsDocument = gql`
    query PastAbsenceRequests($userId: Int!, $limit: Int, $offset: Int) {
  absence {
    pastAbsenceRequests(employeeId: $userId, limit: $limit, offset: $offset) {
      totalCount
      absenceRequests {
        ...AbsenceRequest
      }
    }
  }
}
    ${AbsenceRequestFragmentDoc}`;

/**
 * __usePastAbsenceRequestsQuery__
 *
 * To run a query within a React component, call `usePastAbsenceRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePastAbsenceRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePastAbsenceRequestsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function usePastAbsenceRequestsQuery(baseOptions: Apollo.QueryHookOptions<PastAbsenceRequestsQuery, PastAbsenceRequestsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PastAbsenceRequestsQuery, PastAbsenceRequestsQueryVariables>(PastAbsenceRequestsDocument, options);
      }
export function usePastAbsenceRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PastAbsenceRequestsQuery, PastAbsenceRequestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PastAbsenceRequestsQuery, PastAbsenceRequestsQueryVariables>(PastAbsenceRequestsDocument, options);
        }
export type PastAbsenceRequestsQueryHookResult = ReturnType<typeof usePastAbsenceRequestsQuery>;
export type PastAbsenceRequestsLazyQueryHookResult = ReturnType<typeof usePastAbsenceRequestsLazyQuery>;
export type PastAbsenceRequestsQueryResult = Apollo.QueryResult<PastAbsenceRequestsQuery, PastAbsenceRequestsQueryVariables>;