import {AbsenceType, ContractType, TrackingNote} from '../generated/graphql';
import {DateTime} from "luxon";

export const contractTypeToString = (contractType: ContractType): string => {
    switch (contractType) {
        case 'full_time':
            return 'Vollzeit';
        case 'hourly_basis':
            return 'Auf Stunden-Basis';
        case 'intern':
            return 'Praktikant';
        case 'part_time':
            return 'Teilzeit';
        case 'working_student':
            return 'Werkstudent';
        default:
            return 'Fehler';
    }
};

export const absenceTypeToString = (absenceType: AbsenceType): string => {
    switch (absenceType) {
        case "holiday":
        case "childIllness":
        case "specialLeave":
        case "flexDay":
        case "parentalLeave":
        case "illness":
        case "vacation":
        case "balanceAdjustment":
            return `common.absence.${absenceType}`;
        default:
            return 'common.absence.unknown';
    }
}

export const numberWithLeadingZero = (value: number): string => {
    if (value < 10) {
        return `0${value.toFixed(0)}`;
    } else {
        return value.toFixed(0);
    }
}

export function isNotNull<T>(elem: T | null | undefined): elem is T {
    return elem !== null && elem !== undefined;
}

export const dateToWeekdayString = (date: DateTime): string => {
    switch (date.weekday) {
        case 1:
            return 'common.weekday.monday';
        case 2:
            return 'common.weekday.tuesday';
        case 3:
            return 'common.weekday.wednesday';
        case 4:
            return 'common.weekday.thursday';
        case 5:
            return 'common.weekday.friday';
        case 6:
            return 'common.weekday.saturday';
        case 7:
            return 'common.weekday.sunday';
    }
};

export interface TrackingNoteResult {
    readonly color: 'primary' | 'warning' | 'error';
    readonly message: string;
}

export const trackingNotesToText = (trackingNotes: TrackingNote[]): TrackingNoteResult[] => {
    return trackingNotes.map(trackingNote => {
        switch (trackingNote) {
            case "breakAdjustedAfter6HourWorkDayTooShort":
                return {
                    color: 'warning',
                    message: 'Arbeitszeit wurde angepasst, da weniger als 30 Minuten Pause gemacht wurden'
                };
            case "breakAdjustedAfter9HourWorkDayTooShort":
                return {
                    color: 'warning',
                    message: 'Arbeitszeit wurde angepasst, da weniger als 45 Minuten Pause gemacht wurden'
                };
            case "breakAfter6HourWorkDayTooShort":
                return {color: 'error', message: 'Ab 6 Arbeitsstunden sind mindestens 30 Minuten Pause notwendig'};
            case "breakAfter9HourWorkDayTooShort":
                return {color: 'error', message: 'Ab 9 Arbeitsstunden sind mindestens 45 Minuten Pause notwendig'};
            case "noWorkingHourDespiteWorkingPlan":
                return {
                    color: 'error',
                    message: 'Trotz geplanter Arbeitszeit wurden für diesen Tag keine Zeiten erfasst'
                };
            default:
                return {color: 'primary', message: 'Ein unbekannter Fehler ist aufgetreten'};
        }
    });
}
