import React from 'react';
import {Navigate, useNavigate, useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {useEmployeeByIdQuery} from './employeeById.generated';
import {AccountStatus} from '../../generated/graphql';
import {ContentHeader} from '../../components';
import {useUpdateEmployeeAccountStatusMutation} from '../employees/updateEmployeeAccountStatus.generated';
import {Box, Card, IconButton, Link, Menu, MenuItem, Stack, SxProps, Tab, Tabs, Theme, Typography} from "@mui/material";
import {TabContext, TabPanel} from "@mui/lab";
import EmployeeHours from "./pages/EmployeeHours";
import SettingsIcon from '@mui/icons-material/Settings';
import {useRouteMatch} from "../../utils/useRouteMatch";
import EmployeeTimeSchedule from "./pages/EmployeeTimeSchedule/EmployeeTimeSchedule";
import EmployeeAbsences from "./pages/EmployeeAbsences/EmployeeAbsences";
import {hasAtLeastSubscription} from "../../utils/subscription";
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {UpgradeSubscriptionScreen} from "../../components/organisms/UpgradeSubscriptionScreen";
import errorImage from "../../images/error_creature.png";
import EmployeeAdministration from './pages/EmployeeAdministration/EmployeeAdministration';
import {EmployeeVacation} from "./pages/EmployeeVacation/EmployeeVacation";
import {EmployeeSettings} from "./pages/EmployeeSettings/EmployeeSettings";
import {useFlag} from "@unleash/proxy-client-react";

const tabStyle: SxProps<Theme> = (theme) => ({
    textDecoration: 'none',
    ':hover': {
        textDecoration: 'none'
    },
    ':focus': {
        textDecoration: 'none'
    }
});

const ViewEmployee = () => {
    const {id} = useParams();
    const navigate = useNavigate();

    const isNewSettingEnabled = useFlag('MTT-235_new_settings');

    const user = useSelector((state: RootState) => state.auth.currentUser);

    const [updateEmployeeAccountStatusMutation] =
        useUpdateEmployeeAccountStatusMutation();

    const updateEmployeeAccountStatus = async (
        employeeId: number,
        accountStatus: AccountStatus
    ) => {
        const {data, errors} = await updateEmployeeAccountStatusMutation({
            variables: {employeeId, accountStatus}
        });

        if (errors) {
            const error = errors[0];
            const reason = error.extensions['reason'] as unknown as string;

            if (reason === 'AuthenticationRequired') {
                toast.error('Melde dich erneut an und versuche es nochmal.');
            } else if (reason === 'AdminUserNotFound') {
                toast.error('Ein Fehler mit deinem Nutzer ist aufgetreten');
            } else if (reason === 'UserNotFound') {
                toast.error('Der Nutzer konnte nicht gefunden werden');
            } else if (reason === 'PermissionDenied') {
                toast.error(
                    `Du kannst diesen Nutzer nicht ${
                        accountStatus === 'deleted' ? 'löschen' : 'archivieren'
                    }`
                );
            } else if (reason === 'MissingPaymentData') {
                toast.error('Dein Unternehmen hat noch kein Zahlungsmittel hinterlegt');
            } else if (reason === 'NoUpdateForOwnAccount') {
                toast.error(
                    `Du kannst deinen eigenen Account nicht ${
                        accountStatus === 'deleted' ? 'löschen' : 'archivieren'
                    }`
                );
            } else {
                toast.error('Ein unerwarteter Fehler ist aufgetreten');
            }
        } else if (data) {
            toast.success(`Mitarbeiter wurde erfolgreich aktualisiert`);
        } else {
            toast.error('Ein unbekannter Fehler ist aufgetreten');
        }
    };

    const employeeId = Number(id);

    const {
        data,
        error,
        loading,
        refetch: refetchEmployeeById
    } = useEmployeeByIdQuery({
        variables: {
            employeeId: employeeId
        }
    });

    const employee = data?.employee.byId;

    const routeMatch = useRouteMatch([
        `/employee/:employeeId/administration`,
        `/employee/:employeeId/hours`,
        `/employee/:employeeId/time-schedule`,
        `/employee/:employeeId/absences`,
        `/employee/:employeeId/vacation`,
        `/employee/:employeeId/settings`
    ]);

    const tabValue = routeMatch?.pattern?.path;

    if (!tabValue) {
        return <Navigate to={`/employee/${employeeId}/hours`} replace={true}/>;
    }

    return (
        <>
            <ContentHeader
                title={employee != null ?
                    `Mitarbeiter ${employee.name} ${employee.surname} ${employee.accountStatus === 'archived' ? '(Archiviert)' : ''}`
                    : 'Mitarbeiter'}
                child={<ContextMenu accountStatus={employee?.accountStatus ?? 'active'}
                                    onActivate={() => updateEmployeeAccountStatus(employeeId, 'active')}
                                    onArchive={() => updateEmployeeAccountStatus(
                                        employeeId,
                                        'archived'
                                    )} onDelete={() => updateEmployeeAccountStatus(employeeId, 'deleted')}/>
                }
            />

            <Card>
                <Tabs value={tabValue}>
                    <Tab
                        label="Stunden"
                        value="/employee/:employeeId/hours"
                        component={Link}
                        onClick={() => navigate(`/employee/${employeeId}/hours`)}
                        sx={tabStyle}
                    />
                    <Tab
                        label="Verwaltung"
                        value="/employee/:employeeId/administration"
                        component={Link}
                        onClick={() => navigate(`/employee/${employeeId}/administration`)}
                        sx={tabStyle}
                    />
                    <Tab
                        label="Arbeitszeiten"
                        value="/employee/:employeeId/time-schedule"
                        component={Link}
                        onClick={() => navigate(`/employee/${employeeId}/time-schedule`)}
                        sx={tabStyle}
                    />
                    <Tab
                        label="Abwesenheiten"
                        value="/employee/:employeeId/absences"
                        component={Link}
                        onClick={() => navigate(`/employee/${employeeId}/absences`)}
                        sx={tabStyle}
                    />
                    <Tab
                        label="Urlaubstage"
                        value="/employee/:employeeId/vacation"
                        component={Link}
                        onClick={() => navigate(`/employee/${employeeId}/vacation`)}
                        sx={tabStyle}
                    />
                    {isNewSettingEnabled &&
                        <Tab
                            label="Einstellungen"
                            value="/employee/:employeeId/settings"
                            component={Link}
                            onClick={() => navigate(`/employee/${employeeId}/settings`)}
                            sx={tabStyle}
                        />
                    }
                </Tabs>
                {error &&
                    <Stack alignItems='center' justifyContent='center' sx={{my: 2}}>
                        <Typography variant='h4'>Es tut uns leid!</Typography>
                        <Box component='img' src={errorImage} sx={{width: '30%'}}/>
                        <Typography sx={{mt: 3}}>Beim Laden der Seite ist ein Fehler aufgetreten</Typography>
                    </Stack>
                }
                {(loading || data) && !error &&
                    <TabContext value={tabValue}>
                        <TabPanel value="/employee/:employeeId/hours">
                            <EmployeeHours userId={employeeId}/>
                        </TabPanel>
                        <TabPanel value="/employee/:employeeId/administration">
                            <EmployeeAdministration
                                employee={employee}
                                employeeId={employeeId}
                                onAfterEdit={refetchEmployeeById}
                                canUseTerminal={data?.permission.canUseTablet ?? false}
                                workPlaces={data?.workPlace.listWorkPlaces ?? []}
                            />
                        </TabPanel>
                        <TabPanel value="/employee/:employeeId/time-schedule">
                            {!hasAtLeastSubscription(user, 'professional') &&
                                <UpgradeSubscriptionScreen/>
                            }
                            {hasAtLeastSubscription(user, 'professional') &&
                                <EmployeeTimeSchedule employee={employee}/>
                            }
                        </TabPanel>
                        <TabPanel value="/employee/:employeeId/absences">
                            {!hasAtLeastSubscription(user, 'professional') &&
                                <UpgradeSubscriptionScreen/>
                            }
                            {hasAtLeastSubscription(user, 'professional') &&
                                <EmployeeAbsences employee={employee}/>
                            }
                        </TabPanel>
                        <TabPanel value="/employee/:employeeId/vacation">
                            {!hasAtLeastSubscription(user, 'expert') &&
                                <UpgradeSubscriptionScreen/>
                            }
                            {hasAtLeastSubscription(user, 'expert') &&
                                <EmployeeVacation employee={employee}/>
                            }
                        </TabPanel>
                        <TabPanel value="/employee/:employeeId/settings">
                            <EmployeeSettings employee={employee} />
                        </TabPanel>
                    </TabContext>
                }
            </Card>
        </>
    );
};

const ContextMenu = ({onArchive, onDelete, onActivate, accountStatus}: {
    onArchive: () => void,
    onDelete: () => void,
    onActivate: () => void,
    accountStatus: AccountStatus
}) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                sx={{color: '#212529'}}
            >
                <SettingsIcon fontSize='large'/>
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {accountStatus === 'archived' &&
                    <MenuItem onClick={() => {
                        handleClose();
                        onActivate();
                    }
                    }>
                        Aktivieren
                    </MenuItem>
                }
                {accountStatus !== 'archived' &&
                    <MenuItem onClick={() => {
                        handleClose();
                        onArchive();
                    }
                    }>
                        Archivieren
                    </MenuItem>
                }
                {accountStatus !== 'deleted' && (
                    <MenuItem onClick={() => {
                        handleClose();
                        onDelete();
                    }}>
                        Löschen
                    </MenuItem>
                )}
            </Menu>
        </>
    );
};

export default ViewEmployee;
