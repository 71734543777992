import React from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {useListEmployeesQuery} from './listEmployees.generated';
import {ContentHeader} from '../../components';
import {contractTypeToString} from '../../utils/display';
import {
    Box,
    Button,
    Card,
    CardContent,
    Grid, MenuItem,
    Pagination, Select,
    Skeleton,
    SxProps,
    TextField,
    Theme,
    Tooltip, useMediaQuery, useTheme
} from "@mui/material";
import {CSSProperties} from "styled-components";
import LockIcon from '@mui/icons-material/Lock';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import EmployeeContextMenu from "../../components/molecules/EmployeeContextMenu";
import {ErrorScreen} from "../../components/organisms/ErrorScreen";
import {useEmployeeOverviewListingQuery} from "./employeeOverviewListing.generated";
import {EmployeeListFilterOption} from "../../generated/graphql";
import {useFlag} from "@unleash/proxy-client-react";
import {Duration} from "luxon";
import SaldoTimeText from "../../components/molecules/SaldoTimeText";

const gridItemStyle: SxProps<Theme> = (theme) => ({
    paddingTop: '10px',
    paddingBottom: '10px',
    textDecoration: 'none'
});

const linkNoDecorationStyle: CSSProperties = {textDecoration: 'none', color: '#333333'};

const Employees = () => {
    const isNewListingEnabled = useFlag('new-employee-listing');

    if (isNewListingEnabled) {
        return <EmployeesNew/>;
    } else {
        return <EmployeesOld/>;
    }
}

const EmployeesNew = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = React.useState(1);
    const [filterOption, setFilterOption] = React.useState<EmployeeListFilterOption>('all');
    const [searchQuery, setSearchQuery] = React.useState<string>('');

    const {data, refetch, error, loading} = useEmployeeOverviewListingQuery({
        fetchPolicy: 'no-cache', variables: {
            limit: itemsPerPage,
            filterOption: filterOption,
            offset: (currentPage - 1) * itemsPerPage,
            searchQuery: searchQuery.length > 0 ? searchQuery : undefined
        }
    });

    const employeeListingRows = data?.employee.employeeOverviewListing.employeeRows;
    const totalCount = data?.employee.employeeOverviewListing.totalCount ?? 0;

    const hasBalanceColumn = employeeListingRows?.some(value => !!value.balanceSeconds);
    const showBalanceColumn = hasBalanceColumn && isDesktop;

    const hasVacationColumn = employeeListingRows?.some(value => !!value.remainingVacationMinutes);
    const showVacationColumn = hasVacationColumn && isDesktop;

    const totalPages = Math.ceil(totalCount / itemsPerPage);

    if (error) {
        return <div>
            <ContentHeader title="Mitarbeiter"/>
            <Card>
                <ErrorScreen/>
            </Card>
        </div>;
    }

    return (
        <div>
            <ContentHeader title="Mitarbeiter"/>

            <Card>
                <CardContent>
                    <Grid container>
                        <Grid item md={6}>
                            <Button variant='contained' onClick={() => navigate('/employee/create')}>Neuer
                                Mitarbeiter</Button>
                        </Grid>
                        <Grid item md={6} sx={{display: 'flex', gap: 2}}>
                            <TextField id="searchQuery"
                                       name='searchQuery'
                                       placeholder="Suchen..."
                                       variant='outlined'
                                       fullWidth
                                       size='small'
                                       value={searchQuery}
                                       onChange={(event) => setSearchQuery( event.target.value)}
                            />
                            <Select
                                id='filterOption'
                                name='filterOption'
                                labelId="filter-option-label"
                                size='small'
                                fullWidth
                                value={filterOption}
                                onChange={(event) => setFilterOption(event.target.value as EmployeeListFilterOption)}
                                sx={{backgroundColor: '#fff'}}
                            >
                                <MenuItem value='all'>Alle anzeigen</MenuItem>
                                <MenuItem value='onlyActive'>Nur aktive anzeigen</MenuItem>
                            </Select>
                        </Grid>
                    </Grid>
                </CardContent>
                <CardContent>
                    <Grid container spacing={1}
                          sx={{
                              marginBottom: '1rem',
                              minHeight: '50px',
                              alignItems: 'center',
                              borderRadius: '10px'
                          }}>
                        <Grid item xs={0.5} sx={gridItemStyle}></Grid>
                        <Grid item xs sx={gridItemStyle}><b>Name</b></Grid>
                        <Grid item xs sx={gridItemStyle}><b>Vorname</b></Grid>
                        <Grid item xs sx={gridItemStyle}><b>Vertragsart</b></Grid>
                        {showVacationColumn && <Grid item xs sx={gridItemStyle}><b>Urlaubskonto</b></Grid>}
                        {showBalanceColumn && <Grid item xs sx={gridItemStyle}><b>Überstundenkonto</b></Grid>}
                        <Grid item xs={0.5}></Grid>
                    </Grid>
                    {employeeListingRows && employeeListingRows.length > 0 && <>
                        {employeeListingRows.map((employeeRow, index) => {
                            const employee = employeeRow.employee;

                            const vacationDays = employeeRow.remainingVacationMinutes ? employeeRow.remainingVacationMinutes / 60 / 8 : undefined;
                            const balanceDuration = employeeRow.balanceSeconds ? Duration.fromMillis(employeeRow.balanceSeconds * 1000).shiftTo('hours', 'minutes').toObject() : undefined;

                            return <Grid key={index} container spacing={1} sx={{
                                backgroundColor: employee.accountStatus === 'archived' ? '#d9d9d9' : '#F8FAFE',
                                marginBottom: '1rem',
                                minHeight: '50px',
                                alignItems: 'center',
                                borderRadius: '10px',
                                width: '100%',
                                marginLeft: 0,
                                ':hover': {
                                    backgroundColor: employee.accountStatus === 'archived' ? '#c9c9c9' : '#E5EDFA'
                                }
                            }}>
                                <Grid item xs={11} sm={11.5} sx={{paddingTop: '0 !important'}}>
                                    <Link to={`/employee/${employee.userId}`} style={linkNoDecorationStyle}>
                                        <Grid container sx={{alignItems: 'center'}}>
                                            <Grid item xs={0.5} sx={{
                                                ...gridItemStyle,
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                            }}>
                                                {employee.accountStatus === 'archived' &&
                                                    <Tooltip
                                                        title='Dieser Mitarbeiter ist archivert und kann sich nicht mehr anmelden'>
                                                        <LockIcon/>
                                                    </Tooltip>
                                                }
                                                {employee.accountStatus === 'notVerified' &&
                                                    <Tooltip
                                                        title='Der Mitarbeiter hat seine E-Mail-Adresse noch nicht verifiziert'>
                                                        <HourglassEmptyIcon/>
                                                    </Tooltip>
                                                }
                                            </Grid>
                                            <Grid item xs sx={gridItemStyle}>{employee.surname}</Grid>
                                            <Grid item xs sx={gridItemStyle}>{employee.name}</Grid>
                                            <Grid item xs
                                                  sx={gridItemStyle}>{contractTypeToString(employee.contractType)}</Grid>
                                            {showVacationColumn && <Grid item xs sx={gridItemStyle}>
                                                {vacationDays ? `${vacationDays} Tage` : ''}
                                            </Grid>}
                                            {showBalanceColumn && <Grid item xs sx={gridItemStyle}>
                                                {balanceDuration &&
                                                    <SaldoTimeText saldo={{hours: balanceDuration.hours, minutes: balanceDuration.minutes}} />
                                                }
                                            </Grid>}
                                        </Grid>
                                    </Link>
                                </Grid>
                                <Grid item xs={1} sm={0.5}
                                      sx={{...gridItemStyle, paddingLeft: '0 !important', paddingTop: '0 !important'}}>
                                    <EmployeeContextMenu employee={employee} employeeId={employee.userId}
                                                         onUpdate={refetch}/>
                                </Grid>
                            </Grid>;
                        })}
                        <Box sx={{display: 'flex', justifyContent: 'center', paddingTop: 2}}>
                            <Pagination page={currentPage} count={totalPages} color='primary'
                                        onChange={(event, page) => setCurrentPage(page)}/>
                        </Box>
                    </>}
                    {loading &&
                        Array.from(Array(5).keys()).map((_, index) => {
                            return <Skeleton key={index} variant="rounded" width='100%' height={50}
                                             sx={{marginTop: '10px'}}/>;
                        })
                    }
                </CardContent>
            </Card>
        </div>
    );
}

const EmployeesOld = () => {
    const navigate = useNavigate();
    const {data, refetch, error} = useListEmployeesQuery({fetchPolicy: 'no-cache'});

    const employees = data?.employee.listEmployees;

    if (error) {
        return <div>
            <ContentHeader title="Mitarbeiter"/>
            <Card>
                <ErrorScreen/>
            </Card>
        </div>;
    }

    return (
        <div>
            <ContentHeader title="Mitarbeiter"/>

            <Card>
                <CardContent>
                    <Box sx={{display: 'flex', justifyContent: 'end'}}>
                        <Button variant='contained' onClick={() => navigate('/employee/create')}>Neuer
                            Mitarbeiter</Button>
                    </Box>
                </CardContent>
                <CardContent>
                    <Grid container spacing={1}
                          sx={{
                              marginBottom: '1rem',
                              minHeight: '50px',
                              alignItems: 'center',
                              borderRadius: '10px'
                          }}>
                        <Grid item xs={0.5} sx={gridItemStyle}></Grid>
                        <Grid item xs sx={gridItemStyle}><b>Name</b></Grid>
                        <Grid item xs sx={gridItemStyle}><b>Vorname</b></Grid>
                        <Grid item xs sx={gridItemStyle}><b>Vertragsart</b></Grid>
                        <Grid item xs={0.5}></Grid>
                    </Grid>
                    {employees && employees.length > 0 && (
                        employees.map((employee, index) => {
                            return <Grid key={index} container spacing={1} sx={{
                                backgroundColor: employee.accountStatus === 'archived' ? '#d9d9d9' : '#F8FAFE',
                                marginBottom: '1rem',
                                minHeight: '50px',
                                alignItems: 'center',
                                borderRadius: '10px',
                                width: '100%',
                                marginLeft: 0,
                                ':hover': {
                                    backgroundColor: employee.accountStatus === 'archived' ? '#c9c9c9' : '#E5EDFA'
                                }
                            }}>
                                <Grid item xs={11} sm={11.5} sx={{paddingTop: '0 !important'}}>
                                    <Link to={`/employee/${employee.userId}`} style={linkNoDecorationStyle}>
                                        <Grid container sx={{alignItems: 'center'}}>
                                            <Grid item xs={0.5} sx={{
                                                ...gridItemStyle,
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                            }}>
                                                {employee.accountStatus === 'archived' &&
                                                    <Tooltip
                                                        title='Dieser Mitarbeiter ist archivert und kann sich nicht mehr anmelden'>
                                                        <LockIcon/>
                                                    </Tooltip>
                                                }
                                                {employee.accountStatus === 'notVerified' &&
                                                    <Tooltip
                                                        title='Der Mitarbeiter hat seine E-Mail-Adresse noch nicht verifiziert'>
                                                        <HourglassEmptyIcon/>
                                                    </Tooltip>
                                                }
                                            </Grid>
                                            <Grid item xs sx={gridItemStyle}>{employee.surname}</Grid>
                                            <Grid item xs sx={gridItemStyle}>{employee.name}</Grid>
                                            <Grid item xs
                                                  sx={gridItemStyle}>{contractTypeToString(employee.contractType)}</Grid>
                                        </Grid>
                                    </Link>
                                </Grid>
                                <Grid item xs={1} sm={0.5}
                                      sx={{...gridItemStyle, paddingLeft: '0 !important', paddingTop: '0 !important'}}>
                                    <EmployeeContextMenu employee={employee} employeeId={employee.userId}
                                                         onUpdate={refetch}/>
                                </Grid>
                            </Grid>;
                        })
                    )}
                    {!employees &&
                        Array.from(Array(5).keys()).map((_, index) => {
                            return <Skeleton key={index} variant="rounded" width='100%' height={50}
                                             sx={{marginTop: '10px'}}/>;
                        })
                    }
                </CardContent>
            </Card>
        </div>
    );
};

export default Employees;
