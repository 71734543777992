import {DepartmentFragment} from "../../../../fragments/department.generated";
import {useDepartmentCalendarEventsLazyQuery} from "./departmentCalendarEvents.generated";
import {DateTime} from "luxon";
import {CalendarWidget} from "../../../../components/organisms/CalendarWidget";
import React, {useEffect} from "react";

interface DepartmentCalendarProps {
    department?: DepartmentFragment;
}

export const DepartmentCalendar = ({department}: DepartmentCalendarProps) => {
    const [calenderEventsQuery, {
        data: eventData,
        loading,
        refetch: refetchCalendarEventsQuery
    }] = useDepartmentCalendarEventsLazyQuery();

    const calendarEvents = eventData?.calendar.byDepartment ?? [];
    const employees = department?.employees ?? [];

    const onDateChanged = (startDate: DateTime, endDate: DateTime) => {
        if (!department) {
            return;
        }
        calenderEventsQuery({
            variables: {
                departmentId: department.departmentId,
                startDate: startDate.toFormat('yyyy-MM-dd'),
                endDate: endDate.toFormat('yyyy-MM-dd'),
            }
        });
    };

    useEffect(() => {
        if (department && !eventData && !loading) {
            calenderEventsQuery({
                variables: {
                    departmentId: department.departmentId,
                    startDate: DateTime.now().set({weekday: 1}).toFormat('yyyy-MM-dd'),
                    endDate: DateTime.now().set({weekday: 7}).toFormat('yyyy-MM-dd'),
                }
            });
        }
    }, [department, eventData, loading, calenderEventsQuery]);

    return <CalendarWidget calendarEvents={calendarEvents} employees={employees} onChangeDates={onDateChanged}
                           onEditSave={refetchCalendarEventsQuery}/>;
};
