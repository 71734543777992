import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {decodeToken} from 'react-jwt';
import {DateTime} from "luxon";
import Cookies from "js-cookie";

export interface AuthState {
    isLoggedIn: boolean;
    token: string | null;
    currentUser?: User;
}

export type StripeSubscription = 'standard' | 'professional' | 'expert';

type AccessTokenSubscription =
    { Trial: { startDate: string } }
    | { Active: { stripeSubscription: StripeSubscription } }
    | { Cancelled: {} };

export interface AccessToken {
    sub: number;
    name: string;
    surname: string;
    companyName: string;
    exp: number;
    subscriptionType: SubscriptionType;
    subscription: AccessTokenSubscription;
    userType: string;
    sessionType: SessionType;
    companyId: number;
    departmentId: number;
}

export type Subscription = {
    subscriptionCategory: 'trial';
    startDate: DateTime;
} | {
    subscriptionCategory: 'active';
    stripeSubscription: StripeSubscription;
} | {
    subscriptionCategory: 'cancelled';
};

export interface User {
    userId: number;
    companyId: number;
    departmentId: number;
    name: string;
    surname: string;
    companyName: string;
    subscription: Subscription;
    stripeSubscription: StripeSubscription;
    expiringAccessToken: number;
    userType: UserType;
    sessionType: SessionType;
}

export type SubscriptionType = 'initial' | 'standard' | 'professional' | 'expert';
export type UserType = 'employee' | 'admin';
export type SessionType = 'default' | 'imitation';

const initialState = (): AuthState => {
    const token = localStorage.getItem('token');
    if (token) {
        return stateFromToken(token);
    }
    return {
        isLoggedIn: false,
        token: token
    };
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        updateUser: (state, action: PayloadAction<User | undefined>) => {
            return {...state, currentUser: action.payload};
        },
        loginUser: (state, {payload}) => {
            localStorage.setItem('token', payload);
            Cookies.set('token', payload, { expires: 1 });
            return stateFromToken(payload);
        },
        logoutUser: (state) => {
            localStorage.removeItem('token');
            Cookies.remove('token');
            state.currentUser = undefined;
            state.isLoggedIn = false;
            state.token = null;
        }
    }
});

export const stateFromToken = (token: string): AuthState => {
    const decoded = decodeToken<AccessToken>(token);

    let user: AccessToken | undefined;
    if (decoded == null) {
        user = undefined;
    } else {
        user = {
            ...decoded,
            sub: Number(decoded.sub)
        };
    }

    let subscription: Subscription | undefined = undefined;
    if (user && user.subscription) {
        if ('Trial' in user.subscription) {
            subscription = {
                subscriptionCategory: 'trial',
                startDate: DateTime.fromISO(user.subscription.Trial.startDate)
            }
        } else if ('Active' in user.subscription) {
            subscription = {
                subscriptionCategory: 'active',
                stripeSubscription: user.subscription.Active.stripeSubscription
            }
        } else {
            subscription = {
                subscriptionCategory: 'cancelled'
            }
        }
    } else {
        subscription = {
            subscriptionCategory: 'trial',
            startDate: DateTime.now()
        }
    }

    if (user && subscription) {
        return {
            isLoggedIn: true,
            token: token,
            currentUser: user ? userFromAccessToken(user, subscription) : undefined
        };
    } else {
        return {
            isLoggedIn: false,
            token: token,
            currentUser: undefined
        };
    }
};

const userFromAccessToken = (accessToken: AccessToken, subscription: Subscription): User => ({
    userId: accessToken.sub,
    companyId: accessToken.companyId,
    departmentId: accessToken.departmentId,
    name: accessToken.name,
    surname: accessToken.surname,
    companyName: accessToken.companyName,
    subscription: subscription,
    stripeSubscription: stripeSubscriptionFromSubscription(subscription),
    expiringAccessToken: accessToken.exp,
    userType: accessToken.userType as UserType,
    sessionType: accessToken.sessionType as SessionType
});

const stripeSubscriptionFromSubscription = (subscription: Subscription): StripeSubscription => {
    switch (subscription.subscriptionCategory) {
        case "active":
            return subscription.stripeSubscription;
        case "trial":
            return 'expert';
        case "cancelled":
            return 'standard';
    }
};

export const {loginUser, logoutUser, updateUser} = authSlice.actions;

export default authSlice.reducer;
