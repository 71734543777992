import * as Types from './generated/graphql';

import { CompanySettingFragment } from './fragments/companySetting.generated';
import { gql } from '@apollo/client';
import { CompanySettingFragmentDoc } from './fragments/companySetting.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateAccessTokenMutationVariables = Types.Exact<{
  refreshToken: Types.Scalars['String'];
}>;


export type CreateAccessTokenMutation = { __typename?: 'Mutation', employee: { __typename?: 'employeeMutations', createAccessToken: { __typename?: 'LoginInformation', accessToken: string, refreshToken: string, companySetting: (
        { __typename?: 'CompanySetting' }
        & CompanySettingFragment
      ) } } };


export const CreateAccessTokenDocument = gql`
    mutation CreateAccessToken($refreshToken: String!) {
  employee {
    createAccessToken(refreshToken: $refreshToken) {
      accessToken
      refreshToken
      companySetting {
        ...CompanySetting
      }
    }
  }
}
    ${CompanySettingFragmentDoc}`;
export type CreateAccessTokenMutationFn = Apollo.MutationFunction<CreateAccessTokenMutation, CreateAccessTokenMutationVariables>;

/**
 * __useCreateAccessTokenMutation__
 *
 * To run a mutation, you first call `useCreateAccessTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccessTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccessTokenMutation, { data, loading, error }] = useCreateAccessTokenMutation({
 *   variables: {
 *      refreshToken: // value for 'refreshToken'
 *   },
 * });
 */
export function useCreateAccessTokenMutation(baseOptions?: Apollo.MutationHookOptions<CreateAccessTokenMutation, CreateAccessTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAccessTokenMutation, CreateAccessTokenMutationVariables>(CreateAccessTokenDocument, options);
      }
export type CreateAccessTokenMutationHookResult = ReturnType<typeof useCreateAccessTokenMutation>;
export type CreateAccessTokenMutationResult = Apollo.MutationResult<CreateAccessTokenMutation>;
export type CreateAccessTokenMutationOptions = Apollo.BaseMutationOptions<CreateAccessTokenMutation, CreateAccessTokenMutationVariables>;