import React from "react";
import {IconButton, Menu, MenuItem} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {AccountStatus} from "../../generated/graphql";
import {toast} from "react-toastify";
import {useUpdateEmployeeAccountStatusMutation} from "../../pages/employees/updateEmployeeAccountStatus.generated";
import {useNavigate} from "react-router-dom";
import {EmployeeFragment} from "../../fragments/employee.generated";

export interface EmployeeContextMenuProps {
    employee?: EmployeeFragment;
    /**
     * The id of the employee we open the context menu for.
     */
    employeeId: number;
    /**
     * The method called when the user gets archived / deleted.
     */
    onUpdate: () => void;
}

const EmployeeContextMenu = ({employee, employeeId, onUpdate}: EmployeeContextMenuProps) => {
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const [updateEmployeeAccountStatusMutation] =
        useUpdateEmployeeAccountStatusMutation();

    const updateEmployeeAccountStatus = async (
        employeeId: number,
        accountStatus: AccountStatus
    ) => {
        const {data, errors} = await updateEmployeeAccountStatusMutation({
            variables: {employeeId, accountStatus}
        });

        if (errors) {
            const error = errors[0];
            const reason = error.extensions?.['reason'];

            if (reason === 'AuthenticationRequired') {
                toast.error('Melde dich erneut an und versuche es nochmal.');
            } else if (reason === 'AdminUserNotFound') {
                toast.error('Ein Fehler mit deinem Nutzer ist aufgetreten');
            } else if (reason === 'UserNotFound') {
                toast.error('Der Nutzer konnte nicht gefunden werden');
            } else if (reason === 'PermissionDenied') {
                toast.error(
                    `Du kannst diesen Nutzer nicht ${
                        accountStatus === 'deleted' ? 'löschen' : 'archivieren'
                    }`
                );
            } else if (reason === 'MissingPaymentData') {
                toast.error('Dein Unternehmen hat noch kein Zahlungsmittel hinterlegt');
            } else if (reason === 'NoUpdateForOwnAccount') {
                toast.error('Du kannst deinen eigenen Account nicht löschen oder archivieren');
            } else if (reason === 'NoActivationForNotVerifiedUser') {
                toast.error('Der Mitarbeiter muss sich selbst verifizieren und wird dann automatisch aktiviert');
            } else {
                toast.error('Ein unerwarteter Fehler ist aufgetreten');
            }
        } else if (data) {
            onUpdate();
            toast.success(`Mitarbeiter wurde erfolgreich aktualisiert`);
        } else {
            toast.error('Ein unbekannter Fehler ist aufgetreten');
        }
    };

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                sx={{paddingLeft: '2px', paddingRight: '2px', color: '#212529'}}
            >
                <MoreVertIcon/>
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={() => {
                    handleClose();
                    navigate(`/employee/${employeeId}`)
                }}>Bearbeiten</MenuItem>
                {employee?.accountStatus === 'archived' && (
                    <MenuItem onClick={() => {
                        handleClose();
                        updateEmployeeAccountStatus(employeeId, 'active')
                    }}>Aktivieren</MenuItem>
                )}
                {employee?.accountStatus !== 'archived' && (
                    <MenuItem onClick={() => {
                        handleClose();
                        updateEmployeeAccountStatus(employeeId, 'archived')
                    }}>Archivieren</MenuItem>
                )}
                {employee?.accountStatus !== 'deleted' && (
                    <MenuItem onClick={() => {
                        handleClose();
                        updateEmployeeAccountStatus(employeeId, 'deleted')
                    }}>Löschen</MenuItem>
                )}
            </Menu>
        </>
    );
};

export default EmployeeContextMenu;
