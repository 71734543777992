import {Box, Typography} from '@mui/material';
import React, {Children} from 'react';
import {StripeSubscription, updateUser} from '../../store/reducers/auth';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../store/store';
import {useUpdateSubscriptionMutation} from './updateSubscription.generated';
import {LoadingButton} from '@mui/lab';
import {toast} from 'react-toastify';
import {useCreateAccessTokenMutation} from '../../createAccessToken.generated';
import {ProductAdvantage} from "../molecules/ProductAdvantage";
import Cookies from "js-cookie";

export interface MTTProductProps {
    readonly stripeSubscription: StripeSubscription;
    readonly price: string;
    readonly name: string;
    readonly selected?: boolean;
    readonly buttonText?: string;
    readonly isFirstSelect?: boolean;
    readonly onSelect?: () => void;
    readonly loading?: boolean;
}

export const MTTProduct: React.FC<React.PropsWithChildren<MTTProductProps>> = ({
                                                                                   stripeSubscription,
                                                                                   price,
                                                                                   name,
                                                                                   selected,
                                                                                   isFirstSelect,
                                                                                   children,
                                                                                   onSelect,
                                                                                   loading
                                                                               }) => {
    const dispatch = useDispatch();

    const externalLoading = loading ?? false;

    const user = useSelector((state: RootState) => state.auth.currentUser);

    const [updateSubscriptionMutation, {loading: mutationLoading}] =
        useUpdateSubscriptionMutation();
    const [refreshTokenMutation] = useCreateAccessTokenMutation();

    const handleSelection = async () => {
        if (onSelect) {
            onSelect();
        } else {
            const {data, errors} = await updateSubscriptionMutation({
                variables: {
                    stripeSubscription: stripeSubscription
                }
            });

            if (!errors && data?.payment.updateSubscription === null) {
                const {data, errors} = await refreshTokenMutation({
                    variables: {
                        refreshToken: localStorage.getItem('refreshToken')!
                    }
                });

                if (!errors && data) {
                    dispatch(
                        updateUser(
                            user ? {
                                ...user, subscription: {
                                    subscriptionCategory: 'active',
                                    stripeSubscription: stripeSubscription
                                }
                            } : undefined
                        )
                    );

                    const information = data.employee.createAccessToken;

                    localStorage.setItem('token', information.accessToken);
                    localStorage.setItem('refreshToken', information.refreshToken);
                    Cookies.set('token', information.accessToken, { expires: 1 });

                    if (isFirstSelect) {
                        toast.success('Abonnement wurde erfolgreich ausgewählt');
                    } else {
                        toast.success('Abonnment erfolgreich geändert');
                    }
                } else {
                    toast.error('Ein unbekannter Fehler ist aufgetreten');
                }
            } else {
                toast.error('Ein unbekannter Fehler ist aufgetreten');
            }
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexDirection: 'column',
                padding: '0.5rem 2rem',
                marginTop: '1rem',
                height: '100%',
                textAlign: 'center',
                backgroundColor: '#F8FAFE'
            }}
        >
            <Box sx={{mt: 2}}>
                <Typography variant='h5' sx={{marginBottom: '1rem'}}>{name}</Typography>
                <Typography variant='h6' sx={{marginBottom: '1rem'}}>
                    {price}€ pro Nutzer und Monat
                </Typography>
                <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'start', textAlign: 'left'}}>
                    {Children.map(Children.toArray(children), (child) => {
                        return <ProductAdvantage>
                            {child}
                        </ProductAdvantage>;
                    })}
                </Box>
            </Box>

            <LoadingButton
                variant="contained"
                color="primary"
                sx={{marginTop: '2rem', marginBottom: '1rem'}}
                onClick={handleSelection}
                loading={onSelect ? externalLoading : mutationLoading}
                disabled={selected ?? false}
            >
                {selected
                    ? 'Ausgewählt'
                    : (isFirstSelect
                        ? 'Abonnement auswählen'
                        : 'Abonnement ändern')}
            </LoadingButton>
        </Box>
    );
};
