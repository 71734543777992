import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IsEmployeeForgotPasswordTokenValidQueryVariables = Types.Exact<{
  token: Types.Scalars['String'];
}>;


export type IsEmployeeForgotPasswordTokenValidQuery = { __typename?: 'Query', token: { __typename?: 'tokenQueries', isEmployeeForgotPasswordTokenValid: boolean } };


export const IsEmployeeForgotPasswordTokenValidDocument = gql`
    query IsEmployeeForgotPasswordTokenValid($token: String!) {
  token {
    isEmployeeForgotPasswordTokenValid(token: $token)
  }
}
    `;

/**
 * __useIsEmployeeForgotPasswordTokenValidQuery__
 *
 * To run a query within a React component, call `useIsEmployeeForgotPasswordTokenValidQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsEmployeeForgotPasswordTokenValidQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsEmployeeForgotPasswordTokenValidQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useIsEmployeeForgotPasswordTokenValidQuery(baseOptions: Apollo.QueryHookOptions<IsEmployeeForgotPasswordTokenValidQuery, IsEmployeeForgotPasswordTokenValidQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsEmployeeForgotPasswordTokenValidQuery, IsEmployeeForgotPasswordTokenValidQueryVariables>(IsEmployeeForgotPasswordTokenValidDocument, options);
      }
export function useIsEmployeeForgotPasswordTokenValidLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsEmployeeForgotPasswordTokenValidQuery, IsEmployeeForgotPasswordTokenValidQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsEmployeeForgotPasswordTokenValidQuery, IsEmployeeForgotPasswordTokenValidQueryVariables>(IsEmployeeForgotPasswordTokenValidDocument, options);
        }
export type IsEmployeeForgotPasswordTokenValidQueryHookResult = ReturnType<typeof useIsEmployeeForgotPasswordTokenValidQuery>;
export type IsEmployeeForgotPasswordTokenValidLazyQueryHookResult = ReturnType<typeof useIsEmployeeForgotPasswordTokenValidLazyQuery>;
export type IsEmployeeForgotPasswordTokenValidQueryResult = Apollo.QueryResult<IsEmployeeForgotPasswordTokenValidQuery, IsEmployeeForgotPasswordTokenValidQueryVariables>;