import * as Types from '../generated/graphql';

import { WorkingTimeByDayFragment } from './workingTimeByDay.generated';
import { AbsenceRowFragment } from './absenceRow.generated';
import { gql } from '@apollo/client';
import { WorkingTimeByDayFragmentDoc } from './workingTimeByDay.generated';
import { AbsenceRowFragmentDoc } from './absenceRow.generated';
export type TimeUnion_AbsenceRow_Fragment = (
  { __typename?: 'AbsenceRow' }
  & AbsenceRowFragment
);

export type TimeUnion_WorkingTimeByDay_Fragment = (
  { __typename?: 'WorkingTimeByDay' }
  & WorkingTimeByDayFragment
);

export type TimeUnionFragment = TimeUnion_AbsenceRow_Fragment | TimeUnion_WorkingTimeByDay_Fragment;

export const TimeUnionFragmentDoc = gql`
    fragment TimeUnion on TimeUnion {
  ... on WorkingTimeByDay {
    ...WorkingTimeByDay
  }
  ... on AbsenceRow {
    ...AbsenceRow
  }
}
    ${WorkingTimeByDayFragmentDoc}
${AbsenceRowFragmentDoc}`;