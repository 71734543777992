import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteDayMutationVariables = Types.Exact<{
  employeeId: Types.Scalars['Int'];
  date: Types.Scalars['DateType'];
}>;


export type DeleteDayMutation = { __typename?: 'Mutation', tracking: { __typename?: 'trackingMutations', deleteTrackingForDay: any } };


export const DeleteDayDocument = gql`
    mutation DeleteDay($employeeId: Int!, $date: DateType!) {
  tracking {
    deleteTrackingForDay(employeeId: $employeeId, date: $date)
  }
}
    `;
export type DeleteDayMutationFn = Apollo.MutationFunction<DeleteDayMutation, DeleteDayMutationVariables>;

/**
 * __useDeleteDayMutation__
 *
 * To run a mutation, you first call `useDeleteDayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDayMutation, { data, loading, error }] = useDeleteDayMutation({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useDeleteDayMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDayMutation, DeleteDayMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDayMutation, DeleteDayMutationVariables>(DeleteDayDocument, options);
      }
export type DeleteDayMutationHookResult = ReturnType<typeof useDeleteDayMutation>;
export type DeleteDayMutationResult = Apollo.MutationResult<DeleteDayMutation>;
export type DeleteDayMutationOptions = Apollo.BaseMutationOptions<DeleteDayMutation, DeleteDayMutationVariables>;