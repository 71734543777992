import {useFormik} from "formik";
import * as Yup from "yup";
import {Box, Button, DialogActions, FormHelperText, Grid, MenuItem, Select, TextField} from "@mui/material";
import {EmployeeInformation} from "./EmployeeInformation";
import React from "react";
import LoadingButton from "@mui/lab/LoadingButton";

export interface WorkPlaceFormValues {
    name: string;
    country?: string;
    state?: string;
    city?: string;
    address?: string;
    postalCode?: string;
}

interface WorkPlaceFormProps {
    initialValues?: WorkPlaceFormValues;
    onSubmit: (values: WorkPlaceFormValues) => void;
    onAbort: () => void;
    saveButtonLoading?: boolean;
}


interface Country {
    readonly name: string;
    readonly states?: string[];
}

const countries: Country[] = [
    {name: 'Belgien'},
    {name: 'Bulgarien'},
    {name: 'Dänemark'},
    {
        name: 'Deutschland',
        states: [
            'Bayern',
            'Baden-Württemberg',
            'Berlin',
            'Bremen',
            'Brandenburg',
            'Sachsen',
            'Mecklenburg-Vorpommern',
            'Niedersachsen',
            'Thüringen',
            'Schleswig-Holstein',
            'Saarland',
            'Nordrhein-Westfalen',
            'Hamburg',
            'Sachsen-Anhalt',
            'Hessen',
            'Rheinland-Pfalz'
        ]
    },
    {name: 'Estland'},
    {name: 'Finnland'},
    {name: 'Frankreich'},
    {name: 'Griechenland'},
    {name: 'Niederlande'},
    {name: 'Italien'},
    {name: 'Irland'},
    {name: 'Kroatien'},
    {name: 'Lettland'},
    {name: 'Litauen'},
    {name: 'Luxemburg'},
    {name: 'Malta'},
    {
        name: 'Österreich',
        states: [
            'Vorarlberg',
            'Tirol',
            'Burgenland',
            'Wien',
            'Niederösterreich',
            'Oberösterreich',
            'Salzburg',
            'Kärnten',
            'Steiermark'
        ]
    },
    {name: 'Polen'},
    {name: 'Portugal'},
    {name: 'Rumänien'},
    {name: 'Schweden'},
    {name: 'Slowakei'},
    {name: 'Slowenien'},
    {name: 'Spanien'},
    {name: 'Tschechien'},
    {name: 'Ungarn'},
    {name: 'Zypern'},
    {
        name: 'Schweiz',
        states: [
            'Aargau',
            'Appenzell Ausserrhoden',
            'Appenzell Innerrhoden',
            'Basel-Landschaft',
            'Basel-Stadt',
            'Glarus',
            'Luzern',
            'Nidwalden',
            'Obwalden',
            'Schaffhausen',
            'Schwyz',
            'Solothurn',
            'St. Gallen',
            'Thurgau',
            'Uri',
            'Zug',
            'Zürich',
            'Genf',
            'Jura',
            'Neuenburg',
            'Waadt',
            'Bern',
            'Freiburg',
            'Wallis',
        ]
    },
];

export const WorkPlaceForm = ({initialValues, onSubmit, onAbort, saveButtonLoading}: WorkPlaceFormProps) => {

    const {handleChange, values, handleSubmit, handleBlur, touched, errors} = useFormik<WorkPlaceFormValues>({
        initialValues: {
            name: initialValues?.name ?? '',
            country: initialValues?.country ?? '',
            state: initialValues?.state ?? '',
            city: initialValues?.city ?? '',
            address: initialValues?.address ?? '',
            postalCode: initialValues?.postalCode ?? ''
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Gib den Namen des Standorts an'),
        }),
        onSubmit: onSubmit
    });

    const selectedCountry: Country | undefined = countries.find(country => country.name === values.country);

    return <Box component='form' onSubmit={handleSubmit}>
        <Grid container spacing={2}>
            <EmployeeInformation label='Name des Standorts' xs={12} md={12}>
                <TextField id="name"
                           name='name'
                           placeholder="Name"
                           type="text"
                           value={values.name}
                           error={!!(errors.name && touched.name)}
                           onChange={handleChange}
                           onBlur={handleBlur}
                           variant='outlined'
                           fullWidth
                           size='small'
                />
                {errors.name && (
                    <FormHelperText error={!!(errors.name && touched.name)}>
                        {errors.name}
                    </FormHelperText>
                )}
            </EmployeeInformation>
            <EmployeeInformation label='Land (optional)' xs={12} md={12}>
                <Select
                    id='country'
                    name='country'
                    labelId="country-label"
                    size='small'
                    placeholder='Land'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.country}
                    error={!!(errors.country && touched.country)}
                    fullWidth
                    displayEmpty
                >
                    <MenuItem value="" disabled>Land</MenuItem>
                    {countries.map((country, index) => (
                        <MenuItem key={index} value={country.name}>{country.name}</MenuItem>))}
                </Select>
                {errors.country && (
                    <FormHelperText error={!!(errors.country && touched.country)}>
                        {errors.country}
                    </FormHelperText>
                )}
            </EmployeeInformation>
            {selectedCountry?.states &&
                <EmployeeInformation label='Bundesland (optional)' xs={12} md={12}>
                    <Select
                        id='state'
                        name='state'
                        labelId="state-label"
                        size='small'
                        placeholder='Bundesland'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.state}
                        error={!!(errors.state && touched.state)}
                        fullWidth
                        displayEmpty
                    >
                        <MenuItem value="" disabled>Bundesland</MenuItem>
                        {selectedCountry.states?.map((state, index) => (
                            <MenuItem key={index} value={state}>{state}</MenuItem>))}
                    </Select>
                    {errors.state && (
                        <FormHelperText error={!!(errors.state && touched.state)}>
                            {errors.state}
                        </FormHelperText>
                    )}
                </EmployeeInformation>
            }
            <EmployeeInformation label='Postleitzahl (optional)' xs={12} md={12}>
                <TextField id="postalCode"
                           name='postalCode'
                           placeholder="Postleitzahl"
                           type="text"
                           value={values.postalCode}
                           error={!!(errors.postalCode && touched.postalCode)}
                           onChange={handleChange}
                           onBlur={handleBlur}
                           variant='outlined'
                           fullWidth
                           size='small'
                />
                {errors.postalCode && (
                    <FormHelperText error={!!(errors.postalCode && touched.postalCode)}>
                        {errors.postalCode}
                    </FormHelperText>
                )}
            </EmployeeInformation>
            <EmployeeInformation label='Straße und Hausnummer (optional)' xs={12} md={12}>
                <TextField id="address"
                           name='address'
                           placeholder="Adresse"
                           type="text"
                           value={values.address}
                           error={!!(errors.address && touched.address)}
                           onChange={handleChange}
                           onBlur={handleBlur}
                           variant='outlined'
                           fullWidth
                           size='small'
                />
                {errors.address && (
                    <FormHelperText error={!!(errors.address && touched.address)}>
                        {errors.address}
                    </FormHelperText>
                )}
            </EmployeeInformation>
        </Grid>
        <Box sx={{display: 'flex', justifyContent: 'end'}}>
            <DialogActions sx={{gap: 2, mt: 2}}>
                <Button variant='contained' color='inherit' type='button' onClick={onAbort}>ABBRECHEN</Button>
                <LoadingButton loading={saveButtonLoading} variant='contained' type='submit'>SPEICHERN</LoadingButton>
            </DialogActions>
        </Box>
    </Box>;
};
