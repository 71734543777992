import * as Types from '../generated/graphql';

import { TrackingRowFragment } from '../fragments/trackingRow.generated';
import { AbsenceRowFragment } from '../fragments/absenceRow.generated';
import { EmployeeFragment } from '../fragments/employee.generated';
import { NewTrackingRowFragment } from '../fragments/newTrackingRow.generated';
import { gql } from '@apollo/client';
import { TrackingRowFragmentDoc } from '../fragments/trackingRow.generated';
import { AbsenceRowFragmentDoc } from '../fragments/absenceRow.generated';
import { EmployeeFragmentDoc } from '../fragments/employee.generated';
import { NewTrackingRowFragmentDoc } from '../fragments/newTrackingRow.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TrackingChangelogQueryVariables = Types.Exact<{
  date: Types.Scalars['DateType'];
  employeeId: Types.Scalars['Int'];
}>;


export type TrackingChangelogQuery = { __typename?: 'Query', tracking: { __typename?: 'trackingQueries', trackingChangelogEntries: Array<(
      { __typename?: 'TrackingChangelogEntry' }
      & TrackingChangelogEntryFragment
    )>, trackingRowsByDate: Array<(
      { __typename?: 'TrackingRow' }
      & TrackingRowFragment
    )> }, absence: { __typename?: 'absenceQueries', absencesByDate: Array<(
      { __typename?: 'AbsenceRow' }
      & AbsenceRowFragment
    )> }, permission: { __typename?: 'permissionQueries', trackingPermission: { __typename?: 'trackingPermissionQueries', canEditOrDelete: boolean, canStampOverNight: boolean } } };

export type TrackingChangelogEntryFragment = { __typename?: 'TrackingChangelogEntry', date: any, userId: number, createdAt: any, change: (
    { __typename?: 'AutomaticBreakCorrection' }
    & TrackingChange_AutomaticBreakCorrection_Fragment
  ) | (
    { __typename?: 'AutomaticStampOut' }
    & TrackingChange_AutomaticStampOut_Fragment
  ) | (
    { __typename?: 'EditTracking' }
    & TrackingChange_EditTracking_Fragment
  ) | (
    { __typename?: 'ManualInsert' }
    & TrackingChange_ManualInsert_Fragment
  ) | (
    { __typename?: 'StartTracking' }
    & TrackingChange_StartTracking_Fragment
  ) | (
    { __typename?: 'StopTracking' }
    & TrackingChange_StopTracking_Fragment
  ), trackingEditor: (
    { __typename?: 'TrackingEditorAdmin' }
    & TrackingEditor_TrackingEditorAdmin_Fragment
  ) | (
    { __typename?: 'TrackingEditorAutomatic' }
    & TrackingEditor_TrackingEditorAutomatic_Fragment
  ) | (
    { __typename?: 'TrackingEditorUser' }
    & TrackingEditor_TrackingEditorUser_Fragment
  ) };

export type TrackingEditor_TrackingEditorAdmin_Fragment = { __typename: 'TrackingEditorAdmin', user: (
    { __typename?: 'Employee' }
    & EmployeeFragment
  ) };

export type TrackingEditor_TrackingEditorAutomatic_Fragment = { __typename: 'TrackingEditorAutomatic' };

export type TrackingEditor_TrackingEditorUser_Fragment = { __typename: 'TrackingEditorUser', user: (
    { __typename?: 'Employee' }
    & EmployeeFragment
  ) };

export type TrackingEditorFragment = TrackingEditor_TrackingEditorAdmin_Fragment | TrackingEditor_TrackingEditorAutomatic_Fragment | TrackingEditor_TrackingEditorUser_Fragment;

export type TrackingChange_AutomaticBreakCorrection_Fragment = { __typename?: 'AutomaticBreakCorrection', trackingChangeType: string, newRows: Array<(
    { __typename?: 'TrackingRow' }
    & TrackingRowFragment
  )>, oldRows: Array<(
    { __typename?: 'TrackingRow' }
    & TrackingRowFragment
  )> };

export type TrackingChange_AutomaticStampOut_Fragment = { __typename: 'AutomaticStampOut', trackingChangeType: string };

export type TrackingChange_EditTracking_Fragment = { __typename?: 'EditTracking', trackingChangeType: string, newRows: Array<(
    { __typename?: 'NewTrackingRowOutput' }
    & NewTrackingRowFragment
  )>, oldRows: Array<(
    { __typename?: 'TrackingRow' }
    & TrackingRowFragment
  )> };

export type TrackingChange_ManualInsert_Fragment = { __typename?: 'ManualInsert', trackingChangeType: string, rows: Array<(
    { __typename?: 'NewTrackingRowOutput' }
    & NewTrackingRowFragment
  )> };

export type TrackingChange_StartTracking_Fragment = { __typename?: 'StartTracking', startTime: any, trackingChangeType: string };

export type TrackingChange_StopTracking_Fragment = { __typename?: 'StopTracking', stopTime: any, trackingChangeType: string };

export type TrackingChangeFragment = TrackingChange_AutomaticBreakCorrection_Fragment | TrackingChange_AutomaticStampOut_Fragment | TrackingChange_EditTracking_Fragment | TrackingChange_ManualInsert_Fragment | TrackingChange_StartTracking_Fragment | TrackingChange_StopTracking_Fragment;

export const TrackingChangeFragmentDoc = gql`
    fragment TrackingChange on TrackingChangeInterface {
  trackingChangeType
  ... on StartTracking {
    startTime
  }
  ... on StopTracking {
    stopTime
  }
  ... on ManualInsert {
    rows {
      ...NewTrackingRow
    }
  }
  ... on EditTracking {
    newRows {
      ...NewTrackingRow
    }
    oldRows {
      ...TrackingRow
    }
  }
  ... on AutomaticBreakCorrection {
    newRows {
      ...TrackingRow
    }
    oldRows {
      ...TrackingRow
    }
  }
  ... on AutomaticStampOut {
    __typename
  }
}
    ${NewTrackingRowFragmentDoc}
${TrackingRowFragmentDoc}`;
export const TrackingEditorFragmentDoc = gql`
    fragment TrackingEditor on TrackingEditor {
  __typename
  ... on TrackingEditorAdmin {
    user {
      ...Employee
    }
  }
  ... on TrackingEditorUser {
    user {
      ...Employee
    }
  }
}
    ${EmployeeFragmentDoc}`;
export const TrackingChangelogEntryFragmentDoc = gql`
    fragment TrackingChangelogEntry on TrackingChangelogEntry {
  date
  userId
  change {
    ...TrackingChange
  }
  trackingEditor {
    ...TrackingEditor
  }
  createdAt
}
    ${TrackingChangeFragmentDoc}
${TrackingEditorFragmentDoc}`;
export const TrackingChangelogDocument = gql`
    query TrackingChangelog($date: DateType!, $employeeId: Int!) {
  tracking {
    trackingChangelogEntries(date: $date, employeeId: $employeeId) {
      ...TrackingChangelogEntry
    }
    trackingRowsByDate(date: $date, employeeId: $employeeId) {
      ...TrackingRow
    }
  }
  absence {
    absencesByDate(date: $date, employeeId: $employeeId) {
      ...AbsenceRow
    }
  }
  permission {
    trackingPermission {
      canEditOrDelete(userId: $employeeId, date: $date)
      canStampOverNight(userId: $employeeId)
    }
  }
}
    ${TrackingChangelogEntryFragmentDoc}
${TrackingRowFragmentDoc}
${AbsenceRowFragmentDoc}`;

/**
 * __useTrackingChangelogQuery__
 *
 * To run a query within a React component, call `useTrackingChangelogQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrackingChangelogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrackingChangelogQuery({
 *   variables: {
 *      date: // value for 'date'
 *      employeeId: // value for 'employeeId'
 *   },
 * });
 */
export function useTrackingChangelogQuery(baseOptions: Apollo.QueryHookOptions<TrackingChangelogQuery, TrackingChangelogQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TrackingChangelogQuery, TrackingChangelogQueryVariables>(TrackingChangelogDocument, options);
      }
export function useTrackingChangelogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TrackingChangelogQuery, TrackingChangelogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TrackingChangelogQuery, TrackingChangelogQueryVariables>(TrackingChangelogDocument, options);
        }
export type TrackingChangelogQueryHookResult = ReturnType<typeof useTrackingChangelogQuery>;
export type TrackingChangelogLazyQueryHookResult = ReturnType<typeof useTrackingChangelogLazyQuery>;
export type TrackingChangelogQueryResult = Apollo.QueryResult<TrackingChangelogQuery, TrackingChangelogQueryVariables>;