import {InheritableTrackingSettings} from "../../../../components/settings/InheritableTrackingSettings";
import {EmployeeFragment} from "../../../../fragments/employee.generated";
import {useEmployeeSettingsLazyQuery} from "./employeeSettings.generated";
import React, {useEffect} from "react";
import {hasAtLeastSubscription} from "../../../../utils/subscription";
import {UpgradeSubscriptionScreen} from "../../../../components/organisms/UpgradeSubscriptionScreen";
import {useSelector} from "react-redux";
import {RootState} from "../../../../store/store";

export const EmployeeSettings = ({employee}: { employee?: EmployeeFragment }) => {
    const user = useSelector((state: RootState) => state.auth.currentUser);

    const [futureEmployeeSettingsQuery, {
        data,
        loading,
        error
    }] = useEmployeeSettingsLazyQuery({fetchPolicy: 'no-cache'});

    useEffect(() => {
        if (employee) {
            futureEmployeeSettingsQuery({
                variables: {
                    userId: employee.userId
                },
                fetchPolicy: 'no-cache'
            });
        }
    }, [employee, futureEmployeeSettingsQuery]);


    if (!hasAtLeastSubscription(user, 'professional')) {
        return <UpgradeSubscriptionScreen/>;
    }

    return <InheritableTrackingSettings entity={{entityType: 'employee', entityId: employee?.userId ?? 0}}
                                        settings={data?.setting.settingsForUser.inheritableSettings} loading={loading}
                                        error={error}/>;
};
