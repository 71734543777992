import {Box, Grid, Skeleton, Typography} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import React, {useState} from "react";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import {useCreateAccessTokenMutation} from "../../../../createAccessToken.generated";
import {RootState} from "../../../../store/store";
import {useCancelSubscriptionMutation} from "../../cancelSubscription.generated";
import {useUpdateSubscriptionMutation} from "../../../../components/payment/updateSubscription.generated";
import {StripeSubscription} from "../../../../generated/graphql";
import {loginUser} from "../../../../store/reducers/auth";
import {MTTProductStarter} from "../../../../components/payment/MTTProductStarter";
import {SelectSubscriptionForm} from "../../../SelectSubscription/SelectSubscriptionPage";
import {MTTProductProfessional} from "../../../../components/payment/MTTProductProfessional";
import {MTTProductExpert} from "../../../../components/payment/MTTProductExpert";
import {usePaymentInformationQuery} from "./paymentInformation.generated";
import {DateTime} from "luxon";
import {isSubscriptionTrialActive, remainingTrialDays} from "../../../../utils/trial";
import Cookies from "js-cookie";

const SubscriptionPage = () => {
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.auth.currentUser);
    const subscription = user?.subscription;
    const isTrialActive = subscription?.subscriptionCategory === 'trial' && isSubscriptionTrialActive(user);
    const remainingDays = remainingTrialDays(subscription);

    const {data: paymentInformationData, loading: paymentInformationLoading, refetch: refetchPaymentInformation} = usePaymentInformationQuery();
    const paymentInformation = paymentInformationData?.payment.paymentInformation;

    const [cancelSubscriptionMutation, {loading: cancelSubscriptionLoading}] =
        useCancelSubscriptionMutation();
    const [updateSubscriptionMutation, {loading: updateSubscriptionLoading}] =
        useUpdateSubscriptionMutation();
    const [refreshTokenMutation] = useCreateAccessTokenMutation();

    const [selectedSubscription, setSelectedSubscription] = useState<StripeSubscription | undefined>(undefined);

    const [activeStep, setActiveStep] = useState(0);

    const selectSubscription = async (stripeSubscription: StripeSubscription) => {
        setSelectedSubscription(stripeSubscription);
        const {data, errors} = await updateSubscriptionMutation({
            variables: {
                stripeSubscription: stripeSubscription
            }
        });

        if (!errors && data?.payment.updateSubscription === null) {
            const {data, errors} = await refreshTokenMutation({
                variables: {
                    refreshToken: localStorage.getItem('refreshToken')!
                }
            });

            if (!errors && data) {
                const information = data.employee.createAccessToken;

                localStorage.setItem('token', information.accessToken);
                localStorage.setItem('refreshToken', information.refreshToken);
                Cookies.set('token', information.accessToken, { expires: 1 });

                dispatch(loginUser(information.accessToken));

                toast.success('Abonnment erfolgreich geändert');
            } else {
                toast.error('Ein unbekannter Fehler ist aufgetreten');
            }
        } else {
            toast.error('Ein unbekannter Fehler ist aufgetreten');
        }
    };

    const cancelSubscription = async () => {
        const {data, errors} = await cancelSubscriptionMutation();

        if (!errors && data?.payment.cancelSubscription === null) {
            refetchPaymentInformation();
            if (isTrialActive) {
                toast.success('Abonnement wurde gekündigt');
            } else if (paymentInformation) {
                toast.success(`Dein Abonnement wird zum ${DateTime.fromISO(paymentInformation.currentPeriodEnd).toFormat('dd.MM.yyyy')} gekündigt`);
            } else {
                toast.success('Dein Abonnement wird zum nächstmöglichen Zeitpunkt gekündigt');
            }
        } else {
            toast.error(
                'Abonnement konnte nicht gekündigt werden. Bitte wende dich an unseren Support'
            );
        }
    };

    return <>
        {user?.subscription.subscriptionCategory === 'trial' && <SelectSubscriptionForm onActiveStepChanged={setActiveStep}/>}
        {user?.subscription.subscriptionCategory !== 'trial' &&
            <>
                <Grid container spacing={2}>
                    <Grid item md={4}>
                        <MTTProductStarter
                            selected={user?.stripeSubscription === 'standard'}
                            loading={selectedSubscription === 'standard' && updateSubscriptionLoading}
                            onSelect={() => selectSubscription('standard')}
                        />
                    </Grid>
                    <Grid item md={4}>
                        <MTTProductProfessional
                            selected={user?.stripeSubscription === 'professional'}
                            loading={selectedSubscription === 'professional' && updateSubscriptionLoading}
                            onSelect={() => selectSubscription('professional')}
                        />
                    </Grid>
                    <Grid item md={4}>
                        <MTTProductExpert
                            selected={user?.stripeSubscription === 'expert'}
                            loading={selectedSubscription === 'expert' && updateSubscriptionLoading}
                            onSelect={() => selectSubscription('expert')}
                        />
                    </Grid>
                </Grid>
                <Typography sx={{mt: 3}} fontSize='small'>Alle Preise verstehen sich exkl. Umsatzsteuer</Typography>
            </>
        }
        {activeStep === 0 &&
            <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 5}}>
                {isTrialActive &&
                    <Typography>Ihre Testphase endet in <b>{remainingDays} Tagen</b></Typography>
                }
                {!isTrialActive &&
                    <>
                        {paymentInformationLoading && <Skeleton width='230px'/>}
                        {!paymentInformationLoading && paymentInformation &&
                            <Typography>
                                {paymentInformation?.canceledAt && `Gekündigt zum: ${DateTime.fromISO(paymentInformation.canceledAt).toFormat('dd.MM.yyyy')}`}
                                {!paymentInformation?.canceledAt && `Nächste Rechnung: ${DateTime.fromISO(paymentInformation.currentPeriodEnd).toFormat('dd.MM.yyyy')}`}
                            </Typography>
                        }
                    </>
                }
                <LoadingButton
                    loading={cancelSubscriptionLoading}
                    onClick={cancelSubscription}
                    variant="contained"
                    color="error"
                >
                    Jetzt kündigen
                </LoadingButton>
            </Box>
        }
    </>;
};

export default SubscriptionPage;
