import {EntityInformation, SettingUpdateResponse} from "../../types";
import {TrackingClearanceType} from "../../../../generated/graphql";
import React, {useEffect} from "react";
import {Box, Checkbox, FormControlLabel, MenuItem, Select, Stack, Typography} from "@mui/material";
import {TrackingClearanceFragment} from "../../../../fragments/inheritableSettings.generated";
import {useUpdateManualEntryClearanceSettingMutation} from "./updateManualEntryClearanceSetting.generated";

export interface TrackingManualEntrySettingProps {
    entity: EntityInformation;
    setting?: TrackingClearanceFragment;
    onUpdate?: (response: Promise<SettingUpdateResponse>) => void;
}

export const TrackingManualEntrySetting = ({entity, setting, onUpdate}: TrackingManualEntrySettingProps) => {
    const [trackingClearance, setTrackingClearance] = React.useState<TrackingClearanceFragment | undefined>(setting);

    let trackingClearanceType: TrackingClearanceType | undefined;
    let dayLimit: number | undefined;
    switch (trackingClearance?.__typename) {
        case 'AllowedTrackingClearance':
            trackingClearanceType = 'allowed';
            dayLimit = undefined;
            break;
        case 'LimitedTrackingClearance':
            trackingClearanceType = 'limited';
            dayLimit = trackingClearance.dayLimit;
            break;
        case 'ForbiddenTrackingClearance':
            trackingClearanceType = 'forbidden';
            dayLimit = undefined;
            break;
    }

    useEffect(() => {
        setTrackingClearance(setting);
    }, [setting]);

    const [updateManualEntryClearanceSettingMutation] = useUpdateManualEntryClearanceSettingMutation();

    const handleChange = async (newClearance: TrackingClearanceType, dayLimit?: number) => {
        if (newClearance === 'allowed') {
            setTrackingClearance({__typename: 'AllowedTrackingClearance'});
        } else if (newClearance === 'forbidden') {
            setTrackingClearance({__typename: 'ForbiddenTrackingClearance'});
        } else if (newClearance === 'limited' && dayLimit) {
            setTrackingClearance({__typename: 'LimitedTrackingClearance', dayLimit: dayLimit});
        }

        const responsePromise: Promise<SettingUpdateResponse> = updateManualEntryClearanceSettingMutation({
            variables: {
                settingEntityType: entity.entityType,
                entityId: entity.entityId,
                trackingClearanceType: newClearance,
                dayLimit: dayLimit
            }
        }).then(({data, errors}) => ({
            updatedSetting: data?.setting.trackingSetting.updateManualEntryClearanceSetting, errors: errors
        }));

        if (onUpdate) {
            onUpdate(responsePromise);
        }
    };

    const isTrackingClearanceAllowed = trackingClearanceType === 'allowed' || trackingClearanceType === 'limited';

    return <Stack gap={0}>
        <FormControlLabel control={<Checkbox
            checked={isTrackingClearanceAllowed}
            onChange={() => {
                if (isTrackingClearanceAllowed) {
                    handleChange('forbidden', 20);
                } else {
                    handleChange('allowed', undefined);
                }
            }}
        />} label="Darf Stunden manuell eintragen"/>

        {isTrackingClearanceAllowed && <Box sx={{marginLeft: 4, display: 'flex', flexDirection: 'column'}}>
            <Typography variant='caption'>Zeitliche Einschränkung (wie lange in die Vergangenheit darf eingetragen werden?)</Typography>
            <Select onChange={(event) => {
                if(event.target.value === 'Keine') {
                    handleChange('allowed', undefined);
                } else {
                    handleChange('limited', event.target.value as number);
                }
            }} value={dayLimit ?? 'Keine'} size='small'>
                <MenuItem value={'Keine'}>Keine</MenuItem>
                <MenuItem value={1}>1 Tag</MenuItem>
                <MenuItem value={2}>2 Tage</MenuItem>
                <MenuItem value={3}>3 Tage</MenuItem>
                <MenuItem value={5}>5 Tage</MenuItem>
                <MenuItem value={7}>7 Tage</MenuItem>
                <MenuItem value={14}>14 Tage</MenuItem>
                <MenuItem value={30}>30 Tage</MenuItem>
            </Select>
        </Box>}
    </Stack>;
};
