import {InheritableSettingsFragment} from "../../fragments/inheritableSettings.generated";
import {ApolloError} from "@apollo/client";
import React, {PropsWithChildren, useEffect} from "react";
import {Stack, Typography} from "@mui/material";
import {TrackingCommentSetting} from "./tracking/TrackingCommentSetting/TrackingCommentSetting";
import {toast} from "react-toastify";
import {EntityInformation, SettingUpdateResponse} from "./types";
import {TrackingWorkPlaceSetting} from "./tracking/TrackingWorkPlaceSetting/TrackingWorkPlaceSetting";
import {TrackingStampingSetting} from "./tracking/TrackingStampingSetting/TrackingStampingSetting";
import {TrackingManualEntrySetting} from "./tracking/TrackingManualEntrySetting/TrackingManualEntrySetting";
import {TrackingEditDeleteSetting} from "./tracking/TrackingEditDeleteSetting/TrackingEditDeleteSetting";
import {
    AbsenceDurationManualEntrySetting
} from "./absence/AbsenceDurationManualEntrySetting/AbsenceDurationManualEntrySetting";
import {
    AutomaticBreakCorrectionSetting
} from "./tracking/AutomaticBreakCorrectionSetting/AutomaticBreakCorrectionSetting";
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {hasAtLeastSubscription} from "../../utils/subscription";
import {OverNightStampingSetting} from "./tracking/OverNightStampingEnabled/OverNightStampingEnabled";

interface InheritableTrackingSettingsProps {
    entity: EntityInformation;
    settings?: InheritableSettingsFragment;
    loading?: boolean;
    error?: ApolloError;
}

export const InheritableTrackingSettings = ({entity, settings}: InheritableTrackingSettingsProps) => {
    const user = useSelector((state: RootState) => state.auth.currentUser);

    const [latestSettings, setLatestSettings] = React.useState<InheritableSettingsFragment | undefined>(settings);

    useEffect(() => {
        setLatestSettings(settings);
    }, [settings]);

    const handleUpdate = async (response: Promise<SettingUpdateResponse>) => {
        const {updatedSetting, errors} = await response;

        if (errors) {
            const error = errors[0];
            const reason = error.extensions?.['reason'];

            if (reason === 'AuthenticationRequired') {
                toast.error('Melde dich erneut an und versuche es nochmal.');
            } else if (reason === 'PermissionDenied') {
                toast.error('Du hast keine Berechtigungen um diese Einstellung zu ändern.');
            }
        } else if (updatedSetting) {
            setLatestSettings(updatedSetting);
        } else {
            toast.error('Ein unbekannter Fehler ist aufgetreten');
        }
    };

    return <Stack flexDirection='column' gap={2}>
        {hasAtLeastSubscription(user, 'professional') &&
            <TrackingSection name='Stunden'>
                {hasAtLeastSubscription(user, 'expert') &&
                    <AutomaticBreakCorrectionSetting entity={entity}
                                                     setting={latestSettings?.tracking.automaticBreakCorrectionEnabled}
                                                     onUpdate={handleUpdate}/>
                }
                {hasAtLeastSubscription(user, 'expert') &&
                    <OverNightStampingSetting entity={entity}
                                                     setting={latestSettings?.tracking.overNightStampingEnabled}
                                                     onUpdate={handleUpdate}/>
                }
                <TrackingEditDeleteSetting entity={entity} setting={latestSettings?.tracking.editDelete}
                                           onUpdate={handleUpdate}/>
                <TrackingManualEntrySetting entity={entity} setting={latestSettings?.tracking.manualEntry}
                                            onUpdate={handleUpdate}/>
                <TrackingCommentSetting entity={entity} setting={latestSettings?.tracking.comment}
                                        onUpdate={handleUpdate}/>
                <TrackingWorkPlaceSetting entity={entity} setting={latestSettings?.tracking.workPlace}
                                          onUpdate={handleUpdate}/>
            </TrackingSection>
        }
        {hasAtLeastSubscription(user, 'expert') &&
            <TrackingSection name='Stempeln' gap={0}>
                <TrackingStampingSetting entity={entity} setting={latestSettings?.tracking.stamping.platforms}
                                         onUpdate={handleUpdate}/>
            </TrackingSection>
        }
        {hasAtLeastSubscription(user, 'expert') &&
            <TrackingSection name='Abwesenheiten'>
                <AbsenceDurationManualEntrySetting entity={entity}
                                                   setting={latestSettings?.absence.absenceDurationManualEntryEnabled}
                                                   onUpdate={handleUpdate}/>
            </TrackingSection>
        }
    </Stack>;
};

const TrackingSection = ({name, children, gap}: { name: string, gap?: number } & PropsWithChildren) => {
    return <Stack sx={{marginBottom: 4}}>
        <Typography variant='h5'>{name}</Typography>
        <Stack sx={{marginTop: 1}} gap={gap ?? 2}>
            {children}
        </Stack>
    </Stack>
};
