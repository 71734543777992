import * as Types from '../../../../generated/graphql';

import { InheritableSettingsFragment } from '../../../../fragments/inheritableSettings.generated';
import { gql } from '@apollo/client';
import { InheritableSettingsFragmentDoc } from '../../../../fragments/inheritableSettings.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DepartmentSettingsQueryVariables = Types.Exact<{
  departmentId: Types.Scalars['Int'];
}>;


export type DepartmentSettingsQuery = { __typename?: 'Query', setting: { __typename?: 'settingQueries', settingsForDepartment: { __typename?: 'DepartmentSettings', inheritableSettings: (
        { __typename?: 'InheritableSettings' }
        & InheritableSettingsFragment
      ) } } };


export const DepartmentSettingsDocument = gql`
    query DepartmentSettings($departmentId: Int!) {
  setting {
    settingsForDepartment(departmentId: $departmentId) {
      inheritableSettings {
        ...InheritableSettings
      }
    }
  }
}
    ${InheritableSettingsFragmentDoc}`;

/**
 * __useDepartmentSettingsQuery__
 *
 * To run a query within a React component, call `useDepartmentSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDepartmentSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDepartmentSettingsQuery({
 *   variables: {
 *      departmentId: // value for 'departmentId'
 *   },
 * });
 */
export function useDepartmentSettingsQuery(baseOptions: Apollo.QueryHookOptions<DepartmentSettingsQuery, DepartmentSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DepartmentSettingsQuery, DepartmentSettingsQueryVariables>(DepartmentSettingsDocument, options);
      }
export function useDepartmentSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DepartmentSettingsQuery, DepartmentSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DepartmentSettingsQuery, DepartmentSettingsQueryVariables>(DepartmentSettingsDocument, options);
        }
export type DepartmentSettingsQueryHookResult = ReturnType<typeof useDepartmentSettingsQuery>;
export type DepartmentSettingsLazyQueryHookResult = ReturnType<typeof useDepartmentSettingsLazyQuery>;
export type DepartmentSettingsQueryResult = Apollo.QueryResult<DepartmentSettingsQuery, DepartmentSettingsQueryVariables>;