import {
    Box, Button,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    FormHelperText,
    Grid,
    IconButton,
    TextField, Typography
} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {EmployeeInformation} from "../../molecules/EmployeeInformation";
import React from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import {AbsenceRequestFragment} from "../../../fragments/absenceRequest.generated";
import {useCancelAbsenceRequestMutation} from "./cancelAbsenceRequest.generated";
import {toast} from "react-toastify";
import {useSelector} from "react-redux";
import {RootState} from "../../../store/store";
import {hasAtLeastSubscription} from "../../../utils/subscription";
import { LoadingButton } from "@mui/lab";

interface CancelAbsenceRequestDialogProps {
    readonly absenceRequest: AbsenceRequestFragment;

    readonly open?: boolean;
    readonly onClose?: () => void;
}

export const CancelAbsenceRequestDialog = ({absenceRequest, open, onClose}: CancelAbsenceRequestDialogProps) => {
    const user = useSelector((state: RootState) => state.auth.currentUser);

    const [cancelAbsenceRequestMutation, {loading}] = useCancelAbsenceRequestMutation();

    const cancelAbsenceRequest = async (comment: string) => {
        const {data, errors} = await cancelAbsenceRequestMutation({
            variables: {
                absenceRequestId: absenceRequest.absenceRequestId,
                comment: comment
            }
        });

        if (data) {
            if (hasAtLeastSubscription(user, 'expert') && user?.userType !== 'admin') {
                toast.success('Stornierung der Abwesenheit erfolgreich beantragt');
            } else {
                toast.success('Abwesenheit erfolgreich storniert');
            }
            if (onClose) {
                onClose();
            }
        } else if (errors) {
            const error = errors[0];
            const reason = error.extensions?.['reason'];

            switch (reason) {
                case "AuthenticationRequired":
                    toast.error('Melde dich erneut an und versuche es nochmal.');
                    return;
                case "PermissionDenied":
                    toast.error('Du kannst für diesen Mitarbeiter keine Abwesenheit stornieren');
                    return;
                case "AbsenceRequestNotFound":
                    toast.error("Die Abwesenheit konnte nicht gefunden werden");
                    return;
            }
        } else {
            toast.error('Ein unerwarteter Fehler ist aufgetreten. Bitte versuche es später erneut.');
        }
    };

    const {handleChange, values, handleSubmit, handleBlur, touched, errors} = useFormik({
        initialValues: {
            comment: '',
        },
        validationSchema: Yup.object({
            comment: Yup.string().optional()
        }),
        onSubmit: async (values) => {
            cancelAbsenceRequest(values.comment);
        }
    });

    return <Dialog
        open={open ?? false}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        onClose={onClose}
        maxWidth="md"
    >
        <Grid container justifyContent='space-between'>
            <Grid item>
                <DialogTitle id="alert-dialog-title">Abwesenheit stornieren</DialogTitle>
                <Typography sx={{padding: '2px 24px', fontSize: '1.1rem'}}>Bist du dir sicher, dass du diese Abwesenheit
                    stornieren möchtest?</Typography>
            </Grid>
            <Grid item sx={{marginRight: '1rem', marginTop: '0.5rem'}}>
                <IconButton onClick={onClose}>
                    <FontAwesomeIcon icon={solid('xmark')} color='#333333' size="lg"/>
                </IconButton>
            </Grid>
        </Grid>
        <DialogContent>
            <Box component='form' onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <EmployeeInformation label='Kommentar' xs={12} md={12}>
                        <TextField id="comment"
                                   name='comment'
                                   placeholder="Kommentar"
                                   value={values.comment}
                                   error={!!(errors.comment && touched.comment)}
                                   onChange={handleChange}
                                   onBlur={handleBlur}
                                   variant='outlined'
                                   fullWidth
                                   multiline
                                   minRows={2}
                                   size='small'
                        />
                        {errors.comment && (
                            <FormHelperText error={!!(errors.comment && touched.comment)}>
                                {errors.comment}
                            </FormHelperText>
                        )}
                    </EmployeeInformation>
                </Grid>
                <Box sx={{display: 'flex', justifyContent: 'end'}}>
                    <DialogActions sx={{gap: 2, mt: 2}}>
                        <Button variant='contained' color='inherit' type='button' onClick={onClose}>ABBRECHEN</Button>
                        <LoadingButton loading={loading} variant='contained' color='error' type='submit'>{hasAtLeastSubscription(user, 'expert') ? 'STORNIEREN' : 'LÖSCHEN'}</LoadingButton>
                    </DialogActions>
                </Box>
            </Box>
        </DialogContent>
    </Dialog>;
}
