import {
    Box, Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormHelperText,
    Grid,
    IconButton, MenuItem, Select,
    TextField
} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import React from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import {EmployeeInformation} from "../../molecules/EmployeeInformation";
import LoadingButton from "@mui/lab/LoadingButton";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {DateTime} from "luxon";
import {AdapterLuxon} from "@mui/x-date-pickers/AdapterLuxon";

interface CreateCompanyVacationDialogProps {
    open: boolean;
    onClose?: () => void;
}

export const CreateCompanyVacationDialog = ({open, onClose}: CreateCompanyVacationDialogProps) => {

    const {handleChange, values, handleSubmit, setFieldValue, handleBlur, touched, errors} = useFormik({
        initialValues: {
            name: '',
            duration: 'one-day',
            startDate: DateTime.now(),
            endDate: undefined,
            durationStartDate: 'full-day',
            durationEndDate: 'full-day',
            repetition: 'none'
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Gib den Namen des Standorts an'),
        }),
        onSubmit: (values) => {
            console.log('onSubmit');
            console.log(values);
        }
    });

    return <LocalizationProvider dateAdapter={AdapterLuxon}>
        <Dialog
            open={open ?? false}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            onClose={onClose}
            maxWidth="md"
        >
            <Grid container justifyContent='space-between'>
                <Grid item>
                    <DialogTitle id="alert-dialog-title">
                        Betriebsurlaub erstellen
                    </DialogTitle>
                </Grid>
                <Grid item sx={{marginRight: '1rem', marginTop: '0.5rem'}}>
                    <IconButton onClick={onClose}>
                        <FontAwesomeIcon icon={solid('xmark')} color='#333333' size="lg"/>
                    </IconButton>
                </Grid>
            </Grid>
            <DialogContent>
                <Box component='form' onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <EmployeeInformation label='Name des Betriebsurlaubs' xs={12} md={12}>
                            <TextField id="name"
                                       name='name'
                                       placeholder="Name"
                                       type="text"
                                       value={values.name}
                                       error={!!(errors.name && touched.name)}
                                       onChange={handleChange}
                                       onBlur={handleBlur}
                                       variant='outlined'
                                       fullWidth
                                       size='small'
                            />
                            {errors.name && (
                                <FormHelperText error={!!(errors.name && touched.name)}>
                                    {errors.name}
                                </FormHelperText>
                            )}
                        </EmployeeInformation>
                        <EmployeeInformation label='Dauer' xs={12} md={12}>
                            <Select
                                id='duration'
                                name='duration'
                                labelId="duration-label"
                                size='small'
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.duration}
                                error={!!(errors.duration && touched.duration)}
                                fullWidth
                                sx={{backgroundColor: '#fff'}}
                            >
                                <MenuItem value='half-day'>Halber Tag</MenuItem>
                                <MenuItem value='one-day'>Eintägig</MenuItem>
                                <MenuItem value='multiple-day'>Mehrtägig</MenuItem>
                            </Select>
                            {errors.duration && (
                                <FormHelperText
                                    error={!!(errors.duration && touched.duration)}>
                                    {errors.duration}
                                </FormHelperText>
                            )}
                        </EmployeeInformation>
                        <EmployeeInformation label={values.duration === 'multiple-day' ? 'Start-Datum' : 'Datum'} xs={12}
                                             md={values.duration === 'multiple-day' ? 6 : 12}>
                            <DatePicker
                                value={values.startDate}
                                onChange={(value: DateTime | null) => {
                                    if (value?.isValid) {
                                        setFieldValue('startDate', value)
                                    }
                                }}
                                slotProps={{
                                    textField: {
                                        size: 'small',
                                        fullWidth: true,
                                        name: 'startDate',
                                        id: 'startDate',
                                        onBlur: handleBlur
                                    }
                                }}
                                format='dd.MM.yyyy'
                            ></DatePicker>
                        </EmployeeInformation>
                        {values.duration === 'multiple-day' && (
                            <>
                                <EmployeeInformation label='End-Datum' xs={12} md={6}>
                                    <DatePicker
                                        value={values.endDate}
                                        onChange={(value: DateTime | null) => {
                                            if (value?.isValid) {
                                                setFieldValue('endDate', value)
                                            }
                                        }}
                                        slotProps={{
                                            textField: {
                                                size: 'small',
                                                fullWidth: true,
                                                name: 'endDate',
                                                id: 'endDate',
                                                onBlur: handleBlur
                                            }
                                        }}
                                        format='dd.MM.yyyy'
                                    ></DatePicker>
                                </EmployeeInformation>
                                <EmployeeInformation label='Dauer erster Tag' xs={12} md={6}>
                                    <Select
                                        id='durationStartDate'
                                        name='durationStartDate'
                                        labelId="duration-start-date-label"
                                        size='small'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.durationStartDate}
                                        error={!!(errors.durationStartDate && touched.durationStartDate)}
                                        fullWidth
                                        sx={{backgroundColor: '#fff'}}
                                    >
                                        <MenuItem value='half-day'>Halbtags</MenuItem>
                                        <MenuItem value='full-day'>Ganztags</MenuItem>
                                    </Select>
                                    {errors.durationStartDate && (
                                        <FormHelperText
                                            error={!!(errors.durationStartDate && touched.durationStartDate)}>
                                            {errors.durationStartDate}
                                        </FormHelperText>
                                    )}
                                </EmployeeInformation>
                                <EmployeeInformation label='Dauer letzter Tag' xs={12} md={6}>
                                    <Select
                                        id='durationEndDate'
                                        name='durationEndDate'
                                        labelId="duration-end-date-label"
                                        size='small'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.durationEndDate}
                                        error={!!(errors.durationEndDate && touched.durationEndDate)}
                                        fullWidth
                                        sx={{backgroundColor: '#fff'}}
                                    >
                                        <MenuItem value='half-day'>Halbtags</MenuItem>
                                        <MenuItem value='full-day'>Ganztags</MenuItem>
                                    </Select>
                                    {errors.durationEndDate && (
                                        <FormHelperText
                                            error={!!(errors.durationEndDate && touched.durationEndDate)}>
                                            {errors.durationEndDate}
                                        </FormHelperText>
                                    )}
                                </EmployeeInformation>
                            </>
                        )}
                        <EmployeeInformation label='Wiederholung' xs={12} md={12}>
                            <Select
                                id='repetition'
                                name='repetition'
                                labelId="repetition-label"
                                size='small'
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.repetition}
                                error={!!(errors.repetition && touched.repetition)}
                                fullWidth
                                sx={{backgroundColor: '#fff'}}
                            >
                                <MenuItem value='none'>Keine</MenuItem>
                                <MenuItem value='yearly'>Jährlich am selben Datum</MenuItem>
                            </Select>
                            {errors.repetition && (
                                <FormHelperText
                                    error={!!(errors.repetition && touched.repetition)}>
                                    {errors.repetition}
                                </FormHelperText>
                            )}
                        </EmployeeInformation>
                    </Grid>
                    <Box sx={{display: 'flex', justifyContent: 'end'}}>
                        <DialogActions sx={{gap: 2, mt: 2}}>
                            <Button variant='contained' color='inherit' type='button'
                                    onClick={onClose}>ABBRECHEN</Button>
                            <LoadingButton loading={false} variant='contained' type='submit'>SPEICHERN</LoadingButton>
                        </DialogActions>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    </LocalizationProvider>;
};
