import {useSelector} from "react-redux";
import {Navigate, Outlet, useLocation} from "react-router-dom";
import React from "react";
import {RootState} from "../store/store";
import {isSubscriptionTrialActive} from "../utils/trial";

const AdminRoute = () => {
    const location = useLocation();

    const isLoggedIn = useSelector((state: any) => state.auth.isLoggedIn);

    const user = useSelector((state: RootState) => state.auth.currentUser);

    const accountNeedsSubscription = (user?.subscription.subscriptionCategory === 'trial' && !isSubscriptionTrialActive(user)) || user?.subscription.subscriptionCategory === 'cancelled';

    if (accountNeedsSubscription && location.pathname !== '/select-subscription') {
        return user?.userType === 'admin' ? <Navigate to='/select-subscription'/> : <Navigate to="/login"/>;
    }

    if(!isLoggedIn) {
        return <Navigate to="/login" />;
    }

    return user?.userType === 'admin' ? <Outlet /> : <Navigate to="/" />;
};

export default AdminRoute;
