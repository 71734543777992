import * as Types from '../../generated/graphql';

import { TrackingRowFragment } from '../../fragments/trackingRow.generated';
import { WorkingTimeFragment } from '../../fragments/workingTime.generated';
import { TimeUnion_AbsenceRow_Fragment, TimeUnion_WorkingTimeByDay_Fragment } from '../../fragments/timeUnion.generated';
import { CompanySettingFragment } from '../../fragments/companySetting.generated';
import { AbsenceRequestFragment } from '../../fragments/absenceRequest.generated';
import { EmployeeFragment } from '../../fragments/employee.generated';
import { CalendarEvent_AbsenceCalendarEvent_Fragment, CalendarEvent_NoWorkingDayCalendarEvent_Fragment, CalendarEvent_WorkingTimeCalendarEvent_Fragment } from '../calendar/calendarEvents.generated';
import { gql } from '@apollo/client';
import { TrackingRowFragmentDoc } from '../../fragments/trackingRow.generated';
import { WorkingTimeFragmentDoc } from '../../fragments/workingTime.generated';
import { TimeUnionFragmentDoc } from '../../fragments/timeUnion.generated';
import { CompanySettingFragmentDoc } from '../../fragments/companySetting.generated';
import { AbsenceRequestFragmentDoc } from '../../fragments/absenceRequest.generated';
import { EmployeeFragmentDoc } from '../../fragments/employee.generated';
import { CalendarEventFragmentDoc } from '../calendar/calendarEvents.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DashboardQueryVariables = Types.Exact<{
  date: Types.Scalars['DateType'];
  userId: Types.Scalars['Int'];
}>;


export type DashboardQuery = { __typename?: 'Query', tracking: { __typename?: 'trackingQueries', lastTrackingRow?: (
      { __typename?: 'TrackingRow' }
      & TrackingRowFragment
    ) | null, workingTimeThisMonth: (
      { __typename?: 'WorkingTime' }
      & WorkingTimeFragment
    ), workingTimeThisWeek: (
      { __typename?: 'WorkingTime' }
      & WorkingTimeFragment
    ) }, time: { __typename?: 'timeQueries', totalBalance?: (
      { __typename?: 'WorkingTime' }
      & WorkingTimeFragment
    ) | null, last7TimeRows: Array<(
      { __typename?: 'AbsenceRow' }
      & TimeUnion_AbsenceRow_Fragment
    ) | (
      { __typename?: 'WorkingTimeByDay' }
      & TimeUnion_WorkingTimeByDay_Fragment
    )> }, company: { __typename?: 'companyQueries', getCompanySettings: (
      { __typename?: 'CompanySetting' }
      & CompanySettingFragment
    ) }, absence: { __typename?: 'absenceQueries', pendingAbsenceRequests: Array<(
      { __typename?: 'AbsenceRequest' }
      & AbsenceRequestFragment
    )> }, permission: { __typename?: 'permissionQueries', canSeeTaskDashboard: boolean, trackingPermission: { __typename?: 'trackingPermissionQueries', canCreateManualEntry: boolean, canStamp: boolean } }, employee: { __typename?: 'employeeQueries', listEmployees: Array<(
      { __typename?: 'Employee' }
      & EmployeeFragment
    )> }, calendar: { __typename?: 'calendarQueries', companyEvents: Array<(
      { __typename?: 'AbsenceCalendarEvent' }
      & CalendarEvent_AbsenceCalendarEvent_Fragment
    ) | (
      { __typename?: 'NoWorkingDayCalendarEvent' }
      & CalendarEvent_NoWorkingDayCalendarEvent_Fragment
    ) | (
      { __typename?: 'WorkingTimeCalendarEvent' }
      & CalendarEvent_WorkingTimeCalendarEvent_Fragment
    )> } };


export const DashboardDocument = gql`
    query Dashboard($date: DateType!, $userId: Int!) {
  tracking {
    lastTrackingRow {
      ...TrackingRow
    }
    workingTimeThisMonth {
      ...WorkingTime
    }
    workingTimeThisWeek {
      ...WorkingTime
    }
  }
  time {
    totalBalance {
      ...WorkingTime
    }
    last7TimeRows {
      ...TimeUnion
    }
  }
  company {
    getCompanySettings {
      ...CompanySetting
    }
  }
  absence {
    pendingAbsenceRequests {
      ...AbsenceRequest
    }
  }
  permission {
    canSeeTaskDashboard
  }
  employee {
    listEmployees {
      ...Employee
    }
  }
  calendar {
    companyEvents(startDate: $date, endDate: $date) {
      ...CalendarEvent
    }
  }
  permission {
    trackingPermission {
      canCreateManualEntry(userId: $userId)
      canStamp
    }
  }
}
    ${TrackingRowFragmentDoc}
${WorkingTimeFragmentDoc}
${TimeUnionFragmentDoc}
${CompanySettingFragmentDoc}
${AbsenceRequestFragmentDoc}
${EmployeeFragmentDoc}
${CalendarEventFragmentDoc}`;

/**
 * __useDashboardQuery__
 *
 * To run a query within a React component, call `useDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardQuery({
 *   variables: {
 *      date: // value for 'date'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDashboardQuery(baseOptions: Apollo.QueryHookOptions<DashboardQuery, DashboardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashboardQuery, DashboardQueryVariables>(DashboardDocument, options);
      }
export function useDashboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardQuery, DashboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashboardQuery, DashboardQueryVariables>(DashboardDocument, options);
        }
export type DashboardQueryHookResult = ReturnType<typeof useDashboardQuery>;
export type DashboardLazyQueryHookResult = ReturnType<typeof useDashboardLazyQuery>;
export type DashboardQueryResult = Apollo.QueryResult<DashboardQuery, DashboardQueryVariables>;