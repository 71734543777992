import {Tooltip} from "@mui/material";
import React from "react";

export interface WarningIconProps {
    readonly tooltip?: string;
    readonly color?: 'primary' | 'warning' | 'error';
}

export const WarningIcon = ({tooltip, color}: WarningIconProps) => {
    const propsColor = color ?? 'primary';

    let htmlColor;

    switch(propsColor) {
        case "primary":
            htmlColor = '#0854CF';
            break;
        case "warning":
            htmlColor = '#FAC20A'
            break;
        case "error":
            htmlColor = '#EF3A3E'
    }

    if (tooltip) {
        return <Tooltip title={tooltip}>
            <i style={{color: htmlColor, fontSize: 20}} className="fa fa-exclamation-triangle"/>
        </Tooltip>
    } else {
        return <i style={{color: htmlColor}} className="fa fa-exclamation-triangle"/>
    }
}
