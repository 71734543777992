import * as Types from '../generated/graphql';

import { gql } from '@apollo/client';
export type WorkPlaceFragment = { __typename?: 'WorkPlace', workPlaceId: number, companyId: number, name: string, country?: string | null, state?: string | null, city?: string | null, address?: string | null, postalCode?: string | null };

export const WorkPlaceFragmentDoc = gql`
    fragment WorkPlace on WorkPlace {
  workPlaceId
  companyId
  name
  country
  state
  city
  address
  postalCode
}
    `;