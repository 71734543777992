import {useFormik} from "formik";
import {DateTime} from "luxon";
import * as Yup from "yup";
import {
    Box, Button,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    FormHelperText,
    Grid,
    IconButton,
    TextField
} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {EmployeeInformation} from "../../molecules/EmployeeInformation";
import React from "react";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterLuxon} from "@mui/x-date-pickers/AdapterLuxon";
import {useCreateEmployeeVacationTransferMutation} from "./createEmployeeVacationTransfer.generated";
import {toast} from "react-toastify";

export interface CreateEmployeeTransferDialogProps {
    userId: number;
    open?: boolean;
    onClose?: () => void;
}

export const CreateEmployeeTransferDialog = ({
                                                 userId,
                                                 open,
                                                 onClose
                                             }: CreateEmployeeTransferDialogProps) => {
    const [createEmployeeTransferMutation] = useCreateEmployeeVacationTransferMutation();

    const now = DateTime.now();

    const {handleChange, setFieldValue, values, handleSubmit, handleBlur, touched, errors} = useFormik({
        initialValues: {
            year: DateTime.now().year,
            vacationDays: 0,
            expirationDate: now.set({year: now.year, day: 31, month: 3})
        },
        validationSchema: Yup.object({
            year: Yup.number().required('Gib das Jahr an aus welchem der Urlaub übertragen wird'),
            vacationDays: Yup.number().required('Gib die Anzahl der Urlaubstage an').min(0, 'Die Anzahl der Urlaubstage muss größer als 0 sein'),
            expirationDate: Yup.date().required('Gib das Verfallsdatum an')
        }),
        onSubmit: async (values) => {
            const {data, errors} = await createEmployeeTransferMutation({
                variables: {
                    userId: userId,
                    year: values.year,
                    vacationMinutes: values.vacationDays * 60 * 8,
                    expirationDate: values.expirationDate.toISODate()
                }
            });

            if (data) {
                toast.success('Urlaubsübertrag erfolgreich erstellt');
                if (onClose) {
                    onClose();
                }
            } else if (errors) {
                const error = errors[0];
                const reason = error.extensions?.['reason'];

                switch (reason) {
                    case "AuthenticationRequired":
                        toast.error('Melde dich erneut an und versuche es nochmal.');
                        return;
                    case "PermissionDenied":
                        toast.error('Du kannst für diesen Mitarbeiter keinen Urlaubsplan erstellen');
                        return;
                    case "YearAlreadyExists":
                        toast.error("Für dieses Jahr existiert bereits ein Urlaubsplan");
                        return;
                }
            } else {
                toast.error('Ein unerwarteter Fehler ist aufgetreten. Bitte versuche es später erneut.');
            }
        }
    });

    return <LocalizationProvider dateAdapter={AdapterLuxon}>
        <Dialog
            open={open ?? false}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            onClose={onClose}
            maxWidth="md"
        >
            <Grid container justifyContent='space-between'>
                <Grid item>
                    <DialogTitle id="alert-dialog-title">
                        Urlaubsplan erstellen
                    </DialogTitle>
                </Grid>
                <Grid item sx={{marginRight: '1rem', marginTop: '0.5rem'}}>
                    <IconButton onClick={onClose}>
                        <FontAwesomeIcon icon={solid('xmark')} color='#333333' size="lg"/>
                    </IconButton>
                </Grid>
            </Grid>
            <DialogContent>
                <Box component='form' onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <EmployeeInformation label='Jahr aus welchem der Urlaub übertragen wird' xs={12} md={12}>
                            <TextField id="year"
                                       name='year'
                                       placeholder="Jahr"
                                       type="number"
                                       value={values.year}
                                       error={!!(errors.year && touched.year)}
                                       onChange={handleChange}
                                       onBlur={handleBlur}
                                       variant='outlined'
                                       fullWidth
                                       size='small'
                            />
                            {errors.year && (
                                <FormHelperText error={!!(errors.year && touched.year)}>
                                    {errors.year}
                                </FormHelperText>
                            )}
                        </EmployeeInformation>
                        <EmployeeInformation label="Urlaubstage" xs={12} md={12}>
                            <TextField id="vacationDays"
                                       name='vacationDays'
                                       type="number"
                                       placeholder="30"
                                       value={values.vacationDays}
                                       error={!!(errors.vacationDays && touched.vacationDays)}
                                       onChange={handleChange}
                                       onBlur={handleBlur}
                                       variant='outlined'
                                       fullWidth
                                       size='small'
                            />
                            {errors.vacationDays && (
                                <FormHelperText error={!!(errors.vacationDays && touched.vacationDays)}>
                                    {errors.vacationDays}
                                </FormHelperText>
                            )}
                        </EmployeeInformation>
                        <EmployeeInformation label='Ablaufdatum' xs={12} md={12}>
                            <DatePicker
                                slotProps={{
                                    textField: {
                                        error: !!(errors.expirationDate && touched.expirationDate),
                                        fullWidth: true,
                                        size: 'small',
                                        name: 'date',
                                        id: 'date',
                                        disabled: true
                                    }
                                }}
                                value={values.expirationDate}
                                onChange={(value: DateTime | null) => {
                                    if (value?.isValid) {
                                        setFieldValue('expirationDate', value)
                                    }
                                }}
                                format='dd.MM.yyyy'
                                disabled
                            />
                            {errors.expirationDate && (
                                <FormHelperText error={!!(errors.expirationDate && touched.expirationDate)}>
                                    Bitte gib ein gültiges Ablaufdatum an
                                </FormHelperText>
                            )}
                        </EmployeeInformation>
                    </Grid>
                    <Box sx={{display: 'flex', justifyContent: 'end'}}>
                        <DialogActions sx={{gap: 2, mt: 2}}>
                            <Button variant='contained' color='inherit' type='button' onClick={onClose}>ABBRECHEN</Button>
                            <Button variant='contained' type='submit'>SPEICHERN</Button>
                        </DialogActions>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    </LocalizationProvider>;
};
