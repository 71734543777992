import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateEmployeeAccountStatusMutationVariables = Types.Exact<{
  employeeId: Types.Scalars['Int'];
  accountStatus: Types.AccountStatus;
}>;


export type UpdateEmployeeAccountStatusMutation = { __typename?: 'Mutation', admin: { __typename?: 'adminMutations', updateEmployeeAccountStatus: any } };


export const UpdateEmployeeAccountStatusDocument = gql`
    mutation UpdateEmployeeAccountStatus($employeeId: Int!, $accountStatus: AccountStatus!) {
  admin {
    updateEmployeeAccountStatus(
      employeeId: $employeeId
      accountStatus: $accountStatus
    )
  }
}
    `;
export type UpdateEmployeeAccountStatusMutationFn = Apollo.MutationFunction<UpdateEmployeeAccountStatusMutation, UpdateEmployeeAccountStatusMutationVariables>;

/**
 * __useUpdateEmployeeAccountStatusMutation__
 *
 * To run a mutation, you first call `useUpdateEmployeeAccountStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmployeeAccountStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmployeeAccountStatusMutation, { data, loading, error }] = useUpdateEmployeeAccountStatusMutation({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      accountStatus: // value for 'accountStatus'
 *   },
 * });
 */
export function useUpdateEmployeeAccountStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEmployeeAccountStatusMutation, UpdateEmployeeAccountStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEmployeeAccountStatusMutation, UpdateEmployeeAccountStatusMutationVariables>(UpdateEmployeeAccountStatusDocument, options);
      }
export type UpdateEmployeeAccountStatusMutationHookResult = ReturnType<typeof useUpdateEmployeeAccountStatusMutation>;
export type UpdateEmployeeAccountStatusMutationResult = Apollo.MutationResult<UpdateEmployeeAccountStatusMutation>;
export type UpdateEmployeeAccountStatusMutationOptions = Apollo.BaseMutationOptions<UpdateEmployeeAccountStatusMutation, UpdateEmployeeAccountStatusMutationVariables>;