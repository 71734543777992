import * as Types from '../generated/graphql';

import { gql } from '@apollo/client';
export type TrackingRowFragment = { __typename?: 'TrackingRow', start: any, end?: any | null, comment?: string | null, workPlace?: string | null };

export const TrackingRowFragmentDoc = gql`
    fragment TrackingRow on TrackingRow {
  start
  end
  comment
  workPlace
}
    `;