import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import {useFormik} from 'formik';

import * as Yup from 'yup';

import {useLoginMutation} from './login.generated';
import {removeWindowClass} from '../../utils/helpers';
import {loginUser} from '../../store/reducers/auth';
import LoggedOutScreen from '../../components/LoggedOutScreen';
import {Box, FormHelperText, Link, TextField, Typography} from "@mui/material";
import {LoadingButton} from "@mui/lab";

const Login = () => {
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const [loginMutation, {loading}] = useLoginMutation();

    const [error, setError] = useState<string>();

    const login = async (email: string, password: string) => {
        const {data, errors} = await loginMutation({variables: {email, password}});

        if (errors) {
            const error = errors[0];
            const reason: unknown = error.extensions['reason'];

            switch (reason) {
                case 'EmailNotFound':
                    setError('Es wurde kein Account mit dieser E-Mail gefunden');
                    break;
                case 'UserNotVerified':
                    setError(
                        'Bitte verifiziere zuerst deinen Account, um dich einloggen zu können.'
                    );
                    break;
                case 'IncorrectPassword':
                    setError('Falsches Passwort');
                    break;
                case 'SubscriptionCancelled':
                    setError('Dein Unternehmen hat die Mitgliedschaft gekündigt');
                    break;
                default:
                    setError('Ein unbekannter Fehler ist aufgetreten');
                    break;
            }
        } else if (data) {
            const accessToken = data.employee.login.accessToken;
            localStorage.setItem('refreshToken', data.employee.login.refreshToken);

            toast.success('Du hast dich erfolgreich angemeldet!');
            removeWindowClass('login-page');
            removeWindowClass('hold-transition');

            dispatch(loginUser(accessToken));

            navigate('/');
        } else {
            setError('Ein unbekannter Fehler ist aufgetreten.');
        }
    };

    const {handleChange, values, handleSubmit, handleBlur, touched, errors, setErrors} =
        useFormik({
            initialValues: {
                email: '',
                password: ''
            },
            validationSchema: Yup.object({
                email: Yup.string().required(
                    'Bitte gib deine E-Mail an, mit der du dich registriert hast.'
                ),
                password: Yup.string().required(
                    'Bitte gib dein Passwort an, um dich anzumelden.'
                )
            }),
            onSubmit: (values) => {
                login(values.email, values.password);
            }
        });

    // Reset errors after submit for a nicer UX.
    const resetErrors = () => {
        setErrors({});
        setError(undefined);
    };

    return (
        <LoggedOutScreen type='login'>
            <Typography component='h1' variant='h4' fontWeight='bold'>Login</Typography>
            <Typography sx={{marginTop: 1}} color='#696579'>Logge dich ein, um MyTimeTracker nutzen zu
                können</Typography>
            <Box component='form'
                 sx={{marginTop: 5}}
                 onSubmit={handleSubmit}
                 onChange={resetErrors}
            >
                <Typography fontWeight='bold'>E-Mail</Typography>
                <TextField
                    sx={{marginTop: 0.5}}
                    id="email"
                    name='email'
                    placeholder="E-Mail"
                    value={values.email}
                    error={!!(errors.email && touched.email)}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant='outlined'
                    fullWidth
                    size='small'
                />
                {errors.email && (
                    <FormHelperText error={!!(errors.email && touched.email)}>
                        {errors.email}
                    </FormHelperText>
                )}
                <Typography fontWeight='bold' sx={{marginTop: 2}}>Passwort</Typography>
                <TextField
                    sx={{marginTop: 0.5}}
                    id="password"
                    name='password'
                    type='password'
                    className='sentry-mask'
                    placeholder="Passwort"
                    value={values.password}
                    error={!!(errors.password && touched.password)}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant='outlined'
                    fullWidth
                    size='small'
                />
                {errors.password && (
                    <FormHelperText error={!!(errors.password && touched.password)}>
                        {errors.password}
                    </FormHelperText>
                )}
                <Box sx={{display: 'flex', justifyContent: 'end', marginTop: 1}}>
                    <Link underline='none' href='/forgot-password'>Passwort vergessen?</Link>
                </Box>
                {error && (
                    <p className="text-danger">
                        <i className="fa fa-exclamation-triangle"/> <span>{error}</span>
                    </p>
                )}
                <LoadingButton
                    type='submit'
                    sx={{marginTop: 3}}
                    variant='contained'
                    fullWidth
                    loading={loading}
                >Anmelden</LoadingButton>
                <Box sx={{display: 'flex', marginTop: 2, gap: 0.5}}>
                    <Typography>
                        Noch keinen Account?
                    </Typography>
                    <Link sx={{fontSize: '1rem', marginTop: '1px'}} href='/register' underline='none'>Jetzt registrieren</Link>
                </Box>
            </Box>
        </LoggedOutScreen>
    );
};

export default Login;
