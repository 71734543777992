import {WorkingTimeFragment} from "../../fragments/workingTime.generated";
import {useTranslation} from "react-i18next";

export interface SaldoTimeTextProps {
    readonly saldo: WorkingTimeFragment;
}

const SaldoTimeText = (props: SaldoTimeTextProps) => {
    const {t} = useTranslation();

    const saldo = props.saldo;

    const isNegative = saldo.hours < 0 || saldo.minutes < 0;

    const color = isNegative ? '#DB534C' : '#38D968';

    const hours = Math.abs(saldo.hours);
    const minutes = Math.abs(saldo.minutes);

    return <p style={{color: color, margin: 0}}>
        <span>{isNegative ? '- ' : '+ '}</span>
        {hours !== 0 &&
            <>
                <span>{hours} </span>
                <span style={{fontSize: '12px'}}>{t('common.hourShort')} </span>
            </>
        }
        <span>{minutes} </span>
        <span style={{fontSize: '12px'}}>{t('common.minuteShort')}</span>
    </p>;
}

export default SaldoTimeText;
