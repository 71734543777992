import {ContentHeader} from "../../components";
import React, {useEffect, useState} from "react";
import {Box, Card, CardContent, Divider, FormHelperText, Grid, IconButton, Stack, Typography} from "@mui/material";
import {FieldArray, FormikProvider, useFormik} from "formik";
import * as Yup from "yup";
import {AdapterLuxon} from "@mui/x-date-pickers/AdapterLuxon";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {LoadingButton} from "@mui/lab";
import {DateTime, Duration} from "luxon";
import {useCompanySettings} from "../../store/companySettings";
import {toast} from "react-toastify";
import WarningIcon from "@mui/icons-material/Warning";
import {useNavigate, useParams} from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import {v4} from "uuid";
import {
    FormikEditRow,
    TrackingRowBlock,
    WorkingTimeFormikValues
} from "../../components/organisms/WorkingTimeByDayDialog/WorkingTimeByDayDialog";
import {EmployeeInformation} from "../../components/molecules/EmployeeInformation";
import {useInsertManuallyDynamicMutation} from "./insertManuallyDynamic.generated";
import {useEmployeeByIdLazyQuery} from "../viewemployee/employeeById.generated";
import errorImage from "../../images/error_creature.png";
import {useManualEntryDateCheckLazyQuery} from "./manualEntryDateCheck.generated";
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";
import infoCreatureImage from "../../images/info_creature.png";
import {TrackingDialogAbsenceBox} from "../../dynamic-dialog/TrackingDetailDialog";
import WorkingTimeText, {WorkingTimeTextSpan} from "../../components/molecules/WorkingTimeText";

const ManualInsertPage = () => {
    const {id} = useParams();
    const companySettings = useCompanySettings();
    const user = useSelector((state: RootState) => state.auth.currentUser);

    const employeeId = id ? Number(id) : (user?.userId ?? undefined);

    const [employeeByIdQuery, {
        data: employeeData,
        loading: employeeLoading,
        error: employeeError
    }] = useEmployeeByIdLazyQuery({
        variables: {
            employeeId: employeeId ?? 0
        }
    });

    const navigate = useNavigate();

    const [insertManuallyDynamicMutation, {loading: insertManuallyLoading}] = useInsertManuallyDynamicMutation();

    const [backendError, setBackendError] = useState<string>();

    const insertManually = async (date: DateTime, formikEditRows: FormikEditRow[]) => {
        const {data, errors} = await insertManuallyDynamicMutation({
            variables: {
                employeeId: employeeId ? employeeId : undefined,
                date: date.toFormat('yyyy-MM-dd'),
                trackingRows: formikEditRows.map((editRow) => {
                    return {
                        date: date.toFormat('yyyy-MM-dd'),
                        start: editRow.startDate.toISO(),
                        end: editRow.endDate?.toISO(),
                        comment: editRow.comment,
                        newWorkPlace: editRow.workPlace
                    }
                })
            }
        });

        if (data?.tracking.insertManuallyDynamic === null && !errors) {
            toast.success('Stunden erfolgreich eingetragen');
            navigate(-1);
        } else {
            const error = errors?.[0];
            if (error?.extensions['reason'] !== undefined && error.extensions['reason'] !== null) {
                const reason: string = error.extensions['reason'] as unknown as string;

                if (reason === 'AuthenticationRequired') {
                    setBackendError('Versuche dich neu anzumelden, um die Stunden zu löschen');
                } else if (reason === 'PermissionDenied') {
                    setBackendError('Du hast nicht die notwendigen Berechtigungen');
                } else if (reason === 'DateAlreadyExisting') {
                    setBackendError('Es existiert bereits eine Buchung für diesen Tag');
                } else if (reason === 'ExistingAbsence') {
                    setBackendError('Es existiert bereits eine Abwesenheit für diesen Tag');
                } else if (reason === 'ValidationError') {
                    setBackendError('Bitte gib eine gültige Reihenfolge für die Einträge an');
                } else {
                    setBackendError('Ein unerwarteter Fehler ist aufgetreten');
                }
            } else {
                setBackendError('Ein unerwarteter Fehler ist aufgetreten');
            }
        }
    }

    const dateToday = DateTime.now();

    const formik =
        useFormik<WorkingTimeFormikValues>({
            initialValues: {
                date: DateTime.now(),
                trackingRows: [{
                    uuid: v4(),
                    collapsed: true,
                    startDate: dateToday,
                    endDate: dateToday,
                    comment: '',
                    workPlace: '',
                    isStampedIn: false
                }],
                validationErrors: []
            },
            validationSchema: Yup.object().shape({
                trackingRows: Yup.array()
                    .of(Yup.object().shape({
                        startDate: Yup.date()
                            .nullable()
                            .default(null)
                            .max(Yup.ref('endDate'), 'Die Start-Zeit muss vor der End-Zeit liegen')
                            .required('Bitte gib eine Start-Zeit an'),
                        endDate: Yup.date()
                            .nullable()
                            .default(null)
                            .min(Yup.ref('startDate'), 'Die End-Zeit muss nach der Start-Zeit liegen')
                            .required('Bitte gib eine End-Zeit an'),
                        comment: Yup.string().when('date', {
                            is: () => companySettings.settings?.commentsEnabled,
                            then: Yup.string().required('Bitte gib einen Kommentar an'),
                            otherwise: Yup.string().notRequired()
                        }),
                        workPlace: Yup.string().when('date', {
                            is: () => companySettings.settings?.workingPlaceEnabled,
                            then: Yup.string()
                                .required('Bitte gib einen Arbeitsort an')
                                .oneOf(companySettings.settings?.workingPlaces ?? [], 'Bitt gib einen gültigen Arbeitsort an'),
                            otherwise: Yup.string().notRequired()
                        })
                    }))
            }),
            validate: (values) => {
                let validationErrors: string[] = [];
                let trackingRowErrors: { startDate?: string, endDate?: string }[] = [];
                values.trackingRows.map((trackingRow, index) => {
                    const isLastRow = index + 1 === values.trackingRows.length;

                    if (!isLastRow) {
                        const nextRow = values.trackingRows[index + 1];
                        const endDate = trackingRow.endDate ?? dateToday;
                        const diff = endDate.diff(nextRow.startDate, 'minutes').minutes;
                        if (diff > 0) {
                            validationErrors[index] = 'Du darfst keine negative Pause angeben';
                            trackingRowErrors[index] = {endDate: 'End-Zeit muss vor der Start-Zeit des nächsten Eintrags sein'};
                            trackingRowErrors[index + 1] = {startDate: 'Start-Zeit muss vor der End-Zeit des vorherigen Eintrags liegen'};
                        }
                    }
                    return {};
                });

                return (validationErrors.length === 0 && trackingRowErrors.length === 0) ? undefined : {
                    validationErrors: validationErrors,
                    trackingRows: trackingRowErrors
                };
            },
            onSubmit: (values) => {
                insertManually(values.date!, values.trackingRows);
            }
        });
    const {values, setFieldValue, handleSubmit, touched, errors} = formik;

    const [manualEntryDateCheckQuery, {data: manualEntryDateCheckData}] = useManualEntryDateCheckLazyQuery({
        variables: {
            userId: employeeId ?? 0,
            date: (values.date ?? DateTime.now()).toFormat('yyyy-MM-dd')
        }
    });

    const addTrackingRow = () => {
        setFieldValue('trackingRows', [
            ...values.trackingRows,
            {
                uuid: v4(),
                collapsed: true,
                startDate: values.trackingRows[values.trackingRows.length - 1].endDate ?? dateToday,
                endDate: undefined,
                comment: '',
                workPlace: ''
            }
        ]);
    }

    useEffect(() => {
        manualEntryDateCheckQuery({
            variables: {
                userId: employeeId ?? 0,
                date: (values.date ?? DateTime.now()).toFormat('yyyy-MM-dd')
            }
        });
    }, [values.date, employeeId, manualEntryDateCheckQuery]);

    useEffect(() => {
        if (employeeId && !employeeData && !employeeLoading && !employeeError) {
            employeeByIdQuery();
        }
    });

    const startDate = values.trackingRows[0] ? values.trackingRows[0].startDate : undefined;

    if (!companySettings.settings) {
        return <></>;
    }

    const canCreateManualEntryForDate = manualEntryDateCheckData?.permission.trackingPermission.canCreateManualEntryForDate ?? true;
    const canStampOverNight = manualEntryDateCheckData?.permission.trackingPermission.canStampOverNight ?? false;
    const hasTrackingRows = (manualEntryDateCheckData?.tracking.trackingRowsByDate.length ?? 0) > 0;
    const canInsert = canCreateManualEntryForDate && !hasTrackingRows;

    if (employeeError) {
        return <Stack alignItems='center' justifyContent='center' sx={{my: 2}}>
            <Typography variant='h4'>Es tut uns leid!</Typography>
            <Box component='img' src={errorImage} sx={{width: '30%'}}/>
            <Typography sx={{mt: 3}}>Beim Laden der Seite ist ein Fehler aufgetreten</Typography>
        </Stack>;
    }

    const absenceMinutes = manualEntryDateCheckData?.absence.absencesByDate.map((absenceRow) => {
        return absenceRow.workingTime.hours * 60 + absenceRow.workingTime.minutes;
    }).reduce((prev, current) => prev + current, 0);

    const workingTimeMinutes = values.trackingRows.map((trackingRow) => {
        const startDate = trackingRow.startDate;
        const endDate = trackingRow.endDate ?? trackingRow.startDate;

        const diff = endDate.diff(startDate, ['minutes']);

        return diff.minutes;
    }).reduce((prev, current) => prev + current, 0);

    const breakingMinutes = values.trackingRows.map((trackingRow, index) => {
        const isLastRow = index + 1 === values.trackingRows.length;

        if (isLastRow) {
            return 0;
        } else {
            const nextRow = values.trackingRows[index + 1];

            const endDateThisRow = trackingRow.endDate ?? nextRow.startDate;
            const startDateNextRow = nextRow.startDate;
            const diff = startDateNextRow.diff(endDateThisRow, ['minutes']);

            return diff.minutes;
        }
    }).reduce((prev, current) => prev + current, 0);

    const breakingDuration = Duration.fromMillis((isNaN(breakingMinutes) ? 0 : breakingMinutes) * 60 * 1000).shiftTo('hours', 'minutes').toObject();
    const absenceDuration = absenceMinutes ? Duration.fromMillis((isNaN(absenceMinutes) ? 0 : absenceMinutes) * 60 * 1000).shiftTo('hours', 'minutes').toObject() : undefined;
    const workingDuration = Duration.fromMillis((isNaN(workingTimeMinutes + absenceMinutes) ? 0 : workingTimeMinutes + absenceMinutes) * 60 * 1000).shiftTo('hours', 'minutes').toObject();


    return <LocalizationProvider dateAdapter={AdapterLuxon}>
        <ContentHeader
            title={`Manueller Eintrag ${employeeData ? `für ${employeeData.employee.byId.name} ${employeeData.employee.byId.surname}` : ''}`}/>
        <Card>
            <CardContent>
                <Box component='form' onSubmit={handleSubmit}>
                    <EmployeeInformation label='Datum' xs={12} md={6}>
                        <DatePicker
                            slotProps={{
                                textField: {
                                    error: !!(errors.date && touched.date),
                                    fullWidth: true,
                                    size: 'small',
                                    name: 'date',
                                    id: 'date'
                                }
                            }}
                            value={values.date}
                            onChange={(value: DateTime | null) => {
                                if (value?.isValid) {
                                    setFieldValue('date', value);
                                    setFieldValue('trackingRows', [{
                                        uuid: v4(),
                                        collapsed: true,
                                        startDate: value,
                                        endDate: value,
                                        comment: '',
                                        workPlace: '',
                                        isStampedIn: false
                                    }]);
                                }
                            }}
                            format='dd.MM.yyyy'
                        />
                        {errors.date && (
                            <FormHelperText error={!!(errors.date && touched.date)}>
                                Bitte gib ein Datum an
                            </FormHelperText>
                        )}
                    </EmployeeInformation>
                    {canInsert &&
                        <Grid
                            container
                            spacing={10}
                            sx={{paddingTop: 4}}
                        >
                            <Grid item>
                                <p>
                                    <b>Erfasste Zeit: </b>
                                    <WorkingTimeText workingTime={workingDuration}/>
                                    {absenceDuration &&
                                        <>davon <WorkingTimeTextSpan workingTime={absenceDuration}/> abwesend</>
                                    }
                                </p>
                            </Grid>
                            <Grid item>
                                <p><b>Pause: </b><WorkingTimeText workingTime={breakingDuration}/></p>
                            </Grid>
                        </Grid>
                    }
                    {((manualEntryDateCheckData?.absence.absencesByDate.length ?? 0) > 0) &&
                        <Box sx={{mt: 4}}>
                            {manualEntryDateCheckData?.absence.absencesByDate.map((absenceRow) =>
                                <TrackingDialogAbsenceBox
                                    absenceRow={absenceRow}/>)}
                        </Box>
                    }
                    {(formik && canInsert) &&
                        <Box sx={{pt: 2}}>
                            <FormikProvider value={formik}>
                                <FieldArray
                                    name="trackingRows"
                                    validateOnChange={false}
                                    render={arrayHelpers => (
                                        <div>
                                            {values.trackingRows.map((trackingRow, index) => {
                                                const isLastRow = index + 1 === values.trackingRows.length;

                                                const onDelete = () => {
                                                    setFieldValue('trackingRows', values.trackingRows.filter((trackingRow, rowIndex) => rowIndex !== index));
                                                };

                                                if (isLastRow) {
                                                    return <TrackingRowBlock
                                                        startDate={startDate}
                                                        companySettings={companySettings.settings!}
                                                        key={trackingRow.uuid}
                                                        trackingRow={trackingRow}
                                                        index={index}
                                                        isEditing={true}
                                                        onDelete={onDelete}
                                                        canBeDeleted={values.trackingRows.length > 1}
                                                        formik={formik}
                                                        canStampOverNight={canStampOverNight}
                                                    />
                                                } else {
                                                    const nextRow = values.trackingRows[index + 1];

                                                    const endDateThisRow = trackingRow.endDate ?? DateTime.now();
                                                    const startDateNextRow = nextRow.startDate;
                                                    const diff = startDateNextRow.diff(endDateThisRow, ['hours', 'minutes']);

                                                    return <div key={trackingRow.uuid}>
                                                        <TrackingRowBlock
                                                            startDate={startDate}
                                                            companySettings={companySettings.settings!}
                                                            trackingRow={trackingRow}
                                                            index={index}
                                                            isEditing={true}
                                                            onDelete={onDelete}
                                                            canBeDeleted={values.trackingRows.length > 1}
                                                            formik={formik}
                                                            canStampOverNight={canStampOverNight}
                                                        />
                                                        <Stack sx={{my: 1}} direction='row' justifyContent='center'
                                                               alignItems='center' spacing={2}>
                                                            {errors.validationErrors?.[index] &&
                                                                <WarningIcon color='error'/>
                                                            }
                                                            <Divider sx={{
                                                                backgroundColor: 'rgba(0, 0, 0, 0.87)',
                                                                width: '1px'
                                                            }} flexItem={true} orientation='vertical'/>
                                                            <Typography
                                                                sx={{py: 1.5}}>Pause: {diff.hours}h {diff.minutes.toFixed(0)}min</Typography>
                                                        </Stack>
                                                    </div>;
                                                }
                                            })}
                                        </div>
                                    )}
                                />
                            </FormikProvider>
                        </Box>
                    }
                    {canInsert &&
                        <Box sx={{display: 'flex', justifyContent: 'center', marginTop: 1}}>
                            <IconButton type='button' onClick={addTrackingRow}
                                        sx={{
                                            backgroundColor: '#0854CF',
                                            color: '#fff',
                                            '&:hover': {backgroundColor: '#84868E'}
                                        }}>
                                <AddIcon/>
                            </IconButton>
                        </Box>
                    }
                    {backendError &&
                        <Grid item xs={12} sx={{display: 'flex', gap: 1, paddingLeft: 2, my: 2}}>
                            <WarningIcon color='error'/>
                            <Typography color='error'>{backendError}</Typography>
                        </Grid>
                    }
                    <Box sx={{
                        display: 'flex',
                        justifyContent: {xs: 'center', sm: 'end'},
                        margin: 2,
                        marginTop: {xs: 6, sm: 2}
                    }}>
                        {canInsert &&
                            <LoadingButton loading={insertManuallyLoading}
                                           sx={{float: {xs: 'none', sm: 'right'}, width: {xs: '100%', sm: 'auto'}}}
                                           variant='contained'
                                           type='submit'>Speichern</LoadingButton>
                        }
                    </Box>
                    {!canCreateManualEntryForDate &&
                        <Box sx={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                            <Box component='img' src={infoCreatureImage} sx={{width: '20%'}}/>
                            <Typography sx={{mt: 3}}>Für das gewünschte Datum sind keine Einträge mehr möglich, da dies
                                deaktiviert wurde.</Typography>
                        </Box>
                    }
                    {hasTrackingRows &&
                        <Box sx={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                            <Box component='img' src={infoCreatureImage} sx={{width: '20%'}}/>
                            <Typography sx={{mt: 3}}>Für diesen Tag wurden bereits Stunden erfasst</Typography>
                        </Box>
                    }
                </Box>
            </CardContent>
        </Card>
    </LocalizationProvider>;
}

export default ManualInsertPage;
