import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateEmployeeVacationTransferMutationVariables = Types.Exact<{
  userId: Types.Scalars['Int'];
  year: Types.Scalars['Int'];
  vacationMinutes: Types.Scalars['Int'];
  expirationDate: Types.Scalars['DateType'];
}>;


export type CreateEmployeeVacationTransferMutation = { __typename?: 'Mutation', employee: { __typename?: 'employeeMutations', vacation: { __typename?: 'vacationMutations', createEmployeeVacationTransfer: any } } };


export const CreateEmployeeVacationTransferDocument = gql`
    mutation CreateEmployeeVacationTransfer($userId: Int!, $year: Int!, $vacationMinutes: Int!, $expirationDate: DateType!) {
  employee {
    vacation {
      createEmployeeVacationTransfer(
        userId: $userId
        year: $year
        vacationMinutes: $vacationMinutes
        expirationDate: $expirationDate
      )
    }
  }
}
    `;
export type CreateEmployeeVacationTransferMutationFn = Apollo.MutationFunction<CreateEmployeeVacationTransferMutation, CreateEmployeeVacationTransferMutationVariables>;

/**
 * __useCreateEmployeeVacationTransferMutation__
 *
 * To run a mutation, you first call `useCreateEmployeeVacationTransferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEmployeeVacationTransferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEmployeeVacationTransferMutation, { data, loading, error }] = useCreateEmployeeVacationTransferMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      year: // value for 'year'
 *      vacationMinutes: // value for 'vacationMinutes'
 *      expirationDate: // value for 'expirationDate'
 *   },
 * });
 */
export function useCreateEmployeeVacationTransferMutation(baseOptions?: Apollo.MutationHookOptions<CreateEmployeeVacationTransferMutation, CreateEmployeeVacationTransferMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEmployeeVacationTransferMutation, CreateEmployeeVacationTransferMutationVariables>(CreateEmployeeVacationTransferDocument, options);
      }
export type CreateEmployeeVacationTransferMutationHookResult = ReturnType<typeof useCreateEmployeeVacationTransferMutation>;
export type CreateEmployeeVacationTransferMutationResult = Apollo.MutationResult<CreateEmployeeVacationTransferMutation>;
export type CreateEmployeeVacationTransferMutationOptions = Apollo.BaseMutationOptions<CreateEmployeeVacationTransferMutation, CreateEmployeeVacationTransferMutationVariables>;