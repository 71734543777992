import * as Types from '../../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateTimeScheduleMutationVariables = Types.Exact<{
  userId: Types.Scalars['Int'];
  startDate: Types.Scalars['DateType'];
  endDate?: Types.InputMaybe<Types.Scalars['DateType']>;
  monday: Types.WorkingTimeInput;
  tuesday: Types.WorkingTimeInput;
  wednesday: Types.WorkingTimeInput;
  thursday: Types.WorkingTimeInput;
  friday: Types.WorkingTimeInput;
  saturday: Types.WorkingTimeInput;
  sunday: Types.WorkingTimeInput;
}>;


export type CreateTimeScheduleMutation = { __typename?: 'Mutation', employee: { __typename?: 'employeeMutations', timeSchedule: { __typename?: 'timeScheduleMutations', createTimeSchedule: any } } };


export const CreateTimeScheduleDocument = gql`
    mutation CreateTimeSchedule($userId: Int!, $startDate: DateType!, $endDate: DateType, $monday: WorkingTimeInput!, $tuesday: WorkingTimeInput!, $wednesday: WorkingTimeInput!, $thursday: WorkingTimeInput!, $friday: WorkingTimeInput!, $saturday: WorkingTimeInput!, $sunday: WorkingTimeInput!) {
  employee {
    timeSchedule {
      createTimeSchedule(
        userId: $userId
        startDate: $startDate
        endDate: $endDate
        monday: $monday
        tuesday: $tuesday
        wednesday: $wednesday
        thursday: $thursday
        friday: $friday
        saturday: $saturday
        sunday: $sunday
      )
    }
  }
}
    `;
export type CreateTimeScheduleMutationFn = Apollo.MutationFunction<CreateTimeScheduleMutation, CreateTimeScheduleMutationVariables>;

/**
 * __useCreateTimeScheduleMutation__
 *
 * To run a mutation, you first call `useCreateTimeScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTimeScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTimeScheduleMutation, { data, loading, error }] = useCreateTimeScheduleMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      monday: // value for 'monday'
 *      tuesday: // value for 'tuesday'
 *      wednesday: // value for 'wednesday'
 *      thursday: // value for 'thursday'
 *      friday: // value for 'friday'
 *      saturday: // value for 'saturday'
 *      sunday: // value for 'sunday'
 *   },
 * });
 */
export function useCreateTimeScheduleMutation(baseOptions?: Apollo.MutationHookOptions<CreateTimeScheduleMutation, CreateTimeScheduleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTimeScheduleMutation, CreateTimeScheduleMutationVariables>(CreateTimeScheduleDocument, options);
      }
export type CreateTimeScheduleMutationHookResult = ReturnType<typeof useCreateTimeScheduleMutation>;
export type CreateTimeScheduleMutationResult = Apollo.MutationResult<CreateTimeScheduleMutation>;
export type CreateTimeScheduleMutationOptions = Apollo.BaseMutationOptions<CreateTimeScheduleMutation, CreateTimeScheduleMutationVariables>;