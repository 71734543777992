import {MenuItem, Select, SelectChangeEvent, Stack, Typography} from "@mui/material";
import React, {useEffect} from "react";
import {
    AdditionalFieldSettingFragment,
} from "../../../../fragments/inheritableSettings.generated";
import {AdditionalFieldEnforcement} from "../../../../generated/graphql";
import {EntityInformation, SettingUpdateResponse} from "../../types";
import {useUpdateWorkPlaceAdditionalFieldSettingMutation} from "./updateWorkPlaceAdditionalFieldSetting.generated";

export interface TrackingClearanceSettingProps {
    entity: EntityInformation;
    setting?: AdditionalFieldSettingFragment;
    onUpdate?: (response: Promise<SettingUpdateResponse>) => void;
}

export const TrackingWorkPlaceSetting = ({entity, setting, onUpdate}: TrackingClearanceSettingProps) => {
    const fieldValue = setting?.additionalFieldEnforcement ?? 'disabled';
    const [additionalFieldEnforcement, setAdditionalFieldEnforcement] = React.useState<AdditionalFieldEnforcement>(fieldValue);

    useEffect(() => {
        setAdditionalFieldEnforcement(fieldValue);
    }, [fieldValue]);

    const [updateWorkPlaceAdditionalFieldSettingMutation] = useUpdateWorkPlaceAdditionalFieldSettingMutation();

    const handleChange = async (value: SelectChangeEvent<AdditionalFieldEnforcement>) => {
        const newValue = value.target.value as AdditionalFieldEnforcement;
        setAdditionalFieldEnforcement(newValue);

        const responsePromise: Promise<SettingUpdateResponse> = updateWorkPlaceAdditionalFieldSettingMutation({
            variables: {
                settingEntityType: entity.entityType,
                entityId: entity.entityId,
                additionalFieldEnforcement: newValue
            }
        }).then(({data, errors}) => ({
            updatedSetting: data?.setting.trackingSetting.updateWorkPlaceAdditionalFieldSetting, errors: errors
        }));

        if (onUpdate) {
            onUpdate(responsePromise);
        }
    };

    return <Stack gap={0}>
        <Typography>Ortsangabe bei Stundenbuchungen</Typography>
        <Select
            id='commentFieldEnforcement'
            name='commentFieldEnforcement'
            labelId="comment-field-enforcement-label"
            size='small'
            placeholder='Ortsangabe bei Stundenbuchungen'
            onChange={handleChange}
            value={additionalFieldEnforcement}
            fullWidth
            displayEmpty
        >
            <MenuItem value='disabled'>Deaktiviert</MenuItem>
            {/**<MenuItem value='optional'>Optional</MenuItem> TODO: Disable again after old settings are deprecated**/}
            <MenuItem value='required'>Erforderlich</MenuItem>
        </Select>
    </Stack>;
};
