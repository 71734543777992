import React, {useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Menu, MenuItem} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {useNavigate} from "react-router-dom";
import {WorkPlaceFragment} from "../../../fragments/workPlace.generated";
import {LoadingButton} from "@mui/lab";
import {useDeleteWorkPlaceMutation} from "./deleteWorkPlace.generated";
import {toast} from "react-toastify";

interface WorkPlaceContextMenuProps {
    workPlace: WorkPlaceFragment;
    onUpdate: () => void;
}

export const WorkPlaceContextMenu = ({workPlace, onUpdate}: WorkPlaceContextMenuProps) => {
    const navigate = useNavigate();

    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [deleteWorkPlaceMutation, {loading: deleteWorkPlaceLoading}] = useDeleteWorkPlaceMutation();

    const deleteWorkPlace = async () => {
        const {data, errors} = await deleteWorkPlaceMutation({
            variables: {
                workPlaceId: workPlace.workPlaceId
            }
        });

        if (errors) {
            const error = errors[0];
            const reason = error.extensions?.['reason'];

            if (reason === 'AuthenticationRequired') {
                toast.error('Melde dich erneut an und versuche es nochmal.');
            } else if (reason === 'PermissionDenied') {
                toast.error('Du hast keine Berechtigung diesen Standort zu löschen');
            } else if (reason === 'UserNotFound') {
                toast.error('Der Nutzer konnte nicht gefunden werden');
            } else if (reason === 'WorkPlaceNotFound') {
                toast.error('Der Arbeitsplatz konnte nicht gefunden werden');
            }
        } else if (data) {
            onUpdate();
            toast.success(`Standort wurde erfolgreich gelöscht`);
        } else {
            toast.error('Ein unbekannter Fehler ist aufgetreten');
        }
    };

    return (
        <>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                sx={{paddingLeft: '2px', paddingRight: '2px', color: '#212529'}}
            >
                <MoreVertIcon/>
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={() => {
                    navigate(`/work-place/${workPlace.workPlaceId}`);
                }}>
                    Bearbeiten
                </MenuItem>
                <MenuItem onClick={() => {
                    setDeleteDialogOpen(true);
                }}>
                    Löschen
                </MenuItem>
            </Menu>
            <Dialog
                open={deleteDialogOpen}
                aria-labelledby="delete-dialog-title"
                aria-describedby="delete-dialog-description"
                fullWidth
                onClose={() => setDeleteDialogOpen(false)}
            >
                <DialogTitle>Standort löschen?</DialogTitle>
                <DialogContent>
                    Bist du sicher, dass du diesen Standort löschen möchtest?
                </DialogContent>
                <DialogActions sx={{gap: 2, mb: 2, mr: 2}}>
                    <Button variant='contained' color='inherit'
                            onClick={() => setDeleteDialogOpen(false)}>Abbrechen</Button>
                    <LoadingButton loading={deleteWorkPlaceLoading} variant='contained' color='error'
                                   onClick={() => deleteWorkPlace()}>Löschen</LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
};
