import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateBalanceCompensationMutationVariables = Types.Exact<{
  userId: Types.Scalars['Int'];
  date: Types.Scalars['DateType'];
  balanceCompensationType: Types.BalanceCompensationType;
  hours: Types.Scalars['Int'];
  minutes: Types.Scalars['Int'];
}>;


export type CreateBalanceCompensationMutation = { __typename?: 'Mutation', time: { __typename?: 'timeMutations', createBalanceCompensation: any } };


export const CreateBalanceCompensationDocument = gql`
    mutation CreateBalanceCompensation($userId: Int!, $date: DateType!, $balanceCompensationType: BalanceCompensationType!, $hours: Int!, $minutes: Int!) {
  time {
    createBalanceCompensation(
      userId: $userId
      date: $date
      balanceCompensationType: $balanceCompensationType
      hours: $hours
      minutes: $minutes
    )
  }
}
    `;
export type CreateBalanceCompensationMutationFn = Apollo.MutationFunction<CreateBalanceCompensationMutation, CreateBalanceCompensationMutationVariables>;

/**
 * __useCreateBalanceCompensationMutation__
 *
 * To run a mutation, you first call `useCreateBalanceCompensationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBalanceCompensationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBalanceCompensationMutation, { data, loading, error }] = useCreateBalanceCompensationMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      date: // value for 'date'
 *      balanceCompensationType: // value for 'balanceCompensationType'
 *      hours: // value for 'hours'
 *      minutes: // value for 'minutes'
 *   },
 * });
 */
export function useCreateBalanceCompensationMutation(baseOptions?: Apollo.MutationHookOptions<CreateBalanceCompensationMutation, CreateBalanceCompensationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBalanceCompensationMutation, CreateBalanceCompensationMutationVariables>(CreateBalanceCompensationDocument, options);
      }
export type CreateBalanceCompensationMutationHookResult = ReturnType<typeof useCreateBalanceCompensationMutation>;
export type CreateBalanceCompensationMutationResult = Apollo.MutationResult<CreateBalanceCompensationMutation>;
export type CreateBalanceCompensationMutationOptions = Apollo.BaseMutationOptions<CreateBalanceCompensationMutation, CreateBalanceCompensationMutationVariables>;