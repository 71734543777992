import * as Types from '../../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WorkPlaceHolidayInformationQueryVariables = Types.Exact<{
  workPlaceId: Types.Scalars['Int'];
}>;


export type WorkPlaceHolidayInformationQuery = { __typename?: 'Query', holiday: { __typename?: 'holidayQueries', listPredefinedHolidays: Array<{ __typename?: 'PredefinedHoliday', holidayId: number, name: string, isSelected: boolean }>, listCustomHolidays: Array<{ __typename?: 'CustomHoliday', restPeriodId: number, name: string, date: any, repeatPeriod: Types.RestRepeatPeriod }> } };


export const WorkPlaceHolidayInformationDocument = gql`
    query WorkPlaceHolidayInformation($workPlaceId: Int!) {
  holiday {
    listPredefinedHolidays(workPlaceId: $workPlaceId) {
      holidayId
      name
      isSelected
    }
    listCustomHolidays(workPlaceId: $workPlaceId) {
      restPeriodId
      name
      date
      repeatPeriod
    }
  }
}
    `;

/**
 * __useWorkPlaceHolidayInformationQuery__
 *
 * To run a query within a React component, call `useWorkPlaceHolidayInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkPlaceHolidayInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkPlaceHolidayInformationQuery({
 *   variables: {
 *      workPlaceId: // value for 'workPlaceId'
 *   },
 * });
 */
export function useWorkPlaceHolidayInformationQuery(baseOptions: Apollo.QueryHookOptions<WorkPlaceHolidayInformationQuery, WorkPlaceHolidayInformationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkPlaceHolidayInformationQuery, WorkPlaceHolidayInformationQueryVariables>(WorkPlaceHolidayInformationDocument, options);
      }
export function useWorkPlaceHolidayInformationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkPlaceHolidayInformationQuery, WorkPlaceHolidayInformationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkPlaceHolidayInformationQuery, WorkPlaceHolidayInformationQueryVariables>(WorkPlaceHolidayInformationDocument, options);
        }
export type WorkPlaceHolidayInformationQueryHookResult = ReturnType<typeof useWorkPlaceHolidayInformationQuery>;
export type WorkPlaceHolidayInformationLazyQueryHookResult = ReturnType<typeof useWorkPlaceHolidayInformationLazyQuery>;
export type WorkPlaceHolidayInformationQueryResult = Apollo.QueryResult<WorkPlaceHolidayInformationQuery, WorkPlaceHolidayInformationQueryVariables>;