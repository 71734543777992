import * as Types from '../../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TogglePredefinedHolidayMutationVariables = Types.Exact<{
  workPlaceId: Types.Scalars['Int'];
  holidayId: Types.Scalars['Int'];
}>;


export type TogglePredefinedHolidayMutation = { __typename?: 'Mutation', holiday: { __typename?: 'holidayMutations', togglePredefinedHoliday: any } };


export const TogglePredefinedHolidayDocument = gql`
    mutation TogglePredefinedHoliday($workPlaceId: Int!, $holidayId: Int!) {
  holiday {
    togglePredefinedHoliday(workPlaceId: $workPlaceId, holidayId: $holidayId)
  }
}
    `;
export type TogglePredefinedHolidayMutationFn = Apollo.MutationFunction<TogglePredefinedHolidayMutation, TogglePredefinedHolidayMutationVariables>;

/**
 * __useTogglePredefinedHolidayMutation__
 *
 * To run a mutation, you first call `useTogglePredefinedHolidayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTogglePredefinedHolidayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [togglePredefinedHolidayMutation, { data, loading, error }] = useTogglePredefinedHolidayMutation({
 *   variables: {
 *      workPlaceId: // value for 'workPlaceId'
 *      holidayId: // value for 'holidayId'
 *   },
 * });
 */
export function useTogglePredefinedHolidayMutation(baseOptions?: Apollo.MutationHookOptions<TogglePredefinedHolidayMutation, TogglePredefinedHolidayMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TogglePredefinedHolidayMutation, TogglePredefinedHolidayMutationVariables>(TogglePredefinedHolidayDocument, options);
      }
export type TogglePredefinedHolidayMutationHookResult = ReturnType<typeof useTogglePredefinedHolidayMutation>;
export type TogglePredefinedHolidayMutationResult = Apollo.MutationResult<TogglePredefinedHolidayMutation>;
export type TogglePredefinedHolidayMutationOptions = Apollo.BaseMutationOptions<TogglePredefinedHolidayMutation, TogglePredefinedHolidayMutationVariables>;