import {EntityInformation, SettingUpdateResponse} from "../../types";
import React, {useEffect} from "react";
import {Platform} from "../../../../generated/graphql";
import {Checkbox, FormControlLabel} from "@mui/material";
import {useUpdateStampingPlatformSettingMutation} from "./updateStampingPlatformSetting.generated";

export interface TrackingStampingSettingProps {
    entity: EntityInformation;
    setting?: Platform[];
    onUpdate?: (response: Promise<SettingUpdateResponse>) => void;
}

export const TrackingStampingSetting = ({entity, setting, onUpdate}: TrackingStampingSettingProps) => {
    const [platforms, setPlatforms] = React.useState<Platform[] | undefined>(setting);

    useEffect(() => {
        setPlatforms(setting);
    }, [setting]);

    const [updateStampingPlatformSettingMutation] = useUpdateStampingPlatformSettingMutation();

    const handleChange = async (platformToChange: Platform) => {
        if (!platforms) {
            return;
        }
        let newPlatforms;
        if (platforms?.indexOf(platformToChange) !== -1) {
            newPlatforms = platforms?.filter(p => p !== platformToChange);
        } else {
            newPlatforms = [...platforms, platformToChange];
        }

        const responsePromise: Promise<SettingUpdateResponse> = updateStampingPlatformSettingMutation({
            variables: {
                settingEntityType: entity.entityType,
                entityId: entity.entityId,
                platforms: newPlatforms
            }
        }).then(({data, errors}) => ({
            updatedSetting: data?.setting.trackingSetting.updateStampingPlatformSetting, errors: errors
        }));

        if (onUpdate) {
            onUpdate(responsePromise);
        }
    };

    return <>
        <FormControlLabel control={<Checkbox
            checked={platforms?.indexOf('web') !== -1}
            onChange={() => handleChange('web')}
        />} label="Darf in der Web-Oberfläche stempeln"/>
        <FormControlLabel control={<Checkbox
            checked={platforms?.indexOf('app') !== -1}
            onChange={() => handleChange('app')}
        />} label="Darf in der App stempeln"/>
        <FormControlLabel control={<Checkbox
            checked={platforms?.indexOf('terminal') !== -1}
            onChange={() => handleChange('terminal')}
        />} label="Darf am Tablet-Terminal stempeln"/>
    </>;
};
