import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ImitationCompaniesQueryVariables = Types.Exact<{
  imitationFilter: Types.ImitationCompanyFilter;
  searchQuery?: Types.InputMaybe<Types.Scalars['String']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type ImitationCompaniesQuery = { __typename?: 'Query', imitation: { __typename?: 'imitationQueries', imitationCompanies: { __typename?: 'ImitationCompaniesResult', totalCount: number, companies: Array<{ __typename?: 'ImitationCompanyInformation', companyId: number, name: string, activeEmployees: number, terminalCount: number, activeTerminalCount: number, bookingsLast7Days: number, absencesLast7Days: number, subscription: { __typename: 'ActiveSubscription', stripeSubscription: Types.StripeSubscription } | { __typename: 'CancelledSubscription' } | { __typename: 'TrialSubscription', startDate: any } }> } } };


export const ImitationCompaniesDocument = gql`
    query ImitationCompanies($imitationFilter: ImitationCompanyFilter!, $searchQuery: String, $limit: Int, $offset: Int) {
  imitation {
    imitationCompanies(
      imitationFilter: $imitationFilter
      searchQuery: $searchQuery
      limit: $limit
      offset: $offset
    ) {
      totalCount
      companies {
        companyId
        name
        activeEmployees
        terminalCount
        activeTerminalCount
        bookingsLast7Days
        absencesLast7Days
        subscription {
          __typename
          ... on ActiveSubscription {
            stripeSubscription
          }
          ... on TrialSubscription {
            startDate
          }
        }
      }
    }
  }
}
    `;

/**
 * __useImitationCompaniesQuery__
 *
 * To run a query within a React component, call `useImitationCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useImitationCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImitationCompaniesQuery({
 *   variables: {
 *      imitationFilter: // value for 'imitationFilter'
 *      searchQuery: // value for 'searchQuery'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useImitationCompaniesQuery(baseOptions: Apollo.QueryHookOptions<ImitationCompaniesQuery, ImitationCompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ImitationCompaniesQuery, ImitationCompaniesQueryVariables>(ImitationCompaniesDocument, options);
      }
export function useImitationCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ImitationCompaniesQuery, ImitationCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ImitationCompaniesQuery, ImitationCompaniesQueryVariables>(ImitationCompaniesDocument, options);
        }
export type ImitationCompaniesQueryHookResult = ReturnType<typeof useImitationCompaniesQuery>;
export type ImitationCompaniesLazyQueryHookResult = ReturnType<typeof useImitationCompaniesLazyQuery>;
export type ImitationCompaniesQueryResult = Apollo.QueryResult<ImitationCompaniesQuery, ImitationCompaniesQueryVariables>;