import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateFirstStepUserMutationVariables = Types.Exact<{
  personEmail: Types.Scalars['String'];
}>;


export type CreateFirstStepUserMutation = { __typename?: 'Mutation', admin: { __typename?: 'adminMutations', createFirstStepUser: any } };


export const CreateFirstStepUserDocument = gql`
    mutation CreateFirstStepUser($personEmail: String!) {
  admin {
    createFirstStepUser(personEmail: $personEmail)
  }
}
    `;
export type CreateFirstStepUserMutationFn = Apollo.MutationFunction<CreateFirstStepUserMutation, CreateFirstStepUserMutationVariables>;

/**
 * __useCreateFirstStepUserMutation__
 *
 * To run a mutation, you first call `useCreateFirstStepUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFirstStepUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFirstStepUserMutation, { data, loading, error }] = useCreateFirstStepUserMutation({
 *   variables: {
 *      personEmail: // value for 'personEmail'
 *   },
 * });
 */
export function useCreateFirstStepUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateFirstStepUserMutation, CreateFirstStepUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFirstStepUserMutation, CreateFirstStepUserMutationVariables>(CreateFirstStepUserDocument, options);
      }
export type CreateFirstStepUserMutationHookResult = ReturnType<typeof useCreateFirstStepUserMutation>;
export type CreateFirstStepUserMutationResult = Apollo.MutationResult<CreateFirstStepUserMutation>;
export type CreateFirstStepUserMutationOptions = Apollo.BaseMutationOptions<CreateFirstStepUserMutation, CreateFirstStepUserMutationVariables>;