import React, {useState} from 'react';
import {toast} from 'react-toastify';
import {useNavigate, useParams} from 'react-router-dom';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {useRecoverPasswordMutation} from './recover-password.generated';
import LoggedOutScreen from '../../components/LoggedOutScreen';
import {Box, FormHelperText, TextField, Typography} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {useIsEmployeeForgotPasswordTokenValidQuery} from "./is-token-valid.generated";

const RecoverPassword = () => {
    const navigate = useNavigate();
    const {token} = useParams();

    const {data} = useIsEmployeeForgotPasswordTokenValidQuery({
        variables: {token: token ?? ''}
    });

    const [recoverPassword, {loading}] = useRecoverPasswordMutation();

    const [error, setError] = useState<string>();

    const {handleChange, values, handleSubmit, handleBlur, touched, errors} = useFormik({
        initialValues: {
            password: '',
            confirmPassword: ''
        },
        validationSchema: Yup.object({
            password: Yup.string()
                .min(5, 'Dein Passwort muss mindestens 5 Zeichen lang sein')
                .max(30, 'Dein Passwort darf maximal 30 Zeichen lang sein')
                .required('Bitte gib dein neues Passwort an'),
            confirmPassword: Yup.string()
                .min(5, 'Dein Passwort muss mindestens 5 Zeichen lang sein')
                .max(30, 'Dein Passwort darf maximal 30 Zeichen lang sein')
                .required('Bitte bestätige dein neues Passwort')
                .when('password', {
                    is: (val: string) => !!(val && val.length > 0),
                    then: Yup.string().oneOf(
                        [Yup.ref('password')],
                        'Die Passwörter stimmen nicht überein'
                    )
                })
        }),
        onSubmit: async (values) => {
            const {data, errors} = await recoverPassword({
                variables: {
                    password: values.password,
                    token: token ?? ''
                }
            });

            if (!errors && data?.employee.recoverPassword === null) {
                toast.success('Passwort wurde erfolgreich zurückgesetzt.');
                navigate('/login');
            } else if (errors) {
                const error = errors[0];
                const reason = error.extensions['reason'] as unknown as string;

                switch (reason) {
                    case 'TokenInvalid':
                        setError(
                            'Das Token zum Zurücksetzen das Passworts ist bereits abgelaufen.'
                        );
                        break;
                    case 'UserNotFound':
                        setError('Der Nutzeraccount wurde bereits deaktiviert');
                        break;
                    case 'UserAlreadyVerified':
                        setError('Das Passwort konnte nicht zurückgesetzt werden.');
                        break;
                    default:
                        setError('Ein unbekannter Fehler ist aufgetreten.');
                        break;
                }
            } else {
                setError('Ein unbekannter Fehler ist aufgetreten.');
            }
        }
    });

    if (!token || !data?.token.isEmployeeForgotPasswordTokenValid) {
        return (
            <LoggedOutScreen type='forgot-password'
                             title="Token abgelaufen"
                             subTitle="Versuche erneut ein Passwort zurückzusetzen. Der Link ist nur 24 Stunden gültig."
            />
        );
    }

    return (
        <LoggedOutScreen
            type='forgot-password'
            title="Passwort zurücksetzen"
            subTitle="Gib hier dein neues Passwort an, um dein Passwort zurückzusetzen."
        >
            <Typography component='h1' variant='h4' fontWeight='bold'>Passwort zurücksetzen</Typography>
            <Typography sx={{marginTop: 1}} color='#696579'>Gib hier dein neues Passwort an, um dein Passwort zurückzusetzen.</Typography>
            <Box component='form'
                 sx={{marginTop: 5}}
                 onSubmit={handleSubmit}
            >
                <Typography fontWeight='bold' sx={{marginTop: 2}}>Passwort</Typography>
                <TextField
                    sx={{marginTop: 0.5}}
                    id="password"
                    name='password'
                    type='password'
                    className='sentry-mask'
                    placeholder="Passwort"
                    value={values.password}
                    error={!!(errors.password && touched.password)}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant='outlined'
                    fullWidth
                    size='small'
                />
                {errors.password && (
                    <FormHelperText error={!!(errors.password && touched.password)}>
                        {errors.password}
                    </FormHelperText>
                )}
                <Typography fontWeight='bold' sx={{marginTop: 2}}>Passwort bestätigen</Typography>
                <TextField
                    sx={{marginTop: 0.5}}
                    id="confirmPassword"
                    name='confirmPassword'
                    type='password'
                    className='sentry-mask'
                    placeholder="Passwort bestätigen"
                    value={values.confirmPassword}
                    error={!!(errors.confirmPassword && touched.confirmPassword)}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant='outlined'
                    fullWidth
                    size='small'
                />
                {errors.confirmPassword && (
                    <FormHelperText error={!!(errors.confirmPassword && touched.confirmPassword)}>
                        {errors.confirmPassword}
                    </FormHelperText>
                )}
                {error && (
                    <p className="text-danger">
                        <i className="fa fa-exclamation-triangle"/> {error}
                    </p>
                )}
                <LoadingButton loading={loading} variant='contained' sx={{float: 'right', marginTop: 2}} type="submit">
                    Passwort ändern
                </LoadingButton>
            </Box>
        </LoggedOutScreen>
    );
};

export default RecoverPassword;
