import React from 'react';
import * as Yup from 'yup';
import {useFormik} from 'formik';
import {useResetPasswordMutation} from './reset-password.generated';
import {toast} from 'react-toastify';
import LoggedOutScreen from '../../components/LoggedOutScreen';
import {Box, FormHelperText, TextField, Typography} from "@mui/material";
import {LoadingButton} from "@mui/lab";

const ForgotPassword = () => {
    const [resetPasswordMutation, {loading}] = useResetPasswordMutation();

    const {handleChange, values, handleSubmit, handleBlur, touched, errors} = useFormik({
        initialValues: {
            email: ''
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .email('Bitte gib eine gültige E-Mail Adresse an.')
                .required('Bitte gib eine E-Mail Adresse an.')
        }),
        onSubmit: async (values) => {
            const {data} = await resetPasswordMutation({
                variables: {
                    email: values.email
                }
            });

            if (data?.employee.resetPassword === null) {
                toast.success('Überprüfe deine Mails um ein neues Passwort zu setzen.');
            } else {
                toast.error('Passwort konnte nicht zurückgesetzt werden.');
            }
        }
    });

    return (
        <LoggedOutScreen type='forgot-password'>
            <Typography component='h1' variant='h4' fontWeight='bold'>Passwort vergessen?</Typography>
            <Typography sx={{marginTop: 1}} color='#696579'>Gebe hier deine Email-Adresse an und wir senden dir einen
                Link, um ein neues Passwort zu setzen.</Typography>
            <Box component='form'
                 sx={{marginTop: 5}}
                 onSubmit={handleSubmit}
            >
                <Typography fontWeight='bold'>E-Mail</Typography>
                <TextField
                    sx={{marginTop: 0.5}}
                    id="email"
                    name='email'
                    multiline
                    className='sentry-mask'
                    placeholder="E-Mail"
                    value={values.email}
                    error={!!(errors.email && touched.email)}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant='outlined'
                    fullWidth
                    size='small'
                />
                {errors.email && (
                    <FormHelperText error={!!(errors.email && touched.email)}>
                        {errors.email}
                    </FormHelperText>
                )}
                <LoadingButton sx={{marginTop: 2, float: 'right'}} variant='contained' type='submit'
                               loading={loading}>Absenden</LoadingButton>
            </Box>
        </LoggedOutScreen>
    );
};

export default ForgotPassword;
