import {Box, Stack} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import React, {PropsWithChildren} from "react";

export const ProductAdvantage = ({children}: PropsWithChildren) => {
  return <Stack alignItems='center' flexDirection='row' spacing={1}>
      <Box sx={{
          backgroundColor: '#6ED675',
          borderRadius: '50%',
          width: '20px',
          height: '20px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginRight: 1,
          marginTop: '5px'
      }}>
          <CheckIcon sx={{fontSize: '16px', color: '#fff'}}/>
      </Box>
      {children}
  </Stack>;
};
