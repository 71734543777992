import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CancelAbsenceRequestMutationVariables = Types.Exact<{
  absenceRequestId: Types.Scalars['Int'];
  comment?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type CancelAbsenceRequestMutation = { __typename?: 'Mutation', absence: { __typename?: 'absenceMutations', request: { __typename?: 'requestMutations', cancelAbsenceRequest: any } } };


export const CancelAbsenceRequestDocument = gql`
    mutation CancelAbsenceRequest($absenceRequestId: Int!, $comment: String) {
  absence {
    request {
      cancelAbsenceRequest(absenceRequestId: $absenceRequestId, comment: $comment)
    }
  }
}
    `;
export type CancelAbsenceRequestMutationFn = Apollo.MutationFunction<CancelAbsenceRequestMutation, CancelAbsenceRequestMutationVariables>;

/**
 * __useCancelAbsenceRequestMutation__
 *
 * To run a mutation, you first call `useCancelAbsenceRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelAbsenceRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelAbsenceRequestMutation, { data, loading, error }] = useCancelAbsenceRequestMutation({
 *   variables: {
 *      absenceRequestId: // value for 'absenceRequestId'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useCancelAbsenceRequestMutation(baseOptions?: Apollo.MutationHookOptions<CancelAbsenceRequestMutation, CancelAbsenceRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelAbsenceRequestMutation, CancelAbsenceRequestMutationVariables>(CancelAbsenceRequestDocument, options);
      }
export type CancelAbsenceRequestMutationHookResult = ReturnType<typeof useCancelAbsenceRequestMutation>;
export type CancelAbsenceRequestMutationResult = Apollo.MutationResult<CancelAbsenceRequestMutation>;
export type CancelAbsenceRequestMutationOptions = Apollo.BaseMutationOptions<CancelAbsenceRequestMutation, CancelAbsenceRequestMutationVariables>;