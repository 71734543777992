import {ContentHeader} from "../../components";
import {Box, Card, CardContent, Grid, Step, Stepper, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import CheckIcon from "@mui/icons-material/Check";
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import BusinessIcon from '@mui/icons-material/Business';
import {MTTProductStarter} from "../../components/payment/MTTProductStarter";
import {MTTProductProfessional} from "../../components/payment/MTTProductProfessional";
import {MTTProductExpert} from "../../components/payment/MTTProductExpert";
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {isSubscriptionTrialActive} from "../../utils/trial";
import {StripeSubscription} from "../../store/reducers/auth";
import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";
import {toast} from "react-toastify";
import {useCreateSubscriptionMutation} from "./createSubscription.generated";
import PaymentInformation from "./components/PaymentInformation";
import {useSearchParams} from "react-router-dom";
import PaymentResult from "./components/PaymentResult";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ?? '');

const SelectSubscriptionPage = () => {
    const user = useSelector((state: RootState) => state.auth.currentUser);
    const isTrialActive = user?.subscription.subscriptionCategory === 'trial' && isSubscriptionTrialActive(user);
    const isCancelled = user?.subscription.subscriptionCategory === 'cancelled';

    const displayText = isTrialActive ? 'Abo auswählen' : (isCancelled ? 'Dein Abo ist abgelaufen' : 'Deine Testphase ist abgelaufen, bitte wähle deinen Plan');

    return <>
        <ContentHeader title={displayText}/>
        <Card>
            <CardContent>
                <Box sx={{padding: 3}}>
                    <SelectSubscriptionForm/>
                </Box>
            </CardContent>
        </Card>
    </>;
};

export const SelectSubscriptionForm = ({onActiveStepChanged}: {onActiveStepChanged?: (activeStep: number) => void}) => {
    const [searchParams] = useSearchParams();
    const clientSecret = searchParams.get("payment_intent_client_secret");

    const [createSubscriptionMutation, {
        data: createSubscriptionData,
        loading: createSubscriptionLoading
    }] = useCreateSubscriptionMutation();

    const options = {
        // passing the client secret obtained from the server
        clientSecret: createSubscriptionData?.payment.createSubscription ?? ''
    };

    const [activeStep, setActiveStep] = useState(0);
    const [selectedSubscription, setSelectedSubscription] = useState<StripeSubscription | undefined>(undefined);

    useEffect(() => {
        if (clientSecret !== null) {
            setActiveStep(2);
        }
    }, [clientSecret, setActiveStep]);

    const selectSubscription = async (stripeSubscription: StripeSubscription) => {
        setSelectedSubscription(stripeSubscription);
        const {data} = await createSubscriptionMutation({
            variables: {
                stripeSubscription: stripeSubscription
            }
        });

        if (data) {
            if(onActiveStepChanged) {
                onActiveStepChanged(1);
            }
            setActiveStep(1);
        } else {
            toast.error('Etwas ist schiefgelaufen. Versuche es später erneut');
        }
    };

    return <>
        <Stepper activeStep={activeStep}>
            <Step key={0}>
                <SubscriptionStepperIcon isActive={activeStep === 0} icon={<FormatListBulletedIcon/>}/>
            </Step>
            <Step key={1}>
                <SubscriptionStepperIcon isActive={activeStep === 1} icon={<BusinessIcon/>}/>
            </Step>
            <Step key={2}>
                <SubscriptionStepperIcon isActive={activeStep === 2} icon={<CheckIcon/>}/>
            </Step>
        </Stepper>
        {activeStep === 0 &&
            <>
                <Grid container spacing={2} sx={{paddingTop: 2}}>
                    <Grid item md={4}>
                        <MTTProductStarter isFirstSelect={true} loading={selectedSubscription === 'standard' && createSubscriptionLoading}
                                           onSelect={() => selectSubscription('standard')}/>
                    </Grid>
                    <Grid item md={4}>
                        <MTTProductProfessional
                            isFirstSelect={true}
                            loading={selectedSubscription === 'professional' && createSubscriptionLoading}
                            onSelect={() => selectSubscription('professional')}/>
                    </Grid>
                    <Grid item md={4}>
                        <MTTProductExpert isFirstSelect={true} loading={selectedSubscription === 'expert' && createSubscriptionLoading}
                                          onSelect={() => selectSubscription('expert')}/>
                    </Grid>
                </Grid>
                <Typography sx={{mt: 3}} fontSize='small'>Alle Preise verstehen sich exkl. Umsatzsteuer</Typography>
            </>
        }
        {activeStep === 1 &&
            <Elements stripe={stripePromise} options={options}>
                <PaymentInformation/>
            </Elements>
        }
        {(activeStep === 2 && clientSecret) &&
            <Elements stripe={stripePromise}>
                <PaymentResult clientSecret={clientSecret}/>
            </Elements>
        }
    </>;
};

const SubscriptionStepperIcon = ({isActive, icon}: { isActive: boolean, icon: JSX.Element }) => {
    return <Box sx={{
        width: '50px',
        height: '50px',
        borderRadius: '50%',
        backgroundColor: isActive ? '#3B3C40' : '#F7F7F7',
        color: isActive ? '#fff' : '#000',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }}>
        {icon}
    </Box>;
};

export default SelectSubscriptionPage;
