import * as Types from '../../../../generated/graphql';

import { InheritableSettingsFragment } from '../../../../fragments/inheritableSettings.generated';
import { gql } from '@apollo/client';
import { InheritableSettingsFragmentDoc } from '../../../../fragments/inheritableSettings.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateWorkPlaceAdditionalFieldSettingMutationVariables = Types.Exact<{
  settingEntityType: Types.SettingEntityType;
  entityId?: Types.InputMaybe<Types.Scalars['Int']>;
  additionalFieldEnforcement: Types.AdditionalFieldEnforcement;
}>;


export type UpdateWorkPlaceAdditionalFieldSettingMutation = { __typename?: 'Mutation', setting: { __typename?: 'settingMutations', trackingSetting: { __typename?: 'trackingSettingMutations', updateWorkPlaceAdditionalFieldSetting: (
        { __typename?: 'InheritableSettings' }
        & InheritableSettingsFragment
      ) } } };


export const UpdateWorkPlaceAdditionalFieldSettingDocument = gql`
    mutation UpdateWorkPlaceAdditionalFieldSetting($settingEntityType: SettingEntityType!, $entityId: Int, $additionalFieldEnforcement: AdditionalFieldEnforcement!) {
  setting {
    trackingSetting {
      updateWorkPlaceAdditionalFieldSetting(
        settingEntityType: $settingEntityType
        entityId: $entityId
        additionalFieldEnforcement: $additionalFieldEnforcement
      ) {
        ...InheritableSettings
      }
    }
  }
}
    ${InheritableSettingsFragmentDoc}`;
export type UpdateWorkPlaceAdditionalFieldSettingMutationFn = Apollo.MutationFunction<UpdateWorkPlaceAdditionalFieldSettingMutation, UpdateWorkPlaceAdditionalFieldSettingMutationVariables>;

/**
 * __useUpdateWorkPlaceAdditionalFieldSettingMutation__
 *
 * To run a mutation, you first call `useUpdateWorkPlaceAdditionalFieldSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkPlaceAdditionalFieldSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkPlaceAdditionalFieldSettingMutation, { data, loading, error }] = useUpdateWorkPlaceAdditionalFieldSettingMutation({
 *   variables: {
 *      settingEntityType: // value for 'settingEntityType'
 *      entityId: // value for 'entityId'
 *      additionalFieldEnforcement: // value for 'additionalFieldEnforcement'
 *   },
 * });
 */
export function useUpdateWorkPlaceAdditionalFieldSettingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWorkPlaceAdditionalFieldSettingMutation, UpdateWorkPlaceAdditionalFieldSettingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWorkPlaceAdditionalFieldSettingMutation, UpdateWorkPlaceAdditionalFieldSettingMutationVariables>(UpdateWorkPlaceAdditionalFieldSettingDocument, options);
      }
export type UpdateWorkPlaceAdditionalFieldSettingMutationHookResult = ReturnType<typeof useUpdateWorkPlaceAdditionalFieldSettingMutation>;
export type UpdateWorkPlaceAdditionalFieldSettingMutationResult = Apollo.MutationResult<UpdateWorkPlaceAdditionalFieldSettingMutation>;
export type UpdateWorkPlaceAdditionalFieldSettingMutationOptions = Apollo.BaseMutationOptions<UpdateWorkPlaceAdditionalFieldSettingMutation, UpdateWorkPlaceAdditionalFieldSettingMutationVariables>;