import * as Types from '../generated/graphql';

import { gql } from '@apollo/client';
export type CompanySettingFragment = { __typename?: 'CompanySetting', workingBalanceDisabled: boolean, commentsEnabled: boolean, workingPlaceEnabled: boolean, editAbsenceTimeEnabled: boolean, workingPlaces: Array<string> };

export const CompanySettingFragmentDoc = gql`
    fragment CompanySetting on CompanySetting {
  workingBalanceDisabled
  commentsEnabled
  workingPlaceEnabled
  editAbsenceTimeEnabled
  workingPlaces
}
    `;