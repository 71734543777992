import * as Types from '../generated/graphql';

import { WorkingTimeFragment } from './workingTime.generated';
import { gql } from '@apollo/client';
import { WorkingTimeFragmentDoc } from './workingTime.generated';
export type AbsenceRowFragment = { __typename?: 'AbsenceRow', absenceRequestId: number, date: any, absenceType: Types.AbsenceType, absencePeriod?: Types.AbsencePeriod | null, originAbsenceRequestId?: number | null, workingTime: (
    { __typename?: 'WorkingTime' }
    & WorkingTimeFragment
  ) };

export const AbsenceRowFragmentDoc = gql`
    fragment AbsenceRow on AbsenceRow {
  absenceRequestId
  date
  absenceType
  workingTime {
    ...WorkingTime
  }
  absencePeriod
  originAbsenceRequestId
}
    ${WorkingTimeFragmentDoc}`;