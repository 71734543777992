import {DateRangePicker} from "rsuite";
import {subDays} from "rsuite/cjs/utils/dateUtils";
import {DateTime, Duration} from "luxon";
import React from "react";
import {useWorkingTimeWithRangeQuery} from "../workingTimeWithRange.generated";
import EmployeeHoursTable from "../../../components/organisms/EmployeeHoursTable";
import {Box, Button, Grid, Stack, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import SaldoTimeText from "../../../components/molecules/SaldoTimeText";
import {hasAtLeastSubscription} from "../../../utils/subscription";
import {useSelector} from "react-redux";
import {RootState} from "../../../store/store";
import {CurrentTimePeriodInformation} from "../../../components/molecules/CurrentTimePeriodInformation";
import {
    OvertimeCompensationDialog
} from "../../../components/organisms/OvertimeCompensationDialog/OvertimeCompensationDialog";

const EmployeeHours = ({userId}: { userId: number }) => {
    const user = useSelector((state: RootState) => state.auth.currentUser);

    const [overtimeCompensationDialogOpen, setOvertimeCompensationDialogOpen] = React.useState(false);

    const navigate = useNavigate();

    const startDate = DateTime.now().minus(Duration.fromObject({day: 29}));
    const endDate = DateTime.now();

    const {data: workingTimeData, refetch: refetchWorkingTimeQuery} =
        useWorkingTimeWithRangeQuery({
            variables: {
                startDate: startDate.toFormat('yyyy-MM-dd'),
                endDate: endDate.toFormat('yyyy-MM-dd'),
                userId: userId
            },
            fetchPolicy: 'no-cache'
        });

    const closeOvertimeCompensationDialog = (update: boolean) => {
        setOvertimeCompensationDialogOpen(false);
        if (update) {
            refetchWorkingTimeQuery();
        }
    };

    const hasManualEntryPermission = workingTimeData?.permission.trackingPermission.canCreateManualEntry ?? false;

    const showBalanceOverview = hasAtLeastSubscription(user, 'professional');

    return <>
        <Grid container justifyContent='space-between'>
            <Grid item sx={{width: {xs: '100%', sm: 'auto'}}}>
                {hasManualEntryPermission &&
                    <Stack direction='row' gap={2}>
                        <Button variant='contained' onClick={() => navigate(`/manual-insert/${userId}`)}>
                            Eintragen
                        </Button>
                        {showBalanceOverview &&
                            <>
                                <Button variant='contained'
                                        onClick={() => setOvertimeCompensationDialogOpen(true)}>Überstundenausgleich</Button>
                                <OvertimeCompensationDialog
                                    userId={userId}
                                    open={overtimeCompensationDialogOpen}
                                    onClose={closeOvertimeCompensationDialog}
                                    totalBalance={workingTimeData?.time.totalBalance ?? {hours: 0, minutes: 0}}
                                />
                            </>
                        }
                    </Stack>
                }
            </Grid>
            <Grid item sx={{width: {xs: '100%', sm: 'auto'}, paddingTop: {xs: 2, sm: 0}}}>
                <DateRangePicker
                    preventOverflow={true}
                    placement="bottomEnd"
                    format="dd.MM.yyyy"
                    character=" - "
                    ranges={[
                        {
                            label: 'Letzte 7 Tage',
                            value: [subDays(new Date(), 6), new Date()]
                        },
                        {
                            label: 'Letzte 30 Tage',
                            value: [subDays(new Date(), 29), new Date()]
                        },
                        {
                            label: 'Aktueller Monat',
                            value: [DateTime.now().startOf('month').toJSDate(), DateTime.now().endOf('month').toJSDate()]
                        }
                    ]}
                    defaultValue={[subDays(new Date(), 29), new Date()]}
                    onChange={(dateRange) => {
                        if (dateRange != null) {
                            const startDate = DateTime.fromJSDate(dateRange[0]);
                            const endDate = DateTime.fromJSDate(dateRange[1]);

                            refetchWorkingTimeQuery({
                                startDate: startDate.toFormat('yyyy-MM-dd'),
                                endDate: endDate.toFormat('yyyy-MM-dd'),
                                userId: userId
                            });
                        }
                    }}
                />
            </Grid>
        </Grid>
        <div style={{marginTop: '2rem'}}>
            <Grid container sx={{mb: 4}} spacing={2}>
                {workingTimeData?.tracking.workingTimeThisWeek &&
                    <Grid item xs={12} sm={showBalanceOverview ? 9 : 12}>
                        <CurrentTimePeriodInformation timeInformation={workingTimeData?.time.timeInformationWithRange}/>
                    </Grid>
                }
                {showBalanceOverview &&
                    <Grid item xs={12} sm={3}>
                        <Box sx={{backgroundColor: '#F2F2F2', borderRadius: '10px', padding: 2}}>
                            <Typography fontWeight='bold'>Insgesamt</Typography>
                            <Box sx={{marginTop: 2, padding: 1}}>
                                <Typography>Überstundenkonto</Typography>
                                <SaldoTimeText saldo={workingTimeData?.time.totalBalance ?? {hours: 0, minutes: 0}}/>
                            </Box>
                        </Box>
                    </Grid>
                }
            </Grid>
            <EmployeeHoursTable employeeId={userId} hours={workingTimeData?.time.timeInformationWithRange.timeRows}
                                onChange={refetchWorkingTimeQuery}/>
        </div>
    </>
};


export default EmployeeHours;
