import React from 'react';
import {createRoot} from 'react-dom/client';
import 'rsuite/dist/rsuite.min.css';
import 'font-awesome/css/font-awesome.min.css';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-nestable/dist/styles/index.css';
import './index.scss';
import App from './App';
import {Provider} from 'react-redux';
import store from './store/store';
import {BrowserRouter, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType} from 'react-router-dom';
import TagManager from 'react-gtm-module';
import {createTheme, ThemeProvider} from '@mui/material';
import ReactGA from "react-ga4";
import translationEN from "./locales/en/translation.json";
import translationDE from "./locales/de/translation.json";
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";
import * as Sentry from "@sentry/react";

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: "https://d7df6929ab88181e922238220bff0709@o4507101094936576.ingest.de.sentry.io/4507101096509520",
        integrations: [
            Sentry.reactRouterV6BrowserTracingIntegration({
                useEffect: React.useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes
            }),
            Sentry.replayIntegration({
                maskAllText: false,
                maskAllInputs: false,
                blockAllMedia: false
            })
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });

    TagManager.initialize({
        gtmId: 'GTM-NGZQLCT'
    });
}

const theme = createTheme({
    palette: {
        primary: {
            main: '#2453C8',
        },
        error: {
            main: '#DB534C'
        },
        warning: {
            main: '#F1C444'
        },
        success: {
            main: '#38D968'
        }
    }
});

ReactGA.initialize('G-SG27C2195H', {
    gtagOptions: {
        send_page_view: false
    }
});

i18next.init({
    interpolation: {escapeValue: false},
    lng: 'de',
    fallbackLng: 'de',
    resources: {
        en: {
            translation: translationEN,
        },
        de: {
            translation: translationDE,
        }
    }
});

const container: any = document.getElementById('root');
const root = createRoot(container);

root.render(
    <Sentry.ErrorBoundary>
        <I18nextProvider i18n={i18next}>
            <Provider store={store}>
                <BrowserRouter>
                    <ThemeProvider theme={theme}>
                        <App/>
                    </ThemeProvider>
                </BrowserRouter>
            </Provider>
        </I18nextProvider>
    </Sentry.ErrorBoundary>
);
