import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateWorkPlaceMutationVariables = Types.Exact<{
  name: Types.Scalars['String'];
  country?: Types.InputMaybe<Types.Scalars['String']>;
  state?: Types.InputMaybe<Types.Scalars['String']>;
  city?: Types.InputMaybe<Types.Scalars['String']>;
  address?: Types.InputMaybe<Types.Scalars['String']>;
  postalCode?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type CreateWorkPlaceMutation = { __typename?: 'Mutation', workPlace: { __typename?: 'workPlaceMutations', createWorkPlace: number } };


export const CreateWorkPlaceDocument = gql`
    mutation CreateWorkPlace($name: String!, $country: String, $state: String, $city: String, $address: String, $postalCode: String) {
  workPlace {
    createWorkPlace(
      name: $name
      country: $country
      state: $state
      city: $city
      address: $address
      postalCode: $postalCode
    )
  }
}
    `;
export type CreateWorkPlaceMutationFn = Apollo.MutationFunction<CreateWorkPlaceMutation, CreateWorkPlaceMutationVariables>;

/**
 * __useCreateWorkPlaceMutation__
 *
 * To run a mutation, you first call `useCreateWorkPlaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkPlaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkPlaceMutation, { data, loading, error }] = useCreateWorkPlaceMutation({
 *   variables: {
 *      name: // value for 'name'
 *      country: // value for 'country'
 *      state: // value for 'state'
 *      city: // value for 'city'
 *      address: // value for 'address'
 *      postalCode: // value for 'postalCode'
 *   },
 * });
 */
export function useCreateWorkPlaceMutation(baseOptions?: Apollo.MutationHookOptions<CreateWorkPlaceMutation, CreateWorkPlaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateWorkPlaceMutation, CreateWorkPlaceMutationVariables>(CreateWorkPlaceDocument, options);
      }
export type CreateWorkPlaceMutationHookResult = ReturnType<typeof useCreateWorkPlaceMutation>;
export type CreateWorkPlaceMutationResult = Apollo.MutationResult<CreateWorkPlaceMutation>;
export type CreateWorkPlaceMutationOptions = Apollo.BaseMutationOptions<CreateWorkPlaceMutation, CreateWorkPlaceMutationVariables>;