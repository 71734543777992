import * as Types from '../generated/graphql';

import { TrackingRowFragment } from './trackingRow.generated';
import { WorkingTimeFragment } from './workingTime.generated';
import { gql } from '@apollo/client';
import { TrackingRowFragmentDoc } from './trackingRow.generated';
import { WorkingTimeFragmentDoc } from './workingTime.generated';
export type WorkingTimeByDayFragment = { __typename?: 'WorkingTimeByDay', date: any, trackingNotes: Array<Types.TrackingNote>, rows: Array<(
    { __typename?: 'TrackingRow' }
    & TrackingRowFragment
  )>, workingTime: (
    { __typename?: 'WorkingTime' }
    & WorkingTimeFragment
  ), breakingTime: (
    { __typename?: 'WorkingTime' }
    & WorkingTimeFragment
  ), saldo?: (
    { __typename?: 'WorkingTime' }
    & WorkingTimeFragment
  ) | null, absenceTime?: (
    { __typename?: 'WorkingTime' }
    & WorkingTimeFragment
  ) | null };

export const WorkingTimeByDayFragmentDoc = gql`
    fragment WorkingTimeByDay on WorkingTimeByDay {
  date
  rows {
    ...TrackingRow
  }
  workingTime {
    ...WorkingTime
  }
  breakingTime {
    ...WorkingTime
  }
  saldo {
    ...WorkingTime
  }
  absenceTime {
    ...WorkingTime
  }
  trackingNotes
}
    ${TrackingRowFragmentDoc}
${WorkingTimeFragmentDoc}`;