import * as Types from '../../../../generated/graphql';

import { InheritableSettingsFragment } from '../../../../fragments/inheritableSettings.generated';
import { gql } from '@apollo/client';
import { InheritableSettingsFragmentDoc } from '../../../../fragments/inheritableSettings.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateEditDeleteClearanceSettingMutationVariables = Types.Exact<{
  settingEntityType: Types.SettingEntityType;
  entityId?: Types.InputMaybe<Types.Scalars['Int']>;
  trackingClearanceType: Types.TrackingClearanceType;
  dayLimit?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type UpdateEditDeleteClearanceSettingMutation = { __typename?: 'Mutation', setting: { __typename?: 'settingMutations', trackingSetting: { __typename?: 'trackingSettingMutations', updateEditDeleteClearanceSetting: (
        { __typename?: 'InheritableSettings' }
        & InheritableSettingsFragment
      ) } } };


export const UpdateEditDeleteClearanceSettingDocument = gql`
    mutation UpdateEditDeleteClearanceSetting($settingEntityType: SettingEntityType!, $entityId: Int, $trackingClearanceType: TrackingClearanceType!, $dayLimit: Int) {
  setting {
    trackingSetting {
      updateEditDeleteClearanceSetting(
        settingEntityType: $settingEntityType
        entityId: $entityId
        trackingClearance: $trackingClearanceType
        dayLimit: $dayLimit
      ) {
        ...InheritableSettings
      }
    }
  }
}
    ${InheritableSettingsFragmentDoc}`;
export type UpdateEditDeleteClearanceSettingMutationFn = Apollo.MutationFunction<UpdateEditDeleteClearanceSettingMutation, UpdateEditDeleteClearanceSettingMutationVariables>;

/**
 * __useUpdateEditDeleteClearanceSettingMutation__
 *
 * To run a mutation, you first call `useUpdateEditDeleteClearanceSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEditDeleteClearanceSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEditDeleteClearanceSettingMutation, { data, loading, error }] = useUpdateEditDeleteClearanceSettingMutation({
 *   variables: {
 *      settingEntityType: // value for 'settingEntityType'
 *      entityId: // value for 'entityId'
 *      trackingClearanceType: // value for 'trackingClearanceType'
 *      dayLimit: // value for 'dayLimit'
 *   },
 * });
 */
export function useUpdateEditDeleteClearanceSettingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEditDeleteClearanceSettingMutation, UpdateEditDeleteClearanceSettingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEditDeleteClearanceSettingMutation, UpdateEditDeleteClearanceSettingMutationVariables>(UpdateEditDeleteClearanceSettingDocument, options);
      }
export type UpdateEditDeleteClearanceSettingMutationHookResult = ReturnType<typeof useUpdateEditDeleteClearanceSettingMutation>;
export type UpdateEditDeleteClearanceSettingMutationResult = Apollo.MutationResult<UpdateEditDeleteClearanceSettingMutation>;
export type UpdateEditDeleteClearanceSettingMutationOptions = Apollo.BaseMutationOptions<UpdateEditDeleteClearanceSettingMutation, UpdateEditDeleteClearanceSettingMutationVariables>;