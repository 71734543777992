import {Card, CardContent, Typography,} from "@mui/material";
import React from "react";
import {DateTime} from "luxon";
import './calendar.css';
import {useCalendarEventsLazyQuery} from "./calendarEvents.generated";
import {hasAtLeastSubscription} from "../../utils/subscription";
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {ContentHeader} from "../../components";
import { CalendarWidget } from "../../components/organisms/CalendarWidget";

export const CalendarPage = () => {
    const user = useSelector((state: RootState) => state.auth.currentUser);
    const [calenderEventsQuery, {data: eventData, refetch: refetchCalendarEventsQuery}] = useCalendarEventsLazyQuery();

    const calendarEvents = eventData?.calendar.companyEvents ?? [];
    const employees = eventData?.employee.listEmployees ?? [];

    const showCalendarUpgradeNote = user?.userType === 'admin' && !hasAtLeastSubscription(user, 'expert');

    const onDateChanged = (startDate: DateTime, endDate: DateTime) => {
        calenderEventsQuery({
            variables: {
                startDate: startDate.toFormat('yyyy-MM-dd'),
                endDate: endDate.toFormat('yyyy-MM-dd'),
            }
        });
    };

    let contentHeaderText;
    if (showCalendarUpgradeNote) {
        contentHeaderText = 'Teamkalender: Jetzt im November kostenfrei testen!*';
    } else {
        contentHeaderText = 'Teamkalender';
    }

    return <div>
        <ContentHeader title={contentHeaderText}/>
        {showCalendarUpgradeNote &&
            <Typography sx={{marginBottom: 4}}>*ab 01.12.2023 ist der Teamkalender nur noch im Paket "Expert"
                verfügbar</Typography>
        }
        <Card>
            <CardContent>
                <CalendarWidget calendarEvents={calendarEvents} employees={employees} onChangeDates={onDateChanged} onEditSave={refetchCalendarEventsQuery} />
            </CardContent>
        </Card>
    </div>
}
