import {ContentHeader} from "../../components";
import React from "react";
import EmployeeHoursTable from "../../components/organisms/EmployeeHoursTable";
import {useWorkingTimeWithRangeQuery} from "../viewemployee/workingTimeWithRange.generated";
import {DateTime, Duration} from "luxon";
import {subDays} from "rsuite/cjs/utils/dateUtils";
import {DateRangePicker} from "rsuite";
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {Box, Button, Card, CardContent, Grid} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {ErrorScreen} from "../../components/organisms/ErrorScreen";
import {CurrentTimePeriodInformation} from "../../components/molecules/CurrentTimePeriodInformation";
import {useTranslation} from "react-i18next";

const HoursPage = () => {
    const {t} = useTranslation();

    const navigate = useNavigate();

    const startDate = DateTime.now().minus(Duration.fromObject({day: 29}));
    const endDate = DateTime.now();

    const user = useSelector((state: RootState) => state.auth.currentUser);

    const {data: workingTimeData, refetch: refetchWorkingTimeQuery, error} =
        useWorkingTimeWithRangeQuery({
            variables: {
                startDate: startDate.toFormat('yyyy-MM-dd'),
                endDate: endDate.toFormat('yyyy-MM-dd'),
                userId: user?.userId ?? -1
            },
            fetchPolicy: 'no-cache'
        });

    const hasManualEntryPermission = workingTimeData?.permission.trackingPermission.canCreateManualEntry ?? false;

    if (!user) {
        return <p>Ein Fehler ist aufgetreten</p>;
    }

    if (error) {
        return <div>
            <ContentHeader title={t('hours.pageTitle')}/>
            <Card>
                <ErrorScreen/>
            </Card>
        </div>;
    }

    return (
        <div>
            <ContentHeader title={t('hours.pageTitle')}/>
            <Card>
                <CardContent>
                    <Grid container justifyContent='space-between'>
                        <Grid item sx={{width: {xs: '100%', sm: 'auto'}}}>
                            {hasManualEntryPermission &&
                                <Button
                                    variant='contained'
                                    onClick={() => navigate('/manual-insert')}
                                >
                                    {t('common.insertManuallyButtonText')}
                                </Button>
                            }
                        </Grid>
                        <Grid item sx={{width: {xs: '100%', sm: 'auto'}, paddingTop: {xs: 2, sm: 0}}}>
                            <DateRangePicker
                                style={{width: '100%'}}
                                preventOverflow={true}
                                placement="bottomEnd"
                                format="dd.MM.yyyy"
                                character=" - "
                                ranges={[
                                    {
                                        label: 'Letzte 7 Tage',
                                        value: [subDays(new Date(), 6), new Date()]
                                    },
                                    {
                                        label: 'Letzte 30 Tage',
                                        value: [subDays(new Date(), 29), new Date()]
                                    },
                                    {
                                        label: 'Aktueller Monat',
                                        value: [DateTime.now().startOf('month').toJSDate(), DateTime.now().endOf('month').toJSDate()]
                                    }
                                ]}
                                defaultValue={[subDays(new Date(), 29), new Date()]}
                                onChange={(dateRange) => {
                                    if (dateRange != null) {
                                        const startDate = DateTime.fromJSDate(dateRange[0]);
                                        const endDate = DateTime.fromJSDate(dateRange[1]);

                                        refetchWorkingTimeQuery({
                                            startDate: startDate.toFormat('yyyy-MM-dd'),
                                            endDate: endDate.toFormat('yyyy-MM-dd'),
                                            userId: user?.userId ?? 0
                                        });
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
                <CardContent>
                    {workingTimeData?.time.timeInformationWithRange &&
                        <CurrentTimePeriodInformation timeInformation={workingTimeData?.time.timeInformationWithRange}/>
                    }
                    <Box sx={{marginTop: 4}}>
                        <EmployeeHoursTable hours={workingTimeData?.time.timeInformationWithRange.timeRows}
                                            onChange={refetchWorkingTimeQuery}/>
                    </Box>
                </CardContent>
            </Card>
        </div>
    );
};

export default HoursPage;
