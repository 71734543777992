import {Box, Grid, Skeleton, SxProps, Theme, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import {absenceTypeToString} from "../../../utils/display";
import {DateTime} from "luxon";
import {AbsenceRequestSmallStatusComponent} from "../AbsenceRequestBigStatusComponent";
import noDataClipboard from "../../../images/clippboards.png";
import React, {useEffect} from "react";
import {AbsenceRequestFragment} from "../../../fragments/absenceRequest.generated";
import {CSSProperties} from "styled-components";
import {usePastAbsenceRequestsLazyQuery} from "./pastAbsenceRequests.generated";
import {LoadingButton} from "@mui/lab";
import {useTranslation} from "react-i18next";

const gridItemStyle: SxProps<Theme> = (theme) => ({
    paddingTop: '10px',
    paddingBottom: '10px',
    textDecoration: 'none'
});

const linkNoDecorationStyle: CSSProperties = {textDecoration: 'none', color: '#333333'};

interface AbsencesListProps {
    userId: number;
    futureAbsences?: AbsenceRequestFragment[];
}

const AbsencesList = ({userId, futureAbsences}: AbsencesListProps) => {
    const [pastAbsences, setPastAbsences] = React.useState<AbsenceRequestFragment[]>([]);

    const [pastAbsenceRequestsQuery, {
        data: pastAbsencesData,
        loading: pastAbsenceLoading
    }] = usePastAbsenceRequestsLazyQuery({
        variables: {
            userId: userId,
            limit: 0,
            offset: 0
        }
    });

    useEffect(() => {
        pastAbsenceRequestsQuery();
    }, [pastAbsenceRequestsQuery]);

    const pastAbsenceRequestsTotalCount = pastAbsencesData?.absence.pastAbsenceRequests.totalCount ?? 0;

    const addPastAbsences = async () => {
        const {data} = await pastAbsenceRequestsQuery({
            variables: {
                userId: userId,
                limit: 5,
                offset: pastAbsences.length
            }
        });

        if (data) {
            setPastAbsences([...pastAbsences, ...data.absence.pastAbsenceRequests.absenceRequests]);
        }
    };

    return <>
        <Grid container spacing={1}
              sx={{
                  marginBottom: '1rem',
                  minHeight: '50px',
                  alignItems: 'center',
                  borderRadius: '10px'
              }}>
            <Grid item xs={0.5} sx={gridItemStyle}></Grid>
            <Grid item xs sx={gridItemStyle}><b>Abwesenheit</b></Grid>
            <Grid item xs sx={gridItemStyle}><b>Start-Datum</b></Grid>
            <Grid item xs sx={gridItemStyle}><b>End-Datum</b></Grid>
            <Grid item xs sx={gridItemStyle}><b>Status</b></Grid>
            <Grid item xs={0.5}></Grid>
        </Grid>
        {futureAbsences && futureAbsences.length > 0 && (
            futureAbsences.map((absence, index) => {
                return <AbsenceRequestRow absenceRequest={absence} index={index}/>
            })
        )}
        {futureAbsences && futureAbsences.length === 0 && (
            <Box sx={{display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: 5}}>
                <Box component='img' sx={{maxHeight: '130px'}} src={noDataClipboard}/>
                <Typography sx={{marginTop: 5}}>Es sind keine zukünftigen Anträge vorhanden</Typography>
            </Box>
        )}
        {pastAbsences && pastAbsences.length > 0 && (
            <>
                <Typography variant='h6' sx={{mb: 2, pt: 3}}>Vergangene Abwesenheiten</Typography>
                {pastAbsences.map((absence, index) => {
                    return <AbsenceRequestRow absenceRequest={absence} index={index}/>
                })}
            </>
        )}
        {(futureAbsences && pastAbsenceRequestsTotalCount > pastAbsences.length) &&
            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <LoadingButton sx={{marginTop: 2}} loading={pastAbsenceLoading} variant='contained' color='inherit'
                               onClick={addPastAbsences}>
                    {pastAbsences.length > 0 ? 'Weitere laden' : 'Vergangene Abwesenheiten anzeigen'}
                </LoadingButton>
            </Box>
        }
        {!futureAbsences &&
            Array.from(Array(5).keys()).map((_, index) => {
                return <Skeleton key={index} variant="rounded" width='100%' height={50}
                                 sx={{marginTop: '10px'}}/>;
            })
        }
    </>;
};

const AbsenceRequestRow = ({absenceRequest, index}: { absenceRequest: AbsenceRequestFragment, index: number }) => {
    const {t} = useTranslation();

    return <Grid key={index} container spacing={1} sx={{
        backgroundColor: '#F8FAFE',
        marginBottom: '1rem',
        minHeight: '50px',
        alignItems: 'center',
        borderRadius: '10px',
        width: '100%',
        marginLeft: 0,
        ':hover': {
            backgroundColor: '#E5EDFA'
        }
    }}>
        <Grid item xs={11} sm={11.5} sx={{paddingTop: '0 !important'}}>
            <Link to={`/absence/${absenceRequest.absenceRequestId}`} style={linkNoDecorationStyle}>
                <Grid container sx={{alignItems: 'center', marginTop: '2px'}} spacing={1}>
                    <Grid item xs={0.5} sx={gridItemStyle}></Grid>
                    <Grid item xs
                          sx={gridItemStyle}>{t(absenceTypeToString(absenceRequest.absenceType))}</Grid>
                    <Grid item xs
                          sx={gridItemStyle}>{DateTime.fromISO(absenceRequest.startDate).toFormat('dd.MM.yyyy')}</Grid>
                    <Grid item xs
                          sx={gridItemStyle}>{DateTime.fromISO(absenceRequest.endDate).toFormat('dd.MM.yyyy')}</Grid>
                    <Grid item xs sx={gridItemStyle}>
                        <AbsenceRequestSmallStatusComponent absenceRequestStatus={absenceRequest.status}/>
                    </Grid>
                </Grid>
            </Link>
        </Grid>
    </Grid>;
}

export default AbsencesList;
