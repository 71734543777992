import * as Types from '../../generated/graphql';

import { AbsenceRequestFragment } from '../../fragments/absenceRequest.generated';
import { VacationStatisticsFragment } from '../../fragments/vacationStatistics.generated';
import { PresenceStatisticsFragment } from '../../fragments/presenceStatistics.generated';
import { gql } from '@apollo/client';
import { AbsenceRequestFragmentDoc } from '../../fragments/absenceRequest.generated';
import { VacationStatisticsFragmentDoc } from '../../fragments/vacationStatistics.generated';
import { PresenceStatisticsFragmentDoc } from '../../fragments/presenceStatistics.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FutureAbsencesQueryVariables = Types.Exact<{
  year: Types.Scalars['Int'];
  employeeId: Types.Scalars['Int'];
}>;


export type FutureAbsencesQuery = { __typename?: 'Query', absence: { __typename?: 'absenceQueries', futureAbsenceRequests: Array<(
      { __typename?: 'AbsenceRequest' }
      & AbsenceRequestFragment
    )>, vacationStatistics: (
      { __typename?: 'VacationStatistics' }
      & VacationStatisticsFragment
    ) }, time: { __typename?: 'timeQueries', presenceStatistics: (
      { __typename?: 'PresenceStatistics' }
      & PresenceStatisticsFragment
    ) } };


export const FutureAbsencesDocument = gql`
    query FutureAbsences($year: Int!, $employeeId: Int!) {
  absence {
    futureAbsenceRequests(employeeId: $employeeId) {
      ...AbsenceRequest
    }
    vacationStatistics(year: $year, employeeId: $employeeId) {
      ...VacationStatistics
    }
  }
  time {
    presenceStatistics(year: $year, employeeId: $employeeId) {
      ...PresenceStatistics
    }
  }
}
    ${AbsenceRequestFragmentDoc}
${VacationStatisticsFragmentDoc}
${PresenceStatisticsFragmentDoc}`;

/**
 * __useFutureAbsencesQuery__
 *
 * To run a query within a React component, call `useFutureAbsencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFutureAbsencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFutureAbsencesQuery({
 *   variables: {
 *      year: // value for 'year'
 *      employeeId: // value for 'employeeId'
 *   },
 * });
 */
export function useFutureAbsencesQuery(baseOptions: Apollo.QueryHookOptions<FutureAbsencesQuery, FutureAbsencesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FutureAbsencesQuery, FutureAbsencesQueryVariables>(FutureAbsencesDocument, options);
      }
export function useFutureAbsencesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FutureAbsencesQuery, FutureAbsencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FutureAbsencesQuery, FutureAbsencesQueryVariables>(FutureAbsencesDocument, options);
        }
export type FutureAbsencesQueryHookResult = ReturnType<typeof useFutureAbsencesQuery>;
export type FutureAbsencesLazyQueryHookResult = ReturnType<typeof useFutureAbsencesLazyQuery>;
export type FutureAbsencesQueryResult = Apollo.QueryResult<FutureAbsencesQuery, FutureAbsencesQueryVariables>;