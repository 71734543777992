import {Box, Button, FormHelperText, Grid, MenuItem, Select, TextField} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import React, {useState} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import {
    useCreateDepartmentCompanyInformationQuery
} from "../../../CreateDepartment/createDepartmentCompanyInformation.generated";
import {DepartmentByIdFragment} from "../../departmentById.generated";
import {useEditDepartmentMutation} from "./editDepartment.generated";
import {toast} from "react-toastify";
import { EmployeeInformation } from "../../../../components/molecules/EmployeeInformation";

export interface DepartmentAdministrationProps {
    department?: DepartmentByIdFragment;
    onEditComplete?: () => void;
}

const DepartmentAdministration = ({department, onEditComplete}: DepartmentAdministrationProps) => {
    const [isEditing, setEditing] = useState(false);

    const [editDepartmentMutation] = useEditDepartmentMutation();

    const {data, loading: companyInformationLoading} = useCreateDepartmentCompanyInformationQuery();

    const loading = !department || companyInformationLoading;

    const departments = data?.company.getCompanyInformation.departments;
    const employees = data?.company.getCompanyInformation.employees.filter((employee) => employee.departmentRole === 'employee');;

    const editDepartment = async (departmentName: string, headEmployee: number, parentId: number) => {
        const {data, errors} = await editDepartmentMutation({
            variables: {
                departmentId: department!.departmentId,
                departmentName: departmentName,
                headEmployee: headEmployee,
                parentId: parentId
            }
        });

        if (errors) {
            const error = errors?.[0];
            if (error?.extensions['reason'] !== undefined && error.extensions['reason'] !== null) {
                const reason: string = error.extensions['reason'] as unknown as string;

                if (reason === 'AuthenticationRequired') {
                    toast.error('Melde dich erneut an und versuche es nochmal.');
                } else if (reason === 'PermissionDenied') {
                    toast.error('Du kannst diese Abteilung nicht editieren');
                } else if (reason === 'DepartmentNotFound') {
                    toast.error('Die Abteilung wurde nicht gefunden');
                } else if (reason === 'ParentDepartmentNotFound') {
                    toast.error('Die ausgewählte Oberabteilung wurde nicht gefunden');
                } else if (reason === 'HeadEmployeeNotFound') {
                    toast.error('Der Mitarbeiter als Abteilungsleiter wurde nicht gefunden');
                } else {
                    toast.error('Ein unerwarteter Fehler ist aufgetreten');
                }
            } else {
                toast.error('Ein unerwarteter Fehler ist aufgetreten');
            }
        } else if (data) {
            setEditing(false);
            toast.success('Abteilung wurde erfolgreich bearbeitet');

            if(onEditComplete) {
                onEditComplete();
            }
        } else {
            toast.error('Ein unbekannter Fehler ist aufgetreten');
        }
    };

    const {handleChange, values, handleSubmit, handleBlur, touched, errors} = useFormik({
        enableReinitialize: true,
        initialValues: {
            departmentName: department?.name ?? '',
            parentId: department?.parentId ?? 0,
            headEmployeeId: department?.headEmployeeId ?? 0
        },
        validationSchema: Yup.object({
            departmentName: Yup.string().required('Gib den Namen der Abteilung an'),
            parentId: Yup.number().required('Gib die übergeordneten Abteilung an'),
            headEmployeeId: Yup.number().required('Gib den Abteilungsleiter dieser Abteilung an').min(1, 'Gib den Abteilungsleiter dieser Abteilung an')
        }),
        onSubmit: (values) => {
            editDepartment(values.departmentName, values.headEmployeeId, values.parentId);
        }
    });

    return <Box component='form' onSubmit={handleSubmit}>
        <Grid container spacing={2}>
            <EmployeeInformation label='Name' loading={loading}>
                {isEditing ? <>
                    <TextField id="departmentName"
                               name='departmentName'
                               multiline
                               placeholder="Name"
                               value={values.departmentName}
                               error={!!(errors.departmentName && touched.departmentName)}
                               onChange={handleChange}
                               onBlur={handleBlur}
                               variant='outlined'
                               fullWidth
                               size='small'
                    />
                    {errors.departmentName && (
                        <FormHelperText error={!!(errors.departmentName && touched.departmentName)}>
                            {errors.departmentName}
                        </FormHelperText>
                    )}
                </> : (
                    <p>{department?.name}</p>
                )}
            </EmployeeInformation>
            <EmployeeInformation label='Oberabteilung' loading={loading}>
                {isEditing ? (
                    <>
                        {departments &&
                            <>
                                <Select
                                    id='parentId'
                                    name='parentId'
                                    labelId="parent-id-label"
                                    size='small'
                                    placeholder='Oberabteilung'
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.parentId}
                                    error={!!(errors.parentId && touched.parentId)}
                                    fullWidth
                                    displayEmpty
                                >
                                    {departments.map((department, index) => (<MenuItem key={index}
                                                                                       value={department.departmentId}>{department.name}</MenuItem>))}
                                </Select>
                                {errors.parentId && (
                                    <FormHelperText error={!!(errors.parentId && touched.parentId)}>
                                        {errors.parentId}
                                    </FormHelperText>
                                )}
                            </>
                        }
                    </>
                ) : (<p>{department?.parent?.name}</p>)
                }
            </EmployeeInformation>
            <EmployeeInformation label='Abteilungsleiter' loading={loading}>
                {isEditing ? (
                    <>
                        {employees &&
                            <>
                                <Select
                                    id='headEmployeeId'
                                    name='headEmployeeId'
                                    labelId="head-employee-id-label"
                                    size='small'
                                    placeholder='Abteilungsleiter'
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.headEmployeeId}
                                    error={!!(errors.headEmployeeId && touched.headEmployeeId)}
                                    fullWidth
                                    displayEmpty
                                >
                                    <MenuItem value={-1} disabled>Abteilungsleiter auswählen</MenuItem>
                                    {employees.map((employee, index) => (<MenuItem key={index}
                                                                                   value={employee.userId}>{employee.surname} {employee.name}</MenuItem>))}
                                </Select>
                                {errors.headEmployeeId && (
                                    <FormHelperText
                                        error={!!(errors.headEmployeeId && touched.headEmployeeId)}>
                                        {errors.headEmployeeId}
                                    </FormHelperText>
                                )}
                            </>
                        }
                    </>
                ) : (<p>{department?.headEmployee.name} {department?.headEmployee.surname}</p>)
                }
            </EmployeeInformation>
        </Grid>
        <div style={{display: 'flex', justifyContent: 'right'}}>
            {isEditing ? (
                <>
                    <Button style={{marginRight: '1rem'}} color='inherit' variant='contained'
                            onClick={() => setEditing(false)}>Abbrechen</Button>
                    <LoadingButton loading={loading} variant='contained' type='submit'>Speichern</LoadingButton>
                </>
            ) : (
                <Button variant='contained' onClick={() => setEditing(true)}>Bearbeiten</Button>
            )}
        </div>
    </Box>
};

export default DepartmentAdministration;
