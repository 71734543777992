import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteWorkPlaceMutationVariables = Types.Exact<{
  workPlaceId: Types.Scalars['Int'];
}>;


export type DeleteWorkPlaceMutation = { __typename?: 'Mutation', workPlace: { __typename?: 'workPlaceMutations', deleteWorkPlace: any } };


export const DeleteWorkPlaceDocument = gql`
    mutation DeleteWorkPlace($workPlaceId: Int!) {
  workPlace {
    deleteWorkPlace(workPlaceId: $workPlaceId)
  }
}
    `;
export type DeleteWorkPlaceMutationFn = Apollo.MutationFunction<DeleteWorkPlaceMutation, DeleteWorkPlaceMutationVariables>;

/**
 * __useDeleteWorkPlaceMutation__
 *
 * To run a mutation, you first call `useDeleteWorkPlaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWorkPlaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWorkPlaceMutation, { data, loading, error }] = useDeleteWorkPlaceMutation({
 *   variables: {
 *      workPlaceId: // value for 'workPlaceId'
 *   },
 * });
 */
export function useDeleteWorkPlaceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteWorkPlaceMutation, DeleteWorkPlaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteWorkPlaceMutation, DeleteWorkPlaceMutationVariables>(DeleteWorkPlaceDocument, options);
      }
export type DeleteWorkPlaceMutationHookResult = ReturnType<typeof useDeleteWorkPlaceMutation>;
export type DeleteWorkPlaceMutationResult = Apollo.MutationResult<DeleteWorkPlaceMutation>;
export type DeleteWorkPlaceMutationOptions = Apollo.BaseMutationOptions<DeleteWorkPlaceMutation, DeleteWorkPlaceMutationVariables>;