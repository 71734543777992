import {
    Box, Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormHelperText,
    Grid,
    IconButton, MenuItem, Select,
    TextField
} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import React from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import {EmployeeInformation} from "../../molecules/EmployeeInformation";
import LoadingButton from "@mui/lab/LoadingButton";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {DateTime} from "luxon";
import {AdapterLuxon} from "@mui/x-date-pickers/AdapterLuxon";
import {useCreateCustomHolidayMutation} from "./createCustomHoliday.generated";
import {RestRepeatPeriod} from "../../../generated/graphql";
import {toast} from "react-toastify";
import {WorkPlaceFragment} from "../../../fragments/workPlace.generated";


interface CreateHolidayFormValues {
    readonly name: string;
    readonly date: DateTime;
    readonly repetition: RestRepeatPeriod;
}

interface CreateHolidayDialogProps {
    workPlace: WorkPlaceFragment;
    open: boolean;
    onClose?: () => void;
}

export const CreateHolidayDialog = ({workPlace, open, onClose}: CreateHolidayDialogProps) => {

    const [createCustomHolidayMutation] = useCreateCustomHolidayMutation();

    const createCustomHoliday = async (values: CreateHolidayFormValues) => {
        const {data, errors} = await createCustomHolidayMutation({
            variables: {
                workPlaceId: workPlace.workPlaceId,
                name: values.name,
                date: values.date.toISODate(),
                repeatPeriod: values.repetition
            }
        });

        if (data) {
            toast.success('Individueller Feiertag erfolgreich erstellt');
            if (onClose) {
                onClose();
            }
        } else if (errors) {
            const error = errors[0];
            const reason = error.extensions?.['reason'];

            switch (reason) {
                case "AuthenticationRequired":
                    toast.error('Melde dich erneut an und versuche es nochmal.');
                    return;
                case "PermissionDenied":
                    toast.error('Du hast keine Berechtigung einen Standort zu erstellen');
                    return;
                case "UserNotFound":
                    toast.error('Der Mitarbeiter-Account konnte nicht gefunden werden');
                    return;
                case "WorkPlaceNotFound":
                    toast.error('Der Arbeitsort für diesen Feiertag wurde nicht gefunden');
                    return;
                default:
                    toast.error('Ein unbekannter Fehler ist aufgetreten');
            }
        } else {
            toast.error('Ein unerwarteter Fehler ist aufgetreten. Bitte versuche es später erneut.');
        }
    };

    const {handleChange, values, handleSubmit, setFieldValue, handleBlur, touched, errors} = useFormik<CreateHolidayFormValues>({
        initialValues: {
            name: '',
            date: DateTime.now(),
            repetition: 'oneTime'
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Gib den Namen des Feiertags an'),
            date: Yup.date().required('Gib ein Datum für den Feiertag an'),
            repetition: Yup.string().required('Gib eine Wiederholungsrate für den Feiertag an')
        }),
        onSubmit: (values) => {
            console.log('onSubmit');
            createCustomHoliday(values);
        }
    });

    return <LocalizationProvider dateAdapter={AdapterLuxon}>
        <Dialog
            open={open ?? false}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            onClose={onClose}
            maxWidth="md"
        >
            <Grid container justifyContent='space-between'>
                <Grid item>
                    <DialogTitle id="alert-dialog-title">
                        Individuellen Feiertag erstellen
                    </DialogTitle>
                </Grid>
                <Grid item sx={{marginRight: '1rem', marginTop: '0.5rem'}}>
                    <IconButton onClick={onClose}>
                        <FontAwesomeIcon icon={solid('xmark')} color='#333333' size="lg"/>
                    </IconButton>
                </Grid>
            </Grid>
            <DialogContent>
                <Box component='form' onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <EmployeeInformation label='Name des Feiertags' xs={12} md={12}>
                            <TextField id="name"
                                       name='name'
                                       placeholder="Name"
                                       type="text"
                                       value={values.name}
                                       error={!!(errors.name && touched.name)}
                                       onChange={handleChange}
                                       onBlur={handleBlur}
                                       variant='outlined'
                                       fullWidth
                                       size='small'
                            />
                            {errors.name && (
                                <FormHelperText error={!!(errors.name && touched.name)}>
                                    {errors.name}
                                </FormHelperText>
                            )}
                        </EmployeeInformation>
                        <EmployeeInformation label='Datum' xs={12} md={12}>
                            <DatePicker
                                value={values.date}
                                onChange={(value: DateTime | null) => {
                                    if (value?.isValid) {
                                        setFieldValue('date', value)
                                    }
                                }}
                                slotProps={{
                                    textField: {
                                        size: 'small',
                                        fullWidth: true,
                                        name: 'startDate',
                                        id: 'startDate',
                                        onBlur: handleBlur
                                    }
                                }}
                                format='dd.MM.yyyy'
                            ></DatePicker>
                        </EmployeeInformation>
                        <EmployeeInformation label='Wiederholung' xs={12} md={12}>
                            <Select
                                id='repetition'
                                name='repetition'
                                labelId="repetition-label"
                                size='small'
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.repetition}
                                error={!!(errors.repetition && touched.repetition)}
                                fullWidth
                                sx={{backgroundColor: '#fff'}}
                            >
                                <MenuItem value='oneTime'>Keine</MenuItem>
                                <MenuItem value='yearly'>Jährlich am selben Datum</MenuItem>
                            </Select>
                            {errors.repetition && (
                                <FormHelperText
                                    error={!!(errors.repetition && touched.repetition)}>
                                    {errors.repetition}
                                </FormHelperText>
                            )}
                        </EmployeeInformation>
                    </Grid>
                    <Box sx={{display: 'flex', justifyContent: 'end'}}>
                        <DialogActions sx={{gap: 2, mt: 2}}>
                            <Button variant='contained' color='inherit' type='button'
                                    onClick={onClose}>ABBRECHEN</Button>
                            <LoadingButton loading={false} variant='contained' type='submit'>SPEICHERN</LoadingButton>
                        </DialogActions>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    </LocalizationProvider>;
};
