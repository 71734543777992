import * as Types from '../../../../generated/graphql';

import { TimeScheduleFragment } from '../../../../fragments/timeSchedule.generated';
import { gql } from '@apollo/client';
import { TimeScheduleFragmentDoc } from '../../../../fragments/timeSchedule.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TimeScheduleByUserIdQueryVariables = Types.Exact<{
  userId: Types.Scalars['Int'];
}>;


export type TimeScheduleByUserIdQuery = { __typename?: 'Query', employee: { __typename?: 'employeeQueries', timeSchedule: { __typename?: 'timeScheduleQueries', timeScheduleByUserId: Array<(
        { __typename?: 'TimeSchedule' }
        & TimeScheduleFragment
      )> } } };


export const TimeScheduleByUserIdDocument = gql`
    query TimeScheduleByUserId($userId: Int!) {
  employee {
    timeSchedule {
      timeScheduleByUserId(userId: $userId) {
        ...TimeSchedule
      }
    }
  }
}
    ${TimeScheduleFragmentDoc}`;

/**
 * __useTimeScheduleByUserIdQuery__
 *
 * To run a query within a React component, call `useTimeScheduleByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimeScheduleByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimeScheduleByUserIdQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useTimeScheduleByUserIdQuery(baseOptions: Apollo.QueryHookOptions<TimeScheduleByUserIdQuery, TimeScheduleByUserIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TimeScheduleByUserIdQuery, TimeScheduleByUserIdQueryVariables>(TimeScheduleByUserIdDocument, options);
      }
export function useTimeScheduleByUserIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TimeScheduleByUserIdQuery, TimeScheduleByUserIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TimeScheduleByUserIdQuery, TimeScheduleByUserIdQueryVariables>(TimeScheduleByUserIdDocument, options);
        }
export type TimeScheduleByUserIdQueryHookResult = ReturnType<typeof useTimeScheduleByUserIdQuery>;
export type TimeScheduleByUserIdLazyQueryHookResult = ReturnType<typeof useTimeScheduleByUserIdLazyQuery>;
export type TimeScheduleByUserIdQueryResult = Apollo.QueryResult<TimeScheduleByUserIdQuery, TimeScheduleByUserIdQueryVariables>;