import {
    TrackingChangeFragment,
    TrackingChangelogEntryFragment,
    TrackingEditorFragment
} from "./trackingChangelog.generated";
import {Box, Grid, Typography} from "@mui/material";
import React from "react";
import {DateTime, Duration} from "luxon";
import {TrackingRowFragment} from "../fragments/trackingRow.generated";
import {NewTrackingRowFragment} from "../fragments/newTrackingRow.generated";
import {UserAvatar} from "../components/atoms/UserAvatar";

export const TrackingChangelog = ({changelogEntries}: { changelogEntries: TrackingChangelogEntryFragment[] }) => {
    const userText = (trackingEditor: TrackingEditorFragment): string => {
        switch (trackingEditor.__typename) {
            case "TrackingEditorAdmin":
            case "TrackingEditorUser":
                return `${trackingEditor.user.name} ${trackingEditor.user.surname}`;
            case "TrackingEditorAutomatic":
                return "MyTimeTracker";
        }
    }

    return <>
        {changelogEntries.map((changelogEntry, index) => {
            return <Grid key={index} container spacing={2} sx={{my: 3}}>
                <Grid item xs={1}>
                    <TrackingEditorAvatar trackingEditor={changelogEntry.trackingEditor}/>
                </Grid>
                <Grid item xs={11}>
                    <Box sx={{width: '100%', px: 1}}>
                        <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                            <Typography>{userText(changelogEntry.trackingEditor)}</Typography>
                            <Typography>{DateTime.fromISO(changelogEntry.createdAt).toFormat('dd.MM.yyyy, HH:mm')} Uhr</Typography>
                        </Box>
                        <TrackingChange trackingChange={changelogEntry.change}/>
                    </Box>
                </Grid>
            </Grid>;
        })}
    </>;
};

const TrackingChange = ({trackingChange}: { trackingChange: TrackingChangeFragment }) => {
    const workingTimeFromRows = (rows: (TrackingRowFragment | NewTrackingRowFragment)[]): number => {
        return rows.map((trackingRow) => {
            const startDate = DateTime.fromISO(trackingRow.start);
            const endDate = trackingRow.end ? DateTime.fromISO(trackingRow.end) : undefined;

            const diff = endDate?.diff(startDate, ['minutes']);

            return diff?.minutes ?? 0;
        }).reduce((prev, current) => prev + current, 0);
    }

    const breakFromRows = (rows: (TrackingRowFragment | NewTrackingRowFragment)[]): number => {
        return rows.map((trackingRow, index) => {

            const isLastRow = index + 1 === rows.length;

            if (isLastRow) {
                return 0;
            } else {
                const nextRow: TrackingRowFragment | NewTrackingRowFragment = rows[index + 1];

                const endDateThisRow = trackingRow.end ? DateTime.fromISO(trackingRow.end) : undefined;
                const startDateNextRow = DateTime.fromISO(nextRow.start);
                const diff = endDateThisRow ? startDateNextRow.diff(endDateThisRow, ['minutes']) : {minutes: 0};

                return diff.minutes;
            }
        }).reduce((prev, current) => prev + current, 0);
    }

    const displayFromDuration = (duration: Duration, displaySign: boolean = false): string => {
        const newDuration = duration.shiftTo('hours', 'minutes');
        const isNegative = newDuration.hours < 0 || newDuration.minutes < 0;
        const sign = isNegative ? '-' : '+';

        if(newDuration.hours === 0) {
            return `${displaySign ? sign : ''} ${Math.abs(newDuration.minutes).toFixed(0)}min`;
        } else {
            return `${displaySign ? sign : ''} ${Math.abs(newDuration.hours).toFixed(0)}h ${Math.abs(newDuration.minutes).toFixed(0)}min`;
        }
    };

    switch (trackingChange.__typename) {
        case 'ManualInsert': {
            return <p>Manueller Eintrag</p>;
        }
        case 'StartTracking': {
            const startTime = DateTime.fromISO(trackingChange.startTime);
            return <Typography>hat um {startTime.toFormat('HH:mm')} eingestempelt</Typography>;
        }
        case 'StopTracking': {
            const stopTime = DateTime.fromISO(trackingChange.stopTime);
            return <Typography>hat um {stopTime.toFormat('HH:mm')} ausgestempelt</Typography>;
        }
        case 'EditTracking': {
            const oldWorkingTimeMinutes = workingTimeFromRows(trackingChange.oldRows);
            const oldBreakTimeMinutes = breakFromRows(trackingChange.oldRows);

            const newWorkingTimeMinutes = workingTimeFromRows(trackingChange.newRows);
            const newBreakTimeMinutes = breakFromRows(trackingChange.newRows);

            const diffWorkingTime = Duration.fromMillis((oldWorkingTimeMinutes - newWorkingTimeMinutes) * 60 * 1000);
            const diffBreakTime = Duration.fromMillis((oldBreakTimeMinutes - newBreakTimeMinutes) * 60 * 1000);

            const workingTime = Duration.fromMillis(newWorkingTimeMinutes * 60 * 1000);
            const breakTime = Duration.fromMillis(newBreakTimeMinutes * 60 * 1000);

            return <>
                <Typography>hat eine Zeit bearbeitet</Typography>
                <br/>
                <Typography fontStyle='italic'>Gesamtzeit: {displayFromDuration(workingTime)} ({displayFromDuration(diffWorkingTime, true)})</Typography>
                <Typography fontStyle='italic'>Pause: {displayFromDuration(breakTime)} ({displayFromDuration(diffBreakTime, true)})</Typography>
            </>;
        }
        case 'AutomaticBreakCorrection': {
            return <Typography>Automatische Pausenanpassung</Typography>;
        }
        case 'AutomaticStampOut': {
            return <Typography>hat automatisch ausgestempelt</Typography>;
        }
        default: {
            return <p>Unknown</p>;
        }
    }
};

const TrackingEditorAvatar = ({trackingEditor}: { trackingEditor: TrackingEditorFragment }) => {
    switch (trackingEditor.__typename) {
        case "TrackingEditorAdmin":
        case "TrackingEditorUser":
            return <UserAvatar user={{name: trackingEditor.user.name, surname: trackingEditor.user.surname}}/>;
        case "TrackingEditorAutomatic":
            return <UserAvatar user='MT' />;
    }
};
