import * as Types from '../generated/graphql';

import { WorkingTimeFragment } from './workingTime.generated';
import { TimeUnion_AbsenceRow_Fragment, TimeUnion_WorkingTimeByDay_Fragment } from './timeUnion.generated';
import { gql } from '@apollo/client';
import { WorkingTimeFragmentDoc } from './workingTime.generated';
import { TimeUnionFragmentDoc } from './timeUnion.generated';
export type TimeInformationFragment = { __typename?: 'TimeInformation', totalTime: (
    { __typename?: 'WorkingTime' }
    & WorkingTimeFragment
  ), totalWorkingTime: (
    { __typename?: 'WorkingTime' }
    & WorkingTimeFragment
  ), absenceTime: (
    { __typename?: 'WorkingTime' }
    & WorkingTimeFragment
  ), balance?: (
    { __typename?: 'WorkingTime' }
    & WorkingTimeFragment
  ) | null, timeRows: Array<(
    { __typename?: 'AbsenceRow' }
    & TimeUnion_AbsenceRow_Fragment
  ) | (
    { __typename?: 'WorkingTimeByDay' }
    & TimeUnion_WorkingTimeByDay_Fragment
  )> };

export const TimeInformationFragmentDoc = gql`
    fragment TimeInformation on TimeInformation {
  totalTime {
    ...WorkingTime
  }
  totalWorkingTime {
    ...WorkingTime
  }
  absenceTime {
    ...WorkingTime
  }
  balance {
    ...WorkingTime
  }
  timeRows {
    ...TimeUnion
  }
}
    ${WorkingTimeFragmentDoc}
${TimeUnionFragmentDoc}`;