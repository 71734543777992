import {useNavigate} from "react-router-dom";
import {ContentHeader} from "../../components";
import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    Typography
} from "@mui/material";
import {DateTime} from "luxon";
import React from "react";
import {useFutureAbsencesQuery} from "./futureAbsenceRequests.generated";
import {ArcElement, Chart as ChartJS, Legend, Tooltip} from 'chart.js';
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";
import VacationStatisticsChart from "../../components/molecules/VacationStatisticsChart";
import PresenceStatisticsChart from "../../components/molecules/PresenceStatisticsChart";
import AbsencesList from "../../components/molecules/AbsencesList/AbsencesList";
import {ErrorScreen} from "../../components/organisms/ErrorScreen";

ChartJS.register(ArcElement, Tooltip, Legend);

const AbsencesPage = () => {
    const navigate = useNavigate();

    const user = useSelector((state: RootState) => state.auth.currentUser);

    const {data, error} = useFutureAbsencesQuery({
        variables: {
            year: DateTime.now().year,
            employeeId: Number(user!.userId)
        },
        fetchPolicy: 'no-cache'
    });

    const futureAbsences = data?.absence.futureAbsenceRequests;
    const vacationStatistics = data?.absence.vacationStatistics;
    const presenceStatistics = data?.time.presenceStatistics;

    if (error) {
        return <div>
            <ContentHeader title="Deine Abwesenheiten"/>
            <Card>
                <ErrorScreen/>
            </Card>
        </div>;
    }

    return (
        <div>
            <ContentHeader title="Deine Abwesenheiten"/>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Card>
                        <CardContent>
                            <Typography variant='h6'>Urlaubstage</Typography>
                            <VacationStatisticsChart vacationStatistics={vacationStatistics}/>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6} sx={{mt: {xs: 2, md: 0}}}>
                    <Card>
                        <CardContent>
                            <Typography variant='h6'>Anwesenheit</Typography>
                            <PresenceStatisticsChart presenceStatistics={presenceStatistics}/>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Card sx={{mt: 2}}>
                <CardContent>
                    <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                        <Typography variant='h6'>Anträge</Typography>
                        <Button variant='contained' onClick={() => navigate('/absence/create')}>
                            {user?.stripeSubscription === 'expert' ? 'Abwesenheit beantragen' : 'Abwesenheit erstellen'}
                        </Button>
                    </Box>
                </CardContent>
                <CardContent>
                    <AbsencesList userId={user?.userId ?? 0} futureAbsences={futureAbsences}/>
                </CardContent>
            </Card>
        </div>
    );
};

export default AbsencesPage;
