import React from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {toast} from 'react-toastify';
import {useNavigate} from 'react-router-dom';
import {ContentHeader} from '../../components';
import {Box, Button, Card, CardContent, FormHelperText, Grid, MenuItem, Select, TextField} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {useCreateDepartmentMutation} from "./createDepartment.generated";
import {useCreateDepartmentCompanyInformationQuery} from "./createDepartmentCompanyInformation.generated";
import { EmployeeInformation } from '../../components/molecules/EmployeeInformation';

type CreateDepartmentError = 'AuthenticationRequired' | 'PermissionDenied' | 'ParentNotFound' | 'UserNotFound';

const CreateDepartmentPage = () => {
    const navigate = useNavigate();

    const {data, loading} = useCreateDepartmentCompanyInformationQuery();

    const departments = data?.company.getCompanyInformation.departments;
    const employees = data?.company.getCompanyInformation.employees.filter((employee) => employee.departmentRole === 'employee');

    const [createDepartmentMutation, {loading: createDepartmentLoading}] = useCreateDepartmentMutation();

    const createDepartment = async (
        parentId: number,
        departmentName: string,
        headEmployeeId: number
    ): Promise<void> => {
        const {data, errors} = await createDepartmentMutation({
            variables: {
                parentId: parentId,
                departmentName: departmentName,
                headEmployee: headEmployeeId
            }
        });

        if (data) {
            toast.success('Abteilung wurde erfolgreich erstellt');
            navigate('/company');
        } else if (errors) {
            const error = errors[0];
            const reason = error.extensions['reason'] as unknown as CreateDepartmentError;

            switch(reason) {
                case "AuthenticationRequired":
                    toast.error('Melde dich erneut an und versuche es nochmal.');
                    return;
                case "PermissionDenied":
                    toast.error('Du hast nicht die benötigen Rechte um diese Aktion auszuführen');
                    return;
                case "UserNotFound":
                    toast.error('Der Nutzer, der als Chef diese Abteilung ausgewählt würde, konnte nicht gefunden werden');
                    return;
                case "ParentNotFound":
                    toast.error('Die übergeordnete Abteilung konnte nicht gefunden werden');
                    return;
            }
        } else {
            toast.error('Unknown error');
        }
    };

    const {handleChange, values, handleSubmit, handleBlur, touched, errors} = useFormik({
        enableReinitialize: true,
        initialValues: {
            departmentName: '',
            parentId: departments?.find((dep) => dep.parentId === null)?.departmentId ?? 0,
            headEmployeeId: -1
        },
        validationSchema: Yup.object({
            departmentName: Yup.string().required('Gib den Namen der Abteilung an'),
            parentId: Yup.number().required('Gib die übergeordneten Abteilung an'),
            headEmployeeId: Yup.number().required('Gib den Abteilungsleiter dieser Abteilung an').min(1, 'Gib den Abteilungsleiter dieser Abteilung an')
        }),
        onSubmit: (values) => {
            createDepartment(
                values.parentId,
                values.departmentName,
                values.headEmployeeId
            );
        }
    });

    return (
        <>
            <ContentHeader title="Neue Abteilung"/>
            <section className="content">
                <Card>
                    <CardContent>
                        <Box component='form' onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                <EmployeeInformation label='Name der Abteilung' loading={loading}>
                                    <TextField id="departmentName"
                                               name='departmentName'
                                               multiline
                                               placeholder="Name"
                                               value={values.departmentName}
                                               error={!!(errors.departmentName && touched.departmentName)}
                                               onChange={handleChange}
                                               onBlur={handleBlur}
                                               variant='outlined'
                                               fullWidth
                                               size='small'
                                    />
                                    {errors.departmentName && (
                                        <FormHelperText error={!!(errors.departmentName && touched.departmentName)}>
                                            {errors.departmentName}
                                        </FormHelperText>
                                    )}
                                </EmployeeInformation>
                                <EmployeeInformation
                                    label='Oberabteilung'
                                    helperTooltip='Abteilung unter welcher die neue Abteilung eingeordnet wird'
                                    loading={loading}
                                >
                                    {departments &&
                                        <>
                                            <Select
                                                id='parentId'
                                                name='parentId'
                                                labelId="parent-id-label"
                                                size='small'
                                                placeholder='Oberabteilung'
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.parentId}
                                                error={!!(errors.parentId && touched.parentId)}
                                                fullWidth
                                                displayEmpty
                                            >
                                                {departments.map((department, index) => (<MenuItem key={index}
                                                                                                   value={department.departmentId}>{department.name}</MenuItem>))}
                                            </Select>
                                            {errors.parentId && (
                                                <FormHelperText error={!!(errors.parentId && touched.parentId)}>
                                                    {errors.parentId}
                                                </FormHelperText>
                                            )}
                                        </>
                                    }
                                </EmployeeInformation>
                                <EmployeeInformation
                                    label='Abteilungsleiter auswählen'
                                    helperTooltip='Nur Mitarbeiter, die aktuell nicht Abteilungsleiter sind werden hier angezeigt'
                                    loading={loading}
                                >
                                    {employees &&
                                        <>
                                            <Select
                                                id='headEmployeeId'
                                                name='headEmployeeId'
                                                labelId="head-employee-id-label"
                                                size='small'
                                                placeholder='Abteilungsleiter'
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.headEmployeeId}
                                                error={!!(errors.headEmployeeId && touched.headEmployeeId)}
                                                fullWidth
                                                displayEmpty
                                            >
                                                <MenuItem value={-1} disabled>Abteilungsleiter auswählen</MenuItem>
                                                {employees.map((employee, index) => (<MenuItem key={index}
                                                                                               value={employee.userId}>{employee.surname} {employee.name}</MenuItem>))}
                                            </Select>
                                            {errors.headEmployeeId && (
                                                <FormHelperText
                                                    error={!!(errors.headEmployeeId && touched.headEmployeeId)}>
                                                    {errors.headEmployeeId}
                                                </FormHelperText>
                                            )}
                                        </>
                                    }
                                </EmployeeInformation>
                            </Grid>
                            <Box sx={{marginTop: 1, display: 'flex', justifyContent: 'end', gap: 2}}>
                                <Button variant='contained' color='inherit' style={{width: 150}}
                                        onClick={() => navigate('/')}>Abbrechen</Button>
                                <LoadingButton loading={createDepartmentLoading} variant='contained'
                                               type="submit">Speichern</LoadingButton>
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
            </section>
        </>
    );
};

export default CreateDepartmentPage;
