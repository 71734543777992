import * as Types from '../../../../generated/graphql';

import { InheritableSettingsFragment } from '../../../../fragments/inheritableSettings.generated';
import { gql } from '@apollo/client';
import { InheritableSettingsFragmentDoc } from '../../../../fragments/inheritableSettings.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateStampingPlatformSettingMutationVariables = Types.Exact<{
  settingEntityType: Types.SettingEntityType;
  entityId?: Types.InputMaybe<Types.Scalars['Int']>;
  platforms: Array<Types.Platform> | Types.Platform;
}>;


export type UpdateStampingPlatformSettingMutation = { __typename?: 'Mutation', setting: { __typename?: 'settingMutations', trackingSetting: { __typename?: 'trackingSettingMutations', updateStampingPlatformSetting: (
        { __typename?: 'InheritableSettings' }
        & InheritableSettingsFragment
      ) } } };


export const UpdateStampingPlatformSettingDocument = gql`
    mutation UpdateStampingPlatformSetting($settingEntityType: SettingEntityType!, $entityId: Int, $platforms: [Platform!]!) {
  setting {
    trackingSetting {
      updateStampingPlatformSetting(
        settingEntityType: $settingEntityType
        entityId: $entityId
        platforms: $platforms
      ) {
        ...InheritableSettings
      }
    }
  }
}
    ${InheritableSettingsFragmentDoc}`;
export type UpdateStampingPlatformSettingMutationFn = Apollo.MutationFunction<UpdateStampingPlatformSettingMutation, UpdateStampingPlatformSettingMutationVariables>;

/**
 * __useUpdateStampingPlatformSettingMutation__
 *
 * To run a mutation, you first call `useUpdateStampingPlatformSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStampingPlatformSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStampingPlatformSettingMutation, { data, loading, error }] = useUpdateStampingPlatformSettingMutation({
 *   variables: {
 *      settingEntityType: // value for 'settingEntityType'
 *      entityId: // value for 'entityId'
 *      platforms: // value for 'platforms'
 *   },
 * });
 */
export function useUpdateStampingPlatformSettingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStampingPlatformSettingMutation, UpdateStampingPlatformSettingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStampingPlatformSettingMutation, UpdateStampingPlatformSettingMutationVariables>(UpdateStampingPlatformSettingDocument, options);
      }
export type UpdateStampingPlatformSettingMutationHookResult = ReturnType<typeof useUpdateStampingPlatformSettingMutation>;
export type UpdateStampingPlatformSettingMutationResult = Apollo.MutationResult<UpdateStampingPlatformSettingMutation>;
export type UpdateStampingPlatformSettingMutationOptions = Apollo.BaseMutationOptions<UpdateStampingPlatformSettingMutation, UpdateStampingPlatformSettingMutationVariables>;