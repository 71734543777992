import * as Types from '../generated/graphql';

import { EmployeeFragment } from './employee.generated';
import { gql } from '@apollo/client';
import { EmployeeFragmentDoc } from './employee.generated';
export type DepartmentFragment = { __typename?: 'Department', name: string, departmentId: number, companyId: number, headEmployeeId: number, parentId?: number | null, employees: Array<(
    { __typename?: 'Employee' }
    & EmployeeFragment
  )> };

export const DepartmentFragmentDoc = gql`
    fragment Department on Department {
  name
  departmentId
  companyId
  headEmployeeId
  parentId
  employees {
    ...Employee
  }
}
    ${EmployeeFragmentDoc}`;