import * as Types from '../../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ListEmployeeVacationsQueryVariables = Types.Exact<{
  userId: Types.Scalars['Int'];
}>;


export type ListEmployeeVacationsQuery = { __typename?: 'Query', employee: { __typename?: 'employeeQueries', vacation: { __typename?: 'vacationQueries', listEmployeeVacations: Array<{ __typename?: 'EmployeeVacation', userId: number, startYear: number, endYear?: number | null, vacationMinutes: number, remainingVacationMinutes: number, automaticTransfer: boolean, comment?: string | null, isDeleted: boolean }>, listEmployeeVacationTransfers: Array<{ __typename?: 'EmployeeVacationTransfer', year: number, expirationDate?: any | null, vacationMinutes: number }> } } };


export const ListEmployeeVacationsDocument = gql`
    query ListEmployeeVacations($userId: Int!) {
  employee {
    vacation {
      listEmployeeVacations(userId: $userId) {
        userId
        startYear
        endYear
        vacationMinutes
        remainingVacationMinutes
        automaticTransfer
        comment
        isDeleted
      }
      listEmployeeVacationTransfers(userId: $userId) {
        year
        expirationDate
        vacationMinutes
      }
    }
  }
}
    `;

/**
 * __useListEmployeeVacationsQuery__
 *
 * To run a query within a React component, call `useListEmployeeVacationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListEmployeeVacationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListEmployeeVacationsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useListEmployeeVacationsQuery(baseOptions: Apollo.QueryHookOptions<ListEmployeeVacationsQuery, ListEmployeeVacationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListEmployeeVacationsQuery, ListEmployeeVacationsQueryVariables>(ListEmployeeVacationsDocument, options);
      }
export function useListEmployeeVacationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListEmployeeVacationsQuery, ListEmployeeVacationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListEmployeeVacationsQuery, ListEmployeeVacationsQueryVariables>(ListEmployeeVacationsDocument, options);
        }
export type ListEmployeeVacationsQueryHookResult = ReturnType<typeof useListEmployeeVacationsQuery>;
export type ListEmployeeVacationsLazyQueryHookResult = ReturnType<typeof useListEmployeeVacationsLazyQuery>;
export type ListEmployeeVacationsQueryResult = Apollo.QueryResult<ListEmployeeVacationsQuery, ListEmployeeVacationsQueryVariables>;