import {ContentHeader} from "../../components";
import React, {useEffect, useState} from "react";
import {Box, Button, Card, CardContent, IconButton, Link, Menu, MenuItem, Skeleton} from "@mui/material";
import {DndProvider} from "react-dnd";
import {getBackendOptions, MultiBackend, Tree} from "@minoru/react-dnd-treeview";
import DomainIcon from '@mui/icons-material/Domain';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {useNavigate} from "react-router-dom";
import {useDepartmentCompanyInformationQuery} from "./departmentCompanyInformation.generated";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EmployeeContextMenu from "../../components/molecules/EmployeeContextMenu";
import DeleteDepartmentDialog from "../../components/organisms/DeleteDepartmentDialog/DeleteDepartmentDialog";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useSetEmployeeDepartmentMutation} from "./setEmployeeDepartment.generated";
import {toast} from "react-toastify";
import {useUpdateDepartmentParentMutation} from "./updateDepartmentParent.generated";
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {hasAtLeastSubscription} from "../../utils/subscription";
import {UpgradeSubscriptionScreen} from "../../components/organisms/UpgradeSubscriptionScreen";
import {ErrorScreen} from "../../components/organisms/ErrorScreen";
import { EmployeeFragment } from "../../fragments/employee.generated";


interface ListItem {
    id: string;
    parent: string;
    text: string;
    droppable: boolean;
    data: {
        type: 'employee' | 'department';
        itemId: number;
        isHead?: boolean;
        employee?: EmployeeFragment;
    }
}

const CompanyPage = () => {
    const user = useSelector((state: RootState) => state.auth.currentUser);

    const navigate = useNavigate();

    const [setEmployeeDepartmentMutation] = useSetEmployeeDepartmentMutation();
    const [updateDepartmentParentMutation] = useUpdateDepartmentParentMutation();

    const [departments, setDepartments] = useState<ListItem[] | undefined>(undefined);

    const {data, loading, error, refetch} = useDepartmentCompanyInformationQuery({fetchPolicy: 'no-cache'});

    useEffect(() => {
        if (!loading && data) {
            const graphQlDepartments: ListItem[] | undefined = data?.company.getCompanyInformation.departments.flatMap((department) => {
                const departmentId = `department-${department.departmentId}`;

                const employees: ListItem[] = department.employees.map((employee) => ({
                    id: `employee-${employee.userId}`,
                    parent: departmentId,
                    text: `${employee.name} ${employee.surname} ${employee.accountStatus === 'archived' ? '(Archiviert)' : ''}`,
                    droppable: false,
                    data: {
                        type: 'employee',
                        itemId: employee.userId,
                        isHead: employee.userId === department.headEmployeeId,
                        employee: employee
                    }
                }));

                return [...employees, {
                    id: departmentId,
                    parent: department.parentId ? `department-${department.parentId}` : 'initial',
                    text: department.name,
                    droppable: true,
                    data: {
                        type: 'department',
                        itemId: department.departmentId
                    }
                }];
            });

            setDepartments(graphQlDepartments);
        }
    }, [data, loading]);

    const handleDrop = async (treeList: any, {dragSource, dropTarget}: any) => {
        const oldTreeList = departments;

        const dragItemId = dragSource.data.itemId;
        const dropDepartmentId = dropTarget.data.itemId;

        if (!dragItemId || !dropDepartmentId) {
            return;
        }

        if (dragSource.data.type === 'employee' && dragSource.data.isHead) {
            toast.error('Bitte ernenne zunächst einen anderen Abteilungsleiter, bevor du diesen Mitarbeiter verschieben kannst');
            return;
        }

        setDepartments(treeList);

        let graphQLData;
        let graphQLErrors;

        if (dragSource.data.type === 'department') {
            const {data, errors} = await updateDepartmentParentMutation({
                variables: {
                    departmentId: dragItemId,
                    parentId: dropDepartmentId
                }
            });

            graphQLData = data;
            graphQLErrors = errors;
        } else {
            const {data, errors} = await setEmployeeDepartmentMutation({
                variables: {
                    employeeId: dragItemId,
                    departmentId: dropDepartmentId
                }
            });

            graphQLData = data;
            graphQLErrors = errors;
        }

        if (graphQLErrors && !graphQLData) {
            setDepartments(oldTreeList);

            toast.error('Es ist ein Fehler aufgetreten');
        }
    }

    if(!hasAtLeastSubscription(user, 'expert')) {
        return <UpgradeSubscriptionScreen/>;
    }

    if (error) {
        return <div>
            <ContentHeader title="Deine Abwesenheiten"/>
            <Card>
                <ErrorScreen/>
            </Card>
        </div>;
    }

    return <Box>
        <ContentHeader title="Unternehmen"/>

        <Card>
            <CardContent>
                <Box sx={{display: 'flex', justifyContent: 'end'}}>
                    <Button variant='contained' onClick={() => navigate('/department/create')}>Neue Abteilung</Button>
                </Box>
            </CardContent>
            <CardContent>
                {(!departments && loading) &&
                    <>
                        <Skeleton height='50px'/>
                        <Skeleton height='50px' sx={{marginLeft: '40px'}}/>
                        <Skeleton height='50px' sx={{marginLeft: '40px'}}/>
                        <Skeleton height='50px' sx={{marginLeft: '40px'}}/>
                    </>
                }
                {departments &&
                    <DndProvider backend={MultiBackend} options={getBackendOptions()}>
                        <Tree
                            initialOpen={true}
                            sort={false}
                            tree={departments}
                            rootId='initial'
                            onDrop={handleDrop}
                            render={(node, {depth, isOpen, onToggle}) => {
                                return <Box
                                    className='mtt-department-row'
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Box sx={{display: 'flex', gap: 2, alignItems: 'center', justifyContent: 'center'}}>
                                        {node.droppable && (
                                            <Box sx={{marginRight: 1, color: '#3B3C40'}} component='a' href='#'
                                                 onClick={onToggle}>
                                                {isOpen ? <KeyboardArrowDownIcon/> : <KeyboardArrowRightIcon/>}
                                            </Box>
                                        )}
                                        <Box component={Link}
                                             onClick={() => {
                                                 if (node.data) {
                                                     if (node.data.type === 'department') {
                                                         if (node.parent === 'initial') {
                                                             navigate('/company-settings/settings');
                                                         } else {
                                                             navigate(`/department/${node.data.itemId}`, {replace: false});
                                                         }
                                                     } else {
                                                         navigate(`/employee/${node.data.itemId}`);
                                                     }
                                                 }
                                             }}
                                             sx={{
                                                 display: 'flex',
                                                 alignItems: 'center',
                                                 my: 1,
                                                 gap: 1,
                                                 color: '#3B3C40',
                                                 textDecoration: 'none',
                                                 ':hover': {
                                                     textDecoration: 'none',
                                                     cursor: 'pointer'
                                                 },
                                                 ':focus': {
                                                     textDecoration: 'none'
                                                 }
                                             }}
                                        >
                                            {node.data?.type === 'department' ? <DomainIcon/> : (
                                                node.data?.isHead ? <FontAwesomeIcon icon={solid('crown')}/> :
                                                    <AccountCircleIcon/>
                                            )}
                                            {node.text}
                                        </Box>
                                    </Box>
                                    <Box>
                                        {node.data?.type === 'department' ? (
                                            <DepartmentContextMenu
                                                departmentId={node.data!.itemId}
                                                departmentName={node.text}
                                                onUpdate={refetch}
                                            />
                                        ) : (
                                            <EmployeeContextMenu employee={node.data!.employee!} employeeId={node.data!.itemId} onUpdate={refetch}/>
                                        )}
                                    </Box>
                                </Box>;
                            }}
                            enableAnimateExpand={true}
                            classes={{
                                dropTarget: 'mtt-department-drop-target',
                                listItem: 'mtt-department-list-item',
                                container: 'mtt-department-container',
                                root: 'mtt-department-root-container',
                                placeholder: 'placeholder'
                            }}
                        />
                    </DndProvider>
                }
            </CardContent>
        </Card>

    </Box>;
}

const DepartmentContextMenu = ({
                                   departmentId,
                                   departmentName,
                                   onUpdate
                               }: { departmentId: number, departmentName: string, onUpdate: () => void }) => {
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [isDeleteDepartmentOpen, setDeleteDepartmentOpen] = useState(false);

    const onClose = (deleted: boolean) => {
        setDeleteDepartmentOpen(false);
        if (deleted) {
            onUpdate();
        }
    }

    return <>
        <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            sx={{paddingLeft: '2px', paddingRight: '2px', color: '#212529'}}
        >
            <MoreVertIcon/>
        </IconButton>
        <Menu
            id="long-menu"
            MenuListProps={{
                'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
        >
            <MenuItem onClick={() => {
                handleClose();
                navigate(`/department/${departmentId}`);
            }}>Bearbeiten</MenuItem>
            <MenuItem onClick={() => {
                handleClose();
                setDeleteDepartmentOpen(true);
            }}>Löschen</MenuItem>
        </Menu>
        <DeleteDepartmentDialog
            open={isDeleteDepartmentOpen}
            onClose={onClose}
            departmentId={departmentId}
            departmentName={departmentName}
        />
    </>;
}

export default CompanyPage;
