import {AbsenceRowFragment} from "../../fragments/absenceRow.generated";
import {Grid, IconButton, Link, Menu, MenuItem, Stack, SxProps, Theme, Typography} from "@mui/material";
import {DateTime} from "luxon";
import WorkingTimeText from "./WorkingTimeText";
import React from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {absenceTypeToString, dateToWeekdayString} from "../../utils/display";
import {useNavigate} from "react-router-dom";
import {WorkingTimeByDayRowColumn} from "./WorkingTimeByDayRow";
import {useTranslation} from "react-i18next";

const gridItemStyle: SxProps<Theme> = (theme) => ({
    paddingTop: '0 !important',
    display: 'flex',
    alignItems: 'center'
});

const linkNoDecorationStyle: SxProps<Theme> = {
    textDecoration: 'none',
    color: '#333333',
    ':hover': {
        textDecoration: 'none',
        color: '#333333',
    },
    ':focus': {
        textDecoration: 'none',
        color: '#333333',
    }
};

export interface AbsenceRowProps {
    readonly absenceRow: AbsenceRowFragment;
    readonly columns?: WorkingTimeByDayRowColumn[];
}

const AbsenceRow = ({absenceRow, columns}: AbsenceRowProps) => {
    const {t} = useTranslation();

    const navigate = useNavigate();

    const date = DateTime.fromISO(absenceRow.date);
    const dateString = date.toFormat('dd.MM.yyyy');
    const weekDayString = dateToWeekdayString(date);

    const isCompactRow = (columns?.length ?? 6) <= 4;

    return <>
        <Grid container spacing={1} sx={{
            backgroundColor: '#F8FAFE',
            marginBottom: '1rem',
            alignItems: 'center',
            borderRadius: '10px',
            minHeight: '50px',
            marginLeft: 0,
            width: '100%',
            ':hover': {
                backgroundColor: '#E5EDFA'
            }
        }}>
            <Grid item xs={11} md={isCompactRow ? 11 : 11.5} sx={{paddingTop: '0 !important'}}>
                <Link href='#' sx={linkNoDecorationStyle} onClick={() => navigate(`/absence/${absenceRow.absenceRequestId}`)}>
                    <Grid container>
                        <Grid item sm={1} md={isCompactRow ? 1 : 0.5} display={{xs: 'none', sm: 'flex'}} sx={gridItemStyle}></Grid>
                        <Grid item xs sx={{...gridItemStyle, paddingY: 0.5}}>
                            <Stack>
                                <Typography fontSize={20}>{t(weekDayString)}</Typography>
                                <Typography fontSize={12}>{dateString}</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={2} md={isCompactRow ? 1.5 : 2} sx={gridItemStyle}>
                            {t(absenceTypeToString(absenceRow.absenceType))}
                        </Grid>
                        <Grid item xs={2.0} sm={isCompactRow ? 1 : 3.5} md={isCompactRow ? 2.2 : 5.7}></Grid>
                        <Grid item xs sx={gridItemStyle}>
                            <WorkingTimeText workingTime={absenceRow.workingTime}/>
                        </Grid>
                    </Grid>
                </Link>
            </Grid>
            <Grid item xs={1} md={isCompactRow ? 1 : 0.5} sx={{...gridItemStyle, paddingLeft: '0 !important', paddingTop: '0 !important'}}>
                <ContextMenu onEdit={() => navigate(`/absence/${absenceRow.absenceRequestId}`)}/>
            </Grid>
        </Grid>
    </>;
};

const ContextMenu = ({onEdit}: { onEdit: () => void }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                sx={{paddingLeft: '2px', paddingRight: '2px', color: '#212529'}}
            >
                <MoreVertIcon/>
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={() => {
                    handleClose();
                    onEdit();
                }
                }>
                    Bearbeiten
                </MenuItem>
            </Menu>
        </>
    );
};

export default AbsenceRow;
