import {configureStore} from '@reduxjs/toolkit';

import {createLogger} from 'redux-logger';
import {authSlice} from './reducers/auth';
import {uiSlice} from './reducers/ui';
import {companySettingsSlice} from './reducers/companySetting';

const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    ui: uiSlice.reducer,
    companySettings: companySettingsSlice.reducer
  },
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware().concat(createLogger())
  ]
});

export type RootState = ReturnType<typeof store.getState>;

export default store;
