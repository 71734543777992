import * as Types from '../../../../generated/graphql';

import { CalendarEvent_AbsenceCalendarEvent_Fragment, CalendarEvent_NoWorkingDayCalendarEvent_Fragment, CalendarEvent_WorkingTimeCalendarEvent_Fragment } from '../../../calendar/calendarEvents.generated';
import { gql } from '@apollo/client';
import { CalendarEventFragmentDoc } from '../../../calendar/calendarEvents.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DepartmentCalendarEventsQueryVariables = Types.Exact<{
  departmentId: Types.Scalars['Int'];
  startDate: Types.Scalars['DateType'];
  endDate: Types.Scalars['DateType'];
}>;


export type DepartmentCalendarEventsQuery = { __typename?: 'Query', calendar: { __typename?: 'calendarQueries', byDepartment: Array<(
      { __typename?: 'AbsenceCalendarEvent' }
      & CalendarEvent_AbsenceCalendarEvent_Fragment
    ) | (
      { __typename?: 'NoWorkingDayCalendarEvent' }
      & CalendarEvent_NoWorkingDayCalendarEvent_Fragment
    ) | (
      { __typename?: 'WorkingTimeCalendarEvent' }
      & CalendarEvent_WorkingTimeCalendarEvent_Fragment
    )> } };


export const DepartmentCalendarEventsDocument = gql`
    query DepartmentCalendarEvents($departmentId: Int!, $startDate: DateType!, $endDate: DateType!) {
  calendar {
    byDepartment(
      departmentId: $departmentId
      startDate: $startDate
      endDate: $endDate
    ) {
      ...CalendarEvent
    }
  }
}
    ${CalendarEventFragmentDoc}`;

/**
 * __useDepartmentCalendarEventsQuery__
 *
 * To run a query within a React component, call `useDepartmentCalendarEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDepartmentCalendarEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDepartmentCalendarEventsQuery({
 *   variables: {
 *      departmentId: // value for 'departmentId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useDepartmentCalendarEventsQuery(baseOptions: Apollo.QueryHookOptions<DepartmentCalendarEventsQuery, DepartmentCalendarEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DepartmentCalendarEventsQuery, DepartmentCalendarEventsQueryVariables>(DepartmentCalendarEventsDocument, options);
      }
export function useDepartmentCalendarEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DepartmentCalendarEventsQuery, DepartmentCalendarEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DepartmentCalendarEventsQuery, DepartmentCalendarEventsQueryVariables>(DepartmentCalendarEventsDocument, options);
        }
export type DepartmentCalendarEventsQueryHookResult = ReturnType<typeof useDepartmentCalendarEventsQuery>;
export type DepartmentCalendarEventsLazyQueryHookResult = ReturnType<typeof useDepartmentCalendarEventsLazyQuery>;
export type DepartmentCalendarEventsQueryResult = Apollo.QueryResult<DepartmentCalendarEventsQuery, DepartmentCalendarEventsQueryVariables>;