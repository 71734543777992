import React from 'react';
import {MTTProduct} from './MTTProduct';
import {SxProps, Theme, Typography} from '@mui/material';

export const MTTProductProfessional = ({
                                           selected,
                                           isFirstSelect,
                                           onSelect,
                                           loading
                                       }: {
    selected?: boolean;
    isFirstSelect?: boolean;
    onSelect?: () => void;
    loading?: boolean;
}) => {
    const textStyle: SxProps<Theme> = () => ({
        color: '#3B3C40',
        marginBottom: '0.5rem',
        fontSize: '18px'
    });

    return (
        <MTTProduct
            stripeSubscription="professional"
            name="PROFESSIONAL"
            price="2,49"
            selected={selected}
            isFirstSelect={isFirstSelect}
            onSelect={onSelect}
            loading={loading}
        >
            <Typography sx={textStyle}>alles aus STARTER</Typography>
            <Typography sx={textStyle}>Arbeitszeitmodelle</Typography>
            <Typography sx={textStyle}>Konto für Überstunden</Typography>
            <Typography sx={textStyle}>Statistiken für Abwesenheiten</Typography>
            <Typography sx={textStyle}>Arbeitsort bei Stundenbuchungen</Typography>
            <Typography sx={textStyle}>Kommentare bei Stundenbuchungen</Typography>
            <Typography sx={textStyle}>Erweiterte Einstellungsmöglichkeiten</Typography>
        </MTTProduct>
    );
};
