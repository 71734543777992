import {Avatar} from "@mui/material";
import React from "react";

export interface UserInformation {
    name: string,
    surname: string
}

export interface UserAvatarProps {
    readonly user: string | UserInformation;
    readonly size?: number;
    readonly fontSize?: number;
}

export const UserAvatar = ({user, size, fontSize}: UserAvatarProps) => {
    let text;
    if (typeof user === 'string') {
        text = user;
    } else {
        const userInformation = user as UserInformation;
        text = `${userInformation.name[0]}${userInformation.surname[0]}`;
    }

    return <Avatar sx={{width: size ?? 30, height: size ?? 30, fontSize: fontSize ?? 14}}>{text}</Avatar>;
}
