import React from 'react';

import {Navigate, Route, Routes} from 'react-router-dom';
import {toast, ToastContainer} from 'react-toastify';

import PublicRoute from './routes/PublicRoute';
import PrivateRoute from './routes/PrivateRoute';
import {setContext} from '@apollo/client/link/context';
import {ApolloClient, ApolloProvider, from, HttpLink, InMemoryCache} from '@apollo/client';
import {DateTime} from 'luxon';
import {decodeToken} from 'react-jwt';
import {AccessToken} from './store/reducers/auth';
import {
    CreateAccessTokenDocument,
    CreateAccessTokenMutation,
    CreateAccessTokenMutationVariables
} from './createAccessToken.generated';
import Login from './modules/login/Login';
import Register from './modules/register/Register';
import RecoverPassword from './modules/recover-password/RecoverPassword';
import Main from './modules/main/Main';
import ViewEmployee from './pages/viewemployee/ViewEmployee';
import Employees from './pages/employees/Employees';
import CreateEmployee from './pages/employee/CreateEmployee';
import ForgotPassword from './modules/forgot-password/ForgotPassword';
import CreateReport from './pages/create-report/CreateReport';
import CompanySettingsPage from './pages/CompanySettings/CompanySettingsPage';
import {createTheme, ThemeProvider} from '@mui/material';
import HoursPage from "./pages/hours/HoursPage";
import DashboardPage from "./pages/dashboard/Dashboard";
import VerifyPage from "./modules/verify/Verify";
import AdminRoute from "./routes/AdminRoute";
import ManualInsertPage from "./pages/ManualInsert/ManualInsert";
import './App.css';
import CreateDepartmentPage from "./pages/CreateDepartment/CreateDepartmentPage";
import ViewDepartment from "./pages/ViewDepartment/ViewDepartment";
import CompanyPage from "./pages/company/CompanyPage";
import CreateAbsencePage from "./pages/CreateAbsence/CreateAbsencePage";
import AbsencesPage from "./pages/absences/AbsencesPage";
import ViewAbsenceRequestPage from "./pages/ViewAbsence/ViewAbsenceRequestPage";
import SelectSubscriptionPage from "./pages/SelectSubscription/SelectSubscriptionPage";
import {onError} from "@apollo/client/link/error";
import Cookies from 'js-cookie';
import {CalendarPage} from './pages/calendar/CalendarPage';
import FlagProvider, {IConfig} from "@unleash/proxy-client-react";
import { ViewWorkPlace } from './pages/ViewWorkPlace/ViewWorkPlace';
import { InternalRoute } from './routes/InternalRoute';
import { ImitationPage } from './pages/imitation/ImitationPage';
import { ImitationCompanyPage } from './pages/imitation/company/ImitationCompanyPage';

const App = () => {
    const errorLink = onError((error) => {
        if (error.networkError) {
            const operationType = 'operation' in error.operation.query.definitions[0] ? error.operation.query.definitions[0].operation : undefined;
            const operationName = 'name' in error.operation.query.definitions[0] ? error.operation.query.definitions[0].name : undefined;
            if (operationType === 'mutation' && operationName?.value !== 'CreateAccessToken') {
                toast.error('Es ist etwas schief gelaufen. Bitte überprüfe deine Netzwerkverbindung');
            }
        }
    });

    const httpLink = new HttpLink({
        uri:
            process.env.NODE_ENV === 'production'
                ? '/graphql'
                : 'http://localhost:8888/graphql'
    });

    const authLink = setContext(async (req, {headers, operation}) => {
        // get the authentication token from local storage if it exists
        const token = localStorage.getItem('token');
        const refreshToken = localStorage.getItem('refreshToken');

        if (token) {
            const decodedToken = decodeToken<AccessToken>(token);

            if (decodedToken) {
                const expireSeconds = decodedToken.exp;
                const expireDate = DateTime.fromMillis(expireSeconds * 1000);

                const now = DateTime.now();

                const diff = expireDate.diff(now);

                // AccessToken is valid for 5 seconds
                if (diff.milliseconds > 5000) {
                    return {
                        headers: {
                            ...headers,
                            authorization: token ? `${token}` : '',
                            'MTT-Platform': 'web'
                        }
                    };
                } else if (!req.operationName?.includes('CreateAccessToken')) {
                    if (refreshToken) {
                        const {data} = await client.mutate<
                            CreateAccessTokenMutation,
                            CreateAccessTokenMutationVariables
                        >({
                            mutation: CreateAccessTokenDocument,
                            variables: {
                                refreshToken: refreshToken
                            }
                        });

                        if (data) {
                            const information = data.employee.createAccessToken;

                            localStorage.setItem('token', information.accessToken);
                            localStorage.setItem('refreshToken', information.refreshToken);

                            Cookies.set('token', information.accessToken, {expires: 1});
                            return {
                                headers: {
                                    ...headers,
                                    authorization: token ? `${information.accessToken}` : '',
                                    'MTT-Platform': 'web'
                                }
                            };
                        }
                    }
                }
            }
        }

        return {
            headers: headers,
            'MTT-Platform': 'web'
        };
    });

    const client = new ApolloClient({
        link: from([errorLink, authLink, httpLink]),
        cache: new InMemoryCache({
            possibleTypes: {
                "TimeUnion": [
                    "WorkingTimeByDay",
                    "AbsenceRow"
                ],
                "GrantUserTerminalAccessResponseType": [
                    "GrantUserTerminalAccessEmailSent",
                    "GrantUserTerminalAccessInitialPin"
                ],
                "TrackingChangeInterface": [
                    "StartTracking",
                    "StopTracking",
                    "ManualInsert",
                    "EditTracking",
                    "AutomaticBreakCorrection"
                ],
                "TrackingEditor": [
                    "TrackingEditorAdmin",
                    "TrackingEditorUser",
                    "TrackingEditorAutomatic"
                ],
                "CalendarEventInterface": [
                    "AbsenceCalendarEvent",
                    "WorkingTimeCalendarEvent",
                    "NoWorkingDayCalendarEvent"
                ],
                "TrackingClearance": [
                    "AllowedTrackingClearance",
                    "ForbiddenTrackingClearance",
                    "LimitedTrackingClearance"
                ]
            }
        }),
        defaultOptions: {
            query: {
                errorPolicy: 'all',
                fetchPolicy: 'no-cache'
            },
            mutate: {
                errorPolicy: 'all',
                fetchPolicy: 'no-cache'
            }
        }
    });

    const finalTheme = createTheme({
        palette: {
            primary: {
                main: '#0854CF',
                light: '#42a5f5',
                dark: '#1565c0',
                contrastText: '#fff'
            },
            error: {
                main: '#ED4545',
                light: '#ef5350',
                dark: '#c62828',
                contrastText: '#fff'
            },
            success: {
                main: '#38D968'
            },
            warning: {
                main: '#F1C444',
                contrastText: '#fff'
            }
        },
        components: {
            MuiCard: {
                styleOverrides: {
                    root: ({ownerState}) => ({
                        boxShadow: '0 3px 10px rgba(0,0,0,.161)',
                        border: 'none',
                        borderRadius: '10px'
                    })
                }
            }
        }
    });

    const unleashConfig: IConfig = {
        url: 'https://feature.mytimetracker.de/api/frontend',
        appName: 'MyTimeTracker Web',
        clientKey: process.env.REACT_APP_UNLEASH_CLIENT_KEY ?? '',
        refreshInterval: 30,
        environment: process.env.NODE_ENV
    };

    return (
        <FlagProvider config={unleashConfig}>
            <ThemeProvider theme={finalTheme}>
                <ApolloProvider client={client}>
                    <Routes>
                        <Route path="/login" element={<PublicRoute/>}>
                            <Route path="/login" element={<Login/>}/>
                        </Route>
                        <Route path="/register" element={<PublicRoute/>}>
                            <Route path="/register" element={<Register/>}/>
                        </Route>
                        <Route path="/forgot-password" element={<PublicRoute/>}>
                            <Route path="/forgot-password" element={<ForgotPassword/>}/>
                        </Route>
                        <Route path="/recover-password" element={<PublicRoute/>}>
                            <Route
                                path="/recover-password/:token"
                                element={<RecoverPassword/>}
                            />
                        </Route>
                        <Route
                            path="/verify/:token"
                            element={<VerifyPage/>}
                        />
                        <Route path="/" element={<PrivateRoute/>}>
                            <Route path="/" element={<Main/>}>
                                <Route path="/" element={<DashboardPage/>}/>
                                <Route path="/dashboard" element={<DashboardPage/>}/>
                                <Route path="/hours" element={<HoursPage/>}/>
                                <Route path="/manual-insert" element={<ManualInsertPage/>}/>
                                <Route path="/absence/create" element={<CreateAbsencePage/>}/>
                                <Route path="/absences" element={<AbsencesPage/>}/>
                                <Route path="/absence/:id" element={<ViewAbsenceRequestPage/>}>
                                    <Route path="administration" element={<ViewAbsenceRequestPage/>}/>
                                </Route>
                                <Route path="/calendar" element={<CalendarPage/>}/>
                            </Route>
                        </Route>
                        <Route path="/" element={<AdminRoute/>}>
                            <Route path="/" element={<Main/>}>
                                <Route path="/select-subscription" element={<SelectSubscriptionPage/>}/>
                                <Route path="/employee/create" element={<CreateEmployee/>}/>
                                <Route path="/employee/:id" element={<ViewEmployee/>}>
                                    <Route path="administration" element={<ViewEmployee/>}/>
                                    <Route path="hours" element={<ViewEmployee/>}/>
                                    <Route path="time-schedule" element={<ViewEmployee/>}/>
                                    <Route path="absences" element={<ViewEmployee/>}/>
                                    <Route path="vacation" element={<ViewEmployee/>}/>
                                    <Route path="settings" element={<ViewEmployee/>}/>
                                </Route>
                                <Route path="/report/create" element={<CreateReport/>}/>
                                <Route path="/company-settings" element={<CompanySettingsPage/>}>
                                    <Route path="settings" element={<CompanySettingsPage/>}/>
                                    <Route path="work-places" element={<CompanySettingsPage/>}/>
                                    <Route path="subscription" element={<CompanySettingsPage/>}/>
                                    <Route path="tablet-terminal" element={<CompanySettingsPage/>}/>
                                </Route>
                                <Route path="/employees" element={<Employees/>}/>
                                <Route path="/company" element={<CompanyPage/>}/>
                                <Route path="/department/create" element={<CreateDepartmentPage/>}/>
                                <Route path="/department/:id" element={<ViewDepartment/>}>
                                    <Route path="administration" element={<ViewDepartment/>}/>
                                    <Route path="settings" element={<ViewDepartment/>}/>
                                    <Route path="calendar" element={<ViewDepartment/>}/>
                                </Route>
                                <Route path="/manual-insert/:id" element={<ManualInsertPage/>}/>
                                <Route path="/absence/create/:id" element={<CreateAbsencePage/>}/>
                                <Route path="/work-place/:id" element={<ViewWorkPlace/>}>
                                    <Route path="settings" element={<ViewWorkPlace/>}/>
                                    <Route path="holidays" element={<ViewWorkPlace/>}/>
                                    <Route path="company-vacation" element={<ViewWorkPlace/>}/>
                                </Route>
                            </Route>
                        </Route>
                        <Route path="/" element={<InternalRoute/>}>
                            <Route path="/" element={<Main/>}>
                                <Route path="/imitation" element={<ImitationPage/>}/>
                                <Route path="/imitation/:id" element={<ImitationCompanyPage/>}/>
                            </Route>
                        </Route>
                        <Route path='*' element={<Navigate to='/login'/>}/>
                    </Routes>
                    <ToastContainer
                        autoClose={3000}
                        draggable={false}
                        position="top-right"
                        hideProgressBar={false}
                        newestOnTop
                        closeOnClick
                        rtl={false}
                        pauseOnHover
                    />
                </ApolloProvider>
            </ThemeProvider>
        </FlagProvider>
    );
};

export default App;
