import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteEmployeeVacationMutationVariables = Types.Exact<{
  userId: Types.Scalars['Int'];
  startYear: Types.Scalars['Int'];
}>;


export type DeleteEmployeeVacationMutation = { __typename?: 'Mutation', employee: { __typename?: 'employeeMutations', vacation: { __typename?: 'vacationMutations', deleteEmployeeVacation: any } } };


export const DeleteEmployeeVacationDocument = gql`
    mutation DeleteEmployeeVacation($userId: Int!, $startYear: Int!) {
  employee {
    vacation {
      deleteEmployeeVacation(userId: $userId, startYear: $startYear)
    }
  }
}
    `;
export type DeleteEmployeeVacationMutationFn = Apollo.MutationFunction<DeleteEmployeeVacationMutation, DeleteEmployeeVacationMutationVariables>;

/**
 * __useDeleteEmployeeVacationMutation__
 *
 * To run a mutation, you first call `useDeleteEmployeeVacationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEmployeeVacationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEmployeeVacationMutation, { data, loading, error }] = useDeleteEmployeeVacationMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      startYear: // value for 'startYear'
 *   },
 * });
 */
export function useDeleteEmployeeVacationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEmployeeVacationMutation, DeleteEmployeeVacationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteEmployeeVacationMutation, DeleteEmployeeVacationMutationVariables>(DeleteEmployeeVacationDocument, options);
      }
export type DeleteEmployeeVacationMutationHookResult = ReturnType<typeof useDeleteEmployeeVacationMutation>;
export type DeleteEmployeeVacationMutationResult = Apollo.MutationResult<DeleteEmployeeVacationMutation>;
export type DeleteEmployeeVacationMutationOptions = Apollo.BaseMutationOptions<DeleteEmployeeVacationMutation, DeleteEmployeeVacationMutationVariables>;