import * as Types from '../generated/graphql';

import { gql } from '@apollo/client';
export type EmployeeFragment = { __typename?: 'Employee', name: string, surname: string, email: string, contractType: Types.ContractType, weeklyHours: number, accountStatus: Types.AccountStatus, userId: number, departmentRole: Types.DepartmentRole, workPlaceId: number };

export const EmployeeFragmentDoc = gql`
    fragment Employee on Employee {
  name
  surname
  email
  contractType
  weeklyHours
  accountStatus
  userId
  departmentRole
  workPlaceId
}
    `;