import React from 'react';
import {MTTProduct} from './MTTProduct';
import {SxProps, Theme, Typography} from '@mui/material';

export const MTTProductExpert = ({
                                     selected,
                                     isFirstSelect,
                                     onSelect,
                                     loading
                                 }: {
    selected?: boolean;
    isFirstSelect?: boolean;
    onSelect?: () => void;
    loading?: boolean;
}) => {
    const textStyle: SxProps<Theme> = () => ({
        color: '#3B3C40',
        marginBottom: '0.5rem',
        fontSize: '18px'
    });

    return (
        <MTTProduct
            stripeSubscription="expert"
            name="EXPERT"
            price="3,79"
            selected={selected}
            isFirstSelect={isFirstSelect}
            onSelect={onSelect}
            loading={loading}
        >
            <Typography sx={textStyle}>alles aus PROFESSIONAL</Typography>
            <Typography sx={textStyle}>Genehmigungen für Abwesenheiten</Typography>
            <Typography sx={textStyle}>Abteilungen und Vorgesetzte</Typography>
            <Typography sx={textStyle}>Teamkalendar</Typography>
            <Typography sx={textStyle}>Abwesenheits-Dashboard</Typography>
            <Typography sx={textStyle}>Urlaubskonto mit Urlaubsmodellen</Typography>
            <Typography sx={textStyle}>Automatischer Pausenabzug möglich</Typography>
        </MTTProduct>
    );
};
