import * as Types from '../../generated/graphql';

import { CompanySettingFragment } from '../../fragments/companySetting.generated';
import { gql } from '@apollo/client';
import { CompanySettingFragmentDoc } from '../../fragments/companySetting.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCompanySettingsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCompanySettingsQuery = { __typename?: 'Query', company: { __typename?: 'companyQueries', getCompanySettings: (
      { __typename?: 'CompanySetting' }
      & CompanySettingFragment
    ) } };


export const GetCompanySettingsDocument = gql`
    query GetCompanySettings {
  company {
    getCompanySettings {
      ...CompanySetting
    }
  }
}
    ${CompanySettingFragmentDoc}`;

/**
 * __useGetCompanySettingsQuery__
 *
 * To run a query within a React component, call `useGetCompanySettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanySettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanySettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanySettingsQuery(baseOptions?: Apollo.QueryHookOptions<GetCompanySettingsQuery, GetCompanySettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompanySettingsQuery, GetCompanySettingsQueryVariables>(GetCompanySettingsDocument, options);
      }
export function useGetCompanySettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanySettingsQuery, GetCompanySettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompanySettingsQuery, GetCompanySettingsQueryVariables>(GetCompanySettingsDocument, options);
        }
export type GetCompanySettingsQueryHookResult = ReturnType<typeof useGetCompanySettingsQuery>;
export type GetCompanySettingsLazyQueryHookResult = ReturnType<typeof useGetCompanySettingsLazyQuery>;
export type GetCompanySettingsQueryResult = Apollo.QueryResult<GetCompanySettingsQuery, GetCompanySettingsQueryVariables>;